import React, { useRef } from 'react';
import styled from 'styled-components';

const CustomInputStyled = styled.div`
	position: relative;
    min-height: 30px;

    span.custom-input-label {
        position: absolute;
        left: 5px;
        z-index: 2;
        font-size: 9px;
        color: var(--bs-primary);
        user-select: none;
    }

    input.custom-input-input {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        max-height: auto !important;
        z-index: 1;
        padding-top: 20px;
        padding-bottom: 10px;
        padding-left: 5px;
        font-size: 11px !important;
    }
`;

export default function CustomInput(props) {
    let inputRef = useRef(null);

    let inputProps = {...props};
    if ( inputProps.hasOwnProperty('className') ) inputProps.className += ' custom-input-input';
    if ( inputProps.hasOwnProperty('placeholder') ) delete inputProps.placeholder;
    if ( inputProps.hasOwnProperty('label') ) delete inputProps.label;

    let label = props.placeholder ?? null;
    if ( props.label ) label = props.label ?? null;
	
	return (
		<CustomInputStyled className="custom-input">
            {label && <span className="custom-input-label" onClick={() => inputRef.current.focus()}>{label}</span>}
			<input ref={inputRef} {...inputProps} />
		</CustomInputStyled>
	);
}