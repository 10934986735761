import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader } from 'helpers/generic';

let axiosCancelToken = null;

// Get proxy callback from parent opener to make updates if possible
let parentWindowProxyCallback = window.opener?.PopupProxyCallback;

export default function CustomFieldsForm(props) {
	const navigate = useNavigate();
	const params = useParams();
	const type = params.type;

	let [data, setData] = useState({
		type: type,
		fieldtype: 'text',
		order: 0,
		name: ''
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				await axios.get('/api/manager/custom-fields/'+type+'/get/' + params.id, {
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(response.data);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		} else {
			const getLastOrder = () => {
				axios.get('/api/manager/custom-fields/'+type+'/get-last-order', {
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(data => ({...data, order: response.data.order}));
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});
			}
			getLastOrder();
		}
	}, [type, params.id]);

	const deleteField = () => {
		const c = window.confirm('¿Quieres eliminar este campo?');
		if ( !c ) return;

		axios.delete('/api/manager/custom-fields/'+type+'/delete/' + data.id, {}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Campo borrado')
	    	navigate('/custom-fields/' + type);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		}).then(() => {
			if ( parentWindowProxyCallback ) parentWindowProxyCallback();
		});	
	}

	const saveData = (goBack = true) => {
		setErrors({});

		axios.post('/api/manager/custom-fields/'+type+'/save' + (data.id ? '/' + data.id : ''), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			setData({...response.data.field});
			if ( goBack ) navigate('/custom-fields/' + type);
			else {
		    	navigate('/custom-fields/'+type+'/edit/' + response.data.field.id);
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		}).then(() => {
			if ( parentWindowProxyCallback ) parentWindowProxyCallback();
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	return (
		<PopupLayout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de campo personalizado</h1>
					<button onClick={() => navigate('/custom-fields/' + type)} className="btn btn-sm btn-light ms-auto">Volver</button>
				</div>

				<div className="page-content mb-4">
					<div className="row">
						<div className="col-lg-4">
							<div className="mb-2">
								<label>Nombre</label>
								<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('name', e.target.value)} value={data.name ?? ''} />
								{ errors.name &&
									<div className="invalid-feedback d-block">{ errors.name[0] }</div>
								}
							</div>
						</div>
						<div className="col-lg-4">
							<div className="mb-2">
								<label>Orden</label>
								<input type="number" className="form-control form-control-sm" onChange={(e) => setDataField('order', e.target.value)} value={data.order ?? ''} />
								{ errors.order &&
									<div className="invalid-feedback d-block">{ errors.order[0] }</div>
								}
							</div>
						</div>
						<div className="col-lg-4">
							<div className="mb-2">
								<label>Tipo de campo</label>
								<select className="form-control form-control-sm" onChange={(e) => setDataField('fieldtype', e.target.value)} value={data.fieldtype ?? ''}>
									<option value="text">Texto</option>
									<option value="link">Enlace</option>
								</select>
								{ errors.fieldtype &&
									<div className="invalid-feedback d-block">{ errors.fieldtype[0] }</div>
								}
							</div>
						</div>
					</div>
				</div>

				<footer id="footer-fixed">
					<div className="row h-100 d-flex align-items-center">
						<div className="col-6">
							{ data.id &&
								<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteField()}>Eliminar</button>							
							}
						</div>
						<div className="col-6 text-end py-2 pe-4">
							<React.Fragment>
								<button className="btn btn-sm btn-primary text-white d-inline m-1" onClick={() => saveData(false)}>Guardar</button>							
								<button className="btn btn-sm btn-primary text-white d-inline ms-2 m-1" onClick={() => saveData()}>Guardar y salir</button>
							</React.Fragment>						
						</div>
					</div>
				</footer>
			</section>
		</PopupLayout>
	);
}