import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import moment from 'moment/min/moment-with-locales';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import EmpoweredSelector from 'components/EmpoweredSelector';
import PopupLayout from 'layouts/PopupLayout';
import { openPopupWindow, ucfirst, CristalLoader } from 'helpers/generic';
import { getUser } from 'helpers/user';
import { getCommunity } from 'helpers/community';
import { getRouterBasename } from 'helpers/config';
import useStore from "store";

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';

moment.locale(navigator.language || navigator.userLanguage);

const CalendarWrapper = styled.div`
	position: fixed;
	display: flex;
	flex-direction: column;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	.fc {
		height: 100%;
		position: relative;

		table {
			margin: 0 !important;
		}

		.fc-col-header-cell {
			background: #f0f0f0;
			padding: 3px 6px;

			.fc-col-header-cell-cushion:first-letter {
			 	text-transform:uppercase;
			}
		}

		.fc-daygrid-day-number {
			display: block;
			width: 100%;
			text-decoration: none;
		}

		.fc-event {
			cursor: pointer;
			background: var(--bs-gray-200);
			color: var(--bs-gray-700);
			padding: 5px;

			.fc-daygrid-event-dot {
				display: none;
			}

			.fc-event-time {
				font-weight: 500;
				padding-right: 5px;
			}

			.fc-event-title {
				font-weight: normal;
				white-space: normal;
				line-height: 100%;
			}

			&.passed {
				background: #ff1e175e;
			}
		}
	}	
`;

const Toolbar = styled.div`
	padding: 10px;
`;

const CellCustomHeader = styled.div`
	display: flex;
	width: 100%;

	button {
		color: var(--bs-secondary);
	}

	span {
		margin-left: auto;
	}
`;

let axiosCancelToken = null;

export default function Diary() {
	let calendarRef = useRef();

    const strictMode = useStore(state => state.strictMode);
    const dispatch = useStore(state => state.dispatch);

	let [loading, setLoading] = useState(false);
	let [events, setEvents] = useState([]);
	let [forceReload, setForceReload] = useState(null);
	let [viewOnlyNotFinished, setViewOnlyNotFinished] = useState(true);
	let [dateFrom, setDateFrom] = useState(null);
	let [dateTo, setDateTo] = useState(null);
	let [year, setYear] = useState(parseInt(moment().format('YYYY')));
	let [month, setMonth] = useState({
		id: parseInt(moment().format('M')),
		number: moment().format('M'),
		number_full: moment().format('MM'),
		name: ucfirst(moment().format('MMMM'))
	});
	let [community, setCommunity] = useState({
		id: getCommunity()?.id,
		name: getCommunity()?.name
	});
	let [user, setUser] = useState({
		id: getUser()?.id,
		name: getUser()?.name
	});

	// Disable react strict mode because it is incompatible with FullCalendar
	useEffect(() => {
		if ( strictMode ) dispatch({
			type: 'setStrictMode',
			data: false
		});
	}, [strictMode, dispatch]);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	// Proxy for popup window to update when fields are changed on popup
	useEffect(() => {
		window.PopupProxyCallback = () => {
			setForceReload(Math.random());
		}
	}, []);

	useEffect(() => {
		const loadData = async () => {
			// Check if dates set
			if ( !dateFrom || !dateTo ) return;

			// Show loader
			setLoading(true);

		  	// Load events
			await axios.get('/api/manager/events/list', {
				params: {
					no_paginate: true,
					community_id: community?.id,
					user_id: user?.id,
					finished: viewOnlyNotFinished ? 0 : null,
					date_from: dateFrom,
					date_to: dateTo
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				// Set events to state
				let formatted = response.data.map((el) => {
					el.title = (el.community ? el.community.name + ' | ' : '') + el.name;
					el.start = el.date_from;
					el.end = el.date_to;
					el.borderColor = 'transparent';
					el.className = moment(el.date_to).isBefore(moment()) && !el.finished ? 'passed' : '';
					return el;
				});
		    	setEvents(formatted);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			})

		  	// Hide loader
			setLoading(false);
		}
		loadData();
	}, [forceReload, dateFrom, dateTo, community, user, viewOnlyNotFinished]);

	const loadMonths = (input, callback) => {
		let months = moment.months();
		let formatted = months.filter((el) => ""+el.toLowerCase().indexOf(input.toLowerCase().trim()) !== -1).map((el, idx) => {
			return {
				value: {
					id: idx+1,
					number: idx+1,
					number_full: (() => {
						let number = idx+1;
						if ( number < 10 ) return '0' + number;
						return number;
					})(),
					name: ucfirst(el)
				},
				label: ucfirst(el)
			};
		});
		callback(formatted);
	};

	const loadCommunities = (input, callback) => {
		axios.get('/api/manager/communities/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const loadUsers = (input, callback) => {
		axios.get('/api/manager/users/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();
		e.stopPropagation();

		openPopupWindow(e.currentTarget.href);
	}

	const openEditEventWindow = (e) => {
		openPopupWindow(getRouterBasename() + '/events/edit/' + e.event.id);
	} 

	const openAddEventWindow = (arg) => {
		let dateStr = moment(arg.date).format('YYYY-MM-DD');

		let date_from = dateStr + ' ' + moment().format('HH:mm');
		let date_to = dateStr + ' ' + moment().add(1, 'hours').format('HH:mm');
		openPopupWindow(getRouterBasename() + '/events/add?date_from='+date_from+'&date_to='+date_to);
	} 

	const calendarDatesSetCallback = (e) => {
		setDateFrom(moment(e.start).format('YYYY-MM-DD'));
		setDateTo(moment(e.end).format('YYYY-MM-DD'));
	}

	const setCalendarDate = (date) => {
		let calendarApi = calendarRef.current.getApi();
		let toDate = moment(date).format('YYYY-MM-DD');
		calendarApi.gotoDate(toDate);
	}

	const setCalendarYear = (newYear) => {
		let date = moment(dateFrom).set('year', newYear).set('month', month.number-1).startOf('month').format('YYYY-MM-DD');
		setCalendarDate(date);
		setYear(newYear);
	}

	const setCalendarMonth = (newMonth) => {
		let date = moment(dateFrom).set('year', year).set('month', newMonth.number-1).startOf('month').format('YYYY-MM-DD');
		setCalendarDate(date);
		setMonth({...newMonth});
	}

	if ( strictMode ) return <div>this component can't work with react strict mode</div>;

	return (
		<PopupLayout>
			<CalendarWrapper>
				{ loading && <CristalLoader /> }
				<Toolbar>
					<div className="row">
						<div className="col-md-3 mb-1 mb-md-0">
							<EmpoweredSelector
								load={loadCommunities}
								onChange={(value) => setCommunity({id: value?.id, name: value?.name})}
								timeout={250}
								label={community?.name ?? ''}
								placeholder="Todas las comunidades"
								value={community?.id}
							/>
						</div>
						<div className="col-md-3 mb-1 mt-1 mb-md-0 mt-md-0">
							<EmpoweredSelector
								load={loadUsers}
								onChange={(value) => setUser({id: value?.id, name: value?.name})}
								timeout={250}
								label={user?.name ?? ''}
								placeholder="Todos los usuarios"
								value={user?.id}
							/>
						</div>
						<div className="col-md-6 mb-1 mt-1 mb-md-0 mt-md-0">
							<div className="row">
								<div className="col-6">
									<div className="input-group input-group-sm">
										<button className="btn btn-light btn-sm" onClick={() => setCalendarYear(year-1)}><i className="bi bi-caret-left-fill"></i></button>
										<input type="number" min="1" style={{minWidth: '50px'}} className="form-control form-control-sm no-arrows text-center" value={year} onChange={(e) => setCalendarYear(parseInt(e.target.value))}  />
										<button className="btn btn-light btn-sm" onClick={() => setCalendarYear(year+1)}><i className="bi bi-caret-right-fill"></i></button>
									</div>
								</div>
								<div className="col-6 ps-0">
									<EmpoweredSelector
										load={loadMonths}
										onChange={(value) => setCalendarMonth({...value})}
										label={month?.name ?? ''}
										placeholder="- Mes -"
										value={month?.id}
										noRemovable={true}
										noSearcheable={true}
									/>
								</div>
							</div>
						</div>
						<div className="col-md-10 mt-1 mt-md-2 d-flex align-items-center justify-content-end">
							<div className="form-check form-switch d-flex align-items-center m-0" style={{fontSize: '13px', lineHeight: '13px'}}>
								<input className="form-check-input me-2 mb-1" style={{minWidth: '25px'}} type="checkbox" id="toggleNotFinished" checked={viewOnlyNotFinished} onChange={(e) => setViewOnlyNotFinished(e.target.checked)} />
								<label className="form-check-label" htmlFor="toggleNotFinished">Mostrar solo eventos sin finalizar</label>
							</div>
						</div>
						<div className="col-md-2 mt-1 mt-md-2">
							<NavLink to="/events/add" className="btn btn-primary text-white btn-sm w-100" onClick={openPopupInfoWindow}>Nuevo evento</NavLink>
						</div>						
					</div>
				</Toolbar>
				<FullCalendar
					ref={calendarRef}
					height="100%"
					locale={esLocale}
					plugins={[ dayGridPlugin, interactionPlugin ]}
			        initialView="dayGridMonth"
					eventClick={(event) => openEditEventWindow(event)}
					headerToolbar={false}
					events={events}
					datesSet={(e) => calendarDatesSetCallback(e)}
					dayCellContent={
						(arg) => {
							return (
								<CellCustomHeader>
									<button className="btn btn-sm btn-link p-0" onClick={() => openAddEventWindow(arg)}><i className="bi bi-plus-circle"></i></button>
									<span>{arg.dayNumberText}</span>
								</CellCustomHeader>
							);
						}
					}
		      	/>
	      	</CalendarWrapper>
		</PopupLayout>
	);
}


