import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useSearchParams } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import PopupLayout from "layouts/PopupLayout";
import { getCommunity, authUserPermission } from 'helpers/community';
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import { jsonToArray } from 'helpers/generic';

const Table = styled.table`
	th,td {
		&:nth-child(1) {
		}

		&:nth-child(2) {
			width: 100px;
			text-align: center;

			small {
				font-size: 10px;
			}
		}

		&:nth-child(3) {
			width: 150px;
		}

		&:nth-child(4) {
			width: 160px;
		}

		&:nth-child(5) {
			width: 50px;
		}
	}

	td {
		&:nth-child(2) {
			line-height: 12px;
		}
	}

	span[class*="status-"] {
		padding: 5px;
		user-select: none;

		&.status-draft {
			background: var(--bs-gray-200);
		}
		
		&.status-sending {
			background: var(--bs-primary);
			color: white;
		}
		
		&.status-pause {
			background: var(--bs-secondary);
			color: white;
		}
		
		&.status-sent {
			background: var(--bs-success);
			color: white;
		}
	}
`;

let axiosCancelToken = null;
let searchTimeout = null;

const RECIPIENTS_TYPES = {
	owners: 'Propietarios',
	renters: 'Inquilinos',
	providers: 'Proveedores',
	clients: 'Clientes',
	contacts: 'Contactos'
};

const STATUSES = {
	draft: 'Borrador',
	sending: 'Enviando',
	pause: 'Pausado',
	sent: 'Enviado'
};

export default function Emails() {
	const [queryParams] = useSearchParams();

	let tableBodyRef = useRef(null);

	let [forceReload, setForceReload] = useState(null);
	let [emails, setEmails] = useState({});
	let [search, setSearch] = useState(undefined);
	let [recipientsType, setRecipientsType] = useState(undefined);
	let [status, setStatus] = useState(undefined);
	let [sortDirection, setSortDirection] = useState('desc');
	let [sortField, setSortField] = useState('created_at');
	let [detailsOpened, setDetailsOpened] = useState([]);
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setEmails({...emails, data: undefined});
		_setPage(page);
	}

	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const toggleDetails = (rowId) => {
		if ( detailsOpened.includes(rowId) ) {
			detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
			setDetailsOpened([...detailsOpened]);
		} else {
			setDetailsOpened([...detailsOpened, rowId]);
		}
	}

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getEstimates = () => {
			axios.get('/api/manager/emails/list', {
				params: {
					community_id: getCommunity()?.id,
					page: page,
					search: search,
					sort: sortField,
					direction: sortDirection,
					recipients_type: recipientsType,
					status: status
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setEmails({...response.data});
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getEstimates();
	}, [forceReload, page, search, sortField, sortDirection, recipientsType, status]);

	const deleteEmail = (email) => {
		const c = window.confirm('¿Quieres eliminar este email?');
		if ( !c ) return;

		axios.post('/api/manager/emails/delete/' + email.id, {
			community_id: email.community_id
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Email borrado')
	    	setForceReload(Math.random());
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const sendEmail = (email) => {
		axios.post('/api/manager/emails/send/' + email.id, {
			community_id: email.community_id
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.success('Enviando email')
	    	setForceReload(Math.random());
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const pauseEmail = (email) => {
		axios.post('/api/manager/emails/pause/' + email.id, {
			community_id: email.community_id
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Email pausado')
	    	setForceReload(Math.random());
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	return (
		<PopupLayout>
			<section>
				<div className="page-title">
					<h1>Emails</h1>
					{ authUserPermission('edit') &&
						<NavLink to="/emails/add" className="btn btn-sm btn-light ms-auto">Nuevo email</NavLink>
					}
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<input type="search" className="form-control form-control-sm" placeholder="Buscar" onChange={(e) => setSearchTimeout(e.target.value)} />
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" onChange={(e) => setRecipientsType(e.target.value)} value={recipientsType}>
										<option value="">- Destinatario -</option>
										{ jsonToArray(RECIPIENTS_TYPES).map((el, idx) => {
											return (
												<option key={idx} value={el.key}>{el.value}</option>
											)
										})}
									</select>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" onChange={(e) => setStatus(e.target.value)} value={status}>
										<option value="">- Estado -</option>
										{ jsonToArray(STATUSES).map((el, idx) => {
											return (
												<option key={idx} value={el.key}>{el.value}</option>
											)
										})}
									</select>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'subject'} onClick={() => sortTableClick('subject')}>Asunto</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'recipients.count'} onClick={() => sortTableClick('recipients.count')}>Destinatarios</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'init_at'} onClick={() => sortTableClick('init_at')}>Enviado el</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'status'} onClick={() => sortTableClick('status')}>Estado</ThSortable>
											<th style={{width: '40px'}}></th>
										</tr>
									</thead>
									<tbody ref={tableBodyRef}>
										{ emails.data?.length > 0 &&
											emails.data.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr 
															className="has-details" 
															onClick={() => toggleDetails(el.id)}
														>
															<td>{el.subject}</td>
															<td>
																<div>{el.recipients_ids?.length ?? 0}</div>
																<small>
																	{ RECIPIENTS_TYPES[el.recipients_type] }
																</small>
															</td>
															<td>{el.init_at ? moment(el.init_at).format('DD-MM-YYYY') : '-'}</td>
															<td><span className={'status-' + el.status}>{STATUSES[el.status]}</span></td>
															<td>
																<NavLink className="btn-table me-3" to={'/emails/edit/' + el.id}><i className="bi bi-pencil"></i></NavLink>
																<NavLink 
																	className="btn-table text-secondary" 
																	to={'/emails/add?copy_from_id=' + el.id} 
																	onClick={(e) => {
																		if ( !window.confirm('¿Quieres copiar este email?') ) {
																			e.preventDefault();
																			e.stopPropagation();
																		}
																	}}
																>
																	<i className="bi bi-copy"></i>
																</NavLink>
															</td>
														</tr>
														{ detailsOpened.includes(el.id) && 	
															<tr className="details">
																<td colSpan="100%">
																	<div className="row ms-0 me-0">
																		<div className="col-md-4">
																			<div><b className="sbold">Asunto</b>: {el.subject ?? '-'}</div>
																			<div>
																				<b className="sbold">Adjuntos</b>:&nbsp;
																				{el.attachments?.map((aEl, aIdx) => {
																					return (
																						<span className="badge bg-light text-secondary fw-normal me-1" key={aIdx}>{aEl.name}</span>
																					);
																				}) ?? '-'}
																			</div>
																			<div className="mb-3 sbold">
																				<b className="sbold">Mensaje</b>:
																				<div dangerouslySetInnerHTML={{__html: el.message ?? '-'}}></div>
																			</div>
																		</div>
																		<div className="col-md-6">
																			<div>
																				<b className="sbold">Tipo de destinatario</b>:&nbsp;
																				{ RECIPIENTS_TYPES[el.recipients_type] }
																			</div>
																			<div><b className="sbold">Envio iniciado el</b>: {el.init_at ? moment(el.init_at).format('DD-MM-YYYY') : '-'}</div>
																			<div><b className="sbold">Finalizado el</b>: {el.sent_at ? moment(el.sent_at).format('DD-MM-YYYY') : '-'}</div>
																			<div><b className="sbold">Estado</b>: <span className={'small status-' + el.status}>{STATUSES[el.status]}</span></div>
																		</div>
																		<div className="col-md-2">
																			{ authUserPermission('edit') &&
																				<React.Fragment>
																					{ el.status === 'draft' && <NavLink  to={'/emails/edit/' + el.id} className="btn btn-sm btn-secondary text-white w-100 mb-2">Editar</NavLink> }
																					{ ((el.status === 'draft' || el.status === 'pause') && el.recipients_ids?.length > 0) && <button className="btn btn-sm btn-primary text-white w-100 mb-2" onClick={() => sendEmail(el)}>Enviar</button> }
																					{ el.status === 'sending' && <button className="btn btn-sm btn-warning w-100 mb-2" onClick={() => pauseEmail(el)}>Parar</button> }
																				</React.Fragment>
																			}
																			{ authUserPermission('delete') &&
																				<React.Fragment>
																					{ el.is_deletable && <button className="btn btn-sm btn-danger text-white w-100 mb-2" onClick={() => deleteEmail(el)}>Eliminar</button> }
																				</React.Fragment>
																			}
																		</div>
																	</div>
																</td>
															</tr>
														}
													</React.Fragment>
												);
											})
										}

										{ emails.data && !emails.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ emails.data === undefined && <TrSkeleton rows={skeletonRows} columns={5} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end" id="footer-fixed">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={emails?.current_page}
									max={emails?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</PopupLayout>
	);
}


