import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import LabeledFrame from 'components/LabeledFrame';
import { useSearchParams } from 'react-router-dom';
import EmpoweredSelector from 'components/EmpoweredSelector';

const RecipientsStyled = styled.div`
    
`;

const RecipientRow = styled.label`
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    margin-top: 5px;
    padding: 5px 10px;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
        background: var(--bs-gray-100);
    }

    &::not(.no-email) {
        cursor: pointer;

        &:hover {
            background: var(--bs-gray-100);
        }
    }

    input[type=checkbox] {
        margin: 0;
        padding: 0;
        margin-right: 5px;
    }

    div {
        display: flex;
        align-items: center;
    }

    small {
        display: block;
        margin-left: 18px;
        font-size: 10px;
        color: var(--bs-primary);

        &.no-email {
            color: var(--bs-danger);
        }
    }
`;

let axiosCancelToken = null;

export default function Recipiehts(props) {
    const data = props.data;
    const setData = props.setData;
    const errors = props.errors;

    const [queryParams] = useSearchParams();

    const [recipients, setRecipients] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

    // const loadCommunities = (input, callback) => {
	// 	axios.get('/api/manager/communities/list', {
	// 		params: {
	// 			search: input,
	// 			no_paginate: true,
	// 		},
	// 		cancelToken: axiosCancelToken.token
	// 	}).then((response) => {
	// 		let formatted = response.data.map((el) => {
	// 			return {
	// 				value: el, 
	// 				label: el.name
	// 			};
	// 		});
	// 		callback(formatted);
	//   	}).catch((error) => {
	// 		if ( axios.isCancel(error) ) return;
	// 	});	
	// }

    const loadStreets = (input, callback, args) => {
		axios.get('/api/manager/communities/get/' + args.community_id, {
			params: {
				community_id: args.community_id
			},
			cancelToken: axiosCancelToken?.token
		}).then((response) => {
			let formatted = response.data.streets.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

    const loadBuildings = (input, callback, args) => {
		axios.get('/api/manager/buildings/list', {
			params: {
				search: input,
				no_paginate: true,
				community_id: args.community_id
			},
			cancelToken: axiosCancelToken?.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

    const loadStairs = (input, callback, args) => {
		axios.get('/api/manager/properties/list', {
			params: {
				search: input,
				no_paginate: true,
				community_id: args.community_id,
                building_id: args.building?.id
			},
			cancelToken: axiosCancelToken?.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el.stairs, 
					label: el.stairs
				};
			})
            .filter(el => el.value && el.value.length > 0)
            .filter((v, i, a) => a.findIndex(el => el.value === v.value) === i)
            .sort()
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

    const toggleRecipient = (id, status) => {
        let newRecipientsIds = data.recipients_ids ? [...data.recipients_ids] : [];
        if ( status ) newRecipientsIds.push(id);
        else {
            let idx = newRecipientsIds.findIndex(el => el === id);
            if ( idx !== -1 ) newRecipientsIds.splice(idx, 1);
        }
        setData((prev) => ({...prev, recipients_ids: newRecipientsIds}));
    }

    const toggleAllRecipients = (action) => {
        let newRecipientsIds = [];
        if ( action === 'select' ) {
            newRecipientsIds = recipients.filter(el => el.email_default).map(el => el.id);
        } else if ( action === 'unselect' ) {
            newRecipientsIds = [];
        }
        setData((prev) => ({...prev, recipients_ids: newRecipientsIds}));
    }

    useEffect(() => {
        if ( !data.community_id || !data.recipients_type ) return;

        const loadRecipients = async () => {
            let orderField = 'name';
            if ( data.recipients_type === 'providers' ) orderField = 'business_name';
    
            return axios.get('/api/manager/' + data.recipients_type + '/list', {
                params: {
                    no_paginate: true,
                    no_limit: true,
                    sort: orderField,
                    direction: 'asc',
                    community_id: data.community_id,
                },
                cancelToken: axiosCancelToken.token
            }).then((response) => {
                let recipients = [...response.data].filter((el) => {
                    let street_id = data.recipients_filters?.street?.id ?? null;
                    let building_id = data.recipients_filters?.building?.id ?? null;
                    let stairs = data.recipients_filters?.stairs ?? null;

                    if ( street_id && el.properties.filter(pEl => pEl.street_id === street_id).length === 0 ) return false;
                    if ( building_id && el.properties.filter(pEl => pEl.building_id === building_id).length === 0 ) return false;
                    if ( stairs && el.properties.filter(pEl => pEl.stairs && pEl.stairs.indexOf(stairs) !== -1).length === 0 ) return false;

                    return true;
                });
                       
                setRecipients([...recipients]);
            }).catch((error) => {
                if ( axios.isCancel(error) ) return;
            });	
        }

        const loadAsync = async () => {
            await loadRecipients();

            // From query params
            let recipients_type = queryParams.get('recipients_type') ?? null;
            let recipients_ids = queryParams.get('recipients_ids') ?? null;
            if ( recipients_ids ) recipients_ids = recipients_ids.split(',').map(el => parseInt(el));

            if ( recipients_type && recipients_ids ) {
                setData((prev) => ({...prev, recipients_type: recipients_type, recipients_ids: recipients_ids}));
            }
        }
        loadAsync();
    }, [data.recipients_type, data.recipients_filters?.street, data.recipients_filters?.building, data.recipients_filters?.stairs, data.community_id, queryParams, setData]);

    const selectStreet = (street) => {
        let newFilters = data.recipients_filters ?? {};
        newFilters.street = {
            id: street?.id,
            name: street?.name
        };

        setData((prev) => ({
            ...prev,
            recipients_filters: newFilters
        }));
    }

    const selectBuilding = (building) => {
        let newFilters = data.recipients_filters ?? {};
        newFilters.building = {
            id: building?.id,
            name: building?.name
        };

        setData((prev) => ({
            ...prev,
            recipients_filters: newFilters
        }));
    }

    const selectStairs = (stairs) => {
        let newFilters = data.recipients_filters ?? {};
        newFilters.stairs = stairs;

        setData((prev) => ({
            ...prev,
            recipients_filters: newFilters
        }));
    }

    // console.log(data);

	return (
        <RecipientsStyled>
            <LabeledFrame 
                label="Destinatarios"
            >
                <div className="row">
                    {/* <div className="col-lg-4">
                        <div className="mb-2">
                            <label>Comunidad</label>
                            <EmpoweredSelector
                                load={loadCommunities}
                                onChange={(value) => setData((prev) => ({...prev, community: value, community_id: value?.id}))}
                                timeout={250}
                                label={data.community?.name ?? ''}
                                placeholder=""
                                value={data.community?.id}
                            />
                            { errors.community_id &&
                                <div className="invalid-feedback d-block">{ errors.community_id[0] }</div>
                            }
                        </div>
                    </div> */}
                    <div className="col-lg-4">
                        <div className="mb-2">
                            <label>Tipo de destinatario</label>
                            <select className="form-control form-control-sm" value={data.recipients_type} onChange={(e) => setData((prev) => ({...prev, recipients_type: e.target.value, recipients_ids: []}))}>
                                <option value="owners">Propietarios</option>
                                <option value="renters">Inquilinos</option>
                                <option value="providers">Proveedores</option>
                                {/* <option value="clients">Clientes</option> */}
                                {/* <option value="contacts">Contactos</option> */}
                            </select>
                            { errors.recipients_type &&
                                <div className="invalid-feedback d-block">{ errors.recipients_type[0] }</div>
                            }
                        </div>
                    </div>
                    { ['owners', 'renters'].indexOf(data.recipients_type) !== -1 &&
                        <React.Fragment>
                            <div className="col-lg-4">
                                <div className="mb-2">
                                    <label>Calle</label>
                                    <EmpoweredSelector
										load={loadStreets}
										onChange={(value) => selectStreet(value)}
                                        args={{community_id: data.community_id}}
										timeout={250}
										label={data.recipients_filters?.street?.name ?? ''}
										placeholder="Seleccionar"
										value={data.recipients_filters?.street?.id ?? null}
                                        noSearcheable={true}
									/>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="mb-2">
                                    <label>Bloque / Portal</label>
                                    <EmpoweredSelector
										load={loadBuildings}
										onChange={(value) => selectBuilding(value)}
                                        args={{community_id: data.community_id}}
										timeout={250}
										label={data.recipients_filters?.building?.name ?? ''}
										placeholder="Seleccionar"
										value={data.recipients_filters?.building?.id ?? null}
									/>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="mb-2">
                                    <label>Escalera</label>
                                    <EmpoweredSelector
										load={loadStairs}
										onChange={(value) => selectStairs(value)}
                                        args={{community_id: data.community_id}}
										timeout={250}
										label={data.recipients_filters?.stairs ?? ''}
										placeholder="Seleccionar"
										value={data.recipients_filters?.stairs ?? null}
                                        noSearcheable={true}
									/>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                    <div className="col-lg-4">
                        <div className="mb-2">
                            <label>Buscar</label>
                            <input type="text" className="form-control form-control-sm" onChange={(e) => setSearch(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-sm btn-light p-0 px-1 me-2" onClick={() => toggleAllRecipients('select')} disabled={recipients?.filter(el => el.email_default).length === data.recipients_ids?.length}>seleccionar todos</button>
                            <button className="btn btn-sm btn-light p-0 px-1" onClick={() => toggleAllRecipients('unselect')} disabled={data.recipients_ids?.length === 0}>deseleccionar todos</button>
                        </div>
                        <hr />
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            {recipients?.filter(el => {
                                if ( !search ) return true;
                                return el.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 || (el.email_default && el.email_default.toLowerCase().indexOf(search.toLowerCase()) !== -1);
                            }).map((recipient, rIdx) => {
                                return (
                                    <RecipientRow key={rIdx} className="col-md-4">
                                        <div className={!recipient.email_default ? 'no-email' : ''}>
                                            <input 
                                                type="checkbox" 
                                                checked={data.recipients_ids?.includes(recipient.id) ?? false} 
                                                onChange={(e) => toggleRecipient(recipient.id, e.target.checked)} 
                                                disabled={!recipient.email_default || data.status !== 'draft'}
                                            /> 
                                            {recipient.name}
                                        </div>
                                        <small className={!recipient.email_default ? 'no-email' : ''}>{recipient.email_default ? recipient.email_default : 'No tiene email'}</small>
                                    </RecipientRow>
                                )
                            })}

                            {!recipients.length &&
                                <small key="nohay">No hay destinatarios</small>
                            }
                        </div>
                    </div>
                </div>
            </LabeledFrame>
        </RecipientsStyled>
	);
}