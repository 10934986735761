import React from 'react';
import { useParams, NavLink, Navigate } from 'react-router-dom';
import LoggedLayout from "layouts/LoggedLayout";
import Model347 from './Model347';
import Model111 from './Model111';
import Model184 from './Model184';

export default function AdministrationModels() {
    const params = useParams();
    const type = params.type;

    if ( !type ) return <Navigate to="/administration-models/347" />

	return (
		<LoggedLayout>
            <section>
				<div className="page-title">
					<h1>Modelos para la administración</h1>
				</div>

				<div className="page-content">
                    <ul className="nav nav-tabs">
                        <li className="nav-item bg-white me-2">
                            <NavLink className="nav-link" to="/administration-models/347">Modelo 347</NavLink>
                        </li>
                        <li className="nav-item bg-white me-2">
                            <NavLink className="nav-link" to="/administration-models/111">Modelo 111</NavLink>
                        </li>
                        <li className="nav-item bg-white">
                            <NavLink className="nav-link" to="/administration-models/184">Modelo 184</NavLink>
                        </li>
                    </ul>
                    
					<div className="card border-0 shadow-sm">
						<div className="card-body p-3">
                            {type === '347' && <Model347 />}
                            {type === '111' && <Model111 />}
                            {type === '184' && <Model184 />}
						</div>
					</div>
				</div>
			</section>
        </LoggedLayout>
	);
}


