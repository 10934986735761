import axios from 'axios';

export default class AfterBanks {

	static axiosCancelToken = null;

	static getBanksList = (search = null) => {
		this.newAxiosCancelToken();

		return axios.get('api/manager/afterbanks/banks-list', {
			params: {
				search: search
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}

	static testConnection = (data) => {
		this.newAxiosCancelToken();

		return axios.post('api/manager/afterbanks/test-connection', {
			data: data
		}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}