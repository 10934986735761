import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
	body {
		background: white !important;
	}
`;

const Layout = styled.div`

`;

const Main = styled.main`
	position: relative;	
	height: 100%;

	.page-title {
		display: flex;
		background: var(--bs-blue);
		padding: 20px;
		border-bottom: 1px solid var(--bs-gray-300);
		align-items: center;

		h1 {
			color: white;
			font-size: 25px;
			line-height: 25px;
			margin: 0;
		}
	}

	.page-content {
		padding-bottom: 40px;

		@media (max-width: 768px) {
			padding-bottom: 80px;
		}

		& > .row {
			margin: 0;

			& > .col-md-12,
			& > .col-lg-12 {
				margin: 0;
				padding: 0;

				& > .card {
					box-shadow: none !important;
				}
			}
		}
	}

	@media (max-width: 992px) {
    	& {
    		margin-left: 0 !important;
    	}
    }

    #footer-fixed {
    	position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		background: var(--bs-gray-400);
		border-top: 0;
    }
`;

export default function PopupLayout(props) { 

	return (
		<React.Fragment>
			<GlobalStyle />
			<Layout id="popup-layout">
				<Main className={''}>
					{ props.children }
				</Main>
			</Layout>
		</React.Fragment>
	);
}