import React, { useState, useEffect, useRef } from "react";
import { Dropdown } from 'bootstrap/dist/js/bootstrap';

const contextMenuStyleTemplate = {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 9999999999999999999,
    boxShadow: '2px 2px 1px rgba(1,1,1,0.2)'
};

export default function ActionsContextMenu(props) {

    let ref = useRef(null);
    let actionsDropdownRef = props.actionsDropdownRef;
    let [showAsContextMenu, setShowAsContextMenu] = useState(false);
    let [contextMenuStyle, setContextMenuStyle] = useState({...contextMenuStyleTemplate});
   
    useEffect(() => {
        const contextMenuHandler = (e) => {
            let el = e.target;
            if (el instanceof HTMLElement && el.isContentEditable) return true;
            if (el instanceof HTMLInputElement) return !(el.disabled || el.readOnly);
            if (el instanceof HTMLTextAreaElement) return !(el.disabled || el.readOnly);

            e.preventDefault();

            // If dropdown is opened as a regular dropdown, first hide to prevent do weirds things
            let actionsDropdown = Dropdown.getInstance(actionsDropdownRef.current);
            actionsDropdown?.hide();

            // Update state to open and position
            setContextMenuStyle({
                ...contextMenuStyleTemplate,
                top: e.clientY,
                left: e.clientX,
            });
            setShowAsContextMenu(true);
        }
        document.addEventListener('contextmenu', contextMenuHandler);

        return function cleanup() {
            document.removeEventListener('contextmenu', contextMenuHandler);
        }
    }, []);

    useEffect(() => {
        const mousedownHandler = (e) => {
            let isChild = false;
            let el = e.target;
            while(el.parentElement) {
                if ( el === ref.current ) {
                    isChild = true;
                    break;
                }
                el = el.parentElement;
            }

            if ( !isChild && showAsContextMenu ) {
                setShowAsContextMenu(false);
            }
        }

        const clickHandler = (e) => {
            if ( showAsContextMenu ) setShowAsContextMenu(false);
        }

        document.addEventListener('mousedown', mousedownHandler);
        document.addEventListener('click', clickHandler);

        return function cleanup() {
            document.removeEventListener('mousedown', mousedownHandler);
            document.removeEventListener('click', clickHandler);
        }
    }, [showAsContextMenu]);

    const copySelectionToClipboard = () => {
        document.execCommand("copy");
    }

    const pasteFromClipboard = () => {
        document.execCommand("paste");
    }

	return (
        <ul ref={ref} className={'dropdown-menu ' + (showAsContextMenu ? 'context-menu show' : '')} style={showAsContextMenu ? contextMenuStyle : null}>
            {showAsContextMenu &&
                <>
                    <li><button className="dropdown-item" onClick={() => copySelectionToClipboard()}>Copiar</button></li>
                    {/* <li><button className="dropdown-item" onClick={() => pasteFromClipboard()}>Pegar</button></li> */}
                    <li><hr className="dropdown-divider" /></li>
                </>
            }
            {props.children}
        </ul>
	);
}