import React, { useRef, useState, useEffect } from "react";
import { Navigate, NavLink } from "react-router-dom";
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getUser, setUser } from 'helpers/user';
import { setConfig } from 'helpers/config';
import { assets } from 'helpers/generic';
import useStore from "store";

const Logo = styled.div`
	display: block;
	margin: 0 auto;
	margin-bottom: 20px;
	width: 100%;
	text-align: center;

	img {
		max-width: 200px;
	}
`;

export default function Login() {
	const usernameRef = useRef();
	const passwordRef = useRef();

	const debug = useStore(state => state.debug);

	const [logoUrl, setLogoUrl] = useState(null);
 
	const login = (e) => {
		e.preventDefault();

		axios.get('sanctum/csrf-cookie').then(response => {
			axios.post('api/login', {
			    username: usernameRef.current.value,
			    password: passwordRef.current.value
		  	})
		  	.then((response) => {
		  		setConfig(response.data.config);
		    	setUser(response.data.user, true);
		  	})
		  	.catch((error) => {
		  		toast.error('Datos inválidos');
		  	});
		});
	};

	useEffect(() => {
		const getLogoUrl = () => {
			// Get company subdomain
			let subdomain = "";
			if ( !debug ) {
				subdomain = window.location.host.split('.')[0];
			} else {
				subdomain = 'company1';
			}

			// Check if exists image
			const url = assets('/storage/companies-logos/' + subdomain + '.webp');
			const img = new Image();
	  		img.src = url;

			if (img.complete) {
			    setLogoUrl(url);
		  	} else {
		    	img.onload = () => {
		      		setLogoUrl(url);
			    };
		  	}
		}
		getLogoUrl();
	}, [debug]);

	if ( getUser() ) return <Navigate to="/" />;

	return (
		<div className="container">
			<div className="row justify-content-center mt-5">
				<div className="col-md-4">
					<Logo>
						{ logoUrl &&
							<img src={logoUrl} alt="company-logo" />
						}
					</Logo>

					<div className="card">
						<div className="card-header">
							<h4 className="card-title text-center mb-0">Acceso</h4>
						</div>
						<div className="card-body">
							<form onSubmit={(e) => login(e)}>
								<div className="mb-3">
									{ debug &&
										<select ref={usernameRef} className="form-control form-control-sm">
											<option value="admin@admin.com">Admin</option>
											<option value="usuario@usuario.com">Usuario</option>
											<option value="superadmin@superadmin.com">Super Admin</option>
											<option value="rita@fincascoruna.com">Rita</option>
										</select>
									}
									{ !debug &&
										<input type="text" ref={usernameRef} className="form-control form-control-sm" placeholder="Usuario" />
									}
								</div>
								<div className="mb-3">
									<input type="password" ref={passwordRef} defaultValue={debug ? '123456' : ''} className="form-control form-control-sm" placeholder="Contraseña" />
								</div>
								<div className="text-center">
									<button className="btn btn-primary btn-sm text-white" onClick={(e) => login(e)}>Entrar</button>	
								</div>
								<div className="text-center mt-2">
									<NavLink to="/reset-password" className="btn btn-link btn-sm text-dark">¿Has olvidado tu contraseña?</NavLink>	
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}