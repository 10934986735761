import React, { useState, useEffect } from "react";
import axios from 'axios';
import styled from 'styled-components';
import { loader } from "helpers/generic";
import { getAjaxUrl } from "helpers/config";
import useStore from "store";
import { getCommunity } from "helpers/community";

const ButtonStyled = styled.button` 
    display: inline-flex;
    align-items: center;
`;

const CustomLoader = styled.div`
    display: inline-flex;
    width: ${props => props.size && props.size[0] ? props.size[0] : 30}px;
    height: ${props => props.size && props.size[1] ? props.size[1] : 30}px;
    margin-left: 10px;

    img {
        width: 100%;
        height: 100%;
    }
`;

let axiosCancelToken = null;

export default function ButtonExportFicha(props) {
    const entityKey = props.entityKey;
    const entityId = props.entityId;
    const fileName = props.fileName;
    const type = props.type ?? 'pdf';
    const extension = props.extension ?? 'pdf';
    const className = props.className;
    const actionBeforeClick = props.actionBeforeClick;

    const debug = useStore(state => state.debug);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

        return function cleanup() {
            axiosCancelToken.cancel();
        }
    }, []);

    const downloadFile = async () => {
        setLoading(true);

		let apiUrl = 'api/manager/' + entityKey + '/get-' + type + '/' + entityId;

		if ( debug ) {
			console.log(getAjaxUrl() + apiUrl + '/I?community_id=' + getCommunity()?.id);
		}

		await axios.post(apiUrl, {
            community_id: getCommunity()?.id
        }, {
			responseType: 'blob',
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName + '.' + extension);
			document.body.appendChild(link);
			link.click();
			link.parentElement.removeChild(link);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});
		
		setLoading(false);
    }

    const clickEvent = () => {
        if ( actionBeforeClick ) actionBeforeClick(downloadFile);
        else downloadFile();
    }

	return (
        <React.Fragment>
            <ButtonStyled className={className ?? 'dropdown-item'} onClick={clickEvent} disabled={loading}>
                Descargar ficha en {type.toUpperCase()}
                { loading && <CustomLoader size={props.loaderSize ?? null}>{loader}</CustomLoader> }
            </ButtonStyled>
        </React.Fragment>
	);
}