import React, { useRef, useState, createPortal } from 'react';
import styled from 'styled-components';
import BulkUploaderHelper from './BulkUploaderHelper';

const StyledUploadButton = styled.div`

    input[type=file] {
        display: none;
    }
`;

const BulkUploadButton = (props) => {
    const community = props.community;
    const parentReload = props.parentReload;

    const fileRef = useRef(null);
    const [files, setFiles] = useState([]);

    const openFileDialog = () => {
		fileRef.current.value = null; // Set empty if was previusly opened
        fileRef.current.click();
    }

    const onChangeHandler = (e) => {
        const filesToUpload = [];
        for (let i = 0; i < e.target.files.length; i++) {
            filesToUpload.push(e.target.files[i]);
        }

        setFiles(filesToUpload);
    }

    return (
        <StyledUploadButton>
		    <button className="btn btn-sm btn-light ms-auto" onClick={openFileDialog}>Subir varias facturas</button>

            <input type="file" ref={fileRef} onChange={(e) => onChangeHandler(e)} multiple />

            {files.length > 0 && <BulkUploaderHelper 
                files={files} 
                close={() => {
                    setFiles([]);
                    parentReload();
                }} 
                community={community} 
            />}
        </StyledUploadButton>
    )
}

export default BulkUploadButton;