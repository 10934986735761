import React, { useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import LabeledFrame from 'components/LabeledFrame';

const TasksList = styled.div`
	width: 100%;
`;

const Task = styled.div`
	display: flex;
	width: 100%;
	position: relative;
	background: var(--bs-gray-300);
	padding: 5px;
	border-radius: 5px;
	border: 1px solid transparent;

	&.with-errors {
		border-color: var(--bs-danger);
	}

	&.checked {
		background: var(--bs-gray-300);
	}

	&:not(:last-of-type) {
		margin-bottom: 10px;
	}

	.left {
		display: flex;
		align-items: center;
		width: 25px;
		font-size: 20px;

		i {
			cursor: pointer;
			color: var(--bs-gray-500);

			&.checked {
				color: var(--bs-primary);
			}

			&:hover {
				&:not(.checked) {
					color: var(--bs-gray-600);
				}
			}
		}
	}

	.center {
		width: 100%;

		textarea {
			resize: none;
			line-height: 110%;
			height: 40px;

			&.definition {
				width: 100%;
				margin-bottom: 5px;
			}

			&.result {

			}
		}
	}

	.right {
		--width: 15px;
		--height: 15px;

		position: absolute;
		top: calc(calc(var(--height) / 2) * -1);
		right: calc(calc(var(--width) / 2) * -1);

		width: var(--width);
		height: var(--height);
		
		background: var(--bs-red);
		color: white;

		display: flex;
		justify-content: center;
		align-items: center;

		border-radius: 50%;

		&:hover {
			cursor: pointer;

		}
	}
`;

let axiosCancelToken = null;

export default function Tasks(props) {
	const emptyTask = {
		definition: null,
		result: null,
		checked: false,
	};

	const tasks = props.tasks ? [...props.tasks] : [];
	const setTasks = props.setTasks;
	const errors = props.errors;

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	const addTask = () => {
		let newTasks = [...tasks, {...emptyTask}];
		setTasks(newTasks);
	}

	const deleteTask = (idx) => {
		let newTasks = [...tasks];
		newTasks.splice(idx, 1);
		setTasks(newTasks);
	}

	const setDataField = (idx, field, value) => {
		let newTasks = [...tasks];
		newTasks[idx][field] = value;
		setTasks(newTasks);
	}

	return (
		<LabeledFrame 
			className="mb-3"
			label="Tareas" 
			buttons={
				<React.Fragment>
					<button className="btn-unstyled text-white" onClick={addTask}>
						<i className="bi bi-plus-circle-fill"></i> Nueva tarea
					</button>
				</React.Fragment>
			}
			buttonsBackground={'primary'}
		>	
			{ tasks.length > 0 ?
				<TasksList id="tasks-list">
					{tasks.map((el, idx) => {
						return (
							<React.Fragment key={idx}>
								<Task className={'task ' + (el.checked ? 'checked' : '') + ' ' + (errors[idx] ? 'with-errors' : '')}>
									<div className="left">
										<i className={'bi bi-' + (el.checked ? 'check-circle-fill checked' : 'circle-fill')} onClick={(e) => setDataField(idx, 'checked', !el.checked)}></i>
									</div>
									<div className="center">
										<textarea className="definition form-control form-control-sm" onChange={(e) => setDataField(idx, 'definition', e.target.value)} value={el.definition ?? ''} placeholder={'Definición de la tarea'} />
										<textarea className="result form-control form-control-sm" onChange={(e) => setDataField(idx, 'result', e.target.value)} value={el.result ?? ''} placeholder={'Resultado de la tarea'} />
										{ errors[idx] &&
											<div className="invalid-feedback d-block">{ errors[idx] }</div>
										}
									</div>
									<div className="right">
										<i className="bi bi-x" onClick={(e) => deleteTask(idx)}></i>
									</div>
								</Task>
							</React.Fragment>
						);
					})}
				</TasksList>
				:
				<div>No hay tareas</div>
			}
		</LabeledFrame>
	);
}