import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { getCommunity } from 'helpers/community';
import { openPopupWindow } from 'helpers/generic';
import EmpoweredSelector from 'components/EmpoweredSelector';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';

const ModalStyled = styled.div`
	background: rgba(0, 0, 0, 0.4);
`;

let axiosCancelToken = null;

export default function ExpenseModal(props) {
	const modalRef = useRef(null);

	const group = props.group;
	const groupIdx = props.groupIdx;

	const title = props.title;
	const titleIdx = props.titleIdx;
	
	const idx = props.idx;
	const expense = props.expense;
	const setExpense = props.setExpense;
	const expensesCount = props.expensesCount;
	const closeCallback = props.closeCallback;

	const [number, setNumber] = useState(props.expense?.number ?? expensesCount+1);
	const [denomination, setDenomination] = useState(props.expense?.denomination ?? '');
	const [meter, setMeter] = useState(expense?.meter);
	const [contableAccount, _setContableAccount] = useState(expense?.account);
	const setContableAccount = (value) => {
		_setContableAccount(value);
		setDenomination(value.name);
	}
	const [quotaconcept, setQuotaconcept] = useState(expense?.quotaconcept);
	const [amount, setAmount] = useState(props.expense?.amount);
	const [test, setTest] = useState(props.expense?.test ?? 0);
	const [defaultQuotaConcept, setDefaultQuotaConcept] = useState({});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const modal = new Modal(modalRef.current, {
			backdrop: false
		});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();
			closeCallback();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

		modal.show();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [closeCallback]);

	useEffect(() => {
		window.PopupProxyCallback = (data) => {
			setContableAccount(data);
		}

		window.PopupProxyQuotasConceptsCallback = (data) => {
			setQuotaconcept(data);
		}

		return function cleanup() {
			window.PopupProxyCallback = undefined;
		}
	}, []);

	// Set default quota concept
	useEffect(() => {
		if ( quotaconcept?.id || defaultQuotaConcept?.id ) return;
		
		const getDefaultQuotaConcept = async () => {
			await axios.get('/api/manager/quotas-concepts/list', {
				params: {
					community_id: getCommunity()?.id,
					no_paginate: true,
					default: true
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				if ( response.data[0] ) {
					setDefaultQuotaConcept(response.data[0]);
					setQuotaconcept(response.data[0]);
				}
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getDefaultQuotaConcept();
	}, [quotaconcept?.id, defaultQuotaConcept?.id]);

	const loadContableAccounts = (input, callback, args) => {
		let except_ids = args.group?.titles[titleIdx]?.expenses?.map(el => el.account?.id);
		if ( contableAccount ) except_ids.splice(except_ids.indexOf(contableAccount.id), 1); // Needed to show current account		

		axios.get('/api/manager/contable-accounts/list', {
			params: {
				community_id: getCommunity()?.id,
				search: input,
				no_paginate: true,
				parent_id: args.title?.account?.id,
				except_ids: except_ids
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label:  <div style={{paddingLeft: (el.level * 5) + 'px'}}>
								<b className="me-1">{el.code}</b>  
								<span>{el.name}</span>
							</div>
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const loadMeters = (input, callback) => {
		axios.get('/api/manager/meters/list', {
			params: {
				community_id: getCommunity()?.id,
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const loadQuotasConcepts = (input, callback) => {	
		axios.get('/api/manager/quotas-concepts/list', {
			params: {
				community_id: getCommunity()?.id,
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = () => {
		// Set data
		let expenseData = props.expense ? {...props.expense} : {};
		expenseData.number = number;
		expenseData.denomination = denomination;
		expenseData.contableaccount_id = contableAccount?.id;
		expenseData.account = contableAccount;
		expenseData.quotaconcept_id = quotaconcept?.id;
		expenseData.quotaconcept = quotaconcept;
		expenseData.meter_id = meter?.id;
		expenseData.meter = meter;
		expenseData.amount = group.distribution_type !== 'consumption' ? parseFloat(amount) : 0;
		expenseData.test = test;
		if ( !expenseData.id ) expenseData.is_deletable = true; // Fix
		if ( isNaN(expenseData.amount) ) expenseData.amount = 0;
		setExpense(groupIdx, titleIdx, idx, {...expenseData});

		// Hide modal
		const modal = Modal.getInstance(modalRef.current);
		modal.hide();
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();
		e.stopPropagation();

		openPopupWindow(e.currentTarget.href);
	}

	const openPopupWindowMouseDownFix = (e) => {
		e.preventDefault();
		e.stopPropagation();
		document.activeElement.blur();
	}

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Gasto</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<div className="row">
							<div className="col-md-1">
								<div className="mb-2">
									<CustomInput label="Nº" type="number" className="form-control form-control-sm no-arrows" value={number ?? ''} onChange={(e) => setNumber(e.target.value)} />
								</div>
							</div>
							<div className="col-md-4">
								<div className="mb-2">
									{/* <div className="input-group"> */}
										<EmpoweredSelector
											load={loadContableAccounts}
											args={{group, title}}
											onChange={(value) => setContableAccount(value)}
											timeout={250}
											label={
												contableAccount?.id ?
													<div>
														<b className="me-1">{contableAccount?.code}</b>  
														<span>{contableAccount?.name}</span>
														{contableAccount?.id &&
															<NavLink className="text-decoration-none text-reset ms-2" to={'/contable-accounts/edit/' + contableAccount.id + '?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
														}
													</div>
												: null
											}
											placeholder="Cuenta contable"
											showPlaceholderHelper={true}
											value={contableAccount?.id}
											dropDownMenuWidth="200%"
										/>
										{/* <div className="input-group-append">
											<NavLink to={'/contable-accounts/add?popup=true&parent_id=' + group.contableaccount_id} onClick={openPopupInfoWindow} className="btn btn-sm btn-light2 text-secondary">
												<i className="bi bi-plus-circle"></i>
											</NavLink>
										</div>
									</div> */}
								</div>
							</div>
							<div className="col-md-4">
								<div className="mb-2">
									<CustomInput label="Denominación" type="text" className="form-control form-control-sm no-arrows" value={denomination ?? ''} onChange={(e) => setDenomination(e.target.value)} />
								</div>
							</div>
							<div className="col-md-3">
								<div className="mb-2">
									<CustomInput label="Importe" type="number" className="form-control form-control-sm no-arrows" readOnly={group.distribution_type === 'consumption'} value={group.distribution_type === 'consumption' ? 0 : (amount ?? '')} onChange={(e) => setAmount(e.target.value)} />
								</div>
							</div>
							<div className="col-md-4">
								<div className="mb-2">
									<div className="input-group">
										<EmpoweredSelector
											load={loadQuotasConcepts}
											onChange={(value) => setQuotaconcept(value)}
											timeout={250}
											label={quotaconcept?.name}
											placeholder="Concepto de cuota"
											showPlaceholderHelper={true}
											value={quotaconcept?.id}
										/>	
										<div className="input-group-append">
											<NavLink to={'/quotas-concepts/add?popup=true'} onClick={openPopupInfoWindow} className="btn btn-sm btn-light2 text-secondary">
												<i className="bi bi-plus-circle"></i>
											</NavLink>
										</div>
									</div>
								</div>
							</div>
							{/* <div className="col-md-2">
								<div className="mb-2">
									<CustomSelect label="Para pruebas" className="form-control form-control-sm" onChange={(e) => setTest(e.target.value)} value={test ?? ''}>
										<option value="1">Si</option>
										<option value="0">No</option>
									</CustomSelect>
								</div>
							</div> */}
							{ group.distribution_type === 'consumption' &&
								<div className="col-md-4">
									<div className="mb-2">
										<EmpoweredSelector
											load={loadMeters}
											onChange={(value) => setMeter(value)}
											timeout={250}
											label={meter?.name}
											placeholder="Contador"
											showPlaceholderHelper="Contador"
											value={meter?.id}
										/>	
									</div>
								</div>
							}
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className={'btn btn-sm btn-primary text-white ' + (!contableAccount?.id ? 'disabled' : '')} onClick={() => saveData()}>Aceptar</button>
					</div>
				</div>
			</div>
		</ModalStyled>
	);
}


