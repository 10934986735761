import React, { useEffect, useRef, useState } from "react";
import axios from 'axios';
import styled from 'styled-components';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { Editor } from '@tinymce/tinymce-react';

const ModalStyled = styled.div`
	textarea {
		resize: none;
		height: 150px;
	}

	label {
		font-weight: 500;
		font-size: 15px;
	}
`;

const Option = styled.div`
	position: relative;
	display: inline-block;
	margin-right: 15px;
	margin-bottom: 10px;

	button {
		position: absolute;
		top: -10px;
		right: 0;
		border: none;
		width: 15px;
		height: 15px;
		background: none;

		i {
			color: var(--bs-danger);
		}
	}

	div.recount {
		position: absolute;
		right: -7px;
		bottom: -7px;
		width: 20px;
		height: 20px;
		background: white;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 10px;
		border: 1px solid var(--bs-gray-400);
		border-radius: 50%;
		color: var(--bs-gray-600);
	}
`;

const TablePersons = styled.table`
	td,th {
		position: relative;
	}

	select {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
`;

let axiosCancelToken = null;

export default function ReasonModal(props) {
	const modalRef = useRef(null);

	let data = props.data;
	let setField = props.setField;
	let deleteReason = props.delete;
	let number = props.number;
	let properties = props.properties;
	let closeCallback = props.closeCallback;

	let optionInputRef = useRef(null);

	let [modalObj, setModalObj] = useState(null);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const modal = Modal.getOrCreateInstance(modalRef.current);
		modal.show();
		setModalObj(modal);

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( optionInputRef.current !== null ) {
			optionInputRef.current.focus();
			optionInputRef.current = null;
		}
	}, [data.options]);

	const addOption = () => {
		let newOptions = data.options ? [...data.options] : [];
		newOptions.push('');
		setField('options', newOptions);
	}

	const removeOption = (idx) => {
		let newOptions = data.options ? [...data.options] : [];
		newOptions.splice(idx, 1);
		setField('options', newOptions);
	}

	const setOptionValue = (idx, value) => {
		let newOptions = data.options ? [...data.options] : [];
		newOptions[idx] = value;
		setField('options', newOptions);
	}

	const setVote = (propertyId, option) => {
		let newVotes = data.votes ? [...data.votes] : [];
		let idx = newVotes.findIndex(el => el.property_id === propertyId);
		if ( idx === -1 ) {
			newVotes.push({
				property_id: propertyId,
				option: option
			});
		} else {
			newVotes[idx].option = option;
		}
		setField('votes', newVotes);
	}

	const hideModal = (saveData = true) => {
		modalObj.hide();
		modalObj.dispose();

		closeCallback(saveData);

		document.body.style.overflow = 'auto'; 
		document.body.style.paddingRight = '0';
	}

	const deleteReasonFix = () => {
		deleteReason();
		hideModal(false);
	}

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Orden del día nº {number}</h5>
						<button type="button" className="btn-close" onClick={() => hideModal(false)} aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<div className="mb-2">
							<div className="options_wrapper">
								<div className="mb-1">
									<label>Opciones para votar</label>
									<button className="btn-unstyled text-primary ms-3 small" onClick={addOption}><i className="bi bi-plus-circle"></i></button>
								</div> 
								<div>
									{data.options?.map((el, idx) => {
										return (
											<Option key={idx}>
												<input 
													type="text" 
													ref={idx === data.options.length-1 ? optionInputRef : null}
													value={el} 
													className="form-control form-control-sm d-inline-block w-auto" 
													onChange={(e) => setOptionValue(idx, e.target.value)} 
													placeholder="Escribe aquí..."
												/>
												<div className="recount">{data.votes?.filter(vEl => vEl.option === el).length}</div>
												<button onClick={() => removeOption(idx)} tabIndex="-1"><i className="bi bi-x-circle-fill"></i></button>
											</Option>
										);
									})}
									{data.options?.length === 0 && <div className="text-danger small">Añade opciones de votación</div>}
								</div>
							</div>
						</div>

						<div className="mb-2">
							<TablePersons className="table table-sm table-bordered">
								<thead>
									<tr>
										<th>
											Propietario
										</th>
										<th>
											Votación
										</th>
									</tr>	
								</thead>
								<tbody>
									{properties?.map((el, idx) => {
										let vote = data.votes?.filter(vEl => vEl.property_id === el.property_id)[0] ?? null;

										return (
											<tr key={idx}>
												<td>
													{el.property?.name}
													{ el.owner &&
														<div>{el.owner.name} {el.owner.vatnumber}</div>
													}
													{ el.representative &&
														<div>
															<b className="sbold">Representante:</b> {el.representative.name} {el.representative.vatnumber}
														</div>
													}
												</td>
												<td>
													<select value={vote?.option} onChange={(e) => setVote(el.property_id, e.target.value)}>
														<option value=""></option>
														{data.options?.map((el, idx) => {
															return (
																<option key={idx} value={el}>{el}</option>
															);
														})}
													</select>	
												</td>
											</tr>
										);
									})}
								</tbody>
							</TablePersons>
						</div>
					</div>
					<div className="modal-footer d-flex">
						<button className="btn btn-sm btn-link text-danger" onClick={() => deleteReasonFix()}>Eliminar</button>
						<button type="button" className="btn btn-sm btn-primary text-white ms-auto" onClick={() => hideModal()}>Aceptar</button>
					</div>
				</div>
			</div>
		</ModalStyled>
	);
}


