import React, { useState, useEffect } from 'react';
import { Navigate, NavLink, useSearchParams } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import PopupLayout from "layouts/PopupLayout";
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import { formatNumber, openPopupWindow } from 'helpers/generic';
import { getCommunity } from 'helpers/community';
import { authUserPermission } from 'helpers/community';
import { viewReceiptPdf } from 'helpers/receipts';
import EmpoweredSelector from 'components/EmpoweredSelector';
import FieldSmallComment from 'components/FieldSmallComment';
import ExportModal from 'components/ExportModal';

const Table = styled.table`
	& > thead > tr > th, 
	& > tbody > tr > td {
		&:nth-child(1) {
			width: 100px;
		}

		&:nth-child(2) {
			width: 100px;
		}

		&:nth-child(3) {
			width: 20px;
			white-space: nowrap;
		}

		&:nth-child(4) {
			width: 100px;
		}

		&:nth-child(5) {
			
		}

		&:nth-child(6) {
			width: 150px;
			white-space: nowrap;
		}

		&:nth-child(7) {
			width: 50px;
			white-space: nowrap;
			text-align: right;
		}

		&:nth-child(8) {
			width: 50px;
			white-space: nowrap;
			text-align: center;
		}

		&:nth-child(9) {
			width: 60px;
			white-space: nowrap;
			text-align: right;

			a:first-of-type {
				margin-right: 8px;
			}
		}
	}
`;

let axiosCancelToken = null;
let searchTimeout = null;

export default function Receipts() {
	const [queryParams] = useSearchParams();

	let popup = queryParams.get('popup') === "true";
	let payerable_type = queryParams.get('payerable_type') ?? null;
	let payerable_id = queryParams.get('payerable_id') ?? null;
	let property_id = queryParams.get('property_id') ?? null;
	let hide_payerable_selector = queryParams.get('hide_payerable_selector') === "true";
	let hide_property_selector = queryParams.get('hide_property_selector') === "true";
	let hide_buttons_add = queryParams.get('hide_buttons_add') === "true";
	let hide_back_button = queryParams.get('hide_back_button') === "true";

	let community = getCommunity();
	let [forceReload, setForceReload] = useState(null);
	let [showExportModal, setShowExportModal] = useState(false);
	let [receipts, setReceipts] = useState({});
	let [property, setProperty] = useState({
		id: property_id ?? null,
		name: null
	});
	let [payerable, setPayerable] = useState({
		id: payerable_id,
		type: payerable_type ? 'App\\Models\\' + payerable_type.substring(0, payerable_type.length-1) : null
	});
	let [dateFrom, setDateFrom] = useState(null);
	let [dateTo, setDateTo] = useState(null);
	let [receiptStatus, _setReceiptStatus] = useState('');
	const setReceiptStatus = (value) => {
		_setReceiptStatus(value);
		setPage(1);
	}
	let [search, setSearch] = useState(undefined);
	let [sortDirection, setSortDirection] = useState('desc');
	let [sortField, setSortField] = useState('issuance.date');
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setReceipts({...receipts, data: undefined});
		_setPage(page);
	}

	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const openPopupInfoWindow = (e) => {
		e.preventDefault();
		e.stopPropagation();

		openPopupWindow(e.currentTarget.href);
	}

	const viewReceiptPdfClick = (e, receipt_id) => {
		e.preventDefault();
		e.stopPropagation();

		viewReceiptPdf(receipt_id);
	}

	const loadProperties = (input, callback) => {
		let params = {
			search: input,
			no_paginate: true,
			community_id: getCommunity()?.id,
		};
		if ( payerable_type ) params[payerable_type.toLowerCase().substring(0, payerable_type.length-1) + '_id'] = payerable_id; 

		axios.get('/api/manager/properties/list', {
			params: params,
			cancelToken: axiosCancelToken?.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name + ' (' + el.number + ')'
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const loadPayerables = (input, callback) => {
		axios.get('/api/manager/receipts/get-payerables-list', {
			params: {
				community_id: getCommunity()?.id,
				search: input,
				no_paginate: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label:  <div>
								{el.name}
								<div><small>{el.type.indexOf('Owner') !== -1 ? 'Propietario' : 'Inquilino'}</small></div>
							</div>
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getReceipts = async () => {
			// Prepare params
			let params = {
				community_id: community?.id,
				page: page,
				search: search,
				sort: sortField,
				direction: sortDirection,
				property_id: property?.id,
				payerable_type: payerable?.type,
				payerable_id: payerable?.id,
				date_from: dateFrom,
				date_to: dateTo,
				with_manual_payment_fields: true
			};

			if ( receiptStatus === 'paid' ) params.paid_status = 'full';
			if ( receiptStatus === 'partial' ) params.paid_status = 'partial';
			if ( receiptStatus === 'unpaid' ) params.paid_status = 'unpaid';
			if ( receiptStatus === 'refunded' ) params.only_refunds = true;
			if ( receiptStatus === 'reunificated' ) params.only_reunificated = true;
			if ( receiptStatus === 'reunificated-parent' ) params.only_reunificated_parent = true;

			await axios.get('/api/manager/receipts/list', {
				params: params,
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setReceipts({...response.data});
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getReceipts();
	}, [forceReload, page, search, sortField, sortDirection, community?.id, property, payerable, dateFrom, dateTo, receiptStatus]);

	// Proxy for popup window to update when fields are changed on popup
	useEffect(() => {
		window.PopupProxyCallback = () => {
			setForceReload(Math.random());
		}
	}, []);

	if ( !community?.id ) return <Navigate to="/" />;

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			<section>
				<div className="page-title">
					<h1>Recibos</h1>
					
					{ (authUserPermission('add') && !hide_buttons_add) &&
						<div className="ms-auto">
							<NavLink to="/receiptsissuances/new" className="btn btn-sm btn-light">Realizar emisión</NavLink>
							<NavLink to="/receipts/manual-add" className="btn btn-sm btn-light ms-4">Recibo manual</NavLink>	
						</div>
					}
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<input type="text" className="form-control form-control-sm" placeholder="Buscar" onChange={(e) => setSearchTimeout(e.target.value)} />
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									{ !hide_property_selector &&
										<EmpoweredSelector
											load={loadProperties}
											onChange={(value) => setProperty(value)}
											timeout={250}
											label={property?.name}
											placeholder="- Propiedad -"
											value={property?.id}
										/>
									}
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									{ !hide_payerable_selector &&
										<EmpoweredSelector
											load={loadPayerables}
											onChange={(value) => setPayerable(value)}
											timeout={250}
											label={payerable?.name}
											placeholder="- Pagador -"
											value={payerable?.id}
										/>
									}
								</div>
								{ hide_back_button &&
									<div className="col-md-1 text-end mb-2 mt-2 mb-md-0 mt-md-0"></div>
								}
								<div className="col-md-4 mb-2 mt-2 mb-md-0 mt-md-0">
									<div className="input-group input-group-sm">
										<span className="input-group-text">
											Fechas
										</span>
										<input type="date" className="form-control form-control-sm" placeholder="Desde" value={dateFrom ?? ''} onChange={(e) => setDateFrom(e.target.value)} />
										<input type="date" className="form-control form-control-sm" placeholder="Hasta" value={dateTo ?? ''} onChange={(e) => setDateTo(e.target.value)} />
									</div>
									<FieldSmallComment>Filtra por fecha de vencimiento</FieldSmallComment>
								</div>
								<div className="col-md-1 text-end">
									<select className="form-control form-control-sm" value={receiptStatus} onChange={(e) => setReceiptStatus(e.target.value)}>
										<option value="">- Todos -</option>
										<option value="paid">Pagados</option>
										<option value="partial">Pagados parcialmente</option>
										<option value="unpaid">No pagados</option>
										<option value="refunded">Devueltos</option>
										<option value="reunificated">Reunificados</option>
										<option value="reunificated-parent">Generados por reunificación</option>
									</select>
								</div>
								

								{ !hide_back_button &&
									<React.Fragment>
										<div className="col-md-1 text-end mb-2 mt-2 mb-md-0 mt-md-0">
											<NavLink to="/receiptsissuances" className="btn btn-sm btn-light">Emisiones</NavLink>	
										</div>
										
									</React.Fragment>
								}
								
								<div className="col-md-11"></div>
								<div className="col-md-1 text-end mb-2 mt-2 mb-md-0 mt-md-0">
									<button className="btn btn-sm btn-light" onClick={() => setShowExportModal(true)}>Exportar</button>
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'issuance.date'} onClick={() => sortTableClick('issuance.date')}>Emisión</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'due_date'} onClick={() => sortTableClick('due_date')}>Vencimiento</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'number.full'} onClick={() => sortTableClick('number.full')}>Nº</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'property_id'} onClick={() => sortTableClick('property_id')}>Propiedad</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'payerable_id'} onClick={() => sortTableClick('payerable_id')}>Pagador</ThSortable>
											<th>Conceptos</th>
											<ThSortable direction={sortDirection} active={sortField === 'amount'} onClick={() => sortTableClick('amount')}>Importe</ThSortable>
											<th>Estado</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{ receipts.data?.length > 0 &&
											receipts.data.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr>
															<td>{moment(el.issuance.date).format('DD-MM-YYYY')}</td>
															<td>{moment(el.due_date).format('DD-MM-YYYY')}</td>
															<td>
																{el.number_full}
															</td>
															<td>
																{ el.property?.id &&
																	<React.Fragment>
																		{el.property.name}&nbsp;
																		<NavLink to={'/properties/edit/' + el.property.id + '?popup=true'} onClick={openPopupInfoWindow}><i className="bi bi-eye"></i></NavLink>
																	</React.Fragment>
																}
															</td>
															<td>
																{ el.payerable?.id ?
																	<React.Fragment>
																		{el.payerable?.name}&nbsp;
																		{ el.payerable?.id &&
																			<NavLink to={'/'+el.payer_type+'s/edit/' + el.payerable_id + '?popup=true'} onClick={openPopupInfoWindow}><i className="bi bi-eye"></i></NavLink>
																		}
																	</React.Fragment>
																	:
																	<div className="text-danger">
																		No tiene pagador
																	</div>
																}
																<div className="small">
																	Pago { el.bankaccount_id ? 'por remesa bancaria' : 'al contado' }
																</div>

																{ el.paid_at &&
																	<React.Fragment>
																		<div className="text-success small">Pagado el { moment(el.paid_at).format('DD-MM-YYYY') }</div>
																		{ el.paid_status === 'partial' && 
																			<div className="text-indigo small">Pagado parcialmente</div>
																		}
																	</React.Fragment>
																}	

																{ el.refunded_at &&
																	<React.Fragment>
																		<div className="text-danger small">
																			DEVUELTO <i className={'bi bi-dash-circle' + (el.parent ? '-fill' : '')}></i>

																			{ el.parent ?
																				<span>
																					&nbsp;<i className="bi bi-arrow-right"></i> {el.parent.number_full}
																				</span>
																				:
																				<span>
																					&nbsp; nuevo recibo no generado
																				</span>
																			}
																		</div>
																	</React.Fragment>
																}

																{ (!el.refunded_at && el.parent) &&
																	<React.Fragment>
																		<div className="text-primary small">
																			REUNIFICADO <i className="bi bi-dash-circle-fill"></i>
																			<span>
																				&nbsp;<i className="bi bi-arrow-right"></i> {el.parent.number_full}
																			</span>
																		</div>
																	</React.Fragment>
																}

																{ el.is_reunificated_parent &&
																	<React.Fragment>
																		<div className="text-primary small">
																			GENERADO POR REUNIFICACIÓN DE
																			<span>
																				&nbsp;<i className="bi bi-arrow-right"></i> {el.refunds?.map(rEl => {
																					return rEl.number_full
																				}).join(', ')}
																			</span>
																		</div>
																	</React.Fragment>
																}
																{ el.is_refund_parent &&
																	<React.Fragment>
																		<div className="text-danger small">
																			GENERADO POR DEVOLUCIÓN DE 
																			<span>
																				&nbsp;<i className="bi bi-arrow-right"></i> {el.refunds?.map(rEl => {
																					return rEl.number_full
																				}).join(', ')}
																			</span>
																		</div>
																	</React.Fragment>
																}
															</td>
															<td>
																{el.quotasconcepts?.map((qcEl, qcIdx) => {
																	return (
																		<div key={'qc'+qcIdx}>{qcEl.name}: {formatNumber(qcEl.pivot?.amount)} €</div>
																	);
																})}
															</td>
															<td>
																{formatNumber(el.amount)} €
															</td>
															<td>
																{ (el.paid_status === 'full' && !el.refunded_at) && <span className="badge bg-success">Pagado</span> }
																{ el.paid_status === 'partial' && <span className="badge bg-indigo">Pagado parcialmente</span> }
																{ (el.paid_status === 'unpaid' && !el.parent) && <span className="badge bg-danger">No pagado</span> }
																{ el.refunded_at && <span className="badge bg-danger">Devuelto</span> }
																{ (el.parent && !el.refunded_at) && <span className="badge bg-primary">Reunificado</span> }
															</td>
															<td className="text-center">
																<NavLink to={'/receipts/edit/' + el.id} onClick={openPopupInfoWindow}><i className="bi bi-pencil"></i></NavLink>
																<a href="/" onClick={(e) => viewReceiptPdfClick(e, el.id)}><i className="bi bi-printer text-secondary"></i></a>
															</td>
														</tr>
													</React.Fragment>
												);
											})
										}

										{ receipts.data && !receipts.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ receipts.data === undefined && <TrSkeleton rows={skeletonRows} columns={9} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer text-end" id={popup ? 'footer-fixed' : ''}>
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={receipts?.current_page}
									max={receipts?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			{ showExportModal &&
				<ExportModal
					exportKey={'receipts'}
					fileName={'Recibos ' + moment().format('DD-MM-YYYY HH:mm')}
					fields={{
						issuance_date: 			'Emisión',
						due_date: 				'Vencimiento',
						number: 				'Nº',
						property: 				'Propiedad',
						payerable: 				'Pagador',
						concepts: 				'Conceptos',
						amount: 				'Importe',
						status: 				'Estado',
					}}
					filters={{
						community_id: 					community?.id,
						page: 							page,
						search: 						search,
						sort: 							sortField,
						direction: 						sortDirection,
						property_id: 					property?.id,
						payerable_type: 				payerable?.type,
						payerable_id: 					payerable?.id,
						date_from: 						dateFrom,
						date_to: 						dateTo,
						receipt_status: 				receiptStatus,
						with_manual_payment_fields: 	true,
						no_paginate:					true,
						paid_status:					(() => {
							if ( receiptStatus === 'paid' ) return 'full';
							if ( receiptStatus === 'partial' ) return 'partial';
							if ( receiptStatus === 'unpaid' ) return 'unpaid';
						})(),
						only_refunds: 					receiptStatus === 'refunded',
						only_reunificated: 				receiptStatus === 'reunificated',
						only_reunificated_parent: 		receiptStatus === 'reunificated-parent',

					}}
					closeCallback={() => setShowExportModal(false)}
				/>
			}
		</Layout>
	);
}


