import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { useNavigate, useParams, useSearchParams, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader, openPopupWindow } from 'helpers/generic';
import { getCommunity } from 'helpers/community';
import EmpoweredSelector from 'components/EmpoweredSelector';
import LabeledFrame from 'components/LabeledFrame';
import ButtonExportFicha from 'components/ButtonExportFicha';
import ActionsContextMenu from 'components/ActionsContextMenu';
import CustomInput from 'components/CustomInput';

const Table = styled.table`
	font-size: 13px;

	thead {
		tr {
			th {
				font-weight: 500;

				&:nth-child(2) {
					width: 120px;
				}

				&:nth-child(3) {
					width: 120px;
				}

				&:nth-child(4) {
					width: 80px;
				}

				&:nth-child(5) {
					width: 110px;
				}

				&:nth-child(6) {
					width: 60px;
				}

				&:nth-child(7) {
					width: 80px;
				}

				&:not(:first-of-type) {
					text-align: right;
				}
			}
		}
	}

	tbody {
		tr {
			td {
				position: relative;

				input {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border: 0;
					outline: none;
					text-align: right;
					padding: 5px 10px;

					&[readonly] {
						background: var(--bs-gray-100);
					}
				}
			}
		}
	}
`;

let axiosCancelToken = null;

export default function MetersReadingsForm(props) {
	let actionsDropdownRef = useRef(null);

	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';
	const meter_id = queryParams.get('meter_id');

	let [data, setData] = useState({
		number: 1,
		properties: []
	});
	let [meter, setMeter] = useState({});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);
	let [properties, setProperties] = useState([]);
	let [lastReading, setLastReading] = useState({});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( !meter_id || meter_id === null ) return;

		const loadMeter = async () => {
			await axios.get('/api/manager/meters/get/' + meter_id, {
				params: {
					community_id: getCommunity()?.id,
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setMeter({...response.data});
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}
		loadMeter();
	}, [meter_id]);

	useEffect(() => {
		const loadAsync = async () => {
			setLoading(true);

			// Properties
			await axios.get('/api/manager/properties/list', {
				params: {
					community_id: getCommunity()?.id,
					no_paginate: true
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	if ( response.data ) {
		    		setProperties([...response.data]);

		    		// Prepare properties reading
		    		// let properties_reading = [...data.properties]; // not neccesary i think
		    		let properties_reading = [];
		    		response.data.forEach((el) => {
		    			let idx = properties_reading.findIndex(pEl => pEl.property_id === el.id);
		    			if ( idx === -1 ) {
		    				properties_reading.push({
			    				property_id: el.id
			    			});
			    		}
		    		});
		    		setData((prev) => ({...prev, properties: [...properties_reading]}));
		    	}
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});

			// Data
			if ( params.id ) {
				await axios.get('/api/manager/meters-readings/get/' + params.id, {
					params: {
						community_id: getCommunity()?.id
					},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData((prev) => ({...prev, ...response.data}));
			    	setMeter({...response.data.meter});
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});
			}

			setLoading(false);
		}
		loadAsync();
	}, [params.id]);


	useEffect(() => {
		if ( params.id || !meter?.id ) return;

		const getLastReading = async () => {
			await axios.get('/api/manager/meters-readings/list', {
				params: {
					community_id: getCommunity()?.id,
					meter_id: meter?.id,
					sort: 'number',
					direction: 'desc'
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				let last_reading = response.data.data[0] ?? null;
			
				setLastReading({...last_reading});
				setData((prev) => ({
					...prev, 
					number: (last_reading?.number ?? 0)+1, 
					date_from: last_reading ? moment(last_reading.date_to).add('1', 'days').format('YYYY-MM-DD') : ''
				}));
			
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}
		getLastReading();
	}, [params.id, meter?.id])

	const deleteMeter = () => {
		const c = window.confirm('¿Quieres eliminar este contador?');
		if ( !c ) return;

		axios.post('/api/manager/meters-readings/delete/' + data.id, {
			community_id: getCommunity()?.id,
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Lectura de contador eliminada')
	    	navigate('/meters-readings');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (goBack = true) => {
		setErrors({});

		data.community_id = getCommunity()?.id;
		data.meter_id = meter?.id;

		axios.post('/api/manager/meters-readings/' + (data.id ? 'edit/' + data.id : 'add'), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			setData({...response.data.reading});
			if ( goBack ) navigate('/meters-readings?meter_id=' + meter_id);
			else {
		    	navigate('/meters-readings/edit/' + response.data.reading.id);
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const loadMeters = (input, callback) => {
		axios.get('/api/manager/meters/list', {
			params: {
				community_id: getCommunity()?.id,
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	const openPopupInfoWindowMouseDownFix = (e) => {
		e.preventDefault();
		e.stopPropagation();
		document.activeElement.blur();
	}

	const setPropertyField = (field, property_id, value) => {
		let arIdx = data.properties.findIndex(el => el.property_id === property_id);

		let property = data.properties[arIdx];
		property[field] = value;

		let newProperties = [...data.properties];
		newProperties[arIdx] = {...property};

		setData({
			...data, 
			properties: [...newProperties] 
		});
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de lectura de contador</h1>
					<button onClick={() => navigate('/meters-readings' + (meter_id ? '?meter_id=' + meter_id : ''))} className="btn btn-sm btn-light ms-auto">Volver</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-lg-8 mb-3">
											<LabeledFrame label="Lectura">
												<div className="row">
													<div className="col-md-3">
														<div className="mb-2">
															<CustomInput label="Número" type="text" className="form-control form-control-sm text-end" value={data.number ?? ''} readOnly />
														</div>
													</div>
													<div className="col-md-3">
														<div className="mb-2">
															<div className="input-group">
																<EmpoweredSelector
																	load={loadMeters}
																	onChange={(value) => setMeter(value)}
																	timeout={250}
																	label={
																		<div>
																			{meter?.name ?? ''}&nbsp;
																			{meter?.id &&
																				<NavLink className="text-decoration-none text-reset" to={'/meters/edit/' + meter.id + '?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupInfoWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
																			}
																		</div>
																	}
																	placeholder="Contador"
																	showPlaceholderHelper={true}
																	value={meter?.id}
																	disabled={data.id}
																/>
																<div className="input-group-append">
																	<NavLink className="btn btn-sm btn-light2 text-secondary" to={'/meters/add?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupInfoWindowMouseDownFix(e)}><i className="bi bi-plus-circle"></i></NavLink>
																</div>
															</div>
															{ errors.meter_id &&
																<div className="invalid-feedback d-block">{ errors.meter_id[0] }</div>
															}
														</div>
													</div>
													<div className="col-md-3">
														<div className="mb-2">
															<CustomInput label="Desde" type="date" className="form-control form-control-sm" disabled={data.id} onChange={(e) => setDataField('date_from', e.target.value)} value={data.date_from ?? ''} />
															{ errors.date_from &&
																<div className="invalid-feedback d-block">{ errors.date_from[0] }</div>
															}
														</div>
													</div>
													<div className="col-md-3">
														<div className="mb-2">
															<CustomInput label="Hasta" type="date" className="form-control form-control-sm" disabled={data.id} onChange={(e) => setDataField('date_to', e.target.value)} value={data.date_to ?? ''} />
															{ errors.date_to &&
																<div className="invalid-feedback d-block">{ errors.date_to[0] }</div>
															}
														</div>
													</div>
												</div>
											</LabeledFrame>
										</div>

										<div className="col-lg-12">
											<LabeledFrame label="Consumos">
												<div className="table-responsive">
													<Table className="table table-sm table-bordered mb-0">
														<thead>
															<tr>
																<th>Propiedad</th>
																<th>Lectura anterior</th>
																<th>Lectura actual</th>
																<th>Pasos</th>
																<th>Importe Unidad</th>
																<th>Servicio</th>
																<th>Total</th>
															</tr>
														</thead>
														<tbody>
															{ properties.map((el, idx) => {
																// Get data from last reading
																let last_reading_read = 0; 
																if ( lastReading && lastReading.properties ) {
																	let lrIdx = lastReading.properties.findIndex(lrEl => lrEl.property_id === el.id);
																	if ( lrIdx !== -1 ) last_reading_read = lastReading.properties[lrIdx].read ?? 0;
																}

																// Get data from property
																let last_reading = ''; let actual_reading = ''; let steps = ''; let unit_price = ''; let service_price = ''; let total = '';
																if ( data.properties ) {
																	let arIdx = data.properties.findIndex(pEl => pEl.property_id === el.id);
																	if ( arIdx !== -1 ) {
																		actual_reading = data.properties[arIdx].read ?? '';
																		last_reading = data.properties[arIdx].last_read ?? last_reading_read;
																		if ( isNaN(last_reading) ) last_reading = '';
																		steps = actual_reading - (last_reading ?? 0);
																		if ( steps < 0 ) steps = 0;

																		unit_price = parseFloat(data.properties[arIdx].unit_price ?? meter?.unit_price);
																		if ( isNaN(unit_price) ) unit_price = '';
																		service_price = parseFloat(data.properties[arIdx].extra_amount ?? meter?.service_price);
																		if ( isNaN(service_price) ) service_price = '';

																		total = (steps * unit_price) + service_price;
																	}
																}

																return (
																	<tr key={idx}>
																		<td>{el.name}</td>
																		<td>
																			<input type="number" className="no-arrows" onChange={(e) => setPropertyField('last_read', el.id, e.target.value)} value={last_reading} placeholder={last_reading_read} />
																		</td>
																		<td>
																			<input type="number" className="no-arrows" onChange={(e) => setPropertyField('read', el.id, e.target.value)} value={actual_reading} />
																		</td>
																		<td>
																			<input type="number" className="no-arrows" value={steps} readOnly />
																		</td>
																		<td>
																			<input type="number" className="no-arrows" value={unit_price} placeholder={meter?.unit_price} onChange={(e) => setPropertyField('unit_price', el.id, e.target.value)}  />
																		</td>
																		<td>
																			<input type="number" className="no-arrows" value={service_price} placeholder={meter?.service_price} onChange={(e) => setPropertyField('extra_amount', el.id, e.target.value)} />
																		</td>
																		<td>
																			<input type="number" className="no-arrows" value={total} readOnly />
																		</td>
																	</tr>
																);
															})}

															{ properties.length === 0 && <tr><td colSpan="100%">No hay propiedades</td></tr> }
														</tbody>
													</Table>	
												</div>
											</LabeledFrame>
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="row">
										<div className="col-4">
											{ (data.id && data.is_deletable) &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteMeter()}>Eliminar</button>							
											}
										</div>
										<div className="col-8 text-end">
											{ data.id &&
												<div className="dropdown d-inline">
													<button ref={actionsDropdownRef} className="btn btn-sm btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
														Acciones
													</button>
													<ActionsContextMenu actionsDropdownRef={actionsDropdownRef} className="dropdown-menu">
														<li><ButtonExportFicha entityKey='meters-readings' entityId={data.id} extension='xlsx' type='excel' fileName={'Lectura de contador ' + meter.name + ' N' + data.number} /></li>

														<li><hr className="dropdown-divider" /></li>
														<li><button className="dropdown-item" onClick={() => saveData(false)}>Guardar</button></li>						
														<li><button className="dropdown-item" onClick={() => saveData()}>Guardar y salir</button></li>
													</ActionsContextMenu>
												</div>
											}
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}