import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { formatNumber } from 'helpers/generic';
import Incomes from './Incomes';
import Expenses from './Expenses';
import Morosity from './Morosity';
import IncomesPending from './IncomesPending';

const ShortStyled = styled.div`
    padding: 20px;

    h1 {
        text-align: center;
        font-size: 25px;
        display: block;
        margin-bottom: 20px;
    }

    h2 {
        font-size: 22px;

        b {
            font-weight: 600;
        }
    }

    table#movements {
        font-size: 13px;

        td {
            &:nth-child(2),
            &:nth-child(3) {
                width: 50px;
                text-align: right;
                white-space: nowrap;
            }
        }

        tfoot {
            font-weight: 600;
        }
    }
`;

export default function Short(props) {
	let data = props.data;
	let exportChartsFix = props.exportChartsFix;
	let year = props.year;

    // Calc totals
    let initial_balance_total = parseFloat(data?.initial_balance?.receipts_pending ?? 0) + parseFloat(data?.initial_balance?.providers_pending ?? 0);
    let final_balance_total = parseFloat(data?.final_balance?.receipts_pending ?? 0) + parseFloat(data?.final_balance?.providers_pending ?? 0);

	return (
		<ShortStyled className="row">
            <div className="col-md-12">
                <h1 className="title">BALANCE RESUMIDO EJERCICIO FISCAL {data.financial_year}</h1>
            </div>

            <div className="col-md-12 mb-3">
                <table id="movements" className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>SALDO INICIAL A { moment(year + '-01-01').format('DD-MM-YYYY') }</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.initial_balance?.treasuries?.map((el, idx) => {
                            initial_balance_total += parseFloat(el.amount ?? 0);

                            return (
                                <tr key={idx}>
                                    <td>{el.name}</td>
                                    <td></td>
                                    <td>{formatNumber(el.amount ?? 0, 2, true)} €</td>
                                </tr>
                            );
                        })}
                        <tr>
                            <td>Recibos pendientes de cobro</td>
                            <td></td>
                            <td>{formatNumber(data?.initial_balance?.receipts_pending ?? 0, 2, true)} €</td>
                        </tr>
                        <tr>
                            <td>Facturas pendientes de pago</td>
                            <td></td>
                            <td>{formatNumber(data?.initial_balance?.providers_pending ?? 0, 2, true)} €</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td>TOTAL SALDO INICIAL</td>
                            <td>{formatNumber(initial_balance_total, 2, true)} €</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            
            <div className="col-md-12">
                <div className="row align-items-center">
                    <Incomes 
                        data={data}
                        exportChartFix={exportChartsFix}
                    />
                </div>
            </div>

            <div className="col-md-12">
                <div className="row align-items-center">
                    <Morosity 
                        data={data}
                        exportChartFix={exportChartsFix}
                    />
                </div>
            </div>
            
            <div className="col-md-12">
                <div className="row align-items-start">
                    <Expenses 
                        data={data} 
                        exportChartFix={exportChartsFix}
                    />
                </div>
            </div>

            {/* <div className="col-md-12">
                <div className="row align-items-center">
                    <IncomesPending 
                        data={data}
                        exportChartFix={exportChartsFix}
                    />
                </div>
            </div> */}

            <div className="col-md-12 mt-5 mb-3">
                <table id="movements" className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>
                                SALDO FINAL A&nbsp;
                                {(() => {
                                    let date = null;
                                    if ( parseInt(year) === parseInt(moment().format('YYYY')) ) {
                                        date = moment().format('YYYY-MM-DD');
                                    } else {
                                        date = year + '-12-31';
                                    }
                                    return moment(date).format('DD-MM-YYYY');
                                })()}
                            </th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.final_balance?.treasuries?.map((el, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{el.name}</td>
                                    <td></td>
                                    <td>{formatNumber(el.amount ?? 0, 2, true)} €</td>
                                </tr>
                            );
                        })}
                        <tr>
                            <td>Recibos pendientes de cobro</td>
                            <td></td>
                            <td>{formatNumber(data?.final_balance?.receipts_pending ?? 0, 2, true)} €</td>
                        </tr>
                        <tr>
                            <td>Facturas pendientes de pago</td>
                            <td></td>
                            <td>{formatNumber(data?.final_balance?.providers_pending ?? 0, 2, true)} €</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td>TOTAL SALDO CONTABLE</td>
                            <td>
                                {(() => {
                                    let balance = 0;

                                    data?.final_balance?.treasuries?.map((el, idx) => {
                                        balance += parseFloat(el.amount ?? 0);
                                    })

                                    balance += data?.final_balance?.receipts_pending ?? 0;

                                    balance += data?.final_balance?.providers_pending ?? 0;

                                    return formatNumber(balance, 2, true);
                                })()}
                                €
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>

            {/* <div className="col-md-12 mt-3 text-end">
                <h2 className="mb-0 d-inline-block bg-light px-2">
                    SALDO: 
                    
                    €
                </h2>     
            </div> */}
        </ShortStyled>
	);
}


