import { useEffect, useRef } from 'react';
import LabeledFrame from 'components/LabeledFrame';
import Reason from './Reason';
import Sortable from 'sortablejs';

export default function Reasons(props) {
	let data = props.data;
	let setData = props.setData;
	let checkedReasons = props.checkedReasons;
	let setCheckedReasons = props.setCheckedReasons;

	let reasons = data.reasons ?? [];
	reasons.forEach((el, idx) => {
		if ( !el.order ) el.order = idx + 1; // Fix set order if no exists to prevent errors on old data
		if ( !el.uid ) el.uid = Date.now() + '-' + idx + '-' + Math.random(); // Fix to prevent react do weirds things when reordering and editing on textarea
	});
	reasons = reasons.sort((a, b) => a.order - b.order);

	let reasonsRef = useRef([]);
	if ( reasonsRef.current.length === 0 && reasons.length > 0 ) reasonsRef.current = reasons;

	let setReasons = (newReasons) => {
		reasonsRef.current = newReasons;
		setData((prev) => ({...prev, reasons: newReasons, notSaved: true}));
	}

	useEffect(() => {
		let el = document.getElementById('reasons_wrapper');

		let sortable = Sortable.create(el, {
			handle: '.number',
			animation: 150,
			onEnd: (e) => {
				let newReasons = [...reasonsRef.current];
				let moved = newReasons.splice(e.oldIndex, 1)[0];
				newReasons.splice(e.newIndex, 0, moved);
				
				newReasons.forEach((el, idx) => {
					el.order = idx + 1;
				});

				setReasons(newReasons);
			}
		});

		return () => {
			sortable.destroy();
		}
	}, []);

	const addReason = () => {
		let newReasons = reasons ? [...reasons] : [];
		newReasons.push({
			description: '',
			result: '',
			options: ['Si', 'No', 'Abstención'],
			opened: true,
			order: newReasons.length + 1,
			checked: false
		});
		setReasons(newReasons);
	}

	const deleteReason = (idx) => {
		const c = window.confirm('¿Seguro que quieres eliminar este dato?');
		if ( !c ) return false;

		// Copy
		let newReasons = reasons ? [...reasons] : [];

		// Uncheck
		let uid = newReasons[idx].uid;
		setCheckedReason(uid, false);

		// Delete
		newReasons.splice(idx, 1);
		setReasons(newReasons);
	}

	const setReasonField = (idx, field, value) => {
		let newReasons = reasons ? [...reasons] : [];
		newReasons[idx][field] = value;
		setReasons(newReasons);
	}

	const setCheckedReason = (uid, checked) => {
		let newCheckedReasons = checkedReasons ? [...checkedReasons] : [];
		if ( checked ) {
			newCheckedReasons.push(uid);
		} else {
			newCheckedReasons = newCheckedReasons.filter(el => el !== uid);
		}
		setCheckedReasons(newCheckedReasons);
	}

	const toggleAllChecked = () => {
		let newCheckedReasons = null;

		let allChecked = checkedReasons.length === reasons.length;
		if ( allChecked ) {
			newCheckedReasons = [];
		} else {
			newCheckedReasons = reasons.map(el => el.uid);
		}
		setCheckedReasons(newCheckedReasons);
	}

	return (
		<LabeledFrame 
			className="mt-2 mb-3 mb-md-0" 
			label={
				<div className="d-flex align-items-center">
					<input type="checkbox" checked={checkedReasons.length === reasons.length} onChange={(e) => toggleAllChecked()} />
					&nbsp;
					Orden del día
				</div>
			}
			buttons={
				<button onClick={addReason} className="btn-unstyled text-white"><i className="bi bi-plus-circle"></i> Añadir</button>
			}
			buttonsBackground="primary"
		>
			<div id="reasons_wrapper">
				{reasons.map((el, idx) => {
					return (
						<Reason 
							key={el.uid}
							number={el.order}
							data={el}
							descriptionEditable={data.announcement_sent_date ? false : true}
							resultEditable={data.act_sent_date ? false : true}
							setField={(field, value) => setReasonField(idx, field, value)}
							delete={() => deleteReason(idx)}
							properties={data.properties?.filter(pEl => pEl.vote)}
							saveData={props.saveData}
							checked={checkedReasons.includes(el.uid)}
							setChecked={checked => setCheckedReason(el.uid, checked)}
							isCheckable={!data.notSaved}
						/>
					);
				})}
			</div>
		</LabeledFrame>
	);
}