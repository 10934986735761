import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getCommunity } from 'helpers/community';
import { formatNumber } from 'helpers/generic';
import CounterPartsSelector from 'components/CounterPartsSelector';

const TableMovements = styled.table`
    font-size: 12px;
    
    thead {

        tr {

            th {
                font-weight: 600;
            }
        }
    }

    th, 
    td {
        &:nth-child(1) {
            width: 80px;
        }

        &:nth-child(3) {
            width: 100px;
            text-align: right;
        }
    }
`;

const Table = styled.table`
	font-size: 13px;

	thead {
		th {
			font-weight: 500;
		}
	}

	td, th {
		&:nth-child(2) {
			width: 100px;
			max-width: 100px;
			min-width: 100px;

			position: relative;
			text-align: right;

			input {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 0;
				text-align: right;
				padding-right: 5px;
				padding-left: 5px;
			}
		}
		&:nth-child(3) {
			width: 40px;
			text-align: center;
		}
	}

	td {
		&:first-of-type {
			padding: 0;
		}

		&.has-errors {
			background: var(--bs-danger);
		}
	}
`;

let axiosCancelToken = null;

export default function CounterParts(props) {
    let hideModal = props.hideModal;
    let entity = props.movements.filter((item) => item.class.indexOf('BankMovement') !== -1)[0] ?? null; 

    let [loading, setLoading] = useState(false);
    let [counterParts, setCounterParts] = useState([]);

    useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

    const saveData = async () => {
        setLoading(true);
        await axios.post('/api/manager/bank-reconciliation/save', {
            type: 'counterparts',
            community_id: getCommunity()?.id,
            bankmovement_id: entity.id,
            counterparts: counterParts.map(el => ({estimategroupexpense_id: el.estimategroupexpense_id, amount: el.amount}))
        }, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Movimientos conciliados');
			hideModal(true, true);
	  	}).catch((error) => {
			hideModal(true);

			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error al guardar');
		})
        setLoading(false);
    }

    const addItem = useCallback(() => {
		let newCounterParts = counterParts ? [...counterParts] : [];
		newCounterParts.push({
			estimategroup_id: null,
			amount: counterParts.length === 0 ? entity.amount : ''
		});
		setCounterParts(newCounterParts);
	}, [counterParts, entity.amount]);

	const removeItem = (idx) => {
		let newCounterParts = [...counterParts];
		newCounterParts.splice(idx, 1);
		setCounterParts(newCounterParts);
	}

    const setAmount = (idx, value) => {
		let newCounterParts = [...counterParts];
		newCounterParts[idx].amount = value;
		setCounterParts(newCounterParts);
	}

    // const selectEstimateGroupExpense = (idx, value) => {
	// 	let newCounterParts = [...counterParts];
	// 	newCounterParts[idx].estimategroupexpense = null;
	// 	if ( value ) {
	// 		newCounterParts[idx].estimategroupexpense = {
	// 			id: value?.id,
	// 			denomination: value?.denomination,
	// 			estimategroup: {
	// 				denomination: value?.estimategroup.denomination,
    //                 title: {
    //                     denomination: value?.title.denomination,
    //                 },
	// 				estimate: {
	// 					type: value?.estimate.type,
	// 					number_full: value?.estimate.number_full
	// 				}
	// 			}
	// 		};
	// 	}
	// 	newCounterParts[idx].estimategroupexpense_id = value?.id;
	// 	setCounterParts(newCounterParts);
	// }

    const selectEstimateGroupExpense = (idx, value) => {
		let newCounterParts = [...counterParts];
		newCounterParts[idx].estimategroupexpense = null;
		if ( value ) {
			newCounterParts[idx].estimategroupexpense = {
				id: value?.id,
				denomination: value?.denomination,
				estimategroup: {
					denomination: value?.estimategroup.denomination,
					// title: {
					// 	denomination: value?.title.denomination
					// },
					estimate: {
						// type: value?.estimate.type,
						// number_full: value?.estimate.number_full
						type: value?.estimategroup.estimate.type,
						number_full: value?.estimategroup.estimate.number_full
					}
				},
				title: {
					denomination: value?.title.denomination
				},
			};
		}
		newCounterParts[idx].estimategroupexpense_id = value?.id;
        setCounterParts(newCounterParts);
	}

    useEffect(() => {
        if ( counterParts.length <= 0 ) {
            addItem();
        }
    }, [counterParts, addItem])

    let counterPartsTotal = counterParts.reduce((carry, item) => carry += item.estimategroupexpense?.id ? parseFloat(item.amount) : 0, 0);
    let canReconciliate = counterPartsTotal === entity.amount;

	return (
		<div className="row">
            <div className="col-md-12 mb-3">
                <label className="sbold">Movimiento bancario a conciliar</label>
                <TableMovements className="table table-sm table-bordered mb-0">
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Concepto</th>
                            <th>Importe</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{moment(entity.date).format('DD-MM-YYYY')}</td>
                            <td>{entity.concept ?? ''}</td>
                            <td>{formatNumber(entity.amount ?? 0)}</td>
                        </tr>
                    </tbody>
                </TableMovements>
            </div>

            <div className="col-md-12">
                <label className="sbold">Contrapartidas a conciliar</label>
                <Table className="table table-sm table-bordered mb-0">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Importe</th>
                            <th>
                                <button className="btn-unstyled" onClick={() => addItem()}>
                                    <i className="bi bi-plus-circle-fill text-primary"></i>
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        { counterParts && counterParts.map((el, idx) => {
                            return (
                                <tr key={idx}>
                                    <td 
                                        // className={(errors['counterparts.'+idx+'.estimategroup_id'] ? 'has-errors' : '')}
                                    >
                                        <CounterPartsSelector 
											communityId={getCommunity()?.id}
											expense={el.estimategroupexpense}
											onChange={(value) => selectEstimateGroupExpense(idx, value)}
                                            year={moment(entity.date).format('YYYY')}
										/>
                                    </td>
                                    <td 
                                        // className={(errors['counterparts.'+idx+'.amount'] ? 'has-errors' : '')}
                                    >
                                        <input 
                                            type="number" 
                                            className="no-arrows" 
                                            value={el.amount ?? ''} 
                                            onChange={(e) => setAmount(idx, e.target.value)} 
                                            min={-99999.99} 
                                            max={99999.99} 
                                            disabled={!counterParts[idx].estimategroupexpense?.id}
                                        />
                                    </td>
                                    <td>
                                        <button className={'btn-unstyled text-danger'} onClick={() => removeItem(idx)}><i className="bi bi-x-circle-fill"></i></button>
                                    </td>
                                </tr>
                            );
                        })}
                        { (!counterParts || !counterParts.length) && <tr><td colSpan="100%" className="p-2">No se han añadido contrapartidas</td></tr> }
                    </tbody>
                    { !canReconciliate &&
                        <tfoot>
                            <tr>
                                <td colSpan="100%" className="text-danger text-center">
                                    El importe a conciliar tiene que ser similiar al importe del movimiento
                                </td>
                            </tr>
                        </tfoot>
                    } 
                </Table>
            </div>
            
            <div className="col-md-12">
                <div className="text-end mt-3">
                    <button type="button" className="btn btn-primary text-white" disabled={!canReconciliate || loading} onClick={() => saveData()}>{loading ? 'cargando...' : 'Confirmar conciliación'}</button>
                </div>
            </div>
        </div>
	);
}


