import React, { useEffect, useState } from 'react';
import LabeledFrame from 'components/LabeledFrame';
import axios from 'axios';
import styled from 'styled-components';
import { formatNumber, openPopupWindow, ucfirst } from 'helpers/generic';
import { getRouterBasename } from 'helpers/config';
import { getCommunity } from 'helpers/community';

const Table = styled.table`
	font-size: 12px;
	margin-bottom: 0;

	thead {
		tr {
			th {
				font-weight: 600;
				white-space: nowrap;
			}
		}
	}

	tbody {
		border-top-color: var(--bs-gray-400) !important;

		tr {
			&.border-bottom {
				td {
					border-bottom: 1px solid var(--bs-primary);
				}
			}

			td {
				border-bottom-width: 0;

				&:first-of-type {
				}
			}
		}
	}

	td,th {
		&:nth-child(3),
		&:nth-child(4) {
			text-align: right;
		}

		&:nth-child(2) {
			${props => props.hasProperty ? 'text-align: right; width: 100px;' : '' }
		}
	}
`;

let axiosCancelToken = null;

export default function OwnersRentersQuotesList(props) {
    
	let [quotas, setQuotas] = useState([]);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getQuotas = async () => {
			await axios.get('/api/manager/previsions/quotas-list', {
				params: {
					community_id: getCommunity()?.id,
					payerable_type: props.type !== 'properties' ? 'App\\Models\\' + ucfirst(props.type.substr(0, props.type.length-1)) : null,
					payerable_id: props.id,
					property_id:  props.type === 'properties' ? props.id : null
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setQuotas([...response.data]);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getQuotas();
	}, [props.type, props.id, props.propertyId]);

    const openDetailsWindow = (what, type, id) => {
		let payerable_part = type !== 'properties' ? '&payerable_type=' + ucfirst(type) + '&payerable_id=' + id : '&property_id=' + id + '&hide_property_selector=true';

		if ( what === 'receipts' ) openPopupWindow(getRouterBasename() + '/receipts?popup=true' + payerable_part + '&hide_payerable_selector=true&hide_buttons_add=true&hide_back_button=true');
		if ( what === 'previsions' ) openPopupWindow(getRouterBasename() + '/previsions?popup=true' + payerable_part + '&hide_payerable_selector=true');
    }

	return (
		<LabeledFrame 
			className="mb-3" 
			label="Relación de cuotas"
			buttons={
				<React.Fragment>
					<button className="btn-unstyled text-primary" onClick={() => openDetailsWindow('receipts', props.type, props.id)}>Informe recibos</button>
					&nbsp;|&nbsp;
					<button className="btn-unstyled text-primary" onClick={() => openDetailsWindow('previsions', props.type, props.id)}>Informe previsiones</button>
				</React.Fragment>
			}
		>
            <Table className="table table-sm table-bordered" hasProperty={props.type === 'properties'}>
				<thead>
					<tr>
						<th>Presupuesto</th>
						{ props.type !== 'properties' && <th>Propiedad</th> }
						<th>Emisiones / Emitidas</th>
						<th>Importe</th>
					</tr>
				</thead>
				<tbody>
					{quotas && quotas.map((el, idx) => {
						return (
							<tr key={idx} className={quotas[idx+1] && quotas[idx+1].property.id !== el.property.id ? 'border-bottom' : ''}>
								<td>{el.estimate?.name}</td>
								{ props.type !== 'properties' && <td>{el.property?.name}</td> }
								<td>{el.estimate?.issues_total} / {el.estimate?.issues}</td>
								<td>{formatNumber(el.amount)} €</td>
							</tr>
						);
					})}
					{!quotas.length && <tr><td colSpan="100%">No hay información</td></tr>}
				</tbody>
			</Table>
		</LabeledFrame>
	);
}