import React, { useEffect, useMemo } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import LabeledFrame from 'components/LabeledFrame';
import EmpoweredSelector from 'components/EmpoweredSelector';
import FieldSmallComment from 'components/FieldSmallComment';

const TableResponsive = styled.div`
	@media (min-width: 992px) {
		overflow-x: visible !important;
	}
`;

const Table = styled.table`
	width: 100%;
	font-size: 12px;

	th {
		font-weight: 500;
		text-align: center;

		&:not(:first-of-type) {
			width: 150px;
			min-width: 150px;
			max-width: 150px;

			@media (max-width: 1200px) {
				width: 100px;
				min-width: 100px;
				max-width: 100px;
			}
		}
	}

	td {
		position: relative;
		height: 40px;

		&:nth-child(1) {
			vertical-align: middle;
			text-align: center;
		}

		.empowered-selector {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			max-width: 150px;
			height: 100%;

			.dropdown {
				height: 100%;				
			
				.dropdown-menu {
					right: 0px;
				}
			}

			.empowered-selector_label {
				border: 0;
				height: 100%;
				font-size: 11px;
				line-height: 11px;
				background: transparent;

				/* &:not(.disabled) {
					background: transparent;
				} */

				& > div {
					max-width: 80%;
				}
			}

			.empowered-selector_menu {
				box-shadow: 0px 2px 5px var(--bs-gray-300);
			}
		}
	}
	
`;

const PayerRow = styled.tr`
	
`;


let axiosCancelToken = null;

export default function Payers(props) {
	let payers = useMemo(() => props.payers ?? [], [props.payers]);
	let setPayers = props.setPayers;
	let renters = props.renters ? [...props.renters].map(el => { el.type = 'renter'; return el; }) : [];
	let owners = props.owners ? [...props.owners].map(el => { el.type = 'owner'; return el; }) : [];
	let errors = props.errors ?? [];
	let readOnly = props.readOnly ?? false;

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	const loadBankAccounts = async (el, input, callback, extraFields = []) => {
		axios.get('/api/manager/'+el.type+'s/get/' + el.id, {
			params: {
				bankaccounts_status: 1
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = extraFields.map((efEl) => {
				return {
					value: efEl,
					label: efEl.iban
				};
			});
			formatted = formatted.concat(
				response.data.bankaccounts.map((bEl) => {
					return {
						value: bEl, 
						label: bEl.iban
					};
				})
			);
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectBankAccount = (type, bankaccountType, payer_id, data) => {
		let pyrs = [...payers];

		let idx = pyrs.findIndex((el) => el.type === type && el.payer_id === payer_id);
		if ( idx === -1 ) {
			pyrs.push({});
			idx = pyrs.length-1;
		}

		pyrs.forEach((el, idx) => {
			el[bankaccountType + '_bankaccount'] = null;
			el[bankaccountType + '_bankaccount_id'] = null;
		});

		pyrs[idx].payer_id = payer_id;
		pyrs[idx].type = type;
		pyrs[idx][bankaccountType + '_bankaccount'] = data;
		pyrs[idx][bankaccountType + '_bankaccount_id'] = data?.id;

		// FIX: If another payer has consumptions -2 (juntar con ordinaria)
		if ( bankaccountType === 'ordinary' && data?.id ) {
			pyrs.forEach((el) => {
				if ( el.consumption_bankaccount_id === -2 && el.payer_id !== payer_id ) {
					el.consumption_bankaccount_id = null;
					el.consumption_bankaccount = null;
				}
			});
		}

		setPayers(pyrs);
	}

	let rows = [];
	let ownersRentersArray = [...owners].concat([...renters]);
	ownersRentersArray.forEach((el, idx) => {
		if ( !el.id ) return; // Prevent show empty line

		let payerData = payers.filter((pyEl) => pyEl.type === el.type && pyEl.payer_id === el.id)[0];
		rows.push(
			<PayerRow key={idx}>
				<td className={errors['payers.' + idx + '.payer_id'] ? 'has-errors' : ''}>
					{el.name} <small className="fw-bold">({el.type === 'owner' ? 'Propietario' : 'Inquilino'})</small>
				</td>
				<td>
					<EmpoweredSelector
						load={(input, callback) => loadBankAccounts(el, input, callback, [{id: -1, iban: 'Pago al contado'}])}
						onChange={(value) => selectBankAccount(el.type, 'ordinary', el.id, value)}
						timeout={250}
						label={payerData?.ordinary_bankaccount && <div>{payerData?.ordinary_bankaccount.iban}</div>}
						placeholder=""
						value={payerData?.ordinary_bankaccount_id}
						noSearcheable={true}
						disabled={readOnly}
					/>
				</td>
				<td>
					<EmpoweredSelector
						load={
							(input, callback, args) => {
								let extraFields = [];
								extraFields.push({id: -1, iban: 'Pago al contado'});
								if ( args.payerData?.ordinary_bankaccount_id ) extraFields.push({id: -2, iban: 'Juntar con ordinaria'});

								return loadBankAccounts(
									el, 
									input, 
									callback, 
									extraFields
								);
							}
						}
						args={{payerData}}
						onChange={(value) => selectBankAccount(el.type, 'consumption', el.id, value)}
						timeout={250}
						label={payerData?.consumption_bankaccount && <div>{payerData?.consumption_bankaccount.iban}</div>}
						placeholder=""
						value={payerData?.consumption_bankaccount_id}
						noSearcheable={true}
						disabled={readOnly}
					/>
				</td>
				<td>
					<EmpoweredSelector
						load={(input, callback) => loadBankAccounts(el, input, callback, [{id: -1, iban: 'Pago al contado'}])}
						onChange={(value) => selectBankAccount(el.type, 'extraordinary', el.id, value)}
						timeout={250}
						label={payerData?.extraordinary_bankaccount && <div>{payerData?.extraordinary_bankaccount.iban}</div>}
						placeholder=""
						value={payerData?.extraordinary_bankaccount_id}
						noSearcheable={true}
						disabled={readOnly}
					/>
				</td>
			</PayerRow>
		);
	});

	return (
		<LabeledFrame 
			className="mb-3" 
			label="Pagadores"
		>
			{ rows.length > 0 &&
				<React.Fragment>
					<TableResponsive className="table-responsive">
						<Table className="table table-sm table-bordered mb-0">
							<thead>
								<tr>
									<th></th>
									<th>Cuota ordinaria</th>
									<th>Consumos</th>
									<th>Cuota extraordinaria</th>
								</tr>
							</thead>
							<tbody>
								{rows}
							</tbody>
						</Table>
					</TableResponsive>
					<FieldSmallComment className="mt-2 text-primary"><i className="bi bi-info-circle-fill"></i> Selecciona una cuenta bancaria en cada tipo de cuota para generar recibos independientes. La cuota de consumos se puede juntar con la cuota ordinaria para un mismo pagador.</FieldSmallComment>
				</React.Fragment>
			}
			{ rows.length === 0 &&
				<div className="small">Todavía no hay pagadores</div>
			}
		</LabeledFrame>
	);
}