import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams, Navigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader } from 'helpers/generic';
import { getCommunity } from 'helpers/community';
import { authUserPermission } from 'helpers/community';

let axiosCancelToken = null;

let parentWindowProxyCallback = window.opener?.BuildingPopupProxyCallback;

export default function BuildingsForm(props) {
	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';

	let community = getCommunity();
	let [data, setData] = useState({});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				await axios.get('/api/manager/buildings/get/' + params.id, {
					params: {
						community_id: community?.id
					},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(response.data);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		}
	}, [params.id, community?.id]);

	const deleteBuilding = () => {
		const c = window.confirm('¿Quieres eliminar este bloque/portal?');
		if ( !c ) return;

		axios.post('/api/manager/buildings/delete/' + data.id, {
			community_id: community?.id
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.info('Bloque/portal borrado')
			
			if ( popup ) {
				window.close();
				if (parentWindowProxyCallback) parentWindowProxyCallback(data);
			}
			else navigate('/buildings');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});
	}

	const saveData = (goBack = true) => {
		setErrors({});

		data.community_id = community?.id;

		axios.post('/api/manager/buildings/' + (data.id ? 'edit/' + data.id : 'add'), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			setData({...response.data.building});

			if ( goBack ) {
				if ( popup ) {
					window.close();
					if (parentWindowProxyCallback) parentWindowProxyCallback(response.data.building);
				}
				else navigate('/buildings');
			} else {
		    	navigate('/buildings/edit/' + response.data.building.id + (popup ? '?popup=true' : ''));
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	if ( !community?.id ) return <Navigate to="/" />;

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de bloque/portal</h1>
					<button onClick={() => popup ? window.close() : navigate('/buildings')} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-4">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-lg-12">
											<div className="mb-2">
												<label>Denominación</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('name', e.target.value)} value={data.name ?? ''} />
												{ errors.name &&
													<div className="invalid-feedback d-block">{ errors.name[0] }</div>
												}
											</div>
										</div>
										{/* <div className="col-lg-8">
											<div className="mb-2">
												<label>Dirección</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('address', e.target.value)} value={data.address ?? ''} />
												{ errors.address &&
													<div className="invalid-feedback d-block">{ errors.address[0] }</div>
												}
											</div>
										</div>
										<div className="col-lg-3">
											<div className="mb-2">
												<label>Código postal</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('postalcode', e.target.value)} value={data.postalcode ?? ''} />
												{ errors.postalcode &&
													<div className="invalid-feedback d-block">{ errors.postalcode[0] }</div>
												}
											</div>
										</div>
										<div className="col-lg-3">
											<div className="mb-2">
												<label>Ciudad</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('city', e.target.value)} value={data.city ?? ''} />
												{ errors.city &&
													<div className="invalid-feedback d-block">{ errors.city[0] }</div>
												}
											</div>
										</div>
										<div className="col-lg-3">
											<div className="mb-2">
												<label>Provincia</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('province', e.target.value)} value={data.province ?? ''} />
												{ errors.province &&
													<div className="invalid-feedback d-block">{ errors.province[0] }</div>
												}
											</div>
										</div>
										<div className="col-lg-3">
											<div className="mb-2">
												<label>País</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('country', e.target.value)} value={data.country ?? ''} />
												{ errors.country &&
													<div className="invalid-feedback d-block">{ errors.country[0] }</div>
												}
											</div>
										</div> */}
									</div>	
								</div>
								<div className="card-footer" id={popup ? 'footer-fixed' : ''}>
									<div className="row">
										<div className="col-6">
											{ (data.id && authUserPermission('delete')) &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteBuilding()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											{ ((authUserPermission('add') && !data.id) || (authUserPermission('edit') && data.id)) &&
												<React.Fragment>
													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>
												</React.Fragment>
											}							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}