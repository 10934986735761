import { Canvg, presets } from 'canvg';

const svgElementToB64Png = async (inputEl) => {
    const preset = presets.offscreen();

    const svgData = new XMLSerializer().serializeToString(inputEl);

    const canvas = new OffscreenCanvas(800, 800);
    const ctx = canvas.getContext('2d')
    const v = await Canvg.fromString(ctx, svgData, preset);
    
    await v.render();

    const blob = await canvas.convertToBlob();

    // Convert blob url to image base 64
    let base64 = await new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            resolve(reader.result);
        }
    });
    
    return base64;
}

export {
    svgElementToB64Png
};