import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { getCommunity } from 'helpers/community';
import { openPopupWindow } from 'helpers/generic';
import EmpoweredSelector from 'components/EmpoweredSelector';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';
import PropertiesTypes from "../PropertiesTypes";

const ModalStyled = styled.div`
	background: rgba(0, 0, 0, 0.4);
`;

const ParticipantsTable = styled.table`
	font-size: 13px;

	thead {
		tr {
			th {
				font-weight: 500;

				&:nth-child(2) {
					width: 250px;
				}

				&:nth-child(3),
				&:last-of-type {
					width: 80px;
				}
			}
		}
	}

	tbody {
		tr {
			td {
				
			}
		}
	}

	th,td {
		&:nth-child(1) {
			position: relative;
			width: 25px;
			min-width: 25px;

			.form-check {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				padding: 0;
				margin: 0;
				display: flex;
				justify-content: center;
				align-items: center;

				input {
					padding: 0;
					margin: 0;
					border-radius: 0;
				}
			}
		}


		&:nth-child(3),
		&:last-of-type {
			text-align: right;
			white-space: nowrap;
		}
	}

	thead,
	tfoot {
		background: var(--bs-gray-100);
	}
`;

let axiosCancelToken = null;

export default function GroupModal(props) {
	const modalRef = useRef(null);

	const idx = props.idx;
	const group = props.group;
	const setGroup = props.setGroup;
	const groupsCount = props.groupsCount;
	const closeCallback = props.closeCallback;

	const [btnAddParentId, setBtnAddParentId] = useState(null);

	const [number, setNumber] = useState(group?.number ?? groupsCount+1);
	const [denomination, setDenomination] = useState(group?.denomination ?? '');
	const [distributionType, setDistributionType] = useState(group?.distribution_type ?? 'coefficient');

	const [buildings, setBuildings] = useState([]);
	const [buildingsChecked, setBuildingsChecked] = useState(group?.buildings_ids ?? null);
	const [propertyTypes, setPropertyTypes] = useState([]);
	const [propertyTypesChecked, setPropertyTypesChecked] = useState(group?.property_types_ids ?? null);
	const [properties, setProperties] = useState([]);
	const [propertiesChecked, setPropertiesChecked] = useState(group?.properties_ids ?? []);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const modal = new Modal(modalRef.current, {
			backdrop: false
		});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();
			closeCallback();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

		modal.show();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [closeCallback]);	

	useEffect(() => {
		window.PopupProxyCallback = (data) => {
			// setContableAccount(data);
		}		

		return function cleanup() {
			window.PopupProxyCallback = undefined;
		}
	}, [group]);

	useEffect(() => {
		const getFilters = async () => {
			await axios.get('/api/manager/buildings/list', {
				params: {
					no_paginate: true,
					community_id: getCommunity()?.id
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setBuildings([...response.data]);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	

			await axios.get('/api/manager/properties-types/list', {
				params: {
					no_paginate: true,
					community_id: getCommunity()?.id
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setPropertyTypes([...response.data]);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		};
		getFilters();
	}, []);

	// Set buildings and property type checked by default
	useEffect(() => {
		if ( buildingsChecked === null && buildings.length > 0 ) setBuildingsChecked(buildings.map(el => el.id));
		if ( propertyTypesChecked === null && propertyTypes.length > 0 ) setPropertyTypesChecked(propertyTypes.map(el => el.id));
	}, [buildings, buildingsChecked, propertyTypes, propertyTypesChecked]);

	useEffect(() => {
		// Check if filters to search
		// if ( buildingsChecked?.length === 0 || propertyTypesChecked?.length === 0 ) {
		// 	setProperties([]);
		// 	return;
		// }

		const loadProperties = () => {
			let params = {
				no_paginate: true,
				community_id: getCommunity()?.id,
				with_quotas_concepts: true
			};
			if ( buildingsChecked?.length > 0 ) params.buildings_ids = buildingsChecked;
			if ( propertyTypesChecked?.length > 0 ) params.types_ids = propertyTypesChecked;

			// console.log(params);

			axios.get('/api/manager/properties/list', {
				params: params,
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setProperties([...response.data]);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		};
		loadProperties();
	}, [buildingsChecked, propertyTypesChecked]);

	const toggleBuildingChecked = (id) => {
		let checked = [...buildingsChecked];
		let idx = checked.findIndex(el => el === id);
		if ( idx !== -1 ) checked.splice(idx, 1);
		else checked.push(id);
		setBuildingsChecked(checked);
	}

	const togglePropertyTypeChecked = (id) => {
		let checked = [...propertyTypesChecked];
		let idx = checked.findIndex(el => el === id);
		if ( idx !== -1 ) checked.splice(idx, 1);
		else checked.push(id);
		setPropertyTypesChecked(checked);
	}

	const togglePropertiesChecked = (id) => {
		let checked = [...propertiesChecked];
		let cIdx = checked.findIndex(el => el === id);
		if ( cIdx !== -1 ) checked.splice(cIdx, 1);
		else checked.push(id);
		setPropertiesChecked(checked);
	}

	const toggleAllPropertiesChecked = (status) => {
		let checkedCount = propertiesChecked.length;
		let totalCount = properties.length;

		if ( status && checkedCount < totalCount ) setPropertiesChecked(properties.map(el => el.id));
		if ( !status ) setPropertiesChecked([]);
	}

	const saveData = () => {
		// Set data
		let groupData = {}
		groupData.number = number;
		groupData.denomination = denomination;
		groupData.distribution_type = distributionType;
		groupData.buildings_ids = buildingsChecked;
		groupData.property_types_ids = propertyTypesChecked;
		groupData.properties_ids = propertiesChecked;

		// Add properties entities to group (needed to show property in <QuotesList />)
		groupData.properties = properties.filter(el => propertiesChecked.indexOf(el.id) !== -1);

		setGroup(idx, {...group, ...groupData});

		// Hide modal
		const modal = Modal.getInstance(modalRef.current);
		modal.hide();
	}	

	let totalCoefficient = 0;
	properties.forEach(el => {
		let checked = propertiesChecked.findIndex(id => id === el.id) !== -1;
		totalCoefficient += checked ? parseFloat(el.coefficient) : 0;
	});

	const openPopupInfoWindow = (e) => {
		e.preventDefault();
		e.stopPropagation();

		openPopupWindow(e.currentTarget.href);
	}

	const openPopupWindowMouseDownFix = (e) => {
		e.preventDefault();
		e.stopPropagation();
		document.activeElement.blur();
	}

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Grupo de reparto</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<div className="row">
							<div className="col-md-1">
								<div className="mb-2">
									<CustomInput label="Nº" type="number" className="form-control form-control-sm no-arrows" value={number ?? ''} onChange={(e) => setNumber(e.target.value)} />
								</div>
							</div>
							<div className="col-md-8">
								<div className="mb-2">
									<div className="input-group input-group-sm">
										<CustomInput label="Denominación" type="text" className="form-control form-control-sm no-arrows" value={denomination ?? ''} onChange={(e) => setDenomination(e.target.value)} />
										<button className="btn btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
										<ul className="dropdown-menu dropdown-menu-end">
											<li><button className="dropdown-item" href="." onClick={(e) => setDenomination('Gastos generales comunes')}>Gastos generales comunes</button></li>
											<li><button className="dropdown-item" href="." onClick={(e) => setDenomination('Gastos generales portal')}>Gastos generales portal</button></li>
											<li><button className="dropdown-item" href="." onClick={(e) => setDenomination('Gastos generales escalera')}>Gastos generales escalera</button></li>
											<li><button className="dropdown-item" href="." onClick={(e) => setDenomination('Gastos generales garaje')}>Gastos generales garaje</button></li>
											<li><button className="dropdown-item" href="." onClick={(e) => setDenomination('Gastos extraordinarios')}>Gastos extraordinarios</button></li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className="mb-2">
									<CustomSelect label="Reparto" className="form-control form-control-sm" value={distributionType ?? ''} onChange={(e) => setDistributionType(e.target.value)}>
										<option value="coefficient">Coeficiente</option>
										<option value="equal">Partes iguales</option>
										<option value="consumption">Consumos</option>
									</CustomSelect>
								</div>
							</div>
							<div className="col-md-12 mt-3">
								<div className="row">
									<div className="col-md-6">
										<div className="mb-2">
											<label className="d-flex align-items-center">
												Bloques / Portales

												<div className="form-check form-switch d-inline-block ms-3">
													<input 
														className="form-check-input" 
														type="checkbox" 
														defaultChecked={true}
														onChange={(e) => {
															let checked = [];
															if ( e.target.checked ) checked = buildings.map(el => el.id);
															setBuildingsChecked(checked);
														}} 
													/>
												</div>
											</label>
											<div>
												{buildings.map((el, idx) => {
													let id = 'building_'+el.id;
													return (
														<div key={id} className="form-check form-switch d-inline-block me-3">
															<input className="form-check-input" type="checkbox" id={id} checked={buildingsChecked?.indexOf(el.id) !== -1} onChange={(e) => toggleBuildingChecked(el.id, e.target.checked)} />
															<label className="form-check-label" htmlFor={id}>{el.name}</label>
														</div>
													);
												})}
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="mb-2">
										<label className="d-flex align-items-center">
												Tipos de propiedades
												
												<div className="form-check form-switch d-inline-block ms-3">
													<input 
														className="form-check-input" 
														type="checkbox" 
														defaultChecked={true}
														onChange={(e) => {
															let checked = [];
															if ( e.target.checked ) checked = propertyTypes.map(el => el.id);
															setPropertyTypesChecked(checked);
														}} 
													/>
												</div>
											</label>
											<div>
												{propertyTypes.map((el, idx) => {
													let id = 'propertytype_'+el.id;
													return (
														<div key={id} className="form-check form-switch d-inline-block me-3">
															<input className="form-check-input" type="checkbox" id={id} checked={propertyTypesChecked?.indexOf(el.id) !== -1} onChange={(e) => togglePropertyTypeChecked(el.id, e.target.checked)} />
															<label className="form-check-label" htmlFor={id}>{el.name}</label>
														</div>
													);
												})}
											</div>
										</div>
									</div>	
								</div>
							</div>
							<div className="col-md-12">
								<div className="table-responsive">
									<ParticipantsTable className="table table-sm table-bordered mb-0">
										<thead>
											<tr>
												<th>
													<div className="form-check">
														<input className="form-check-input" type="checkbox" checked={propertiesChecked?.length === properties.length} onChange={(e) => toggleAllPropertiesChecked(e.target.checked)} />
													</div>
												</th>
												<th>Propiedad</th>
												<th>Coeficiente</th>
												<th>Propietarios</th>
												<th>Participación</th>
											</tr>
										</thead>
										<tbody>
											{properties.map((el, idx) => {
												let id = 'property_' + el.id;
												let checked = propertiesChecked.findIndex(id => id === el.id) !== -1;
												let participation = '';
												if ( checked ) {
													if ( distributionType === 'coefficient' ) participation = ((parseFloat(el.coefficient) * 100) / totalCoefficient).toFixed(2);
													if ( distributionType === 'equal' ) participation = (100 / propertiesChecked.length).toFixed(2);
													if ( distributionType === 'consumption' ) participation = <small>según consumo</small>;
												}
												return (
													<tr key={id}>
														<td>
															<div className="form-check">
																<input className="form-check-input" type="checkbox" id={id} checked={propertiesChecked.indexOf(el.id) !== -1} onChange={(e) => togglePropertiesChecked(el.id, e.target.checked)} />
															</div>
														</td>
														<td>{el.name}</td>
														<td>{el.coefficient}</td>
														<td>
															{el.owners?.map((oEl, oIdx) => {
																return (
																	<div key={'owner_' + el.id + '_' + oEl.id}>
																		{el.owners.length > 1 && <span>-</span>} {oEl.name}
																	</div>
																);
															})}
														</td>
														<td>{ participation }</td>
													</tr>
												);
											})}
											{properties.length === 0 &&
												<tr><td colSpan="100%">No existen propiedades con los filtros seleccionados</td></tr>
											}
										</tbody>
										{ (properties.length > 0 && distributionType !== 'consumption') &&
											<tfoot>
												<tr>
													<td></td>
													<td></td>
													<td>{ totalCoefficient.toFixed(4) }</td>
													<td></td>
													<td>{ propertiesChecked.length > 0 ? '100' : '0' }</td>
												</tr>
											</tfoot>
										}
									</ParticipantsTable>
								</div>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className={'btn btn-sm btn-primary text-white '  + (!denomination.length ? 'disabled' : '')} onClick={() => saveData()}>Aceptar</button>
					</div>
				</div>
			</div>
		</ModalStyled>
	);
}


