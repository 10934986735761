import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import styled from 'styled-components';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import readXlsxFile from 'read-excel-file'
import { toast } from "react-toastify";
import FieldSmallComment from "components/FieldSmallComment";
import { getCommunity } from "helpers/community";
import { loader } from "helpers/generic";
import { Tooltip } from 'bootstrap/dist/js/bootstrap';

const ModalStyled = styled.div`
	background: rgba(0, 0, 0, 0.4);

	.modal {
		position: relative;
	}
`;

const FileWrapper = styled.div`
	width: 100%;
	padding: 20px;
	background: var(--bs-light);
	border: 1px solid var(--bs-gray-300);
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	input[type=file] {
		visibility: hidden;
		height: 0;
	}
`;

const Table = styled.table`
	font-size: 12px;
	font-weight: normal;

	tr {
		&.success {
			td {
				background: rgba(var(--bs-success-rgb), 0.5);

				input {
					pointer-events: none;
					outline: 0;
					user-select: none;
				}
			}
		}

		&.errors {
			background: rgba(var(--bs-danger-rgb), 0.2);

			td.error {
				background: rgba(var(--bs-danger-rgb), 0.6);
			}
		}

		&.loading {
			input {
				font-weight: 600;
				pointer-events: none;
				outline: 0;
				user-select: none;
			}

			#loader {
				position: absolute;
				right: 2px;
				top: 5%;
				height: 90%;
				background: rgba(var(--bs-primary-rgb), 0.7);
				border-radius: 50%;
			}
		}
	}

	td {
		position: relative;
		height: 25px;

		&:nth-child(1) {
			width: 15%;
		}
		&:nth-child(2) {
			width: 5%;
		}
		&:nth-child(3) {
			width: 5%;
		}
		&:nth-child(3) {
			width: 10%;
		}
		&:nth-child(5) {
			width: 10%;
		}
		&:nth-child(6) {
			width: 3%;
		}
		&:nth-child(7) {
			width: 10%;
		}
		&:nth-child(8) {
			width: 10%;
		}
		&:nth-child(9) {
			width: 10%;
		}
		&:nth-child(10) {
			width: 10%;
		}

		input[type=text] {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 0;
			background: transparent;
		}
	}
`;

let axiosCancelToken = null;

export default function ImportExcelModal(props) {
	const modalRef = useRef(null);
	const fileRef = useRef(null);

	const closeCallback = props.closeCallback;

	const [file, setFile] = useState(null);
	const [data, setData] = useState([]);
	const [errors, setErrors] = useState(null);

	const [uploadingRow, setUploadingRow] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [uploadSuccess, setUploadSuccess] = useState({});
	const [uploadErrors, setUploadErrors] = useState({});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const modal = new Modal(modalRef.current, {backdrop: false});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();
			closeCallback();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

		modal.show();

		// Fix, because bootstrap removes scroll and add padding on modal opened
		document.body.style.overflow = 'auto'; 
		document.body.style.paddingRight = '0';
		
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [closeCallback]);

	useEffect(() => {
		let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
		let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
		  	return new Tooltip(tooltipTriggerEl)
		});

		return function cleanup() {
			tooltipList.forEach((el) => el?.dispose());
		}
	});

	const loadFile = async (file) => {
		// Reset
		setFile(null);
		setData([]);
		setUploadErrors({});
		setUploadSuccess({});

		// Check if exists the file
        if ( !file ) return false;

		// Read file
		readXlsxFile(file).then((rows) => {
			let data = [...rows].filter((el, idx) => idx > 0).map((el, idx) => {
				return {
					name: el[0] ? ''+el[0] : null,
					vatnumber: el[1] ? ''+el[1] : null,
					position: el[2] ? ''+el[2] : null,
					address: el[3] ? ''+el[3] : null,
					city: el[4] ? ''+el[4] : null,
					postalcode: el[5] ? ''+el[5] : null,
					province: el[6] ? ''+el[6] : null,
					phone: el[7] ? ''+el[7] : null,
					email: el[8] ? ''+el[8] : null,
					instructions: el[9] ? ''+el[9] : null,
				};
			});

			// Validate 
			let errors = null;
			if ( !data.length ) errors = 'No se han encontrado datos';
			setErrors(errors);
			
			// Set data
			setData(data);

			// Set file
			setFile(file);
		}).catch((error) => {
			console.error(error);
			setFile(null);
			setData([]);
			toast.error('Formato de archivo no válido')
			return;
		});	
	}

	const setDataField = (idx, field, value) => {
		let newData = [...data];
		newData[idx][field] = value;
		setData([...newData]);
	}


	const saveData = async () => {
		setUploading(true);
		setUploadErrors({});

		let idx = 0;
		let errorsCount = 0;

		for await (let owner of data) {
			// Prevent save already saved data (after first try)
			if ( uploadSuccess[idx] ) {
				idx++;
				continue;
			}
			
			setUploadingRow(idx);
			
			// Code
			owner.code = await getNewCodeAsync();

			// Position
			let position = await getPositionIdAsync(owner.position);
			if ( position ) owner.position_id = position;

			// Save
			let result = await saveOwnerAsync(owner);
			let successIdx = null;
			let errorIdx = null;

			if ( result.status ) {
				successIdx = idx;
			} else {
				errorIdx = idx;
				errorsCount++;
			}

			setUploadSuccess((prev) => {
				if ( successIdx !== null ) prev[successIdx] = true;
				return prev;
			});
			setUploadErrors((prev) => {
				if ( successIdx !== null ) delete prev[successIdx];
				if ( errorIdx !== null ) {
					prev[errorIdx] = {...result};

					// Sanitize address errors
					let keys = Object.keys(result).filter(el => el.indexOf('addresses') !== -1);
					keys.forEach(key => {
						let split = key.split('.');
						let newKey = split[split.length - 1];
						prev[errorIdx][newKey] = result[key];
					})
				}
				return prev;
			});
			setUploadingRow(null);

			idx++;
		}

		setUploading(false);

		if ( !errorsCount ) closeCallback();
	}

	const getNewCodeAsync = async (data) => {
		return axios.get('/api/manager/owners/get-next-code', {
			params: {
				community_id: getCommunity()?.id
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});
	}

	const getPositionIdAsync = async (data) => {
		if ( !data || !data.length ) return null;

		return axios.get('/api/manager/community-positions/list', {
			params: {
				community_id: getCommunity()?.id,
				search: data,
				no_paginate: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			return response.data && response.data[0] ? response.data[0].id : null;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});
	}

	const saveOwnerAsync = async (data) => {

		// Prepare address
		let address = {
			default: true,
			type: 'both',
			name: data.name,
			address: data.address,
			postalcode: data.postalcode,
			province: data.province,
			phone: [
				{
					phone: data.phone,
					default: true
				}
			],
			email: [
				{
					email: data.email,
					default: true
				}
			]
		};
		if ( !data.address && !data.address && !data.postalcode && !data.phone && !data.email ) {
			address = null;
		}

		return axios.post('/api/manager/owners/add', {
			community_id: getCommunity()?.id,
			rgpd_consent: false,
			code: data.code,
			name: data.name,
			vatnumber: data.vatnumber,
			position_id: data.position_id,
			addresses: address ? [{...address}] : null,
			observations: data.instructions
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			return response.data;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			return error.response.data.errors;
		});	
	}

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-xl">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Importar Excel</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<FileWrapper onClick={() => {fileRef.current.value = null; fileRef.current.click();}}>
							{ file === null ?
								<div>Pulsa para seleccionar el fichero</div>
								:
								<div className="text-center">{file.name}</div>
							}
							<input type="file" ref={fileRef} onChange={(e) => loadFile(e.target.files[0])} />
						</FileWrapper>
						
						{ data.length <= 0 && 
							<>
								<div className="mt-3 mb-1 fw-bold">Ejemplo de excel</div>
								<div className="table-responsive">
									<Table className="table table-sm table-bordered">
										<thead>
											<tr>
												<th>Nombre</th>
												<th>DNI</th>
												<th>Cargo</th>
												<th>Dirección</th>
												<th>Población</th>
												<th>CP</th>
												<th>Provincia</th>
												<th>Teléfono</th>
												<th>Email</th>
												<th>Instrucciones</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>José</td>
												<td>123456A</td>
												<td>Presidente</td>
												<td>Margaritas nº 25</td>
												<td>A Coruña</td>
												<td>15001</td>
												<td>A coruña</td>
												<td>666555111</td>
												<td>email@ejemplo.com</td>
												<td>Escribir aquí observaciones</td>
											</tr>
											<tr>
												<td>...</td>
												<td>...</td>
												<td>...</td>
												<td>...</td>
												<td>...</td>
												<td>...</td>
												<td>...</td>
												<td>...</td>
												<td>...</td>
												<td>...</td>
											</tr>
											<tr>
												<td>...</td>
												<td>...</td>
												<td>...</td>
												<td>...</td>
												<td>...</td>
												<td>...</td>
												<td>...</td>
												<td>...</td>
												<td>...</td>
												<td>...</td>
											</tr>
										</tbody>
									</Table>
								</div>
							</>
						}
					
						{ data.length > 0 && 
							<>
								<div className="mt-3 mb-1 fw-bold">Contenido del archivo seleccionado</div>
								<div className="table-responsive">
									<Table className="table table-sm table-bordered">
										<thead>
											<tr>
												<th>Nombre</th>
												<th>DNI</th>
												<th>Cargo</th>
												<th>Dirección</th>
												<th>Población</th>
												<th>CP</th>
												<th>Provincia</th>
												<th>Teléfono</th>
												<th>Email</th>
												<th>Instrucciones</th>
											</tr>
										</thead>
										<tbody>
											{data && data.map((el, idx) => {
												let success = uploadSuccess[idx] ? true : false;
												let errors = uploadErrors[idx] ?? false;
												let uploading = uploadingRow === idx && loader;

												let FIELDS = ['name', 'vatnumber', 'position', 'address', 'city', 'postalcode', 'province', 'phone', 'email', 'instructions'];

												return (
													<tr key={idx} className={(success ? 'success' : '') + ' ' + (errors ? 'errors' : '') + ' ' + (uploading ? 'loading' : '')}>
														{FIELDS.map((field, fieldIdx) => {
															return (
																<td key={field+idx} className={errors[field] ? 'error' : ''}>
																	{(uploading && fieldIdx === 0) && loader}
																	<input 
																		type="text" 
																		value={el[field] ?? ''} 
																		onChange={(e) => setDataField(idx, field, e.target.value)} 
																		readOnly={success || uploading} 

																		data-bs-toggle="tooltip" 
																		data-bs-placement="bottom" 
																		data-bs-trigger="focus" 
																		title={errors[field] ? errors[field][0] : null}
																	/>
																</td>
															);
														})}
													</tr>
												)
											})}
										</tbody>
									</Table>
								</div>
							</>
						}

						{ errors &&
							<FieldSmallComment className="mt-3 text-danger">{errors}</FieldSmallComment>
						}
					</div>
					<div className="modal-footer">
						<button type="button" className={'btn btn-sm btn-primary text-white'} onClick={() => saveData()} disabled={!data.length || errors || uploading}>Importar datos</button>
					</div>
				</div>
			</div>
		</ModalStyled>
	);
}