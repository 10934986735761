import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { getCommunity } from 'helpers/community';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { formatNumber } from 'helpers/generic';

const Table = styled.table` 
    font-size: 12px;

    thead {
        th {
            font-weight: 500;

            &:nth-child(1) {
                width: 250px;
            }
        }
    }

    th, td {
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
            width: 90px;
            min-width: 90px;
            text-align: right;
        }
    }
`;

let axiosCancelToken = null;

export default function Model347() {
	let [financialYear, setFinancialYear] = useState(moment().format('YYYY'));
	let [minimumAmount, setMinimumAmount] = useState(3000);
    let [community, setCommunity] = useState({
		id: getCommunity()?.id,
		name: getCommunity()?.name
	});
    let [data, setData] = useState([]);

    useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

    useEffect(() => {
        const getData = async () => {     
            axiosCancelToken.cancel(); // Cancel previous request
            axiosCancelToken = axios.CancelToken.source(); // Get new token
            
            await axios.get('/api/manager/administration-models/get-347', {
                params: {
                    community_id: community?.id,
                    financial_year: financialYear,
                    minimum_amount: minimumAmount
                },
                cancelToken: axiosCancelToken.token
            }).then((response) => {
                setData(response.data);
            }).catch((error) => {
                if ( axios.isCancel(error) ) return;
            });	
        }
        getData();
    }, [financialYear, community?.id, minimumAmount]);

    const loadFinancialYears = (input, callback, args) => {
		axios.get('/api/manager/administration-models/financialyears-list', {
			params: {
                community_id: args.community_id
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

    const loadCommunities = (input, callback) => {
		axios.get('/api/manager/communities/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

    const exportModel = async () => {
        await axios.post('api/manager/administration-models/export/347', {
            community_id: community?.id,
            financial_year: financialYear,
            minimum_amount: minimumAmount
        }, {
			responseType: 'blob',
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'modelo347.xlsx');
			document.body.appendChild(link);
			link.click();
			link.parentElement.removeChild(link);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});
    }

	return (
		<React.Fragment>
            <div className="row">
                <div className="col-md-3">
                    <label>Comunidad</label>
                    <EmpoweredSelector
                        load={loadCommunities}
                        onChange={(value) => setCommunity({id: value?.id, name: value?.name})}
                        timeout={250}
                        label={community?.name ?? ''}
                        placeholder="Todas las comunidades"
                        value={community?.id}
                    />
                </div>
                <div className="col-md-2">
                    <label>Año fiscal</label>
                    <EmpoweredSelector
                        load={loadFinancialYears}
                        args={{community_id: community?.id}}
                        onChange={(value) => setFinancialYear(value)}
                        timeout={250}
                        label={financialYear ?? ''}
                        placeholder=""
                        value={financialYear ?? ''}
                        noSearcheable={true}
                    />
                </div>
                <div className="col-md-2">
                    <label>Importe mínimo</label>
                    <input type="number" className="form-control form-control-sm no-arrows" value={minimumAmount} onChange={(e) => setMinimumAmount(e.target.value)} />
                </div>
                <div className="col-md-5 text-end pt-4">
                    <button className="btn btn-sm btn-light" onClick={exportModel}>Exportar</button>
                </div>
            </div>

            <div className="table-responsive mt-3">
                <Table className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>Comunidad</th>
                            <th>Proveedor</th>
                            <th>T-1</th>
                            <th>T-2</th>
                            <th>T-3</th>
                            <th>T-4</th>
                            <th>TOTAL</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((el, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{el.community?.name}</td>
                                    <td>
                                        {el.provider?.business_name}
                                        <span className="badge bg-light">{el.provider?.vatnumber}</span>
                                    </td>
                                    <td>{formatNumber(el.t1)} €</td>
                                    <td>{formatNumber(el.t2)} €</td>
                                    <td>{formatNumber(el.t3)} €</td>
                                    <td>{formatNumber(el.t4)} €</td>
                                    <td>{formatNumber(el.total)} €</td>
                                </tr>
                            )
                        })}
                        {!data.length && <tr><td colSpan="100%">No se han encontrado resultados</td></tr>}
                    </tbody>
                </Table>
            </div>  
        </React.Fragment>
	);
}


