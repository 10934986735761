import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import styled from 'styled-components';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { openPopupWindow, formatNumber, loader } from 'helpers/generic';
import { getCommunity } from "helpers/community";
import { getRouterBasename } from "helpers/config";

const ModalStyled = styled.div`
	background: rgba(0,0,0, 0.7);
`;

const Loader = styled.div`
    width: 200px;
    margin: 0 auto;
    margin-top: 20px;
`;

let axiosCancelToken = null;
let newProviderInvoiceWindow = null;
let saveSuccessSwitch = false;

export default function CounterPartsModal(props) {
	const modalRef = useRef(null);

	const data = props.data;
	const closeCallback = props.closeCallback;

	const [creatingInvoice, setCreatingInvoice] = useState(false);
	const [loading, setLoading] = useState(false);

	const hideModal = () => {
		const modal = Modal.getInstance(modalRef.current);
		if ( modal ) modal.hide();
	}

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const modal = new Modal(modalRef.current, {backdrop: false});

		const hiddenEvent = (e) => {
			if ( e.target !== modal._element ) return; // Fix to avoid close when a child modal is closed

           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();

			closeCallback(saveSuccessSwitch);

			// Close new invoice window if modal is closed and new window is opened
			if ( newProviderInvoiceWindow && !newProviderInvoiceWindow.closed ) newProviderInvoiceWindow.close();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);
		modal.show();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [closeCallback]);

	useEffect(() => {
		window.PopupProxyCallback = (newInvoice) => {
            if ( newInvoice?.id ) {
				updateBankReconciliation(newInvoice);
			}
			setCreatingInvoice(false);
		}

		return function cleanup() {
			window.PopupProxyCallback = null;
		}
	}, []);

	const updateBankReconciliation = async (invoice) => {
		setLoading(true);

		saveSuccessSwitch = false;

		// Delete previous
		let result = await axios.post('/api/manager/bank-reconciliation/delete/' + data.reconciliation[0].bank_reconciliation_id, {
            community_id: getCommunity()?.id,
        }, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			return true;
	  	}).catch((error) => {
			toast.error('Ha ocurrido un error al guardar');
			return false;
		});	
		if ( !result ) return;

		// Create new reconciliation
		let movements = []
		movements.push({
			class: 'App\\Models\\ProviderInvoice',
			concept: invoice.provider?.business_name + ' / ' + invoice.number,
			id: invoice.id,
			amount: invoice.total
		});
		movements.push({
			class: 'App\\Models\\BankMovement',
			concept: data.concept,
			id: data.id,
			amount: data.amount,
			date: data.date,
		});

		let counterparts = [
			data.reconciliation.map(el => ({estimategroupexpense_id: el.entity.id, amount: el.entity.amount * -1}))
		];

		let saveBankReconciliationResult = await axios.post('/api/manager/bank-reconciliation/save', {
			type: 'provider-invoice',
			community_id: getCommunity()?.id,
			movements: movements,
			counterparts: counterparts
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Movimientos conciliados');
			return true;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error al guardar');
			return false
		});

		saveSuccessSwitch = saveBankReconciliationResult;

		console.log(saveSuccessSwitch);

		hideModal();
	}

	const newProviderInvoice = () => {
		newProviderInvoiceWindow = openPopupWindow(
			getRouterBasename() + '/providers-invoices/add?popup=true' + 
			'&page_title=Convertir contrapartidas a factura' +
			'&community_id=' + getCommunity()?.id + 
			'&date_reception=' + moment(data.date).format('YYYY-DD-MM') +
			'&amount=' + (data.amount * -1) +
			'&concept=' + data.concept +
			'&hidden_sections=counterparts,payments,save_button' +
			'&fixed_total=' + (data.amount * -1)
		);

		setCreatingInvoice(true);
	}

	const openEstimateWindow = async (counterpart_id) => {
		let w = openPopupWindow();
		await axios.get('/api/manager/estimates/get-id-by-groupexpense/' + counterpart_id, {
			params: {
				community_id: getCommunity()?.id,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			w.location.href = getRouterBasename() + '/estimates/edit/' + response.data.id + '?popup=true';
		}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error');
		});	
	}

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Detalles de la contrapartida</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						{ creatingInvoice &&
							<div className="col-md-12 mt-3 text-center">
								Esperando la creación de la factura en la ventana abierta... 
								<Loader>{loader}</Loader>
							</div>
						}

						{ !creatingInvoice &&
							<table className="table table-sm table-bordered">
								<thead>
									<tr>
										<td colSpan="100%">
											Contrapartidas
										</td>
									</tr>
								</thead>
								<tbody>
									{data.reconciliation.map((entity, index) => (
										<tr key={index}>
											<td>
												<div className="d-flex justify-content-between">
													<span>{entity.entity.concept}</span>
													<button className="btn btn-sm btn-link p-0" onClick={() => openEstimateWindow(entity.entity.id)}> <i className="bi bi-eye"></i> </button>
												</div>
											</td>
											<td className="text-end">{formatNumber(entity.entity.amount, 2)}</td>
										</tr>
									))}
								</tbody>
							</table>
						}
					</div>
					{ !creatingInvoice && 
						<div className="modal-footer">
							<button className="btn btn-primary text-white" onClick={() => newProviderInvoice()} disabled={loading}>Convertir a factura</button>
						</div>
					}
				</div>
			</div>
		</ModalStyled>
	);
}


