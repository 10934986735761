import React, { useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import LabeledFrame from 'components/LabeledFrame';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { NavLink } from 'react-router-dom';
import { openPopupWindow, formatNumber } from 'helpers/generic';

const TableInvoices = styled.table`
	font-size: 12px;

	tbody {

		tr {

			td {

				&:nth-child(2) {
					min-width: 120px;
					max-width: 120px;
					width: 120px;
				}

				&:nth-child(3) {
					min-width: 80px;
					max-width: 80px;
					width: 80px;
				}
			}

			&.pending {
				background: rgba(1, 1, 1, 0.03);

				td {
					position: relative;
					height: 35px;

					input {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: transparent;
						border: 0;
						padding: 0 5px;
						outline: 0;

						&.has-errors {
							border: 1px solid var(--bs-red);
							color: var(--bs-red);
						}
					}

					&:nth-child(1) {

						span {
							text-transform: uppercase;
							position: absolute;
							top: 2px;
							left: 5px;
							color: var(--bs-primary);
							font-size: 10px;
							font-weight: 500;
						}

						button {
							position: absolute;
							top: -2px;
							right: 1px;
							color: var(--bs-red);
							height: auto;
							padding: 0;
							margin: 0;
							border: none;
							z-index: 2;
							background: transparent;
						}

						input {
							padding-top: 10px;
						}
					}

					&:nth-child(2) {

						a {
							display: inline-block;
							font-size: 11px;
							line-height: 11px;
						}
					}

					&:nth-child(3) {

						input {
							text-align: right;
						}
					}
				}
			}
		}
	}

	tfoot {
		button {
			font-size: 12px;
			padding: 0 5px;
		}
	}
`;

let axiosCancelToken = null;

export default function Provider(props) {
	const data = props.data;
	const setData = props.setData;
	const errors = props.errors;
	const interveners = props.data.interveners.length > 0 ? [...props.data.interveners] : [{id: null, business_name: null}];

	let lastRow = useRef(null);
	let lastRowNumber = useRef(null);

	useEffect(() => {
		if ( lastRow.current ) {
			lastRow.current.focus();
			lastRow.current = null;
		}
	}, [data.pendinginvoices?.length]);

	// Proxy for popup window to update when fields are changed on popup
	useEffect(() => {
		if ( !data.id ) return;

		window.PopupProxyCallbackSecondary = () => { 
			const reloadData = async () => {
				let newData = {};

				await axios.get('/api/manager/providers-invoices/list?notice_id=' + data.id, {
					params: {
						no_paginate: true
					},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
					newData.providerinvoices = [...response.data];
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				await axios.get('/api/manager/notices/get/' + data.id, {
					params: {
						no_paginate: true
					},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
					newData.pendinginvoices = [...response.data.pendinginvoices];
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

			  	setData({...data, ...newData});
			}
			reloadData();
		};
	}, [data, setData]);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	const loadProviders = (input, callback, args) => {
		axios.get('/api/manager/providers/list', {
			params: {
				search: input,
				no_paginate: true,
				community_id: args.community_id,
				except_ids: interveners.map((el) => el.id)
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.business_name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const addIntervener = () => {
		let newData = {...data};
		interveners.push({
			id: null,
			business_name: null
		});
		newData.interveners = interveners;
		setData(newData);
	}

	const deleteIntervener = (idx) => {
		let newData = {...data};
		interveners.splice(idx, 1);
		newData.interveners = interveners;
		setData(newData);
	}

	const selectProvider = (value, idx) => {
		let newData = {...data};
		interveners[idx] = {
			id: value?.id ?? null,
			business_name: value?.business_name ?? null
		};
		newData.interveners = interveners;
		setData(newData);
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	const openPopupWindowMouseDownFix = (e) => {
		e.preventDefault();
		e.stopPropagation();
		document.activeElement.blur();
	}

	const addPendingInvoiceRow = () => {
		let newData = {...data};
		newData.pendinginvoices.push({
			id: null,
			description: '',
			amount: '',
		});
		setData(newData);

		lastRowNumber.current = newData.pendinginvoices.length - 1; // Needed to focus
	}

	const removePendingInvoiceRow = (idx) => {
		let newData = {...data};
		newData.pendinginvoices[idx].remove = true;
		setData(newData);
	}

	const setPendingInvoiceField = (idx, field, value) => {
		let newData = {...data};
		newData.pendinginvoices[idx][field] = value;
		setData(newData);
	}

	return (
		<LabeledFrame 
			label="Intervinientes" 
			buttons={ <button className="btn-unstyled text-white" onClick={() => addIntervener()}><i className="bi bi-plus-circle-fill"></i></button> }
			buttonsBackground="primary"
			className="mb-3"
		>
			<div className="row">					
				{ interveners?.map((el, idx) => {
					return (
						<div key={idx} className="col-md-6 mb-2">
							<div className="input-group">
								<EmpoweredSelector
									load={(input, callback, args) => loadProviders(input, callback, args)}
									args={{community_id: data.community_id}}
									onChange={(value) => selectProvider(value, idx)}
									timeout={250}
									label={
										el?.id &&
										<div>
											{el.business_name ?? ''}&nbsp;
											{el.id && <NavLink className="text-decoration-none text-reset" to={'/providers/edit/' + el.id + '?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink> }
										</div>
									}
									placeholder={'Proveedor ' + (interveners.length > 1 ? idx+1 : '')}
									showPlaceholderHelper={true}
									value={el?.id}
								/>
								{interveners.length > 1 &&
									<div className="input-group-append">
										<button className="btn btn-light btn-sm text-danger border-0" onClick={() => deleteIntervener(idx)}><i className="bi bi-x-circle-fill"></i></button>
									</div>
								}
								<div className="input-group-append">
									<NavLink className="btn btn-light2 btn-sm text-secondary" to={'/providers/add?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-plus-circle-fill"></i></NavLink>
								</div>
							</div>
							{ errors['interveners.' + idx] &&
								<div className="invalid-feedback d-block">{ errors['interveners.' + idx][0] }</div>
							}
						</div>
					);
				})}
				<div className="col-md-12">
					<TableInvoices className="table table-sm table-bordered mb-0">
						<thead>
							<tr className="sbold">
								<td>
									<div className="d-flex justify-content-between">
										Facturas de gastos

										{data.id && <NavLink className="text-primary text-decoration-none" to={'/providers-invoices/add?popup=true&notice_id=' + data.id + '&provider_id=' + (interveners.length === 1 ? interveners[0].id : null) + '&community_id=' + data.community_id} onClick={openPopupInfoWindow}><i className="bi bi-plus-circle"></i> Subir factura</NavLink>}
									</div>
								</td>
								<td className="text-center">Fecha</td>
								<td className="text-end">Total</td>
							</tr>
						</thead>
						<tbody>
							{data.providerinvoices?.map((el, idx) => {
								return (
									<tr key={idx}>
										<td>
											{el.number} <NavLink className="text-decoration-none" to={'/providers-invoices/edit/'+el.id+'?popup=true'} onClick={openPopupInfoWindow}><i className="bi bi-eye-fill"></i></NavLink>
										</td>
										<td className="text-center">
											{moment(el.date).format('DD-MM-YYYY')}
										</td>
										<td className="text-end">
											{ formatNumber(el.total) } €
										</td>
									</tr>
								)
							})}
							{data.pendinginvoices?.map((el, idx) => {
								if ( el.remove ) return null;

								return (
									<tr className="pending" key={'pending' + idx}>
										<td>
											<span>pendiente</span>
											<button onClick={() => removePendingInvoiceRow(idx)}><i className="bi bi-x-circle-fill"></i></button>
											<input 
												type="text" 
												ref={lastRowNumber.current === idx ? lastRow : null} 
												onChange={(e) => setPendingInvoiceField(idx, 'description', e.target.value)} 
												value={el.description ?? ''} 
												className={errors['pendinginvoices.' + idx + '.description'] ? 'has-errors' : ''}
												placeholder={'Descripción'}
											/>
										</td>
										<td className="text-center">
											{ el.id &&
												<NavLink 
													className="text-decoration-none" 
													to={'/providers-invoices/add?popup=true&notice_id=' + data.id + '&provider_id=' + (interveners.length === 1 ? interveners[0].id : null) + '&community_id=' + data.community_id + '&pendinginvoice_id=' + el.id} 
													onClick={openPopupInfoWindow}
												>
													<i className="bi bi-plus-circle"></i> Subir factura
												</NavLink>
											}
										</td>
										<td>
											<input 
												type="number" 
												onChange={(e) => setPendingInvoiceField(idx, 'amount', e.target.value)} value={el.amount ?? ''}
												className={'no-arrows ' + (errors['pendinginvoices.' + idx + '.amount'] ? 'has-errors' : '0')}
												placeholder={'Importe'}
											/>
										</td>
									</tr>
								);
							})}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan="100%" className="text-center">
									<button className="btn btn-link btn-sm text-decoration-none" onClick={addPendingInvoiceRow}><i className="bi-plus-circle-fill"></i> Añadir recordatorio de factura pendiente por recibir</button>
								</td>
							</tr>
						</tfoot>
					</TableInvoices>
				</div>
			</div>	
		</LabeledFrame>
	);
}