import React, { useState } from 'react';
import styled from 'styled-components';
import LabeledFrame from 'components/LabeledFrame';
import PersonsModal from './PersonsModal';

const Stat = styled.div`
	color: var(--bs-${props => props.color});
	margin: 10px 0;
	
	h1 {
		margin: 0;
		padding: 0;
		display: block;
		text-align: center;
		font-size: 20px;
	}

	h2 {
		margin: 0;
		padding: 0;
		display: block;
		text-align: center;
		font-size: 30px;
		margin-top: 10px;
	}
`;

export default function PersonsPanel(props) {
	let [personsModalOpened, setPersonsModalOpened] = useState(false);

	return (
		<React.Fragment>
			<LabeledFrame 
				label="Convocados y asistentes"
				buttons={
					<button className="btn-unstyled text-white" onClick={() => setPersonsModalOpened(true)}><i className="bi bi-pencil"></i> Editar listado</button>
				}
				buttonsBackground={'primary'}
			>
				<div className="row">
					<div className="col-md-4">
						<Stat color="secondary">
							<h1>Convocados</h1>
							<h2>{props.data.properties?.reduce((carry, item) => carry += item.summoned ? 1 : 0, 0) ?? 0}</h2>
						</Stat>
					</div>
					<div className="col-md-4">
						<Stat color="primary">
							<h1>Pueden votar</h1>
							<h2>{props.data.properties?.reduce((carry, item) => carry += item.vote ? 1 : 0, 0) ?? 0}</h2>
						</Stat>
					</div>
					<div className="col-md-4">
						<Stat color="success">
							<h1>Asistentes</h1>
							<h2>{props.data.properties?.reduce((carry, item) => carry += item.attend ? 1 : 0, 0) ?? 0}</h2>
						</Stat>
					</div>
				</div>
			</LabeledFrame>
			{ personsModalOpened && 
				<PersonsModal 
					data={props.data}
					setDataField={props.setDataField}
					closeCallback={async (saveData) => {
						if ( saveData ) await props.saveData();
						setPersonsModalOpened(false);
					}}
				/>
			}
		</React.Fragment>
	);
}