import React, {useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';

let axiosCancelToken = null;

export default function Filters(props) {
    let community = props.community;
    let savedFilters = useMemo(() => props.filters, []);
    let onChange = useCallback((filters) => props.onChange(filters), []);

	let [checkedFromSavedReady, setCheckedFromSavedReady] = useState(false);
	let [allCheckedReady, setAllCheckedReady] = useState(false);
	let [loaded, setLoaded] = useState(false);

	let [streets, setStreets] = useState([]);
	let [buildings, setBuildings] = useState([]);
	let [stairs, setStairs] = useState([]);
	let [plants, setPlants] = useState([]);
	let [doors, setDoors] = useState([]);
    let [types, setTypes] = useState([]);

	const [streetsChecked, setStreetsChecked] = useState([]);
	const [buildingsChecked, setBuildingsChecked] = useState([]);
	const [stairsChecked, setStairsChecked] = useState([]);
	const [plantsChecked, setPlantsChecked] = useState([]);
	const [doorsChecked, setDoorsChecked] = useState([]);
	const [typesChecked, setTypesChecked] = useState([]);

    useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	// Set checked from saved filters
	useEffect(() => {
		
		setStreetsChecked([...savedFilters?.streets ?? []]);
		setBuildingsChecked([...savedFilters?.buildings ?? []]);
		setStairsChecked([...savedFilters?.stairs ?? []]);
		setPlantsChecked([...savedFilters?.plants ?? []]);
		setDoorsChecked([...savedFilters?.doors ?? []]);
		setTypesChecked([...savedFilters?.types ?? []]);

		setCheckedFromSavedReady(true);
	}, [savedFilters]);


    useEffect(() => {
		if ( !allCheckedReady ) return;

        onChange({
            streets:		streetsChecked,
			buildings:		buildingsChecked,
			stairs:			stairsChecked,
			plants:			plantsChecked,
			doors:			doorsChecked,
			types:			typesChecked
        })
    }, [onChange, streetsChecked, buildingsChecked, stairsChecked, plantsChecked, doorsChecked, typesChecked, allCheckedReady]);

	// Check all by default
	useEffect(() => {
		if ( !loaded ) return;

		if ( !savedFilters || (savedFilters && Object.keys(savedFilters).length <= 0) ) {
			if ( streets.length ) setStreetsChecked([...streets.map(el => el.id)]);
			if ( buildings.length ) setBuildingsChecked([...buildings.map(el => el.id)]);
			if ( stairs.length ) setStairsChecked([...stairs.map(el => el)]);
			if ( plants.length ) setPlantsChecked([...plants.map(el => el)]);
			if ( doors.length ) setDoorsChecked([...doors.map(el => el)]);
			if ( types.length ) setTypesChecked([...types.map(el => el.id)]);
		}

		setAllCheckedReady(true);
	}, [streets, buildings, stairs, plants, doors, types, savedFilters, loaded]);

    useEffect(() => {
		if ( !checkedFromSavedReady ) return;

		const getDataAsync = async () => {
			// Types
			let types = await axios.get('/api/manager/properties-types/list', {
				params: {
					no_paginate: true,
					with_properties: true
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				return response.data;
			}).catch((error) => {
				if ( axios.isCancel(error) ) return [];
			});
			setTypes([...types]);

			// Streets
			let streets = await axios.get('/api/manager/communities/get/' + community?.id, {
				params: {
					community_id: community?.id
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	return response.data?.streets ?? [];
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return [];
			});
			setStreets([...streets]);

			// Buildings
			let buildings = await axios.get('/api/manager/buildings/list', {
				params: {
					no_paginate: true,
					community_id: community?.id,
					with_properties: true
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	return response.data;
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return [];
			});
			setBuildings([...buildings]);

			// Stairs, plants, doors
			let properties = await axios.get('/api/manager/properties/list', {
				params: {
					community_id: community?.id,
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				return response.data;
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return [];
			});	

			setStairs([
				...properties
							.map(el => el.stairs)
							.filter(el => el && el.length > 0)
							.filter((v, i, a) => a.indexOf(v) === i)
							.sort()
			]);
			setPlants([
				...properties
							.map(el => el.plant)
							.filter(el => el && el.length > 0)
							.filter((v, i, a) => a.indexOf(v) === i)
							.sort()
			]);
			setDoors([
				...properties
							.map(el => el.door)
							.filter(el => el && el.length > 0)
							.filter((v, i, a) => a.indexOf(v) === i)
							.sort()
			]);

			setLoaded(true);
		}
		getDataAsync();

	}, [community?.id, checkedFromSavedReady]);

	const toggleStreetChecked = (id) => {
		let checked = [...streetsChecked];
		let idx = checked.findIndex(el => el === id);
		if ( idx !== -1 ) checked.splice(idx, 1);
		else checked.push(id);
		setStreetsChecked(checked);
	}

	const toggleBuildingChecked = (id) => {
		let checked = [...buildingsChecked];
		let idx = checked.findIndex(el => el === id);
		if ( idx !== -1 ) checked.splice(idx, 1);
		else checked.push(id);
		setBuildingsChecked(checked);
	}

	const toggleStairChecked = (id) => {
		let checked = [...stairsChecked];
		let idx = checked.findIndex(el => el === id);
		if ( idx !== -1 ) checked.splice(idx, 1);
		else checked.push(id);
		setStairsChecked(checked);
	}

	const togglePlantChecked = (id) => {
		let checked = [...plantsChecked];
		let idx = checked.findIndex(el => el === id);
		if ( idx !== -1 ) checked.splice(idx, 1);
		else checked.push(id);
		setPlantsChecked(checked);
	}

	const toggleDoorChecked = (id) => {
		let checked = [...doorsChecked];
		let idx = checked.findIndex(el => el === id);
		if ( idx !== -1 ) checked.splice(idx, 1);
		else checked.push(id);
		setDoorsChecked(checked);
	}

	const toggleTypeChecked = (id) => {
		let checked = [...typesChecked];
		let idx = checked.findIndex(el => el === id);
		if ( idx !== -1 ) checked.splice(idx, 1);
		else checked.push(id);
		setTypesChecked(checked);
	}

    return (
        <>
			<div className="row">
				<div className="col-md-3">
					<div className="mb-2">
						<label>Calles</label>
						<div>
							{streets.map((el, idx) => {
								let id = 'street_' + idx;
								return (
									<div key={id} className="form-check form-switch d-inline-block me-2">
										<input className="form-check-input" type="checkbox" id={id} checked={streetsChecked?.indexOf(el.id) !== -1} onChange={(e) => toggleStreetChecked(el.id, e.target.checked)} />
										<label className="form-check-label" htmlFor={id}>{el.name}</label>
									</div>
								);
							})}
						</div>
					</div>
				</div>

				<div className="col-md-3">
					<div className="mb-2">
						<label>Bloques / Portales</label>
						<div>
							{buildings.map((el, idx) => {
								let id = 'building_'+idx;
								return (
									<div key={id} className="form-check form-switch d-inline-block me-2">
										<input className="form-check-input" type="checkbox" id={id} checked={buildingsChecked?.indexOf(el.id) !== -1} onChange={(e) => toggleBuildingChecked(el.id, e.target.checked)} />
										<label className="form-check-label" htmlFor={id}>{el.name}</label>
									</div>
								);
							})}
						</div>
					</div>
				</div>

				<div className="col-md-3">
					<div className="mb-2">
						<label>Escalera</label>
						<div>
							{stairs.map((el, idx) => {
								let id = 'stair_'+idx;
								return (
									<div key={id} className="form-check form-switch d-inline-block me-2">
										<input className="form-check-input" type="checkbox" id={id} checked={stairsChecked?.indexOf(el) !== -1} onChange={(e) => toggleStairChecked(el, e.target.checked)} />
										<label className="form-check-label" htmlFor={id}>{el}</label>
									</div>
								);
							})}
						</div>
					</div>
				</div>

				<div className="col-md-3">
					<div className="mb-2">
						<label>Planta</label>
						<div>
							{plants.map((el, idx) => {
								let id = 'plant_'+idx;
								return (
									<div key={id} className="form-check form-switch d-inline-block me-2">
										<input className="form-check-input" type="checkbox" id={id} checked={plantsChecked?.indexOf(el) !== -1} onChange={(e) => togglePlantChecked(el, e.target.checked)} />
										<label className="form-check-label" htmlFor={id}>{el}</label>
									</div>
								);
							})}
						</div>
					</div>
				</div>

				<div className="col-md-3">
					<div className="mb-2">
						<label>Puerta</label>
						<div>
							{doors.map((el, idx) => {
								let id = 'door_'+idx;
								return (
									<div key={id} className="form-check form-switch d-inline-block me-2">
										<input className="form-check-input" type="checkbox" id={id} checked={doorsChecked?.indexOf(el) !== -1} onChange={(e) => toggleDoorChecked(el, e.target.checked)} />
										<label className="form-check-label" htmlFor={id}>{el}</label>
									</div>
								);
							})}
						</div>
					</div>
				</div>

				<div className="col-md-9">
					<div className="mb-2">
						<label>Tipos</label>
						<div>
							{types.map((el, idx) => {
								let id = 'type_' + idx;
								return (
									<div key={id} className="form-check form-switch d-inline-block me-2">
										<input className="form-check-input" type="checkbox" id={id} checked={typesChecked?.indexOf(el.id) !== -1} onChange={(e) => toggleTypeChecked(el.id, e.target.checked)} />
										<label className="form-check-label" htmlFor={id}>{el.name}</label>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</>
    );
}


