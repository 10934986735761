import React, { useState, useEffect } from 'react';
import { Navigate, NavLink, useSearchParams, useNavigate } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import LabeledFrame from 'components/LabeledFrame';
import { formatNumber, openPopupWindow } from 'helpers/generic';
import { getCommunity, authUserPermission } from 'helpers/community';
import { getConfig } from 'helpers/config';
import ExportModal from 'components/ExportModal';
import LabelsModal from './LabelsModal';
import ImportExcelModal from './ImportExcelModal';

const Table = styled.table`
	th,td {
		@media (min-width: 768px) {
			&:nth-child(1),
			&:nth-child(3) {
				width: 300px;
			}
		
			&:nth-child(2) {
				width: 100px;
			}

			&:nth-child(5) {
				width: 40px;
				white-space: nowrap;
			}
			
			&:nth-child(6) {
				text-align: right;
			}

			&:nth-child(8) {
				width: 65px;
			}
		}
		
		@media (max-width: 768px) {
			&:nth-child(2),
			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(6),
			&:nth-child(7) {
				display: none;
			}
		}
	}
`;

let axiosCancelToken = null;
let searchTimeout = null;

export default function Owners() {
	let navigate = useNavigate();
	const [queryParams] = useSearchParams();

	let community = getCommunity();
	let [forceReload, setForceReload] = useState(null);
	let [owners, setOwners] = useState({});
	let [positions, setPositions] = useState([]);
	let [search, setSearch] = useState(undefined);
	let [positionId, setPositionId] = useState(undefined);
	let [sortDirection, setSortDirection] = useState('asc');
	let [sortField, setSortField] = useState('name');
	let [detailsOpened, setDetailsOpened] = useState([]);
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [showExportModal, setShowExportModal] = useState(false);
	let [showLabelsModal, setShowLabelsModal] = useState(false);
	let [showImportExcelModal, setShowImportExcelModal] = useState(false);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setOwners({...owners, data: undefined});
		_setPage(page);
	}

	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const toggleDetails = (rowId) => {
		if ( detailsOpened.includes(rowId) ) {
			detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
			setDetailsOpened([...detailsOpened]);
		} else {
			setDetailsOpened([...detailsOpened, rowId]);
		}
	}

	const openPopupWindowFix = (e, href) => {
		e.preventDefault();
		openPopupWindow(href);
	}

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getPositions = async () => {
			await axios.get('/api/manager/community-positions/list', {
				params: {
					no_paginate: true				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setPositions([...response.data]);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}
		getPositions();

		const getOwners = async () => {
			await axios.get('/api/manager/owners/list', {
				params: {
					community_id: community?.id,
					page: page,
					search: search,
					position_id: positionId,
					sort: sortField,
					direction: sortDirection,
					with_property_receipts_balance: true
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setOwners({...response.data});
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getOwners();
	}, [page, search, positionId, sortField, sortDirection, community?.id, forceReload]);

	const openPopupInfoWindow = (e) => {
		e.preventDefault();
		e.stopPropagation();

		openPopupWindow(e.currentTarget.href);
	}

	if ( !community?.id ) return <Navigate to="/" />;

	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Propietarios</h1>
					
					{ authUserPermission('add') &&
						<NavLink to="/owners/add" className="btn btn-sm btn-light ms-auto">Nuevo propietario</NavLink>
					}
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<input type="search" className="form-control form-control-sm" placeholder="Buscar" onChange={(e) => setSearchTimeout(e.target.value)} />
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" onChange={(e) => setPositionId(e.target.value)} value={positionId ?? ''}>
										<option value="">- Cargos -</option>
										{ positions?.map((el, idx) => {
											return <option value={el.id} key={el.id}>{el.name}</option>;
										})}
									</select>
								</div>
								<div className="col-md-7 mb-2 mt-2 mb-md-0 mt-md-0 text-end">
									<button className="btn btn-sm btn-light me-2" onClick={() => setShowLabelsModal(true)}>Etiquetas</button>
									<button className="btn btn-sm btn-light me-2" onClick={() => setShowExportModal(true)}>Exportar</button>
									<button className="btn btn-sm btn-light" onClick={() => setShowImportExcelModal(true)}>Importar</button>
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div /*className="table-responsive table-responsive-carded"*/>
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'name'} onClick={() => sortTableClick('name')}>Nombre</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'position.order'} onClick={() => sortTableClick('position.order')}>Cargo</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'vatnumber'} onClick={() => sortTableClick('vatnumber')}>DNI</ThSortable>
											<th>Propiedades</th>
											<th>Teléfono</th>
											<th>Saldo</th>
											<ThSortable direction={sortDirection} active={sortField === 'created_at'} onClick={() => sortTableClick('created_at')}>Creado el</ThSortable>
											<th style={{width: '60px'}}></th>
										</tr>
									</thead>
									<tbody>
										{ owners.data?.length > 0 &&
											owners.data.map((el, idx) => {
												let balance = el.properties.reduce((carry, item) => carry += item.receiptscontableaccount?.balance ?? 0, 0);
												
												return ( 
													<React.Fragment key={idx}>
														<tr className="has-details" /*onClick={() => toggleDetails(el.id)}*/ onClick={(e) => !e.target.classList.contains('dropdown-item') ? navigate('/owners/edit/' + el.id) : null}>
															<td>{el.name ?? '-'}</td>
															<td>{el.position?.name ?? '-'}</td>
															<td>{el.vatnumber ?? '-'}</td>
															<td>
																{el.properties?.map((el, idx) => {
																	return <NavLink key={idx} to={'/properties/edit/' + el.id + '?popup=true'} onClick={openPopupInfoWindow} className="badge bg-light text-secondary text-decoration-none me-1">{el.name}</NavLink>;
																})}
															</td>
															<td>{el.phone_default ?? '-'}</td>
															<td>{formatNumber(balance)}</td>
															<td>
																{ moment(el.created_at).format('DD-MM-YYYY') }
																&nbsp;<small>{ moment(el.created_at).format('HH:mm') }</small>
															</td>
															<td className="text-end">
																{ !el.data_completed &&
																	<span className="text-danger me-2"><i className="bi bi-exclamation-circle"></i></span>
																}
																<div className="dropdown d-inline-block">
																	<button className="btn btn-sm btn-table p-0 px-1 text-primary" type="button" data-bs-toggle="dropdown" onClick={(e) => e.stopPropagation()}>
																		<i className="bi bi-three-dots"></i>
																	</button>
																	<ul className="dropdown-menu">
																		<li><NavLink className="dropdown-item" to={'/owners/edit/' + el.id}>Ficha</NavLink></li>
																		<li><NavLink className="dropdown-item" to={'/emails/add?recipients_type=owners&recipients_ids=' + el.id} onClick={(e) => openPopupWindowFix(e, e.target.href)}>Enviar email</NavLink></li>
																		<li><NavLink className="dropdown-item" to={'/notices?petitioner_type=owner&petitioner_id=' + el.id} onClick={(e) => openPopupWindowFix(e, e.target.href)}>Ver avisos</NavLink></li>
																		<li><NavLink className="dropdown-item" to={'/notices/add?petitioner_type=owner&owner_id=' + el.id} onClick={(e) => openPopupWindowFix(e, e.target.href)}>Nuevo aviso</NavLink></li>
																		<li><NavLink className="dropdown-item" to={'/documents?folder_id=' + el.folder_id} onClick={(e) => openPopupWindowFix(e, e.target.href)}>Abrir documentos</NavLink></li>
																	</ul>
																</div>
															</td>
														</tr>
														{ detailsOpened.includes(el.id) && 	
															<tr className="details">
																<td colSpan="100%">
																	<div className="row ms-0 me-0">
																		<div className="col-md-4">
																			<div><b className="sbold">Código</b>: {el.code ?? '-'}</div>
																			<div><b className="sbold">Nombre</b>: {el.name ?? '-'}</div>
																			<div><b className="sbold">DNI</b>: {el.vatnumber ?? '-'}</div>
																			<div className="mb-3"><b className="sbold">Cargo</b>: {el.position?.name ?? '-'}</div>
																			
																			<div><b className="sbold">Ley protección datos</b>: {el.rgpd_consent ? 'Si' : 'No'}</div>
																			<div className="mb-3"><b className="sbold">Contacto preferente</b>: {el.preferred_contact_method ? getConfig().contactmethods[el.preferred_contact_method] : ''}</div>
																			
																			<div><b className="sbold">Propiedades</b>: {el.properties.map((ppty, pptyIdx) => (<div key={pptyIdx}>{ppty.name}</div>))}</div>
																			<div className="mb-3"><b className="sbold">Saldo</b>: {formatNumber(balance)}</div>
																		</div>
																		<div className="col-md-8">
																			<div className="row">
																				{el.addresses.map((aEl, aIdx) => {
																					let label = <div>
																									{aEl.default === 1 ? <span className="sbold">Dirección principal</span> : <span>Dirección secundaria</span>}
																								</div>;
																					return (
																						<div className="col-md-6 mb-2" key={aIdx}>
																							<LabeledFrame 
																								label={label} 
																								background={aEl.default === 1 ? 'primary' : 'gray-300'} 
																								color={aEl.default === 1 ? 'white' : 'gray-600'}
																							>
																								<div><b className="sbold">Calle</b>: {aEl.address}</div>
																								<div><b className="sbold">Código postal</b>: {aEl.postalcode}</div>
																								<div><b className="sbold">Ciudad</b>: {aEl.city}</div>
																								<div><b className="sbold">Provincia</b>: {aEl.province}</div>
																								<div><b className="sbold">País</b>: {aEl.country}</div>
																								<div><b className="sbold">Teléfono</b>: {aEl.phone_default}</div>
																								<div><b className="sbold">Email</b>: {aEl.email_default}</div>
																							</LabeledFrame>
																						</div>
																					);
																				})}
																			</div>	
																		</div>
																	</div>
																</td>
															</tr>
														}
													</React.Fragment>
												);
											})
										}

										{ owners.data && !owners.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ owners.data === undefined && <TrSkeleton rows={skeletonRows} columns={8} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={owners?.current_page}
									max={owners?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			{ showExportModal &&
				<ExportModal
					exportKey={'owners'}
					fileName={'Propietarios ' + moment().format('DD-MM-YYYY HH:mm')}
					fields={{
						code: 						'Código',
						name: 						'Nombre',
						vatnumber: 					'DNI',
						consent: 					'LOPD',
						position: 					'Cargo',
						addresses: 					'Direcciones',
						properties: 				'Propiedades y saldos',
						preferred_contact_method: 	'Método de contacto preferido',
						phones: 					'Teléfono',
						emails: 					'Email',
						bankaccounts:				'Cuentas bancarias'
					}}
					filters={{
						community_id: 				community?.id,
						page: 						page,
						search: 					search,
						position_id: 				positionId,
						sort: 						sortField,
						direction: 					sortDirection,
						no_paginate:				true
					}}
					closeCallback={() => setShowExportModal(false)}
				/>
			}
			{ showLabelsModal &&
				<LabelsModal
					closeCallback={() => setShowLabelsModal(false)}
				/>
			}
			{showImportExcelModal &&
				<ImportExcelModal 
					closeCallback={() => {
						setShowImportExcelModal(false);
						setForceReload(Math.random());
					}}
				/>
			}
		</LoggedLayout>
	);
}


