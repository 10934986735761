import React, { useState, useEffect, useCallback } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import styled from 'styled-components';
import { hasCapability } from 'helpers/user';
import { getCommunity } from 'helpers/community';
import { isTouchEnabled } from 'helpers/generic';
import useStore from "store";

const SidebarStyled = styled.aside`
	--top: ${props => props.top}px;
	--left: ${props => props.left};
	--desktop-width-notvisible: 80px;
	--opened-width: 250px;

	position: fixed;
	top: var(--top);
	left: var(--left);
	z-index: 20;
	height: calc(100% - var(--top));
	width: 100%;
	@media (min-width: 993px) {
	    transition: all 0.3s;
	}
	@media (max-width: 992px) {
	    box-shadow: none !important;
	}

	.sidebar-cristal {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	ul.sidebar-menu {
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
		width: 250px;
		height: 100%;
		background: white;
		padding: 15px;
		overflow-y: auto;
    	box-shadow: 0px 0px 10px rgb(1 41 112 / 10%);
    	position: relative;
    	z-index: 2;
    	transition: all 0.3s;

		li {
			margin: 10px 0;

			&:first-of-type {
				margin-top: 0;
			}

			&:last-of-type {
				margin-bottom: 0;
			}

			a {
				display: flex;
				align-items: center;
				text-decoration: none;
				color: var(--bs-body-color);
				padding: 10px 15px;
				transition: all 0.3s;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				user-select: none;

				i {
					width: 30px;
				}

				&.active {
					border-radius: 5px;
					background: var(--bs-blue);
					color: white;
				}

				&.disabled {
					pointer-events: none;
					background: #f6f9ff;
					opacity: 0.6;
				}

				&:hover:not(.active) {
					border-radius: 5px;
					background: #f6f9ff;
					color: var(--bs-blue);
				}

				.badge {
					font-size: 9px;
					margin-left: 5px;
					display: inline-flex;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}

	// Mobile
    @media (max-width: 992px) {
    	width: 100%;

    	&:not(.visible) {
    		left: -100%;
    	}
    }

    // Desktop
    @media (min-width: 993px) {
    	width: var(--desktop-width-notvisible);

    	&:not(.visible) {
    		& > ul {
    			width: var(--desktop-width-notvisible);

    			& > li > a > span {
    				display: none;
    			}

    			&:hover {
		    		width: var(--opened-width) !important;
		    		overflow-x: hidden;

		    		li > a > span {
		    			display: inherit;
		    		}
		    	}
    		}
    		&.is-touch-screen {
    			& > ul {
		    		display: none;
    			}

    			&.sidebar-cristal {
    				display: none;
    			}
	    	}
    	}
    }
`;

let hoverFix = false; // Needed to prevent close on re-render for desktop

export default function Sidebar(props) {
	const location = useLocation();
    const dispatch = useStore(state => state.dispatch);
    const sidebarVisible = useStore(state => state.sidebarVisible);
    const [hover, _setHover] = useState(hoverFix);
    const setHover = (status) => {
    	if ( !isTouchEnabled() ) hoverFix = status;
    	_setHover(status);
    }
    const community = getCommunity();

    const setSidebarVisible = useCallback((status) => {
    	setHover(status);
	    dispatch({
			type: 'setSidebarVisible',
			status: status
		});
    }, [dispatch]);

    // Always hide sidebar on mobile url change
    useEffect(() => {
    	if ( isTouchEnabled() ) {
    		setSidebarVisible(false);
    	}
    }, [location, setSidebarVisible]);
    
	return (
		<SidebarStyled 
			className={(sidebarVisible || hover ? 'visible' : '') + ' ' + (isTouchEnabled() ? 'is-touch-screen' : '')} 
			onMouseEnter={(e) => setHover(true)} 
			onMouseLeave={(e) => setHover(false)}
			top={props.top}
			left={props.left}
		>
			<div className="sidebar-cristal" onClick={() => setSidebarVisible(false)}></div>
			<ul className="sidebar-menu">
				{ hasCapability('companies') &&
					<li>
						<NavLink to="/companies" className={({ isActive }) => isActive ? 'active' : ''}>
							<i className="bi bi-building"></i> 
							<span>Empresas</span>
						</NavLink>
					</li>
				}
				{ hasCapability('users-all') &&
					<li>
						<NavLink to="/users" className={({ isActive }) => isActive ? 'active' : ''}>
							<i className="bi bi-people"></i> 
							<span>Usuarios</span>
						</NavLink>
					</li>
				}

				{ hasCapability('summary') &&
					<li>
						<NavLink to="/" className={({ isActive }) => isActive ? 'active' : ''}>
							<i className="bi bi-clipboard-data"></i> 
							<span>Dashboard</span>
						</NavLink>
					</li>
				}

				{ hasCapability('properties') &&
					<li>
						<NavLink 
							to="/properties" 
							className={({ isActive }) => {
								return (isActive ? 'active' : '') + (!community?.id ? 'disabled' : '');
							}}
						>
							<i className="bi bi-house"></i> 
							<span>Propiedades</span>
						</NavLink>
					</li>
				}

				{ hasCapability('owners') &&
					<li>
						<NavLink 
							to="/owners" 
							className={({ isActive }) => {
								return (isActive ? 'active' : '') + (!community?.id ? 'disabled' : '');
							}}
						>
							<i className="bi bi-person"></i> 
							<span>Propietarios</span>
						</NavLink>
					</li>
				}

				{ hasCapability('renters') &&
					<li>
						<NavLink 
							to="/renters" 
							className={({ isActive }) => {
								return (isActive ? 'active' : '') + (!community?.id ? 'disabled' : '');
							}}
						>
							<i className="bi bi-person"></i> 
							<span>Inquilinos</span>
						</NavLink>
					</li>
				}

				{ hasCapability('estimates') &&
					<React.Fragment>
						<li>
							<NavLink 
								to="/estimates" 
								className={({ isActive }) => {
									return (isActive ? 'active' : '') + (!community?.id ? 'disabled' : '');
								}}
							>
								<i className="bi bi-cash"></i> 
								<span>Presupuestos</span>
							</NavLink>
						</li>
						<li>
							<NavLink 
								to="/quotas-concepts" 
								className={({ isActive }) => {
									return (isActive ? 'active' : '') + (!community?.id ? 'disabled' : '');
								}}
							>
								<i className="bi bi-card-list"></i> 
								<span>Conceptos de cuotas</span>
							</NavLink>
						</li>
					</React.Fragment>
					
				}

				{ hasCapability('receiptsissuances') &&
					<li>
						<NavLink 
							to="/receiptsissuances" 
							className={({ isActive }) => {
								return (isActive ? 'active' : '') + (!community?.id ? 'disabled' : '');
							}}
						>
							<i className="bi bi-map"></i> 
							<span>Emisiones de recibos</span>
						</NavLink>
					</li>
				}

				{ hasCapability('bankremittances') &&
					<li>
						<NavLink 
							to="/bankremittances" 
							className={({ isActive }) => {
								return (isActive ? 'active' : '') + (!community?.id ? 'disabled' : '');
							}}
						>
							<i className="bi bi-bank"></i> 
							<span>Remesas bancarias</span>
						</NavLink>
					</li>
				}

				{ hasCapability('incomes') &&
					<li>
						<NavLink 
							to="/incomes" 
							className={({ isActive }) => {
								return (isActive ? 'active' : '') + (!community?.id ? 'disabled' : '');
							}}
						>
							<i className="bi bi-graph-up-arrow"></i> 
							<span>Ingresos</span>
						</NavLink>
					</li>
				}

				{ hasCapability('meters') &&
					<li>
						<NavLink 
							to="/meters-readings" 
							className={({ isActive }) => {
								return (isActive ? 'active' : '') + (!community?.id ? 'disabled' : '');
							}}
						>
							<i className="bi bi-speedometer2"></i> 
							<span>Lecturas</span>
						</NavLink>
					</li>
				}

				{ hasCapability('providers') &&
					<li>
						<NavLink 
							to="/providers" 
							className={({ isActive }) => {
								return (isActive ? 'active' : '');
								// return (isActive ? 'active' : '') + (!community?.id ? 'disabled' : '');
							}}
						>
							<i className="bi bi-bag"></i> 
							<span>Proveedores</span>
						</NavLink>
					</li>
				}

				{ hasCapability('providers-invoices') &&
					<li>
						<NavLink 
							to="/providers-invoices" 
							className={({ isActive }) => {
								return (isActive ? 'active' : '');
								// return (isActive ? 'active' : '') + (!community?.id ? 'disabled' : '');
							}}
						>
							<i className="bi bi-receipt"></i> 
							<span>Facturas</span>
						</NavLink>
					</li>
				}

				{ hasCapability('treasury') &&
					<li>
						<NavLink 
							to="/treasury" 
							className={({ isActive }) => {
								return (isActive ? 'active' : '') + (!community?.id ? 'disabled' : '');
							}}
						>
							<i className="bi bi-currency-euro"></i> 
							<span>Tesorería</span>
						</NavLink>
					</li>
				}

				{ hasCapability('balance') &&
					<li>
						<NavLink 
							to="/balance" 
							className={({ isActive }) => {
								return (isActive ? 'active' : '') + (!community?.id ? 'disabled' : '');
							}}
						>
							<i className="bi bi-bar-chart-fill"></i> 
							<span>Balance</span>
						</NavLink>
					</li>
				}

				{ hasCapability('bank-reconciliation') &&
					<li>
						<NavLink 
							to="/bank-reconciliation" 
							className={({ isActive }) => {
								return (isActive ? 'active' : '') + (!community?.id ? 'disabled' : '');
							}}
						>
							<i className="bi bi-arrow-left-right"></i> 
							<span>Conciliación bancaria</span>
						</NavLink>
					</li>
				}

				{ hasCapability('meetings') &&
					<li>
						<NavLink 
							to="/meetings" 
							className={({ isActive }) => {
								return (isActive ? 'active' : '') + (!community?.id ? 'disabled' : '');
							}}
						>
							<i className="bi bi-people"></i> 
							<span>Juntas</span>
						</NavLink>
					</li>
				}		

				{ hasCapability('contracts') &&
					<li>
						<NavLink 
							to="/contracts" 
							className={({ isActive }) => {
								return (isActive ? 'active' : '');
							}}
						>
							<i className="bi bi-file-earmark-person"></i> 
							<span>Contratos</span>
						</NavLink>
					</li>
				}

				{ hasCapability('administration-models') &&
					<li>
						<NavLink 
							to="/administration-models" 
							// className={({ isActive }) => {
							// 	return (isActive ? 'active' : '') + (!community?.id ? 'disabled' : '');
							// }}
						>
							<i className="bi bi-receipt"></i> 
							<span>Modelos</span>
						</NavLink>
					</li>
				}
			</ul>
		</SidebarStyled>
	);
}