import React, { useEffect, useRef, useState } from "react";
import axios from 'axios';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { Editor } from '@tinymce/tinymce-react';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { getCommunity} from 'helpers/community';
import { openPopupWindow } from 'helpers/generic';

const ModalStyled = styled.div`

	textarea {
		resize: none;
		height: 150px;
	}

	label {
		font-weight: 500;
		font-size: 15px;
	}
`;

let axiosCancelToken = null;

export default function ReasonTextFieldModal(props) {
	const modalRef = useRef(null);

	let text = props.text;
	let setText = props.setText;
	let fieldName = props.fieldName;
	let deleteReason = props.delete;
	let number = props.number;
	let templateType = props.templateType;
	let closeCallback = props.closeCallback;

	let textareaRef = useRef(null);

	let [modalObj, setModalObj] = useState(null);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const modal = Modal.getOrCreateInstance(modalRef.current);
		modal.show();
		setModalObj(modal);

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	const loadTemplates = (input, callback) => {
		axios.get('/api/manager/templates/list', {
			params: {
				community_id: getCommunity()?.id,
                without_community: true,
				search: input,
                type: templateType,
                no_paginate: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.title
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const hideModal = async (saveData = true) => {
		modalObj.hide();
		modalObj.dispose();

		closeCallback(saveData);

		document.body.style.overflow = 'auto'; 
		document.body.style.paddingRight = '0';
	}

	const deleteReasonFix = () => {
		deleteReason();
		hideModal(false);
	}

	const setTemplate = (template) => {
		setText(template.content);
	}

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-xl">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Orden del día nº {number}</h5>
						<button type="button" className="btn-close" onClick={() => hideModal(false)} aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<div className="row">
							<div className="col-md-12">
								<div className="mb-2">
									<label className="d-flex justify-content-between w-100 mb-3">
										<span>{fieldName}</span>
										<div className="input-group d-flex me-auto justify-content-end">
											<EmpoweredSelector
												load={loadTemplates}
												onChange={(value) => setTemplate(value)}
												timeout={250}
												label={null}
												placeholder="Seleccionar plantilla"
												value={null}
												className="col-static-200"
											/>
											<NavLink 
												className={'btn btn-secondary btn-sm d-flex align-items-center text-white'}
												to={'/templates/add?popup=true&type='+templateType+'&type_locked=true'}
												onClick={(e) => {
													e.preventDefault();
													openPopupWindow(e.currentTarget.href);
												}}
											>
												<i className="bi bi-plus-circle"></i>
											</NavLink>
										</div>
									</label>
									<Editor tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
										onInit={(evt, editor) => textareaRef.current = editor}
										value={text ?? ''}
										onEditorChange={(newValue, editor) => setText(newValue)}
										init={{
											height: 500,
											menubar: false,
											statusbar: false,
											language: 'es',
											plugins: ['lists', 'autolink', 'link'],
											toolbar: 'bold italic forecolor backcolor bullist numlist link',
											content_style: "body { font-family: Helvetica; }",
											browser_spellcheck: true,
											contextmenu: false
										}}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="modal-footer d-flex">
						<button className="btn btn-sm btn-link text-danger" onClick={() => deleteReasonFix()}>Eliminar</button>
						<button type="button" className="btn btn-sm btn-primary text-white ms-auto" onClick={() => hideModal()}>Aceptar</button>
					</div>
				</div>
			</div>
		</ModalStyled>
	);
}


