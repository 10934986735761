import React, { useState, useEffect } from 'react';
import { Navigate } from "react-router-dom";
import axios from 'axios';
import LoggedLayout from "layouts/LoggedLayout";
import { getCommunity } from 'helpers/community';
import EmpoweredSelector from 'components/EmpoweredSelector';
import Short from './Short';
import Long from './Long';
import Counterparts from './Counterparts';
import ExpensesDistribution from './ExpensesDistribution';
import HideShowModal, { getFromLocalStorage as getVisibleSectionsFromLocalStorage } from './HideShowModal';
import { ucfirst, loader } from 'helpers/generic';
import { svgElementToB64Png } from 'helpers/images';

let axiosCancelToken = null;

export default function Balance() {
	let community = getCommunity();

	let [forceReload, setForceReload] = useState(null);
	let [financialYears, setFinancialYears] = useState([]);
	let [financialYear, setFinancialYear] = useState({
		id: null,
		year: null
	});
	let [type, setType] = useState('short');
	let [data, setData] = useState({});
	let [visibleSections, setVisibleSections] = useState([]);
	let [showHideShowModal, setShowHideShowModal] = useState(false);
	let [exportsChartsFix, setExportsChartsFix] = useState(null);
	let [loading, setLoading] = useState(false);

	useEffect(() => {
		if ( axiosCancelToken ) axiosCancelToken.cancel();
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [type]);
	
	useEffect(() => {
		const getFinancialYears = async () => {
			await axios.get('/api/manager/financial-years/list', {
				params: {
					community_id: community?.id,
					no_paginate: true
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setFinancialYears(response.data);
				setFinancialYear(response.data[0]);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getFinancialYears();
	}, [community?.id]);
	
	useEffect(() => {
		const getBalance = async () => {
			if ( !type || !financialYear?.year ) return;

			setLoading(true);

			await axios.get('/api/manager/balances/' + type, {
				params: {
					community_id: community?.id,
					financial_year: financialYear?.year
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setData(response.data);
				setLoading(false);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getBalance();
	}, [forceReload, community?.id, financialYear, type]);

	useEffect(() => {
		setVisibleSections(getVisibleSectionsFromLocalStorage(type));
	}, [forceReload, type]);

	const loadFinancialYears = (input, callback, args) => {
		let financialYears = args.financialYears;

		let formatted = financialYears?.map((el) => {
			return {
				value: el, 
				label: el.year
			};
		});
		callback(formatted);
	}

	const exportClick = async (e) => {
		// Fix to disable the charts selection
		setExportsChartsFix(Math.random());
		await new Promise(resolve => setTimeout(resolve, 1000));

		// Check if exists charts to send to backend
		let charts = document.querySelectorAll('[data-piecharwrapper]');
		let chartsImg = [];
		if ( charts.length > 0 ) {
			for await (let chart of charts) {
				let svg = chart.querySelector('svg');
				let b64png = await svgElementToB64Png(svg);

				chartsImg.push({
					id: chart.getAttribute('data-piecharwrapper'),
					b64png: b64png
				});
			}
		}

		// Disable fix
		setExportsChartsFix(null);

		// for(let i = 0; i < chartsImg.length; i++) {
		// 	chartsImg[i].b64png = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZAAAAAoCAYAAADQUaxgAAAAjklEQVR42u3VMQEAAAjDsKEc6UMDfyKhTyftBgCexkAAMBAADAQAAwHAQADAQAAwEAAMBAADAcBAAMBAADAQAAwEAAMBwEAAwEAAMBAADAQAAwHAQADAQAAwEAAMBAADAcBAAMBAADAQAAwEAAMBwEAAwEAAMBAADAQAAwHAQGQAwEAAMBAADAQAAwGA5ABCKWOxJUc/vQAAAABJRU5ErkJggg==';
		// }
		// console.log('http://fcgestionfincas.local/api/balances/export-dev/' + type + '/I?community_id=' + getCommunity()?.id + '&financial_year=' + financialYear.year + '&charts_images=' + JSON.stringify(chartsImg) + '&colors=' + JSON.stringify(data?.colors));

		await axios.post('api/manager/balances/export/' + type, {
			community_id: getCommunity()?.id,
			financial_year: financialYear.year,
			visible_sections: visibleSections,
			charts_images: chartsImg,
			colors: data?.colors
		}, {
			responseType: 'blob',
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let fileName = response.headers.file_name;

			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			link.parentElement.removeChild(link);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});
	}

	if ( !community?.id ) return <Navigate to="/" />;

	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Balance</h1>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm" id="card-balance">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" value={type} onChange={(e) => { setType(e.target.value); setData([]); }}>
										<option value="">- Tipo -</option>
										<option value="short">Balance resumido</option>
										<option value="long">Balance detallado</option>
										<option value="counterparts">Contrapartidas</option>
										<option value="expenses-distribution">Distribución de gastos</option>
									</select>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<EmpoweredSelector
										load={loadFinancialYears}
										args={{financialYears}}
										onChange={(value) => setFinancialYear(value)}
										timeout={250}
										label={financialYear?.year}
										placeholder="- Ejercicio -"
										value={financialYear?.id}
										noSearcheable={true}
									/>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<button onClick={() => setForceReload(Math.random())} className="btn btn-light btn-sm"><i className="bi bi-arrow-clockwise"></i></button>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
								</div>
								<div className="col-md-4 text-end mb-2 mt-2 mb-md-0 mt-md-0">
									{ type === 'long' &&
										<button onClick={() => setShowHideShowModal(true)} className="btn btn-light btn-sm">Mostrar u ocultar secciones</button>
									}

									<button className="btn btn-sm btn-light ms-2" onClick={exportClick}>Exportar</button>									
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							{ financialYear?.year &&
								<>
									{loading && <div style={{textAlign: 'center'}}>{loader}</div>}

									{!loading &&
										<>
											{type === 'short' && 
												<Short 
													data={data} 
													year={financialYear.year} 
													exportChartsFix={exportsChartsFix} 
												/>
											}

											{type === 'long' && 
												<Long 
													data={data} 
													year={financialYear.year} 
													visibleSections={visibleSections} 
												/>
											}

											{type === 'counterparts' && 
												<Counterparts 
													data={data} 
													year={financialYear.year} 
													visibleSections={visibleSections} 
												/>
											}
											
											{type === 'expenses-distribution' && 
												<ExpensesDistribution 
													data={data} 
												/>
											}	
										</>
									}
								</>
							}
						</div>
					</div>
				</div>
			</section>
			{ showHideShowModal && 
				<HideShowModal
					type={type}
					fields={ require('./' + ucfirst(type)).sections ?? {} }
					closeCallback={() => {
						setShowHideShowModal(false);
						setVisibleSections( getVisibleSectionsFromLocalStorage(type) );
					}}
				/> 
			}
		</LoggedLayout>
	);
}