import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';

const DraftStyled = styled.div`
    .alert {
        display: flex;
        flex-direction: row;
        align-items: center;

        i {

            &.icon-big {
                font-size: 24px;
                line-height: 0;
            }
        }
    }
`;

let axiosCancelToken = null;

const DraftInvoices = (props) => {
    const community = props.community;
    const forceReload = props.reload;

    const [usingAI, setUsingAI] = useState(false);
    const [processingCount, setProcessingCount] = useState(0);
    const [pendingReviewCount, setPendingReviewCount] = useState(0);

    useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

    useEffect(() => {
		const getProcessingCount = async () => {
			await axios.get('/api/manager/providers-invoices/list-draft', {
				params: {
					community_id: community?.id,
                    count: true,
                    pending_review: false
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setUsingAI(response.data.using_ai);
		    	setProcessingCount(response.data.invoices);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getProcessingCount();

		const getPendingReviewCount = async () => {
			await axios.get('/api/manager/providers-invoices/list-draft', {
				params: {
					community_id: community?.id,
                    count: true,
                    pending_review: true
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setPendingReviewCount(response.data.invoices);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getPendingReviewCount();

        let getProcessingInterval = setInterval(getProcessingCount, 10000);
        let getPendingReviewInterval = setInterval(getPendingReviewCount, 10000);

        return () => {
            clearInterval(getProcessingInterval);
            clearInterval(getPendingReviewInterval);
        }
	}, [community?.id, forceReload]);

    return (
        <>
            {(pendingReviewCount > 0 || processingCount > 0) &&
                <DraftStyled id="draft-invoices">
                    { processingCount > 0 &&
                        <div className="alert alert-warning" role="alert">
                            <div className="spinner-border me-2" role="status"></div>
                            {usingAI ? 'Se están procesando los archivos subidos con inteligencia artificial' : ''}
                        </div>
                    }

                    { pendingReviewCount > 0 &&
                        <div className="alert alert-danger" role="alert">
                            <i className="bi bi-exclamation-triangle-fill icon-big me-2"></i>
                            {usingAI ? 'Hay facturas pendientes de revisión' : 'Hay facturas pendientes de insertar la información'}
                            <NavLink to="/providers-invoices/draft" className="btn btn-danger btn-sm ms-auto text-white">Ver listado</NavLink>
                        </div>
                    }
                </DraftStyled>
            }
        </>
    )
}

export default DraftInvoices;