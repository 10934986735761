import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import randomatic from 'randomatic';
import LoggedLayout from 'layouts/LoggedLayout';
import { CristalLoader } from 'helpers/generic';
import FieldSmallComment from 'components/FieldSmallComment';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';

let axiosCancelToken = null;

export default function NoticesSourcesForm(props) {
	const navigate = useNavigate();
	const params = useParams();

	let [data, setData] = useState({
		code: randomatic('A0', 5),
		order: 0,
		unique: 0,
		default: 0,
		internal_task: 0,
		meeting_agreement: 0
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				await axios.get('/api/manager/notices-sources/get/' + params.id, {
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(response.data);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		} else {
			const getLastOrder = () => {
				axios.get('/api/manager/notices-sources/get-last-order', {
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(data => ({...data, order: response.data.order}));
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});
			}
			getLastOrder();
		}
	}, [params.id]);

	const deleteType = () => {
		const c = window.confirm('¿Quieres eliminar este origen de aviso?');
		if ( !c ) return;

		axios.delete('/api/manager/notices-sources/delete/' + data.id, {}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Origen de aviso borrado')
	    	navigate('/notices-sources');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (goBack = true) => {
		setErrors({});

		axios.post('/api/manager/notices-sources/save' + (data.id ? '/' + data.id : ''), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			setData({...response.data.source});
			if ( goBack ) navigate('/notices-sources');
			else {
		    	navigate('/notices-sources/edit/' + response.data.source.id);
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const setType = (type) => {
		let newData = {...data};
		newData.internal_task = 0;
		newData.meeting_agreement = 0;

		if ( type === 'internal_task' ) newData.internal_task = 1;
		if ( type === 'meeting_agreement' ) newData.meeting_agreement = 1;

		setData(newData);
	}

	let type = null;
	if ( data?.internal_task ) type = 'internal_task';
	if ( data?.meeting_agreement ) type = 'meeting_agreement';

	return (
		<LoggedLayout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de origen de aviso</h1>
					<button onClick={() => navigate('/notices-sources')} className="btn btn-sm btn-light ms-auto">Volver</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-lg-3">
											<div className="mb-2">
												<CustomInput label="Código" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('code', e.target.value)} value={data.code ?? ''} />
												{ errors.code &&
													<div className="invalid-feedback d-block">{ errors.code[0] }</div>
												}
											</div>
										</div>
										<div className="col-lg-3">
											<div className="mb-2">
												<CustomInput label="Nombre" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('name', e.target.value)} value={data.name ?? ''} />
												{ errors.name &&
													<div className="invalid-feedback d-block">{ errors.name[0] }</div>
												}
											</div>
										</div>
										<div className="col-lg-2">
											<div className="mb-2">
												<CustomInput label="Orden" type="number" className="form-control form-control-sm" onChange={(e) => setDataField('order', e.target.value)} value={data.order ?? ''} />
												{ errors.order &&
													<div className="invalid-feedback d-block">{ errors.order[0] }</div>
												}
											</div>
										</div>
										<div className="col-lg-2">
											<div className="mb-2">
												<CustomSelect label="Por defecto" className="form-control form-control-sm" onChange={(e) => setDataField('default', e.target.value)} value={data.default ?? ''}>
													<option value="0">No</option>
													<option value="1">Si</option>
												</CustomSelect>
												<FieldSmallComment className="mt-1">Si se marca por defecto aparecerá como origen preseleccionado en el formulario de avisos</FieldSmallComment>
												{ errors.default &&
													<div className="invalid-feedback d-block">{ errors.default[0] }</div>
												}
											</div>
										</div>
										<div className="col-lg-2">
											<div className="mb-2">
												{/* <CustomSelect label="Tarea interna" className="form-control form-control-sm" onChange={(e) => setDataField('internal_task', e.target.value)} value={data.internal_task ?? ''}>
													<option value="0">No</option>
													<option value="1">Si</option>
												</CustomSelect> */}
												<CustomSelect label="Tipo" className="form-control form-control-sm" onChange={(e) => setType(e.target.value)} value={type ?? ''}>
													<option value=""></option>
													<option value="internal_task">Tarea interna</option>
													<option value="meeting_agreement">Acuerdo de junta</option>
												</CustomSelect>
												<FieldSmallComment className="mt-1">
													{type === 'internal_task' && 'Si se marca como tarea interna, los avisos que utilicen este origen solo podrán ser cerrados por su creador'}
													{type === 'meeting_agreement' && 'Si se marca como acuerdo de junta, los avisos que utilicen este origen se utilizaran para generar avisos desde la pantalla de juntas. Se generará un aviso para acuerdo de la junta.'}
												</FieldSmallComment>
												{ errors.internal_task &&
													<div className="invalid-feedback d-block">{ errors.internal_task[0] }</div>
												}
											</div>
										</div>
									</div>	
								</div>
								<div className="card-footer">
									<div className="row">
										<div className="col-6">
											{ data.id &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteType()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}