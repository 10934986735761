import React, { useState, useEffect } from 'react';
import { Navigate, NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from 'react-toastify';
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import LoggedLayout from "layouts/LoggedLayout";
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import { getCommunity } from 'helpers/community';
import { authUserPermission } from 'helpers/community';
import { formatNumber, openPopupWindow } from 'helpers/generic';
// import { openPopupWindow } from 'helpers/generic';
import ExportModal from 'components/ExportModal';
import NewPropertyModal from './NewPropertyModal';
import ImportExcelCatastroModal from './ImportExcelCatastroModal';
import ImportInitialBalancesBulkModal from './ImportInitialBalancesBulkModal';

const Table = styled.table`

	th {
		&:nth-child(3) {

		}
	}

	th,
	td {
		cursor: pointer;

		&:nth-child(1),
		&:nth-child(3) {
			width: 50px;

			&.coefficient_wrapper {
				position: relative;
				background: var(--bs-light);

				input {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border: 0;
					background: transparent;
					text-align: right;
					cursor: default;
				}
			}
		}

		&:nth-child(2) {
			
		}

		&:nth-child(3) {
			width: 50px;
		}
	
		&:nth-child(4),
		&:nth-child(5) {
			max-width: 250px;
		}
	
		&:nth-child(6),
		&:nth-child(7),
		&:nth-child(8),
		&:nth-child(9) {
			width: 110px;
			text-align: right;
			cursor: default;
		}
	
		&:nth-child(11) {
			width: 40px;
		}
		
		/* @media (max-width: 768px) {
			&:nth-child(1),
			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(6),
			&:nth-child(7) {
				display: none;
			}
		} */
	}

	tr {
		&.hidden {
			display: none;
		}
	}
`;

// const TableOwnersRenters = styled.table`
// 	font-size: 12px;

// 	th, td {
// 		padding: 2px 5px;

// 		&:nth-child(2) {
// 			width: 40px;
// 			text-align: center;
// 		}
// 	}
// `;

let axiosCancelToken = null;
let searchTimeout = null;

export default function Properties() {
	const navigate = useNavigate();
	let [queryParams] = useSearchParams();

	let community = getCommunity();
	let [forceReload, setForceReload] = useState(undefined);
	let [properties, setProperties] = useState(undefined);
	let [balances, setBalances] = useState({});
	let [search, setSearch] = useState(undefined);
	let [types, setTypes] = useState([]);
	let [typeId, setTypeId] = useState(undefined);
	let [streets, setStreets] = useState([]);
	let [buildings, setBuildings] = useState([]);
	let [stairsList, setStairsList] = useState([]);
	let [plantList, setPlantList] = useState([]);
	let [streetId, setStreetId] = useState(undefined);
	let [buildingId, setBuildingId] = useState(undefined);
	let [stairs, setStairs] = useState(undefined);
	let [plant, setPlant] = useState(undefined);
	let [sortDirection, setSortDirection] = useState('asc');
	let [sortField, setSortField] = useState('number');
	// let [detailsOpened, setDetailsOpened] = useState([]);
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [coefficients, setCoefficients] = useState([]);
	let [editableCoefficients, setEditableCoefficients] = useState(false);
	let [showExportModal, setShowExportModal] = useState(false);
	let [showNewModal, setShowNewModal] = useState(false);
	let [showImportCatastroModal, setShowImportCatastroModal] = useState(false);
	let [showImportInitialBalancesBulkModal, setShowImportInitialBalancesBulkModal] = useState(false);
	let [showMorosos, setShowMorosos] = useState(queryParams.get('show_morosos') === "true" ?? false);

	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	// const toggleDetails = (rowId) => {
	// 	if ( detailsOpened.includes(rowId) ) {
	// 		detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
	// 		setDetailsOpened([...detailsOpened]);
	// 	} else {
	// 		setDetailsOpened([...detailsOpened, rowId]);
	// 	}
	// }

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getTypes = async () => {
			await axios.get('/api/manager/properties-types/list', {
				params: {
					no_paginate: true
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setTypes([...response.data]);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}
		getTypes();

		const getStreets = async () => {
			await axios.get('/api/manager/communities/get/' + community?.id, {
				params: {
					community_id: community?.id
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setStreets([...response.data.streets]);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}
		getStreets();

		const getBuildings = async () => {
			await axios.get('/api/manager/buildings/list', {
				params: {
					no_paginate: true,
					community_id: community?.id
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setBuildings([...response.data]);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}
		getBuildings();

		const getProperties = async () => {
			await axios.get('/api/manager/properties/list', {
				params: {
					community_id 	: community?.id,
					search 			: search,
					type_id 		: typeId,
					building_id 	: buildingId,
					street_id 		: streetId,
					stairs 			: stairs,
					plant 			: plant,
					sort 			: sortField,
					direction 		: sortDirection
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setProperties([...response.data]);
		    	setCoefficients([...response.data.map(el => el.coefficient)]);
				setStairsList([
					...response.data
								.map(el => el.stairs)
								.filter(el => el && el.length > 0)
								.filter((v, i, a) => a.indexOf(v) === i)
								.sort()
				]);
				setPlantList([
					...response.data
								.map(el => el.plant)
								.filter(el => el && el.length > 0)
								.filter((v, i, a) => a.indexOf(v) === i)
								.sort()
				]);
		    	setSkeletonRows(response.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	

			// Filters
			await axios.get('/api/manager/properties/list', {
				params: {
					community_id: community?.id,
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				setStairsList([
					...response.data
								.map(el => el.stairs)
								.filter(el => el && el.length > 0)
								.filter((v, i, a) => a.indexOf(v) === i)
								.sort()
				]);
				setPlantList([
					...response.data
								.map(el => el.plant)
								.filter(el => el && el.length > 0)
								.filter((v, i, a) => a.indexOf(v) === i)
								.sort()
				]);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getProperties();
	}, [forceReload, search, typeId, streetId, buildingId, stairs, plant, sortField, sortDirection, community?.id]);

	useEffect(() => {
		if ( !properties || !properties.length ) return;

		let getBalances = async () => {
			await axios.get('/api/manager/properties/get-balances-bulk', {
				params: {
					community_id: community?.id,
					properties_ids: properties.map(el => el.id)
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				setBalances(response.data);
			}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getBalances()
	}, [properties, community?.id]);

	const setCoefficient = (idx, coefficient, sanitize = true) => {
		if ( sanitize ) {
			coefficient = parseFloat(coefficient);
			if ( isNaN(coefficient) ) coefficient = '';
		}

		let newCoefficients = [...coefficients];
		newCoefficients[idx] = coefficient;
		setCoefficients(newCoefficients);
	}

	const updateCoefficient = (idx) => {
		let property = properties[idx];
		let coefficient = coefficients[idx];
		if ( parseFloat(property.coefficient) === parseFloat(coefficient) ) return;

		axios.post('/api/manager/properties/update-coefficient', {
			property_id: property.id,
			community_id: community?.id,
			coefficient: coefficient
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			setCoefficient(idx, response.data.coefficient, false);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error('Error al guardar el coeficiente');
		});	
	}

	const coefficientInputKeyPress = (e, idx) => {
		let charCode = e.charCode;
		if ( charCode === 13 ) {
			document.activeElement.blur();
			document.querySelector('input[data-idx="'+(idx+1)+'"]')?.focus();
		}
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();
		e.stopPropagation();

		openPopupWindow(e.currentTarget.href);
	}

	// const openOwnerRenterWindow = (e) => {
	// 	e.preventDefault();

	// 	openPopupWindow(e.currentTarget.href);
	// }

	if ( !community?.id ) return <Navigate to="/" />;

	let percent_total = 0;
	coefficients.forEach(el => percent_total += parseFloat(el));
	percent_total = formatNumber(percent_total, 4, false);

	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Propiedades</h1>

					<div className="ms-auto">
						<NavLink to="/buildings" className="btn btn-sm btn-warning">Ir a portales</NavLink>

						{ authUserPermission('add') &&
							<NavLink to="/properties/add" className="btn btn-sm btn-light ms-3">Nueva propiedad</NavLink>
						}
					</div>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<input type="search" className="form-control form-control-sm" placeholder="Buscar" onChange={(e) => setSearchTimeout(e.target.value)} />
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" onChange={(e) => setTypeId(e.target.value)} value={typeId ?? ''}>
										<option value="">- Tipo de propiedad -</option>
										{ types?.map((el, idx) => {
											return <option value={el.id} key={el.id}>{el.name}</option>;
										})}
									</select>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" onChange={(e) => setStreetId(e.target.value)} value={streetId ?? ''}>
										<option value="">- Calles -</option>
										{ streets?.map((el, idx) => {
											return <option value={el.id} key={el.id}>{el.name}</option>;
										})}
									</select>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" onChange={(e) => setBuildingId(e.target.value)} value={buildingId ?? ''}>
										<option value="">- Portales -</option>
										{ buildings?.map((el, idx) => {
											return <option value={el.id} key={el.id}>{el.name}</option>;
										})}
									</select>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" onChange={(e) => setStairs(e.target.value)} value={stairs ?? ''}>
										<option value="">- Escaleras -</option>
										{ stairsList?.map((el, idx) => {
											return <option value={el} key={el}>{el}</option>;
										})}
									</select>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" onChange={(e) => setPlant(e.target.value)} value={plant ?? ''}>
										<option value="">- Planta -</option>
										{ plantList?.map((el, idx) => {
											return <option value={el} key={el}>{el}</option>;
										})}
									</select>
								</div>
								<div className="col-md-6"></div>
								<div className="col-md-6 mb-2 mt-2 mb-md-0 mt-md-2 text-end">
									<div className="form-check form-switch d-inline-flex align-items-center me-4">
										<input className="form-check-input me-2 mt-0" type="checkbox" checked={showMorosos} onChange={(e) => setShowMorosos(e.target.checked)} />
										<label className="form-check-label">Mostrar morosos</label>
									</div>

									{ authUserPermission('add') && 
										<button className="btn btn-sm btn-light me-2" onClick={() => setShowImportCatastroModal(true)} style={{fontSize: '10px', lineHeight: '10px'}}>
											IMPORTAR EXCEL<br />
											CATASTRO
										</button> 
									}
									{ authUserPermission('edit') && 
										<button className="btn btn-sm btn-light me-2" onClick={() => setShowImportInitialBalancesBulkModal(true)} style={{fontSize: '10px', lineHeight: '10px'}}>
											IMPORTAR SALDO<br />
											INICIAL
										</button> 
									}
									{ authUserPermission('add') && <button className="btn btn-sm btn-light me-2" onClick={() => setShowNewModal(true)}><i className="bi bi-plus"></i></button> }
									<button className="btn btn-sm btn-light" onClick={() => setShowExportModal(true)}>Exportar</button>
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'number'} onClick={() => sortTableClick('number')}>Nº Dep.</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'name'} onClick={() => sortTableClick('name')}>Dirección</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'coefficient'} onClick={() => sortTableClick('coefficient')}>	
												Coeficiente

												{ authUserPermission('edit') &&
													<i className={'bi bi-pencil text-' + (editableCoefficients ? 'danger' : 'primary') + ' me-3'} onClick={(e) => { e.stopPropagation(); setEditableCoefficients(!editableCoefficients) }}></i>			
												}							
											</ThSortable>
											<th>Propietarios</th>
											<th>Inquilinos</th>
											<th>Sal. Ini. recibos</th>
											<th>Sal. Ini. gastos</th>
											<th>Saldo recibos</th>
											<th>Saldo gastos</th>
											<th style={{width: '40px'}}></th>
										</tr>
									</thead>
									<tbody>
										{ properties?.length > 0 &&
											properties.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														{/* <tr className="has-details" onClick={() => toggleDetails(el.id)}> */}
														<tr onClick={() => navigate('/properties/edit/' + el.id)} className={showMorosos && balances[el.id]?.receipts >= 0 ? 'hidden' : ''}>
															<td>{el.number}</td>
															<td>{el.name}</td>
															<td className="coefficient_wrapper">
																<input 
																	data-idx={idx}
																	tabIndex={idx+1}
																	type="number" 
																	onClick={(e) => e.stopPropagation()} 
																	className="no-arrows" 
																	value={coefficients[idx] ?? ''} 
																	onChange={(e) => setCoefficient(idx, e.target.value)} 
																	onBlur={(e) => updateCoefficient(idx)} 
																	onKeyPress={(e) => coefficientInputKeyPress(e, idx)}
																	onFocus={(e) => e.target.select()}
																	disabled={!editableCoefficients}
																/>
															</td>
															<td>
																{el.owners?.map((el, idx) => {
																	return <NavLink key={idx} to={'/owners/edit/' + el.id + '?popup=true'} onClick={openPopupInfoWindow} className="badge bg-light text-secondary text-decoration-none me-1">{el.name}</NavLink>;
																})}
															</td>
															<td>
																{el.renters?.map((el, idx) => {
																	return <NavLink key={idx} to={'/renters/edit/' + el.id + '?popup=true'} onClick={openPopupInfoWindow} className="badge bg-light text-secondary text-decoration-none me-1">{el.name}</NavLink>;
																})}
															</td>
															<td>{balances[el.id]?.initial_receipts !== undefined ? formatNumber(balances[el.id]?.initial_receipts) : <Skeleton /> }</td>
															<td>{balances[el.id]?.initial !== undefined ? formatNumber(balances[el.id]?.initial) : <Skeleton /> }</td>
															<td>{balances[el.id]?.receipts !== undefined ? formatNumber(balances[el.id]?.receipts) : <Skeleton /> }</td>
															<td>{balances[el.id]?.expenses !== undefined ? formatNumber(balances[el.id]?.expenses) : <Skeleton /> }</td>
															<td>
																<NavLink tabIndex="-1" className="btn-table" to={'/properties/edit/' + el.id}><i className="bi bi-pencil"></i></NavLink>
															</td>
														</tr>
														{/* { detailsOpened.includes(el.id) && 	
															<tr className="details">
																<td colSpan="100%">
																	<div className="row ms-0 me-0">
																		<div className="col-md-4">
																			<div><b className="sbold">Nombre</b>: {el.name ?? '-'}</div>
																			<div><b className="sbold">Código</b>: {el.code ?? '-'}</div>
																			<div><b className="sbold">Nº dependencia</b>: {el.number ?? '-'}</div>
																			<div className="mb-3"><b className="sbold">Coeficiente</b>: {formatNumber(el.coefficient)}</div>

																			<div><b className="sbold">Bloque/Portal</b>: {el.building?.name ?? '-'}</div>
																			<div><b className="sbold">Escaleras</b>: {el.stairs ?? '-'}</div>
																			<div><b className="sbold">Planta</b>: {el.plant ?? '-'}</div>
																			<div><b className="sbold">Puerta</b>: {el.door ?? '-'}</div>
																			<div className="mb-3"><b className="sbold">Tipo</b>: {el.type?.name ?? '-'}</div>

																			<div><b className="sbold">Fecha de compra</b>: {el.buy_date ? moment(el.buy_date).format('DD-MM-YYYY') : '-'}</div>

																		</div>
																		<div className="col-md-4">
																			{ el.owners.length > 0 &&
																				<TableOwnersRenters className="table table-sm table-bordered">
																					<thead>
																						<tr>
																							<th>Propietario</th>
																							<th>%</th>
																						</tr>
																					</thead>
																					<tbody>
																						{el.owners.map((el, idx) => {
																							return (
																								<tr key={idx}>
																									<td><NavLink className="text-decoration-none text-reset" to={'/owners/edit/' + el.id + '?popup=true'} onClick={(e) => openOwnerRenterWindow(e)}>{el.name} <i className="bi bi-eye text-primary"></i></NavLink></td>
																									<td>{formatNumber(el.coefficient, 2)}</td>
																								</tr>
																							);
																						})}
																					</tbody>
																				</TableOwnersRenters>
																			}

																			{ el.renters.length > 0 &&
																				<TableOwnersRenters className="table table-sm table-bordered mt-3">
																					<thead>
																						<tr>
																							<th>Inquilino</th>
																							<th>%</th>
																						</tr>
																					</thead>
																					<tbody>
																						{el.renters.map((el, idx) => {
																							return (
																								<tr key={idx}>
																									<td><NavLink className="text-decoration-none text-reset" target="_blank" to={'/renters/edit/' + el.id + '?popup=true'} onClick={(e) => openOwnerRenterWindow(e)}>{el.name} <i className="bi bi-eye text-primary"></i></NavLink></td>
																									<td>{formatNumber(el.coefficient, 2)}</td>
																								</tr>
																							);
																						})}
																					</tbody>
																				</TableOwnersRenters>
																			}
																		</div>
																		<div className="col-md-4">

																		</div>
																	</div>
																</td>
															</tr>
														} */}
													</React.Fragment>
												);
											})
										}

										{ properties && !properties.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ properties === undefined && <TrSkeleton rows={skeletonRows} columns={11} /> }
									</tbody>
									<tfoot>
										<tr 
											className={
												'sbold ' + 
												(percent_total < 100 ? 'text-secondary' : '') + 
												(percent_total === 100 ? 'text-primary' : '') + 
												(percent_total > 100 ? 'text-danger' : '')
											}
										>
											<td colSpan="2" className="text-end">Total</td>
											<td className="text-end">
												{percent_total}%
											</td>
											<td colSpan="4">
												{ percent_total > 100 &&
													<div className="text-danger sbold">
														<i className="bi bi-exclamation-triangle"></i>&nbsp;
														Has sobrepasado el 100% de coeficiente
													</div>
												}
											</td>
										</tr>
									</tfoot>
								</Table>
							</div>
						</div>
					</div>
				</div>
			</section>
			{ showExportModal &&
				<ExportModal
					exportKey={'properties'}
					fileName={'Propiedades ' + moment().format('DD-MM-YYYY HH:mm')}
					fields={{
						type: 						'Tipo',
						building: 					'Portal',
						code: 						'Código',
						number: 					'Número',
						name: 						'Nombre',
						coefficient: 				'Coeficiente',
						cadastral_reference:		'Referencia catastral',
						buy_date:					'Fecha de compra',
						receipts_balance: 			'Saldo de recibos',
						expenses_balance: 			'Saldo de gastos',
						owners: 					'Propietarios',
						renters: 					'Inquilinos',
						ordinary_payer:				'Pagador cuota ordinaria',
						consumption_payer:			'Pagador consumos',
						extraordinary_payer:		'Pagador cuota extraordinaria',
						
					}}
					filters={{
						community_id: 				community?.id,
						search: 					search,
						type_id: 					typeId,
						building_id: 				buildingId,
						sort: 						sortField,
						direction: 					sortDirection,
						no_paginate:				true
					}}
					closeCallback={() => setShowExportModal(false)}
				/>
			}
			{ showNewModal &&
				<NewPropertyModal
					closeCallback={() => {
						setShowNewModal(false);
						setForceReload(Math.random());
					}}
				/>
			}
			{ showImportCatastroModal &&
				<ImportExcelCatastroModal
					closeCallback={() => {
						setShowImportCatastroModal(false);
						setForceReload(Math.random());
					}}
				/>
			}
			{ showImportInitialBalancesBulkModal &&
				<ImportInitialBalancesBulkModal
					closeCallback={() => {
						setShowImportInitialBalancesBulkModal(false);
						setForceReload(Math.random());
					}}
				/>
			}
		</LoggedLayout>
	);
}


