import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { getCommunity } from 'helpers/community';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { formatNumber } from 'helpers/generic';
import ReactDOMServer from 'react-dom/server';

const Table = styled.table` 
    font-size: 12px;

    thead {
        th {
            font-weight: 500;

            &:nth-child(1),
            &:nth-child(3) {
                width: 200px;
            }
        }
    }

    th, td {
        &:nth-child(4),
        &:nth-child(5) {
            width: 90px;
            min-width: 90px;
            text-align: right;
        }
    }
`;

let axiosCancelToken = null;

export default function Model184() {
	let [financialYear, setFinancialYear] = useState(moment().format('YYYY'));
	let [estimateGroupExpense, setEstimateGroupExpense] = useState(3000);
    let [community, setCommunity] = useState({
		id: getCommunity()?.id,
		name: getCommunity()?.name
	});
    let [data, setData] = useState([]);

    useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

    useEffect(() => {
        const getData = async () => {     
            axiosCancelToken.cancel(); // Cancel previous request
            axiosCancelToken = axios.CancelToken.source(); // Get new token
            
            await axios.get('/api/manager/administration-models/get-184', {
                params: {
                    community_id: community?.id,
                    financial_year: financialYear,
                    estimategroupexpense_id: estimateGroupExpense?.id
                },
                cancelToken: axiosCancelToken.token
            }).then((response) => {
                setData(response.data);
            }).catch((error) => {
                if ( axios.isCancel(error) ) return;
            });	
        }
        getData();
    }, [financialYear, community?.id, estimateGroupExpense]);

    const loadFinancialYears = (input, callback, args) => {
		axios.get('/api/manager/administration-models/financialyears-list', {
			params: {
                community_id: args.community_id
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

    const loadCommunities = (input, callback) => {
		axios.get('/api/manager/communities/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

    const loadEstimateGroupsExpenses = (input, callback, args) => {
		axios.get('/api/manager/estimates/list', {
			params: {
				community_id: args.community_id,
				// search: input,
				no_paginate: true,
				status: 1,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = [];
			response.data.forEach(estimate => {
				estimate.groups.forEach(group => {
                    group.titles.forEach(title => {
                        title.expenses.forEach(expense => {
                            formatted.push({
                                value: {
                                    ...expense,
                                    estimategroup: {...group},
                                    title: {...title},
                                    estimate: {...estimate}
                                },
                                label: 
                                    <React.Fragment>
                                        {'P. ' + (estimate.type === 'ordinary' ? 'Ordinario' : 'Extraordinario') + ' ' + estimate.number_full}
                                        <i className="bi bi-arrow-right mx-1"></i>
                                        {title.denomination}
                                        <i className="bi bi-arrow-right mx-1"></i>
                                        {group.denomination}
                                        <i className="bi bi-arrow-right mx-1"></i>
                                        {expense.denomination}
                                    </React.Fragment>
                            });
                        })
                    });
				});
			});

            formatted = formatted.filter(el => {
				let div = document.createElement('div');
				div.innerHTML = ReactDOMServer.renderToString(el.label);
				let text = (div.textContent || div.innerText || '').toLowerCase();
				
				let inputSplit = input.split(' ');
				let coincidence = false;
				for(let cIdx in inputSplit) {
					let term = inputSplit[cIdx].toLowerCase();
					coincidence = text.indexOf(term) !== -1;
				}

				return coincidence;
			});

			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

    const selectEstimateGroupExpense = (value) => {
		let data = null;
		if ( value ) {
			data = {
				id: value?.id,
				denomination: value?.denomination,
				estimategroup: {
					denomination: value?.estimategroup.denomination,
                    title: {
						denomination: value?.title.denomination
                    },
					estimate: {
						type: value?.estimate.type,
						number_full: value?.estimate.number_full
					}
				}
			};
		}
		setEstimateGroupExpense(data);
	}

    const exportModel = async () => {
        await axios.post('api/manager/administration-models/export/184', {
            community_id: community?.id,
            financial_year: financialYear,
            estimategroupexpense_id: estimateGroupExpense?.id
        }, {
			responseType: 'blob',
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'modelo184.xlsx');
			document.body.appendChild(link);
			link.click();
			link.parentElement.removeChild(link);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});
    }

	return (
		<React.Fragment>
            <div className="row">
                <div className="col-md-3">
                    <label>Comunidad</label>
                    <EmpoweredSelector
                        load={loadCommunities}
                        onChange={(value) => setCommunity({id: value?.id, name: value?.name})}
                        timeout={250}
                        label={community?.name ?? ''}
                        placeholder="Todas las comunidades"
                        value={community?.id}
                    />
                </div>
                <div className="col-md-2">
                    <label>Año fiscal</label>
                    <EmpoweredSelector
                        load={loadFinancialYears}
                        args={{community_id: community?.id}}
                        onChange={(value) => setFinancialYear(value)}
                        timeout={250}
                        label={financialYear ?? ''}
                        placeholder=""
                        value={financialYear ?? ''}
                        noSearcheable={true}
                    />
                </div>
                <div className="col-md-4">
                    <label>Contrapartida presupuesto</label>
                    <EmpoweredSelector
                        load={loadEstimateGroupsExpenses}
                        args={{community_id: community?.id}}
                        onChange={(value) => selectEstimateGroupExpense(value)}
                        timeout={250}
                        label={
                            estimateGroupExpense?.id ? 
                                <span>
                                    {'Presupuesto ' + (estimateGroupExpense.estimategroup.estimate.type === 'ordinary' ? 'Ordinario' : 'Extraordinario') + ' ' + estimateGroupExpense.estimategroup.estimate.number_full}
                                    <i className="bi bi-arrow-right mx-1"></i>
                                    {estimateGroupExpense.estimategroup.title.denomination}
                                    <i className="bi bi-arrow-right mx-1"></i>
                                    {estimateGroupExpense.estimategroup.denomination}
                                    <i className="bi bi-arrow-right mx-1"></i>
                                    {estimateGroupExpense.denomination}
                                </span>
                            :
                                ''
                        }
                        placeholder=""
                        value={estimateGroupExpense?.id ?? ''}
                        // noSearcheable={true}
                    />
                </div>
                <div className="col-md-3 text-end pt-4">
                    <button className="btn btn-sm btn-light" onClick={exportModel}>Exportar</button>
                </div>
            </div>

            <div className="table-responsive mt-3">
                <Table className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>Comunidad</th>
                            <th>Propiedad</th>
                            <th>Propietario</th>
                            <th>Coeficiente</th>
                            <th>Ingresos</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((el, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{el.community?.name}</td>
                                    <td>{el.property?.name}</td>
                                    <td>{el.owner?.name} / {el.owner?.vatnumber}</td>
                                    <td>{el.property?.coefficient}</td>
                                    <td>{formatNumber(el.total)} €</td>
                                </tr>
                            )
                        })}
                        {!data.length && <tr><td colSpan="100%">No se han encontrado resultados</td></tr>}
                    </tbody>
                </Table>
            </div>  
        </React.Fragment>
	);
}


