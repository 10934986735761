import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import randomatic from 'randomatic';
import LoggedLayout from 'layouts/LoggedLayout';
import { CristalLoader } from 'helpers/generic';
import FieldSmallComment from 'components/FieldSmallComment';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';

let axiosCancelToken = null;

export default function CommunityPositionsForm(props) {
	const navigate = useNavigate();
	const params = useParams();

	let [data, setData] = useState({
		code: randomatic('A0', 5),
		order: 0,
		unique: 0,
		visible_on_forms: 0
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				await axios.get('/api/manager/community-positions/get/' + params.id, {
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(response.data);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		} else {
			const getLastOrder = () => {
				axios.get('/api/manager/community-positions/get-last-order', {
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(data => ({...data, order: response.data.order}));
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});
			}
			getLastOrder();
		}
	}, [params.id]);

	const deletePosition = () => {
		const c = window.confirm('¿Quieres eliminar este cargo?');
		if ( !c ) return;

		axios.delete('/api/manager/community-positions/delete/' + data.id, {}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Cargo borrado')
	    	navigate('/community-positions');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (goBack = true) => {
		setErrors({});

		axios.post('/api/manager/community-positions/save' + (data.id ? '/' + data.id : ''), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			setData({...response.data.position});
			if ( goBack ) navigate('/community-positions');
			else {
		    	navigate('/community-positions/edit/' + response.data.position.id);
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	return (
		<LoggedLayout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de cargo de comunidades</h1>
					<button onClick={() => navigate('/community-positions')} className="btn btn-sm btn-light ms-auto">Volver</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-8">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="row">
											<div className="col-lg-4">
												<div className="mb-2">
													<CustomInput label="Código" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('code', e.target.value)} value={data.code ?? ''} />
													{ errors.code &&
														<div className="invalid-feedback d-block">{ errors.code[0] }</div>
													}
												</div>
											</div>
											<div className="col-lg-4">
												<div className="mb-2">
													<CustomInput label="Nombre" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('name', e.target.value)} value={data.name ?? ''} />
													{ errors.name &&
														<div className="invalid-feedback d-block">{ errors.name[0] }</div>
													}
												</div>
											</div>
											<div className="col-lg-4">
												<div className="mb-2">
													<CustomInput label="Orden" type="number" className="form-control form-control-sm" onChange={(e) => setDataField('order', e.target.value)} value={data.order ?? ''} />
													{ errors.order &&
														<div className="invalid-feedback d-block">{ errors.order[0] }</div>
													}
												</div>
											</div>
											<div className="col-lg-4">
												<div className="mb-2">
													<CustomSelect label="Cargo único" className="form-control form-control-sm" onChange={(e) => setDataField('unique', e.target.value)} value={data.unique ?? ''}>
														<option value="0">No</option>
														<option value="1">Si</option>
													</CustomSelect>
													<FieldSmallComment className="mt-1">Solo podrá existir un propietario con este cargo en la comunidad</FieldSmallComment>
													{ errors.unique &&
														<div className="invalid-feedback d-block">{ errors.unique[0] }</div>
													}
												</div>
											</div>
											<div className="col-lg-4">
												<div className="mb-2">
													<CustomSelect label="Visible en formularios" className="form-control form-control-sm" onChange={(e) => setDataField('visible_on_forms', e.target.value)} value={data.visible_on_forms ?? ''}>
														<option value="0">No</option>
														<option value="1">Si</option>
													</CustomSelect>
													<FieldSmallComment className="mt-1">Este cargo de comunidad se mostrará en pantallas informativas donde es necesario mostrar los cargos importantes de una comunidad</FieldSmallComment>
													{ errors.visible_on_forms &&
														<div className="invalid-feedback d-block">{ errors.visible_on_forms[0] }</div>
													}
												</div>
											</div>
										</div>	
									</div>
								</div>
								<div className="card-footer">
									<div className="row">
										<div className="col-6">
											{ data.id &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deletePosition()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}