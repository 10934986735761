import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import ThSortable from 'components/ThSortable';
import TrSkeleton from 'components/TrSkeleton';

const Table = styled.table`
	
	th,
	td {
		&:nth-child(1) {
			width: 150px;
			white-space: nowrap;
		}

		&:nth-child(3) {
			width: 40px;
		}
	}	

`;

let axiosCancelToken = null;

export default function SectorsCheckList(props) {
	const search = props.search;
	const community_id = props.communityId;
	const sectorsId = props.sectorsId;

	let [checks, setChecks] = useState(undefined);
	let [sortDirection, setSortDirection] = useState('asc');
	let [sortField, setSortField] = useState('community_name');
	let [skeletonRows, setSkeletonRows] = useState(5);

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getChecks = async () => {
			await axios.get('/api/manager/contracts/check-by-sectors-list', {
				params: {
					search: search,
					sort: sortField,
					direction: sortDirection,
					community_id: community_id,
					sectors_id: sectorsId,
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setChecks([...response.data]);
		    	setSkeletonRows(response.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getChecks();
	}, [search, sortField, sortDirection, sectorsId, community_id]);

	return (
		<>
			<div className="card-body p-0">
				<div className="table-responsive table-responsive-carded">
					<Table className="table table-hover table-sortable table-carded">
						<thead>
							<tr>
								<ThSortable direction={sortDirection} active={sortField === 'community_name'} onClick={() => sortTableClick('community_name')}>Comunidad</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'sector_name'} onClick={() => sortTableClick('sector_name')}>Sector</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'contracts_count'} onClick={() => sortTableClick('contracts_count')}>Estado</ThSortable>
							</tr>
						</thead>
						<tbody>
							{ checks?.length > 0 &&
								checks.map((el, idx) => {
									return ( 
										<React.Fragment key={idx}>
											<tr>
												<td>{el.community_name}</td>
												<td>{el.sector_name}</td>
												<td>
													<div className={'text-white text-center bg-' + (el.contracts_count > 0 ? 'success' : 'danger')}>
														{ el.contracts_count > 0 ? 'Si' : 'No' }
													</div>	
												</td>
											</tr>
										</React.Fragment>
									);
								})
							}

							{ checks && !checks.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

							{ checks === undefined && <TrSkeleton rows={skeletonRows} columns={3} /> }
						</tbody>
					</Table>
				</div>
			</div>
		</>
	);
}