import React, { useRef } from 'react';
import styled from 'styled-components';
import LabeledFrame from 'components/LabeledFrame';
import { Editor } from '@tinymce/tinymce-react';

const TinymceStyled = styled.div`
	.tox.tox-tinymce {
		border: 0 !important;
	}
`;

export default function Basic(props) {
	let data = props.data;
	let setDataField = props.setDataField;

	const editorAnnouncementRef = useRef(null);
	const editorProceedingRef = useRef(null);

	return (
		<React.Fragment>
			<div className="col-md-6">
				<LabeledFrame 
					label="Observaciones convocatoria"
					contentPadding="0 !important"
				>
					<TinymceStyled>
						<Editor tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
							onInit={(evt, editor) => editorAnnouncementRef.current = editor}
							value={data.observations_announcement ?? ''}
							onEditorChange={(newValue, editor) => setDataField('observations_announcement', newValue)}
							init={{
								height: 200,
								menubar: false,
								statusbar: false,
								language: 'es',
								plugins: ['lists', 'autolink', 'link'],
								toolbar: 'bold italic forecolor backcolor bullist numlist link',
								browser_spellcheck: true,
								contextmenu: false
							}}
						/>
					</TinymceStyled>
				</LabeledFrame>
			</div>
			<div className="col-md-6">
				<LabeledFrame 
					className="mt-3 mt-md-0" 
					label="Observaciones acta"
					contentPadding="0 !important"
				>
					<TinymceStyled>
						<Editor tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
							onInit={(evt, editor) => editorProceedingRef.current = editor}
							value={data.observations_proceeding ?? ''}
							onEditorChange={(newValue, editor) => setDataField('observations_proceeding', newValue)}
							init={{
								height: 200,
								menubar: false,
								statusbar: false,
								language: 'es',
								plugins: ['lists', 'autolink', 'link'],
								toolbar: 'bold italic forecolor backcolor bullist numlist link',
								browser_spellcheck: true,
								contextmenu: false
							}}
						/>
					</TinymceStyled>
				</LabeledFrame>
			</div>
		</React.Fragment>
	);
}