import axios from 'axios';
import { getCommunity } from './community';
import { openPopupWindow } from './generic';

export const viewReceiptPdf = (id) => {
	axios.get('/api/manager/receipts/view-pdf/' + id, {
		params: {
			community_id: getCommunity()?.id
		},
		responseType: 'blob'
	}).then((response) => {
		let objectUrl = URL.createObjectURL(response.data);
		openPopupWindow(objectUrl);
  	});
}