import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import axios from 'axios';
import styled from 'styled-components';
import PopupLayout from 'layouts/PopupLayout';
import TrSkeleton from 'components/TrSkeleton';
import { getPetitionerFormatted, openPopupWindow } from 'helpers/generic';

const Table = styled.table`
	height: 100%;

	th,td {
		&:nth-child(1) {
			white-space: nowrap;
			width: 100px;
		}

		&:nth-child(2) {
			width: 150px;
		}

		&:nth-child(3) {
			width: 150px;
		}

		&:nth-child(4) {
			width: 100px;
		}

		&:nth-child(6) {
			width: 40px;
		}
		
		
		@media (max-width: 768px) {
			&:nth-child(4) {
				display: none;
			}
		}
	}

	td {
		cursor: pointer;
	}
`;

const PetitionerSelectRow = styled.div`
	position: relative;

	.name {

	}

	.type {
		background: var(--bs-secondary);
		color: white;
		padding: 2px 8px;
		font-size: 10px;
		border-radius: 3px;
		display: inline-block;
		margin-right: 5px;

		&:first-of-type {
			width: 80px;
			text-align: center;
		}
	}

	.community {
		&:empty {
			display: none;
		}

		background: var(--bs-primary);
		color: white;
		padding: 2px 8px;
		font-size: 10px;
		border-radius: 3px;
		display: inline-block;
	}
`;

let axiosCancelToken = null;
let searchTimeout = null;

export default function Search() {
	let navigate = useNavigate();

	let [results, setResults] = useState([]);
	let [search, setSearch] = useState(undefined);
	let [type, setType] = useState(undefined);
	let [skeletonRows, setSkeletonRows] = useState(5);

	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
		}, 1000);
	}

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( !search || !search.length ) {
			setResults([]);
			return;
		}

		const getPersons = async () => {
			setResults(undefined);
			await axios.get('/api/manager/search/persons', {
				params: {
					type: type,
					search: search
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setResults([...response.data]);
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getPersons();
	}, [type, search]);

	const openPopupWindowFix = (e, href) => {
		e.preventDefault();
		e.stopPropagation();
		openPopupWindow(href);
	}

	return (
		<PopupLayout>
			<section>
				<div className="page-title">
					<h1>Buscar</h1>
				</div>

				<div className="page-content" style={{paddingBottom: 0}}>
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<input type="search" className="form-control form-control-sm" placeholder="Buscar" onChange={(e) => setSearchTimeout(e.target.value)} />
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" value={type} onChange={(e) => setType(e.target.value)}>
										<option value="">- Tipo -</option>
										<option value="owner">Propietario</option>
										<option value="renter">Inquilino</option>
										<option value="providers">Proveedores</option>
										<option value="clients">Clientes</option>
										<option value="contacts">Contactos</option>
									</select>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							{/* <div className="table-responsive table-responsive-carded"> */}
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<th></th>
											<th>Nombre</th>
											<th>CIF</th>
											<th>Teléfono</th>
											<th>Email</th>
											<th style={{width: '40px'}}></th>
										</tr>
									</thead>
									<tbody>
										{ results?.length > 0 &&
											results.map((el, idx) => {
												return ( 
													<React.Fragment key={'c'+idx}>
														<tr onClick={() => navigate('/'+el.type+'s/edit/' + el.id + '?community_id='+(el.community?.id ?? '')+'&popup=true')}>
															<td>
																<PetitionerSelectRow>
																	<div className="type">{getPetitionerFormatted(el.type)}</div>
																	<div className="community">{el.community?.name}</div>
																</PetitionerSelectRow>
															</td>
															<td>{el.name}</td>
															<td>{el.vatnumber ?? '-'}</td>
															<td>{el.phone ?? '-'}</td>
															<td>{el.email ?? '-'}</td>
															<td>
																<div className="dropdown d-inline-block">
																	<button className="btn btn-sm btn-table p-0 px-1 text-primary" type="button" data-bs-toggle="dropdown" onClick={(e) => e.stopPropagation()}>
																		<i className="bi bi-three-dots"></i>
																	</button>
																	<ul className="dropdown-menu">
																		<li><NavLink className="dropdown-item" to={'/'+el.type+'s/edit/' + el.id + '?community_id='+(el.community?.id ?? '')+'&popup=true'} onClick={(e) => openPopupWindowFix(e, e.target.href)}>Ficha</NavLink></li>
																		<li><NavLink className="dropdown-item" to={'/emails/add?community_id='+(el.community?.id ?? '')+'&recipients_type='+el.type+'s&recipients_ids=' + el.id} onClick={(e) => openPopupWindowFix(e, e.target.href)}>Enviar email</NavLink></li>
																		<li><NavLink className="dropdown-item" to={'/notices?community_id='+(el.community?.id ?? 'undefined')+'&search=' + el.vatnumber} onClick={(e) => openPopupWindowFix(e, e.target.href)}>Ver avisos</NavLink></li>
																		<li><NavLink className="dropdown-item" to={'/notices/add?community_id='+(el.community?.id ?? '')+'&petitioner_type='+el.type+'&owner_id=' + el.id} onClick={(e) => openPopupWindowFix(e, e.target.href)}>Nuevo aviso</NavLink></li>
																		<li><NavLink className="dropdown-item" to={'/documents?folder_id=' + el.folder_id} onClick={(e) => openPopupWindowFix(e, e.target.href)}>Abrir documentos</NavLink></li>
																	</ul>
																</div>
															</td>
														</tr>
													</React.Fragment>
												);
											})
										}

										{ results && !results.length && <tr><td colSpan="100%">{ search ? 'No hay datos disponibles' : 'Escribe algo en el cuadro de búsqueda' }</td></tr> }

										{ results === undefined && <TrSkeleton rows={skeletonRows} columns={6} /> }
									</tbody>
								</Table>
							{/* </div> */}
						</div>
					</div>
				</div>
			</section>
		</PopupLayout>
	);
}


