import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { getCommunity } from 'helpers/community';
import { formatNumber, loader } from 'helpers/generic';
import CounterPartsSelector from 'components/CounterPartsSelector';

const TableMovements = styled.table`
    font-size: 12px;
    
    thead {

        tr {

            th {
                font-weight: 600;
            }
        }
    }

    th, 
    td {
        &:nth-child(1) {
            width: 80px;
        }

        &:nth-child(3) {
            width: 100px;
            text-align: right;
        }
    }
`;

const TableInvoice = styled.table`
    font-size: 12px;
    
    thead {
        tr {
            th {
                font-weight: 600;
            }
        }
    }

    th, 
    td {
        &:nth-child(1) {
            width: 80px;
        }

        &:nth-child(3),
        &:nth-child(4) {
            width: 100px;
            text-align: right;
        }
    }
`;

const TableCounterParts = styled.table`
    font-size: 13px;

    thead {
        th {
            font-weight: 500;
        }
    }

    td, th {
        &:nth-child(2) {
            width: 100px;
            max-width: 100px;
            min-width: 100px;

            position: relative;
            text-align: right;

            input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 0;
                text-align: right;
                padding-right: 5px;
                padding-left: 5px;
            }
        }
        
        &:nth-child(3) {
            width: 40px;
            text-align: center;
        }
    }

    td {
        &:first-of-type {
            padding: 0;
        }
    }
`;

let axiosCancelToken = null;

export default function ProviderInvoice(props) {
    let hideModal = props.hideModal;

    let [loading, setLoading] = useState(false);
    let [error, setError] = useState(null);
    let [counterParts, setCounterParts] = useState([]);

    let invoices = props.movements.filter((item) => item.class.indexOf('ProviderInvoice') !== -1) ?? null; 

    let bankMovements = props.movements.filter((item, idx) => item.class.indexOf('ProviderInvoice') === -1);
    let totalBankMovements = bankMovements.reduce((carry, item) => carry += item.amount, 0);
    totalBankMovements = parseFloat(totalBankMovements.toFixed(2));
    
    let counterPartsTotal = (() => {
        let total = 0;
        counterParts.forEach((item) => {
            total += item.reduce((carry, item) => carry += parseFloat(item.amount), 0);
        });
        return total;
    })();

    let invoicesTotal = invoices.reduce((carry, item) => carry += parseFloat(item.amount_pending), 0);
    invoicesTotal = parseFloat(invoicesTotal.toFixed(2));
    let existsPartialInvoice = invoices.filter(item => item.partial === true).length > 0;

    let canReconciliate = 
        (
            existsPartialInvoice === false && formatNumber(counterPartsTotal, 2, true) === formatNumber(invoicesTotal, 2, true) 
            && 
            (
                // No deja conciliar un importe inferior si hay mas de una factura por que luego no fuciona bien el atributo pending de la factura, no está pensado para funcionar así
                invoices.length > 1 && Math.abs(totalBankMovements) >= invoicesTotal
                ||
                invoices.length === 1 // Si es una sola factura, si deja conciliar aunque el importe de los movimientos bancarios sean menores por que se puede hacer una conciliación parcial
            )  
        )
        || 
        (existsPartialInvoice === true && invoices.length === 1)
    ;

    useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

        return function cleanup() {
            axiosCancelToken.cancel();
        }
    }, []);

    const saveData = async () => {
        setLoading(true);
        await axios.post('/api/manager/bank-reconciliation/save', {
            type: 'provider-invoice',
            community_id: getCommunity()?.id,
            movements: props.movements,
            counterparts: counterParts.map(el => {
                return el.map(cEl => {
                    return {
                        estimategroupexpense_id: cEl.estimategroupexpense_id,
                        amount: cEl.amount
                    }
                });
            })
        }, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Movimientos conciliados');
			hideModal(true, true);
	  	}).catch((error) => {
			// hideModal(true);

			if ( axios.isCancel(error) ) return;
            setError(error.response.data.message);
			toast.error('Ha ocurrido un error al guardar');
		});	
        setLoading(false);
    }

    const addCounterPartRow = useCallback((idx) => {
		let newCounterParts = counterParts ? [...counterParts] : [];

        if ( newCounterParts[idx] === undefined ) newCounterParts[idx] = [];

		newCounterParts[idx].push({
			estimategroup_id: null,
			amount: newCounterParts[idx].length === 0 ? invoices[idx].amount_pending : ''
		});
		setCounterParts(newCounterParts);
	}, [counterParts, invoices]);

	const removeCounterPartRowItem = (iIdx, cIdx) => {
		let newCounterParts = [...counterParts];
		newCounterParts[iIdx].splice(cIdx, 1);
		setCounterParts(newCounterParts);
	}

    const setCounterPartAmount = (iIdx, cIdx, value) => {
		let newCounterParts = [...counterParts];
		newCounterParts[iIdx][cIdx].amount = value;
		setCounterParts(newCounterParts);
	}

    const setCounterPartEstimateGroupExpense = (iIdx, cIdx, value) => {
		let newCounterParts = [...counterParts];
		newCounterParts[iIdx][cIdx].estimategroupexpense = null;
		if ( value ) {
			newCounterParts[iIdx][cIdx].estimategroupexpense = {
				id: value?.id,
				denomination: value?.denomination,
				estimategroup: {
					denomination: value?.estimategroup.denomination,
					estimate: {
						type: value?.estimategroup.estimate.type,
						number_full: value?.estimategroup.estimate.number_full
					}
				},
				title: {
					denomination: value?.title.denomination
				},
			};
		}
		newCounterParts[iIdx][cIdx].estimategroupexpense_id = value?.id;
        setCounterParts(newCounterParts);
	}

	return (
		<div className="row">
               
            <div className="col-lg-6">
                <div className="mb-3">
                    <label className="sbold">Proveedor</label>
                    <div className="small">{invoices[0]?.provider_name}</div>
                </div>
            </div>
        
            <div className="col-md-12 mb-3">
                <label className="sbold">Movimientos bancarios a conciliar</label>
                <TableMovements className="table table-sm table-bordered mb-0">
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Concepto</th>
                            <th>Importe</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bankMovements.map((item, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{moment(item.date).format('DD-MM-YYYY')}</td>
                                    <td>{item.concept ?? ''}</td>
                                    <td>{formatNumber(item.amount ?? 0)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot>
                        { bankMovements.length > 1 &&
                            <tr>
                                <td></td>
                                <td></td>
                                <td className="sbold">{formatNumber(totalBankMovements, 2)}</td>
                            </tr>
                        }
                        { (Math.abs(totalBankMovements) < invoicesTotal && invoices.length === 1) &&
                            <tr>
                                <td colSpan="100%" className="text-center text-danger">Parcialmente pagada</td>
                            </tr>
                        }
                        { (Math.abs(totalBankMovements) < invoicesTotal && invoices.length > 1) &&
                            <tr>
                                <td colSpan="100%" className="text-center text-danger">Importe insuficiente</td>
                            </tr>
                        }
                    </tfoot>
                </TableMovements>
            </div>
        
            <div className="col-md-12">
                <label className="sbold">Facturas de proveedor a conciliar</label>
                <TableInvoice className="table table-sm table-bordered mb-0">
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Nº</th>
                            <th>Importe</th>
                            <th>Pendiente</th>
                        </tr>
                    </thead>
                    <tbody>
                        { invoices.map((item, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{moment(item.date).format('DD-MM-YYYY')}</td>
                                    <td>{item.invoice_number ?? ''}</td>
                                    <td>{formatNumber(item.amount ?? 0)}</td>
                                    <td>{formatNumber(item.amount_pending ?? 0)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </TableInvoice>
            </div>
                 
            { !existsPartialInvoice &&
                <div className="col-md-12 mt-3">
                    { 
                        invoices.map((item, idx) => {
                            return (
                                <div key={'cpw' + idx} className={idx !== 0 ? 'mt-2' : ''}>
                                    <label className="sbold">
                                        Seleccionar contrapartidas 
                                        { invoices.length > 1 &&
                                            <>
                                                &nbsp;de la factura {idx+1}
                                            </>
                                        } 
                                    </label>
                                    <TableCounterParts className="table table-sm table-bordered mb-0">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Importe</th>
                                                <th>
                                                    <button className="btn-unstyled" onClick={() => addCounterPartRow(idx)}>
                                                        <i className="bi bi-plus-circle-fill text-primary"></i>
                                                    </button>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { counterParts[idx] && counterParts[idx].map((cEl, cIdx) => {
                                                return (
                                                    <tr key={'cp-' + idx + '-' + cIdx}>
                                                        <td>
                                                            <CounterPartsSelector 
                                                                communityId={getCommunity()?.id}
                                                                expense={cEl.estimategroupexpense}
                                                                onChange={(value) => setCounterPartEstimateGroupExpense(idx, cIdx, value)}
                                                                year={(() => {
                                                                    let date = null;
                                                                    
                                                                    try {
                                                                        date = bankMovements.sort((a, b) => {
                                                                            return new Date(b.date) - new Date(a.date);
                                                                        })[0].date ?? null;
                                                                    } catch (e) {}

                                                                    if ( date ) return moment(date).format('YYYY');

                                                                    return null;
                                                                })()}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input 
                                                                type="number" 
                                                                className="no-arrows" 
                                                                value={cEl.amount ?? ''} 
                                                                onChange={(e) => setCounterPartAmount(idx, cIdx, e.target.value)} 
                                                                min={-99999.99} 
                                                                max={99999.99} 
                                                                disabled={!cEl.estimategroupexpense?.id}
                                                            />
                                                        </td>
                                                        <td>
                                                            <button className={'btn-unstyled text-danger'} onClick={() => removeCounterPartRowItem(idx, cIdx)}><i className="bi bi-x-circle-fill"></i></button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            { (!counterParts[idx] || !counterParts[idx].length) && <tr><td colSpan="100%" className="p-2">No se han añadido contrapartidas</td></tr> }
                                        </tbody>

                                        { (counterParts[idx] && counterParts[idx].length > 0 && formatNumber(item.amount_pending, 2, true) !== formatNumber(counterParts[idx].reduce((carry, item) => carry += parseFloat(item.amount), 0), 2, true)) &&
                                            <tfoot>
                                                <tr>
                                                    <td colSpan="100%" className="text-danger text-center">
                                                        El importe de las contrapartidas tiene que ser similiar al importe de la factura
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        } 
                                    </TableCounterParts>
                                </div>
                            );
                        })
                    }
                </div>
            }
          

            { error && 
                <div className="invalid-feedback d-block">
                    {error}
                </div>
            }

            <div className="text-end mt-3">
				<button type="button" className="btn btn-primary text-white" onClick={() => saveData()} disabled={loading || !canReconciliate}>{loading ? 'cargando...' : 'Confirmar conciliación'}</button>
            </div>
        </div>
	);
}


