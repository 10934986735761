import React, { useState, useEffect } from 'react';
import { NavLink, useSearchParams, Navigate } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from 'components/TrSkeleton';
import { getCommunity } from 'helpers/community';
import { loader, formatNumber } from 'helpers/generic';

const Table = styled.table`
	th,
	td {
		font-size: 14px;

		&:last-of-type {
			width: 40px;
		}

		&:nth-child(1) {
			width: 20px;
		}

		&:nth-child(2) {
			width: 100px;
			text-align: right;
		}

		&:nth-child(3),
		&:nth-child(4),
		&:nth-child(5) {
			width: 100px;
			text-align: right;
		}
	}

	tr.details {
		border-bottom: 2px solid var(--bs-primary);
	}
`;

const MovementsTable = styled.table`
	td,th {
		font-size: 12px !important;
		vertical-align: middle;

		&:nth-child(1) {
			width: 180px;
		}

		&:nth-child(2) {
			text-align: left;
		}
		&:nth-child(3),
		&:nth-child(4) {
			width: 50px;
			text-align: right;
		}
	}

	tbody, 
	tfoot {
		border-top: 0 !important;
	}
`;

let axiosCancelToken = null;

export default function AccountingEntries() {
	const [queryParams] = useSearchParams();

	let [financialYears, setFinancialYears] = useState([]);
	let [financialYear, setFinancialYear] = useState(null);
	let [forceReload/*, setForceReload*/] = useState(null);
	let [entries, setEntries] = useState({});
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [sortDirection, setSortDirection] = useState('desc');
	let [sortField, setSortField] = useState('number');
	let [dateFrom, setDateFrom] = useState(null);
	let [dateTo, setDateTo] = useState(null);
	let [detailsOpened, setDetailsOpened] = useState([]);
	let [details, setDetails] = useState({});
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setEntries({...entries, data: undefined});
		_setPage(page);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const toggleDetails = (rowId) => {
		if ( detailsOpened.includes(rowId) ) {
			detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
			setDetailsOpened([...detailsOpened]);

			// Remove details
			delete details[rowId];
			setDetails({...details});
		} else {
			setDetailsOpened([...detailsOpened, rowId]);
		}
	}

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getDataAsync = async () => {
			if ( !financialYear ) return;

			setEntries({});
			
			// Entries
			await axios.get('/api/manager/accounting-entries/list', {
				params: {
					community_id: getCommunity()?.id,
					sort: sortField,
					direction: sortDirection,
					date_from: dateFrom,
					date_to: dateTo,
					financial_year: financialYear,
					page: page
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setEntries({...response.data});
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getDataAsync();
	}, [forceReload, page, sortField, sortDirection, dateFrom, dateTo, financialYear]);

	useEffect(() => {
		const getDataAsync = async () => {
			// Financial Years
			return axios.get('/api/manager/contable-accounts/financialyears-list', {
				params: {
					community_id: getCommunity()?.id
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setFinancialYears([...response.data]);
				setFinancialYear(response.data[0] ?? null);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}
		getDataAsync();
	}, [forceReload]);

	useEffect(() => {
		// Loop and load details if empty
		detailsOpened.forEach((el) => {
			axios.get('/api/manager/accounting-entries/get/' + el, {
				params: {
					community_id: getCommunity()?.id
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				setDetails((prev) => ({...prev, [el]: {...response.data}}));
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		});
	}, [detailsOpened, forceReload]);

	if ( !getCommunity()?.id ) return <Navigate to="/" />;

	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Asientos contables</h1>

					<NavLink to="/accounting-entries/add" className="btn btn-sm btn-light ms-auto">Nuevo asiento</NavLink>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" value={financialYear ?? ''} onChange={(e) => setFinancialYear(e.target.value)}>
										<option disabled>- Ejercicios -</option>
										{financialYears.map((el, idx) => {
											return (<option key={idx} value={el}>{el}</option>);
										})}
									</select>
								</div>
								<div className="col-md-4 mb-2 mt-2 mb-md-0 mt-md-0">
									<div className="input-group input-group-sm">
										<span className="input-group-text">
											Fechas
										</span>
										<input type="date" className="form-control form-control-sm" placeholder="Desde" value={dateFrom ?? ''} onChange={(e) => setDateFrom(e.target.value)} />
										<input type="date" className="form-control form-control-sm" placeholder="Hasta" value={dateTo ?? ''} onChange={(e) => setDateTo(e.target.value)} />
									</div>
								</div>
								<div className="col-md-5 mb-2 mt-2 mb-md-0 mt-md-0 text-end">
									<NavLink className="btn btn-sm btn-light" to="/contable-accounts">Ver cuentas contables</NavLink>
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'number'} onClick={() => sortTableClick('number')}>Nº</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'date'} onClick={() => sortTableClick('date')}>Fecha</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'movements.debe'} onClick={() => sortTableClick('movements.debe')}>Debe</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'movements.haber'} onClick={() => sortTableClick('movements.haber')}>Haber</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'movements.count'} onClick={() => sortTableClick('movements.count')}>Movimientos</ThSortable>
											<th></th>
											<th style={{width: '40px'}}></th>
										</tr>
									</thead>
									<tbody>
										{ entries.data?.length > 0 &&
											entries.data.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr className="has-details" onClick={() => toggleDetails(el.id)}>
															<td>{el.number}</td>
															<td>{moment(el.date).format('DD-MM-YYYY')}</td>
															<td>{formatNumber(el.debe)}</td>
															<td>{formatNumber(el.haber)}</td>
															<td>{formatNumber(el.movements_count)}</td>
															<td></td>
															<td>
																<NavLink className="btn-table" to={'/accounting-entries/edit/' + el.id}><i className="bi bi-pencil"></i></NavLink>
															</td>
														</tr>
														{ detailsOpened.includes(el.id) &&
															<tr className="details">
																<td colSpan="100%">
																	<div className="row ms-0 me-0">
																		{ !details[el.id] && 
																			<div className="col-md-12 text-center">
																				{loader}
																			</div>
																		}

																		{ details[el.id] && 
																			<React.Fragment>
																				<div className="col-md-6 ps-0">
																					<MovementsTable className="table table-sm table-bordered mb-0">
																						<thead>
																							<tr>
																								<th>Cuenta contable</th>
																								<th>Descripción</th>
																								<th>Debe</th>
																								<th>Haber</th>
																							</tr>
																						</thead>
																						<tbody>
																							{details[el.id].movements?.map((mEl, mIdx) => {
																								return (
																									<tr key={idx}>
																										<td><b>{mEl.code}</b>&nbsp;{mEl.name}</td>
																										<td>{mEl.pivot.description}</td>
																										<td>{mEl.pivot.amount <= 0 ? formatNumber(Math.abs(mEl.pivot.amount)) : ''}</td>
																										<td>{mEl.pivot.amount >= 0 ? formatNumber(mEl.pivot.amount) : ''}</td>
																									</tr>
																								);
																							})}
																						</tbody>
																					</MovementsTable>
																				</div>
																			</React.Fragment>
																		}
																	</div>
																</td>
															</tr>
														}
													</React.Fragment>
												);
											})
										}

										{ entries.data && !entries.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ entries.data === undefined && <TrSkeleton rows={skeletonRows} columns={7} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={entries?.current_page}
									max={entries?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}


