import React, { useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'react-toastify';
import { loader } from 'helpers/generic';
import { getCommunity } from 'helpers/community';

const Loader = styled.div`
    width: 200px;
    margin: 0 auto;
    margin-top: 20px;
`;

let axiosCancelToken = null;

export default function NewManualReceipt(props) {
    let movements = useMemo(() => [...props.movements], [props.movements]);

    let hideModalFix = props.hideModal;
    let hideModal = useCallback((showSkeletonOnHide) => {
        hideModalFix(true, showSkeletonOnHide);
    }, [hideModalFix]);

    useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

    const saveData = useCallback((receipt) => {
        let newMovements = [...movements];
        newMovements.push({
            amount: receipt.amount,
            class: 'App\\Models\\Receipt',
            concept: 'Recibo ' + receipt.number_full,
            date: receipt.due_date,
            id: receipt.id
        });

        axios.post('/api/manager/bank-reconciliation/save', {
            type: 'receipt',
            community_id: getCommunity()?.id,
            movements: newMovements
        }, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Movimientos conciliados');
            hideModal(true);
	  	}).catch((error) => {
            hideModal(false);
            
			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error al guardar');
		});
    }, [movements, hideModal]);

    useEffect(() => {
		window.PopupProxyCallback = (newReceipt) => {
            if ( newReceipt.id ) saveData(newReceipt);
            else hideModal(false);
		}

		return function cleanup() {
			window.PopupProxyCallback = null;
		}
	}, [saveData, hideModal]);

	return (
		<div className="row">
            <div className="col-md-12 text-center">
                Esperando la creación del recibo en la ventana abierta... 
                <Loader>{loader}</Loader>
            </div>
        </div>
	);
}


