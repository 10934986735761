import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const DocumentViewerStyled = styled.div`
    position: fixed;
    top: ${props => props.top}px;
    right: -480px;
    width: 500px;
    height: ${props => props.height}px;
    background: white;
    z-index: 3;
    /* border-left: 1px solid var(--bs-primary);
    border-top: 1px solid var(--bs-primary);
    border-bottom: 1px solid var(--bs-primary); */
    box-shadow: 0px 0px 5px rgba(1, 1, 1, 0.6);
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    overflow: hidden;

    &:hover {
        right: 0px;
    }

    iframe, 
    img {
        border: 0;
        width: 100%;
        height: 100%;
    }

    @media (max-width: 992px) {
        display: none;
    }
`;

export default function DocumentViewer(props) {
    let file = props.file;
    let [top, setTop] = useState(0);
    let [height, setHeight] = useState(0);
    let [isViewable, setIsViewable] = useState(false);
    let [type, setType] = useState(0);
    let [url, setUrl] = useState(null);

    useEffect(() => {
        if ( !file ) return;

        let handlePosition = () => {
            let headerEl = document.querySelector('[data-docviewheaderfix]');
            let footerEl = document.querySelector('[data-docviewfooterfix]');

            let headerBounding = headerEl.getBoundingClientRect();
            let footerBounding = footerEl.getBoundingClientRect();

            let top = headerBounding.y + headerBounding.height + 10;
            let height = footerBounding.y - top - 10;

            setTop(top);
            setHeight(height);
        }
        handlePosition();
 
        let interval = setInterval(() => {
            handlePosition();
        }, [100]);

        return function cleanup() {
            clearInterval(interval);
        }
    }, [file]);

    useEffect(() => {
        // Check if viewable
        let isViewable = false;
        let type = file?.type;
        if ( !type ) return;
        setType(type);

        if ( type.indexOf('pdf') !== -1 || type.indexOf('image') !== -1 ) isViewable = true;
        setIsViewable(isViewable);
        if ( !isViewable ) return;

        // Prepare url
        let url = URL.createObjectURL(file);
        setUrl(url);
    }, [file]);

    console.log(file);

	return (
        <React.Fragment>
            { isViewable &&
                <DocumentViewerStyled 
                    top={top}
                    height={height} 
                    id="document-viewer"
                >
                    <iframe src={url}></iframe>
                </DocumentViewerStyled>
            }
        </React.Fragment>
	);
}