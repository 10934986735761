import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import { formatNumber } from 'helpers/generic';
import { getCommunity } from 'helpers/community';
import LabeledFrame from 'components/LabeledFrame';
import EmpoweredSelector from 'components/EmpoweredSelector';

const TableQuotasConcepts = styled.table`
	font-size: 13px;

	td, th {
		position: relative;

		&.has-errors {
			background: red;
			color: white;

			.empowered-selector_label {
				color: white;
			}
		}

		&:nth-child(1) {
			text-align: left;

			.empowered-selector {

				.empowered-selector_label {
					border: 0;
					background: transparent;
				}

				.empowered-selector_menu {
					box-shadow: 0px 2px 5px var(--bs-gray-300);
				}
			}
		}

		&:nth-child(2) {
			text-align: right;
			width: 100px;

			input[type=number] {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				font-size: 12px;
				text-align: right;
				background: transparent;
				border: 0;
			}
		}

		&:nth-child(3) {
			width: 20px; 
			position: relative;

			button {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 0;
				padding: 0;
				font-size: 15px;
			}
		}

	}
`;

const TablePendingRefunds = styled.table`
	font-size: 13px;

	table-layout: fixed;

	thead {
		font-weight: 500;
		display: flex;
	}

	tbody {
		border-top: 0 !important;
		overflow: overlay;
		display: flex;
		width:  100%;
		max-height: 150px;
		flex-wrap: wrap;	
	}

	tr {
		width: 100%;
		display:block;
		border-color: var(--bs-gray-300);
	}

	td, 
	th {
		&:nth-child(1) {
			min-width: 80px;
			max-width: 80px;
			width: 80px;
		}
		&:nth-child(2) {
			min-width: 80px;
			max-width: 80px;
			width: 80px;
		}

		&:nth-child(3) {
			width: 100%;
		}

		&:nth-child(4) {
			min-width: 80px;
			max-width: 80px;
			width: 80px;
			text-align: right;
		}

		&:nth-child(5) {
			min-width: 30px;
			max-width: 30px;
			width: 30px;
			text-align: center;
			vertical-align: middle;
		}
	}
`;

let axiosCancelToken = null;

let parentWindowProxyCallback = window.opener?.PopupProxyCallback;

export default function ReceiptsForm(props) {
	const navigate = useNavigate();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';
	const dueDate = queryParams.get('due_date');
	const mandatoryAmount = queryParams.get('mandatory_amount');

	let [data, setData] = useState({
		issuance_date: moment().format('YYYY-MM-DD'),
		due_date: dueDate ? dueDate : moment().format('YYYY-MM-DD'),
		quotasconcepts: [],
	});
	let [pendingRefunds, setPendingRefunds] = useState([]);
	let [errors, setErrors] = useState({});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		window.onbeforeunload = () => {
			parentWindowProxyCallback(data);
		};
	}, [data]);

	// Check if property has pending refunds
	useEffect(() => {
		setPendingRefunds([]);

		if ( !data.property_id ) return;

		let getData = async () => {
			let refunds = await axios.get('/api/manager/receipts/list', {
				params: {
					community_id: getCommunity()?.id,
					property_id: data.property_id,
					pending_refunds: true,
					no_paginate: true
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				return response.data;
			}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
			
			let unpaid = await axios.get('/api/manager/receipts/list', {
				params: {
					community_id: getCommunity()?.id,
					property_id: data.property_id,
					paid_status: 'unpaid',
					no_parent: true,
					no_refunded: true,
					no_paginate: true
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				return response.data;
			}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		
			setPendingRefunds([...refunds, ...unpaid]);
		}
		getData();

	}, [data.property_id]);

	const loadPayerables = (input, callback, args) => {
		axios.get('/api/manager/receipts/get-payerables-list', {
			params: {
				community_id: getCommunity()?.id,
				property_id: args.property?.id,
				search: input,
				no_paginate: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label:  <div>
								{el.name}
								<div><small>{el.type.indexOf('Owner') !== -1 ? 'Propietario' : 'Inquilino'}</small></div>
							</div>
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectPayerable = (payerable) => {
		setData((prev) => ({
			...prev,
			payerable: payerable,
			payerable_id: payerable?.id,
			payerable_type: payerable?.type,
			bankaccount: null,
			bankaccount_id: null
		}));
	}

	const loadBankAccounts = (input, callback, args) => {
		let formatted = args.payerable?.bankaccounts?.map((el) => {
			return {
				value: el, 
				label: el.iban
			};
		});
		formatted.unshift({
			value: {
				id: -1,
				iban: 'Pago al contado'
			},
			label: 'Pago al contado'
		})
		callback(formatted);
	}

	const selectBankAccount = (bankaccount) => {
		setData((prev) => ({
			...prev,
			bankaccount: bankaccount,
			bankaccount_id: bankaccount?.id,
		}));
	}

	const loadProperties = (input, callback) => {
		axios.get('/api/manager/properties/list', {
			params: {
				community_id: getCommunity()?.id,
				search: input,
				no_paginate: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label:  <div>
								{el.name}
								<div className="badge bg-light text-secondary ms-2"><small>{el.building?.name}</small></div>
							</div>
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const loadQuotasConcepts = (input, callback) => {
		axios.get('/api/manager/quotas-concepts/list', {
			params: {
				community_id: getCommunity()?.id,
				search: input,
				no_paginate: true,
				except_ids: data.quotasconcepts.map(el => el.quotaconcept_id).filter(el => el !== null)
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label:  el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectProperty = (property) => {
		setData((prev) => ({
			...prev,
			property: property,
			property_id: property?.id,
		}));
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const setQuotaConceptDataField = (idx, field, value) => {
		let quotasconcepts = [...data.quotasconcepts];
		quotasconcepts[idx][field] = value;
		setDataField('quotasconcepts', quotasconcepts);
	}

	const setQuotaConceptField = (idx, value) => {
		let quotasconcepts = [...data.quotasconcepts];
		quotasconcepts[idx].quotaconcept = value;
		quotasconcepts[idx].quotaconcept_id = value?.id;
		setDataField('quotasconcepts', quotasconcepts);
	}

	const addQuotaConcept = () => {
		let quotasconcepts = [...data.quotasconcepts];
		quotasconcepts.push({
			quotaconcept_id: null,
			amount: 0
		});
		setDataField('quotasconcepts', quotasconcepts);
	}

	const deleteQuotaConcept = (idx) => {
		let quotasconcepts = [...data.quotasconcepts];
		quotasconcepts.splice(idx, 1);
		setDataField('quotasconcepts', quotasconcepts);
	}

	const saveData = () => {
		setErrors({});

		data.community_id = getCommunity()?.id;
		data.pendingrefunds = pendingRefunds.map(el => el.checked ? el.id : null).filter(el => el !== null);

		axios.post('/api/manager/receipts/add-manual', data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			if ( popup ) {
				data = response.data.receipt; // Fix to return to parent updated data
				window.close();
			} 
			else navigate(-1);

			toast.success('Datos guardados');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const togglePendingRefundChecked = (idx, status) => {
		let newPendingRefunds = [...pendingRefunds];
		newPendingRefunds[idx].checked = status;
		setPendingRefunds(newPendingRefunds);
		setData((prev) => ({...prev, quotasconcepts: []}));
	}

	// Observe pendingRefunds and update concepts and total
	useEffect(() => {
		let concepts = [];
		pendingRefunds.forEach((prEl, prIdx) => {
			if ( !prEl.checked ) return;

			prEl.quotasconcepts.forEach((qcEl, qcIdx) => {
				let cIdx = concepts.findIndex((cncpt) => cncpt.quotaconcept_id === qcEl.id);
				if ( cIdx === -1 ) {
					concepts.push({
						quotaconcept: qcEl,
						quotaconcept_id: qcEl.id,
						amount: parseFloat(qcEl.pivot.amount)
					});
				} else {
					concepts[cIdx].amount += parseFloat(qcEl.pivot.amount);
				}
			});

			if ( prEl.refund_data?.expenses ) concepts[0].amount += parseFloat(prEl.refund_data?.expenses ?? 0);
		});
		setData((prev) => ({...prev, quotasconcepts: concepts}))
	}, [pendingRefunds]);

	const Layout = popup ? PopupLayout : LoggedLayout;

	const mandatoryAmountError = mandatoryAmount && parseFloat(mandatoryAmount) !== data.quotasconcepts.reduce((carry, item) => carry += parseFloat(item.amount), 0);

	return (
		<Layout>
			<section>
				<div className="page-title">
					<h1>Generar un recibo manual</h1>
					<button onClick={() => popup ? window.close() : navigate(-1)} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-8">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-md-12 mb-3">
											<LabeledFrame label="Fechas">
												<div className="row">
													<div className="col-lg-6">
														<div className="mb-2">
															<label>Fecha de emisión</label>
															<input type="date" className="form-control form-control-sm" value={data.issuance_date ?? ''} onChange={(e) => setDataField('issuance_date', e.target.value)} />
															{ errors.issuance_date &&
																<div className="invalid-feedback d-block">{ errors.issuance_date[0] }</div>
															}
														</div>
													</div>
													<div className="col-lg-6">
														<div className="mb-2">
															<label>Fecha de vencimiento/cobro</label>
															<input type="date" className="form-control form-control-sm" onChange={(e) => setDataField('due_date', e.target.value)} value={data.due_date ?? ''} />
															{ errors.due_date &&
																<div className="invalid-feedback d-block">{ errors.due_date[0] }</div>
															}
														</div>
													</div>
												</div>
											</LabeledFrame>
										</div>

										<div className="col-md-12 mb-3">
											<LabeledFrame label="Propiedad y pagador">
												<div className="row">
													<div className="col-lg-4">
														<div className="mb-2">
															<label>Propiedad</label>
															<EmpoweredSelector
																load={loadProperties}
																onChange={(value) => selectProperty(value)}
																timeout={250}
																label={
																	<React.Fragment>
																		{ data.property?.id &&
																			<div>
																				{data.property?.name}
																				<div className="badge bg-light text-secondary ms-2"><small>{data.property?.building?.name}</small></div>
																			</div>
																		}
																	</React.Fragment>
																}
																value={data.property?.id}
															/>
															{ errors.property_id &&
																<div className="invalid-feedback d-block">{ errors.property_id[0] }</div>
															}
														</div>
													</div>
													<div className="col-lg-4">
														<div className="mb-2">
															<label>Pagador</label>
															<EmpoweredSelector
																load={loadPayerables}
																args={{property: data.property}}
																onChange={(value) => selectPayerable(value)}
																timeout={250}
																label={data.payerable?.name ?? ''}
																value={data.payerable?.id}
																disabled={!data.property_id}
															/>
															{ errors.payerable_id &&
																<div className="invalid-feedback d-block">{ errors.payerable_id[0] }</div>
															}
														</div>
													</div>
													<div className="col-lg-4">
														<div className="mb-2">
															<label>Forma de pago</label>
															<EmpoweredSelector
																load={loadBankAccounts}
																args={{payerable: data.payerable}}
																onChange={(value) => selectBankAccount(value)}
																timeout={250}
																label={data.bankaccount?.iban ?? 'Pago al contado'}
																value={data.bankaccount?.id ?? -1}
																disabled={!data.payerable_id}
															/>
															{ errors.bankaccount_id &&
																<div className="invalid-feedback d-block">{ errors.bankaccount_id[0] }</div>
															}
														</div>
													</div>
												</div>
											</LabeledFrame>
										</div>

										{ pendingRefunds.length > 0 && 
											<div className="col-md-12 mb-3">
												<LabeledFrame label="Recibos devueltos y pendientes" background={'danger'} color={'white'}>
													<TablePendingRefunds className="table table-sm table-bordered mb-0">
														<thead>
															<tr>
																<td>Recibo</td>
																<td>Estado</td>
																<td>Detalles</td>
																<td>Importe</td>
																<td></td>
															</tr>
														</thead>
														<tbody>
															{ pendingRefunds.map((el, idx) => {
																return (
																	<tr key={idx}>
																		<td>{el.number_full}</td>
																		<td>{el.refunded_at ? 'Devuelto' : 'Pendiente'}</td>
																		<td>
																			{el.quotasconcepts.map((qcEl, qcIdx) => {
																				return (
																					<div key={qcIdx}>{qcEl.name}: {formatNumber(qcEl.pivot.amount)} €</div>
																				)
																			})}
																			{ el.refund_data?.expenses && 
																				<div>Gastos bancarios por devolución: {formatNumber(el.refund_data.expenses)} €</div>
																			}
																		</td>
																		<td>
																			{formatNumber(parseFloat(el.amount) + (parseFloat(el.refund_data?.expenses ?? 0)))} €
																		</td>
																		<td>
																			<input type="checkbox" className="form-check-input" checked={el.checked ?? false} onChange={(e) => togglePendingRefundChecked(idx, e.target.checked)} />
																		</td>
																	</tr>
																);
															})}
														</tbody>
													</TablePendingRefunds>
												</LabeledFrame>
											</div>
										}

										<div className="col-md-12">
											<LabeledFrame 
												label="Conceptos e importe"
												buttons={
													<button className="btn-unstyled text-primary" onClick={() => addQuotaConcept()}><i className="bi bi-plus-circle-fill"></i></button>
												}
											>
												<div className="row">
													<div className="col-lg-12">
														<div className="mb-2 text-end">
															<TableQuotasConcepts className="table table-sm table-bordered mb-0 mt-2">
																<thead>
																	<tr>
																		<td className="sbold">Concepto</td>
																		<td className="sbold">Importe</td>
																		<td></td>
																	</tr>
																</thead>
																<tbody>
																	{data.quotasconcepts?.map((el, idx) => {
																		return (
																			<tr key={idx}>
																				<td className={errors['quotasconcepts.' + idx + '.quotaconcept_id'] ? 'has-errors' : ''}>
																					<EmpoweredSelector
																						load={loadQuotasConcepts}
																						onChange={(value) => setQuotaConceptField(idx, value)}
																						timeout={250}
																						label={el.quotaconcept?.name}
																						value={el.quotaconcept?.id}
																					/>
																				</td>
																				<td className={errors['quotasconcepts.' + idx + '.amount'] ? 'has-errors' : ''}>
																					<input 
																						type="number" 
																						className={'form-control form-control-sm no-arrows ' + (errors['quotasconcepts.'+idx+'.amount'] ? 'text-white bg-danger' : '')} 
																						value={el.amount ?? ''} 
																						onChange={(e) => setQuotaConceptDataField(idx, 'amount', e.target.value)} 
																					/>
																				</td>
																				<td>
																					<button className="bg-danger text-white" onClick={() => deleteQuotaConcept(idx)}><i className="bi bi-x"></i></button>
																				</td>
																			</tr>
																		);
																	})}
																	{data.quotasconcepts?.length === 0 && <tr><td colSpan="100%">Añade un concepto</td></tr>}
																</tbody>
															</TableQuotasConcepts>
															{ errors.quotasconcepts &&
																<div className="invalid-feedback d-block">{ errors.quotasconcepts[0] }</div>
															}
														</div>
													</div>

													<div className="col-md-9">
														<div className="mb-2">
															<label>Descripción de la emisión</label>
															<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('issuance_description', e.target.value)} value={data.issuance_description ?? ''} />
															{ errors.issuance_description &&
																<div className="invalid-feedback d-block">{ errors.issuance_description[0] }</div>
															}
														</div>
													</div>
													<div className="col-lg-3">
														<div className="mb-2 text-end">
															<label>Importe total</label>
															<input type="number" className="form-control form-control-sm no-arrows text-end" value={data.quotasconcepts.reduce((carry, item) => carry += parseFloat(item.amount), 0).toFixed(2)} readOnly />
														</div>
													</div>
													{ mandatoryAmountError &&
														<div className="col-md-12">
															<div className="invalid-feedback d-block text-end">
																El importe total no coincide con el importe obligatorio: {formatNumber(mandatoryAmount)} €
															</div>
														</div>
													}
												</div>
											</LabeledFrame>
										</div>
									</div>
								</div>
								<div className="card-footer" id={'footer-fixed'}>
									<div className="row">
										<div className="col-4">
											
										</div>
										<div className="col-8 text-end">
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()} disabled={mandatoryAmountError}>Guardar</button>							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>	
	);
}