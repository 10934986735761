import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import randomatic from 'randomatic';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import LabeledFrame from 'components/LabeledFrame';
import { CristalLoader, openPopupWindow } from 'helpers/generic';
import { getCommunity } from 'helpers/community';
// import { getUser } from 'helpers/user';
import CustomFieldsPanel from 'components/CustomFieldsPanel';
import Notes from 'components/Notes';
// import PhoneEmailPanel from 'components/PhoneEmailPanel';
import DocumentsPanel from 'components/DocumentsPanel';
import Sectors from './Sectors';
import EmpoweredSelector from 'components/EmpoweredSelector';
import FieldSmallComment from 'components/FieldSmallComment';
import AddressesPanel from 'components/AddressesPanel';
import ActionsContextMenu from 'components/ActionsContextMenu';
import CustomInput from 'components/CustomInput';

let axiosCancelToken = null;

let parentWindowProxyCallback = window.opener?.PopupProxyCallback;
let parentWindowProxyCallbackSecondary = window.opener?.PopupProxyCallbackSecondary;

export default function ProvidersForm(props) {
	let actionsDropdownRef = useRef(null);

	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';
	const forceCanEdit = queryParams.get('edit') === 'true';

	let [data, setData] = useState({
		code: randomatic('A0', 5),
		phone: [],
		email: [],
		sectors: []
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);
	let [canEdit, setCanEdit] = useState(true);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				await axios.get('/api/manager/providers/get/' + params.id, {
					params: {},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(response.data);
					if ( !forceCanEdit ) setCanEdit(false);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		}
	}, [params.id, forceCanEdit]);

	useEffect(() => {
		window.onbeforeunload = () => {
			parentWindowProxyCallback(data, 'provider');
			parentWindowProxyCallbackSecondary(data);
		};
	}, [data]);

	const deleteProvider = () => {
		const c = window.confirm('¿Quieres eliminar este proveedor?');
		if ( !c ) return;

		axios.delete('/api/manager/providers/delete/' + data.id, {}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Proveedor borrado')
	    	navigate('/providers');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (goBack = true) => {
		setErrors({});

		axios.post('/api/manager/providers/' + (data.id ? 'edit/' + data.id : 'add'), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			if ( goBack ) {
				if ( popup ) {
					data = response.data.provider; // Fix to send last data on before unload
					window.close();
				}
				else navigate(-1);
			} else if ( !data.id ) {
		    	navigate('/providers/edit/' + response.data.provider.id + '?edit=true' + (popup ? '&popup=true' : ''));
			}
			setData({...response.data.provider});
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();
		e.stopPropagation();

		openPopupWindow(e.currentTarget.href);
	}

	const loadTaxes = (input, callback) => {
		axios.get('/api/manager/taxes/list', {
			params: {
				search: input,
				no_paginate: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const toggleCommunity = (status) => {
		axios.post('/api/manager/providers/toggle-community/' + data.id, {
			community_id: getCommunity()?.id,
			status: status
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let communities_ids = [...data.communities_ids];
			if ( status && response.status ) communities_ids.push(getCommunity()?.id);
			else communities_ids.splice(communities_ids.indexOf(getCommunity()?.id), 1);
			setData((prev) => ({...prev, communities_ids: communities_ids}));
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de proveedor</h1>
					<button onClick={() => popup ? window.close() : navigate(-1)} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-lg-7">

											<LabeledFrame 
												className="mb-3" 
												label="Datos fiscales"
												buttons={
														getCommunity()?.id ?
															<div className="form-check form-switch d-inline-flex align-items-center mb-0">
																<input disabled={!canEdit} className="form-check-input me-2 mb-0" type="checkbox" checked={data.communities_ids?.indexOf(getCommunity()?.id) !== -1} onChange={(e) => toggleCommunity(e.target.checked)} />
																Activo en comunidad
															</div>
															:
															null
														
												}	
											>
												<div className="row">
													<div className="col col-static-100">
														<div className="mb-2">
															<CustomInput 
																label="Código" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('code', e.target.value)} 
																value={data.code ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.code &&
																<div className="invalid-feedback d-block">{ errors.code[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-200">
														<div className="mb-2">
															<CustomInput 
																label="Razón social" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('business_name', e.target.value)} 
																value={data.business_name ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.business_name &&
																<div className="invalid-feedback d-block">{ errors.business_name[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-120">
														<div className="mb-2">
															<CustomInput 
																label="CIF" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('vatnumber', e.target.value)} 
																value={data.vatnumber ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.vatnumber &&
																<div className="invalid-feedback d-block">{ errors.vatnumber[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-270">
														<div className="mb-2">
															<CustomInput 
																label="Nombre comercial" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('brand_name', e.target.value)} 
																value={data.brand_name ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.brand_name &&
																<div className="invalid-feedback d-block">{ errors.brand_name[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-230">
														<div className="mb-2">
															<CustomInput 
																label="Dirección" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('address', e.target.value)} 
																value={data.address ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.address &&
																<div className="invalid-feedback d-block">{ errors.address[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-80">
														<div className="mb-2">
															<CustomInput 
																label="C.P." 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('postalcode', e.target.value)} 
																value={data.postalcode ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.postalcode &&
																<div className="invalid-feedback d-block">{ errors.postalcode[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-200">
														<div className="mb-2">
															<CustomInput 
																label="Ciudad" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('city', e.target.value)} 
																value={data.city ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.city &&
																<div className="invalid-feedback d-block">{ errors.city[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-180">
														<div className="mb-2">
															<CustomInput 
																label="Provincia" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('province', e.target.value)} 
																value={data.province ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.province &&
																<div className="invalid-feedback d-block">{ errors.province[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-150">
														<div className="mb-2">
															<CustomInput 
																label="País" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('country', e.target.value)} 
																value={data.country ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.country &&
																<div className="invalid-feedback d-block">{ errors.country[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-200">
														<div className="mb-2">
															<EmpoweredSelector
																load={loadTaxes}
																onChange={(value) => setData((prev) => ({...prev, tax_id: value?.id, tax: value}))}
																timeout={250}
																label={data.tax?.name ?? ''}
																value={data.tax?.id}
																placeholder="Impuesto asociado"
																showPlaceholderHelper={true}
																disabled={!canEdit}
															/>
															<FieldSmallComment>Para profesionales</FieldSmallComment>
															{ errors.tax_id &&
																<div className="invalid-feedback d-block">{ errors.tax_id[0] }</div>
															}
														</div>
													</div>
												</div>											
											</LabeledFrame>

											<AddressesPanel
												addresses={data.addresses}
												errors={errors}
												setAddresses={(addresses) => setData({...data, addresses: addresses})}
												readOnly={!canEdit}
											/>

											{/* <LabeledFrame className="mb-3" label="Contacto">
												<div className="row">
													<div className="col-lg-12">
														<div className="mb-2">
															<label>Persona de contacto</label>
															<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('contact_person', e.target.value)} value={data.contact_person ?? ''} />
															{ errors.contact_person &&
																<div className="invalid-feedback d-block">{ errors.contact_person[0] }</div>
															}
														</div>
													</div>
												</div>
												
												<PhoneEmailPanel
													phone={data.phone}
													email={data.email}
													errors={errors}
													setPhone={(phone) => setData({...data, phone: phone})}
													setEmail={(email) => setData({...data, email: email})}
												/>
											</LabeledFrame> */}
										</div>
										<div className="col-lg-5">
											<Sectors
												sectors={data.sectors}
												setSectors={(newSectors) => setData({...data, sectors: newSectors})}
												errors={errors}
												readOnly={!canEdit}
											/>

											<CustomFieldsPanel 
												className="mb-3"
												type="providers"
												fields={data.customfields_array}
												setFields={(fields) => setData((data) => ({...data, customfields_array: fields}))}
												readOnly={!canEdit}
											/>

											<LabeledFrame className="mb-3" label="Instrucciones">
												<textarea readOnly={!canEdit} className="form-control form-control-sm" onChange={(e) => setDataField('observations', e.target.value)} value={data.observations ?? ''}></textarea>
											</LabeledFrame>
											
											<Notes
												className="mb-3"
												notes={data.notes ?? []}
												updateParentCallback={(notes) => setData((data) => ({...data, notes: notes}))}
												readOnly={!canEdit}
											/>

											{ data.id && 
												<DocumentsPanel
													className="mb-3"
													folderId={data.folder_id ?? null}
													community={getCommunity()}
												/>
											}
										</div>
									</div>
								</div>
								<div className="card-footer" id={popup ? 'footer-fixed' : ''}>
									<div className="row">
										<div className="col-6">
											{/*{ (getUser().company_role === 'principal' && data.id) &&*/}
											{ data.id &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteProvider()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											{/*{ (!data.id || (data.id && getUser().company_role === 'principal')) &&*/}
												<React.Fragment>
													{ data.id &&
														<div className="dropdown d-inline">
															<button ref={actionsDropdownRef} className="btn btn-sm btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
																Acciones
															</button>
															<ActionsContextMenu actionsDropdownRef={actionsDropdownRef} className="dropdown-menu">
																<li><button className="dropdown-item" onClick={() => setCanEdit(!canEdit)}>{canEdit ? 'Desactivar edición' : 'Editar'}</button></li>
																<li><hr className="dropdown-divider" /></li>

																<li><NavLink className="dropdown-item" to={'/notices?petitioner_type=provider&petitioner_id=' + data.id} onClick={openPopupInfoWindow}>Ver avisos</NavLink></li>
																<li><NavLink className="dropdown-item" to={'/notices/add?petitioner_type=provider&provider_id=' + data.id} onClick={openPopupInfoWindow}>Nuevo aviso</NavLink></li>
																<li><NavLink className="dropdown-item" to={'/providers-invoices?popup=true&search=' + data.vatnumber} onClick={openPopupInfoWindow}>Ver facturas</NavLink></li>
																{ (data.email_default && getCommunity()?.id) &&
																	<li><NavLink className="dropdown-item" to={'/emails/add?recipients_type=providers&recipients_ids=' + data.id} onClick={openPopupInfoWindow}>Enviar email</NavLink></li>
																}

																<li><hr className="dropdown-divider" /></li>
																<li><button className="dropdown-item" onClick={() => saveData(false)}>Guardar</button></li>						
																<li><button className="dropdown-item" onClick={() => saveData()}>Guardar y salir</button></li>
															</ActionsContextMenu>
														</div>
													}

													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>
												</React.Fragment>
											{/*}*/}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}