import styled from 'styled-components';

const Frame = styled.div`
	--background: ${props => 'var(--bs-'+props.background+')'};
	--buttonsBackground: ${props => 'var(--bs-'+props.buttonsBackground+')'};
	--labelSecondaryBackground: ${props => 'var(--bs-'+props.labelSecondaryBackground+')'};
	--color: ${props => 'var(--bs-'+props.color+')'};
	--radius: 3px;
	--padding: 10px;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
`;

const Label = styled.div`
	display: inline-block;
	background: var(--background);
	${props => props.type === 'secondary' ? 'background: var(--labelSecondaryBackground);' : ''}
	padding: 1px var(--padding);
	border-top-left-radius: var(--radius);
	border-top-right-radius: var(--radius);
	color: var(--color);
	font-size: 12px;
`;

const Buttons = styled.div`
	display: inline-block;
	background: var(--buttonsBackground);
	padding: 1px var(--padding);
	border-top-left-radius: var(--radius);
	border-top-right-radius: var(--radius);
	color: var(--color);
	font-size: 12px;
`;

const Content = styled.div`
	padding: ${props => props.padding ?? 'var(--padding)'};
	border: 1px solid var(--background);
	border-radius: var(--radius);
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
`;

export default function LabeledFrame(props) {
	
	return (
		<Frame 
			className={props.className ?? ''} 
			style={props.style ?? {}} 
			background={props.background ?? 'gray-400'} 
			color={props.color ?? 'dark'}
			buttonsBackground={props.buttonsBackground ?? 'gray-300'} 
			labelSecondaryBackground={props.labelSecondaryBackground ?? 'gray-400'} 
		>
			<Header>
				<Label>{props.label}</Label>
				{props.labelSecondary && <Label type="secondary">{props.labelSecondary}</Label>}
				{props.buttons && <Buttons>{props.buttons}</Buttons>}
			</Header>
			<Content padding={props.contentPadding}>
				{props.children}
			</Content>
		</Frame>
	);
}