import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import LabeledFrame from 'components/LabeledFrame';
import { formatNumber } from 'helpers/generic';

const Table = styled.table`
	font-size: 12px;

	th {
		font-weight: 600;
	}

	td,
	th {
		&:not(:first-of-type) {
			text-align: right;
			width: 100px;
		}

		position: relative;

		&.initial {
			font-weight: 500;
		}
	}

	input {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
		text-align: right;
		padding-right: 5px;
		outline: none;
		border-radius: 0;

		&.with-errors {
			background: var(--bs-red);
			color: white;
		}
	}
`;

let axiosCancelToken = null;

export default function Balances(props) {
	let propertyId = props.propertyId ?? null;
	let communityId = props.communityId ?? null;
	let initialBalanceReceipts = props.initialBalanceReceipts ?? 0;
	let initialBalanceExpenses = props.initialBalanceExpenses ?? 0;
	let updateParentInitialBalanceReceipts = props.updateParentInitialBalanceReceipts;
	let updateParentInitialBalanceExpenses = props.updateParentInitialBalanceExpenses;
	let initialBalanceError = props.initialBalanceError ?? null;
	let readOnly = props.readOnly ?? false;
	let forceReloadParent = props.forceReloadParent ?? false;

	const [balances, setBalances] = useState(null);
	const [currentFinancialYear, setCurrentFinancialYear] = useState(null);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getPropertyBalances = async () => {
			await axios.get('/api/manager/properties/get-balances/' + propertyId, {
				params: {
					community_id: communityId
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setBalances(response.data);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}
		getPropertyBalances();

		const getActualFinancialYear = async () => {
			await axios.get('/api/manager/financial-years/list', {
				params: {
					community_id: communityId
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setCurrentFinancialYear(response.data.data[0]?.year ?? null);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}
		getActualFinancialYear();
	}, [propertyId, communityId, forceReloadParent]);

	return (
		<LabeledFrame 
			className="mb-3" 
			label="Saldos"
		>
			<Table className="table table-sm table-bordered mb-0">
				<thead>
					<tr>
						<th></th>
						<th>Saldo</th>
					</tr>
				</thead>
				<tbody>
					{ balances !== null &&
						<React.Fragment>
							<tr>
								<td>Saldo de recibos</td>
								<td className={'text-' + (balances.receipts.balance >= 0  ? 'success' : 'danger')}>{formatNumber(balances.receipts.balance)}</td>
							</tr>
							<tr>
								<td>Gasto generado de propiedades</td>
								<td className={'text-' + (balances.expenses.balance >= 0 ? 'success' : 'danger')}>{formatNumber(balances.expenses.balance)}</td>
							</tr>
						</React.Fragment>
				}
				</tbody>
				{ currentFinancialYear &&
					<tfoot>
						<tr>
							<td className="initial">Saldo inicial de recibos en {currentFinancialYear}</td>
							<td>
								<input type="number" step="0.01" className={'form-control no-arrows ' + (initialBalanceError ? 'with-errors' : '')} onChange={(e) => updateParentInitialBalanceReceipts(e.target.value ?? 0)} value={initialBalanceReceipts} readOnly={readOnly} />
							</td>
						</tr>
						<tr>
							<td className="initial">Saldo inicial de gastos en {currentFinancialYear}</td>
							<td>
								<input type="number" step="0.01" className={'form-control no-arrows ' + (initialBalanceError ? 'with-errors' : '')} onChange={(e) => updateParentInitialBalanceExpenses(e.target.value ?? 0)} value={initialBalanceExpenses} readOnly={readOnly} />
							</td>
						</tr>
					</tfoot>
				}
			</Table>
		</LabeledFrame>
	);
}