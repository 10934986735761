import React, { useState, useEffect } from 'react';
import { formatNumber, ucfirst } from 'helpers/generic';
import styled from 'styled-components';
import { PieChart } from 'react-minimal-pie-chart';

const TableStyled = styled.table`

    tbody {
        tr {
            cursor: pointer;

            &.opened {
                font-weight: bold;
            }

            &.mouse-over {
                background: var(--bs-light);
            }

            &.pie-segment-over {
                background: rgba(var(--bs-primary-rgb), 0.2);
            }

            &.mouse-over.pie-segment-over {
                background: rgba(var(--bs-primary-rgb), 0.3);
            }

            &.income {
                display: none;
                font-size: 11px;

                td {

                    &:first-child {
                        padding-left: 25px;
                    }
                }

                &.visible {
                    display: table-row;
                }
            }
        }
    }
`;

const PieChartWrapper = styled.div`
    position: sticky;
    top: ${props => props.stickyTop}px;
`;

const Morosity = (props) => {
    const morosity = props.data?.morosity ?? {};
    const colors = props.data?.colors?.morosity ?? [];
    const exportChartFix = props.exportChartFix;

    let [rowSelected, setRowSelected] = useState({});
    let [pageTitleBottom, setPageTitleBottom] = useState(0);
    let [trOverIndex, setTrOverIndex] = useState(null);

    let morosityData =[
        {
            name: 'Ingresos por remesas',
            amount: morosity?.incomes_bankremittances ?? 0,
            color: colors['incomes_bankremittances'] ?? 'var(--bs-light)'
        },
        {
            name: 'Ingresos por recibos',
            amount: morosity?.incomes_receipts ?? 0,
            color: colors['incomes_receipts'] ?? 'var(--bs-light)'
        },
        {
            name: 'Ingresos por exceso de saldo',
            amount: morosity?.incomes_excess ?? 0,
            color: colors['incomes_excess'] ?? 'var(--bs-light)'
        },
        {
            name: 'Devoluciones de recibos',
            amount: morosity?.refunded ?? 0,
            color: colors['refunded'] ?? 'var(--bs-light)'
        },
        {
            showSeparate: true,
            name: 'Recibos pendientes de cobro',
            amount: morosity?.receipts_pending ?? 0,
            color: colors['receipts_pending'] ?? 'var(--bs-light)'
        }
        
    ];

    const chartData = morosityData.length > 1 ? 
        morosityData.map((entry, i) => {

            let row = {
                title: entry.name,
                value: Math.abs(entry.amount),
                color: entry.color
            }

            let color = entry.color;
            if ( Object.keys(rowSelected).length > 0 && exportChartFix === null ) {
                if ( rowSelected[i] ) {
                    color = 'var(--bs-primary)';
                } else {
                    if ( trOverIndex === i ) {
                        color = 'var(--bs-secondary)';
                    } else {
                        color = 'var(--bs-gray-400)';
                    }
                }
            } else {
                if ( trOverIndex === i ) {
                    color = 'var(--bs-secondary)';
                }
            }
            
            return {
                ...row,
                color: color
            };
        })
        :
        [
            {
                title: 'No hay datos disponibles',
                value: 100,
                color: 'var(--bs-light)'
            },
        ] 
    ;

    useEffect(() => {
        let pageTitleBottom = document.querySelector('.page-title').getBoundingClientRect().bottom;
        setPageTitleBottom(pageTitleBottom);
    }, []);
    
    const toggleRowSelected = (idx) => {
        let newRowSelected = {...rowSelected};
        newRowSelected[idx] ? delete newRowSelected[idx] : newRowSelected[idx] = true;
        setRowSelected(newRowSelected);
    }

    return (
        <>
            <div className="col-md-8 mt-3">
                <TableStyled id="movements" className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>INGRESOS</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {morosityData.map((el, idx) => {
                            if ( el.showSeparate ) return null;

                            return (
                                <tr 
                                    key={idx}
                                    className={(rowSelected[idx] ? 'pie-segment-over opened' : '') + ' ' + (trOverIndex === idx ? 'mouse-over' : '')} 
                                    onClick={() => {
                                        toggleRowSelected(idx)
                                    }}
                                    onMouseOver={() => setTrOverIndex(idx)}
                                    onMouseOut={() => setTrOverIndex(null)}
                                >
                                    <td>
                                        <div className="d-flex align-items-center">
                                            {el.color &&
                                                <span className="badge rounded-pill me-2" style={{backgroundColor: el.color}}>&nbsp;</span>
                                            }
                                            {el.name}
                                        </div>    
                                    </td>
                                    <td></td>
                                    <td>{formatNumber(el.amount ?? 0, 2, true)} €</td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td>TOTAL INGRESOS</td>
                            {/* <td>{formatNumber((morosity?.incomes_bankremittances ?? 0) + (morosity?.incomes_receipts ?? 0) + (morosity?.incomes_excess ?? 0) + (morosity?.refunded ?? 0), 2, true)}</td> */}
                            <td>{formatNumber((morosity?.incomes_bankremittances ?? 0) + (morosity?.incomes_receipts ?? 0) + (morosity?.incomes_excess ?? 0) + (morosity?.refunded ?? 0), 2, true)}</td>
                        </tr>
                    </tfoot>
                </TableStyled> 

                <TableStyled id="movements" className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>PENDIENTES DE COBRO</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {morosityData.map((el, idx) => {
                            if ( !el.showSeparate ) return null;

                            return (
                                <tr 
                                    key={idx}
                                    className={(rowSelected[idx] ? 'pie-segment-over opened' : '') + ' ' + (trOverIndex === idx ? 'mouse-over' : '')} 
                                    onClick={() => {
                                        toggleRowSelected(idx)
                                    }}
                                    onMouseOver={() => setTrOverIndex(idx)}
                                    onMouseOut={() => setTrOverIndex(null)}
                                >
                                    <td>
                                        <div className="d-flex align-items-center">
                                            {el.color &&
                                                <span className="badge rounded-pill me-2" style={{backgroundColor: el.color}}>&nbsp;</span>
                                            }
                                            {el.name}
                                        </div>    
                                    </td>
                                    <td></td>
                                    <td>{formatNumber(el.amount ?? 0, 2, true)} €</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </TableStyled>
            </div>

            <PieChartWrapper className="col-md-4 mt-3" stickyTop={pageTitleBottom} data-piecharwrapper="morosity">
                <PieChart
                    data={chartData}
                    labelStyle={{
                        fontSize: '3.5px'
                    }}
                    radius={45}
                    segmentsShift={(index) => ((rowSelected[index] || trOverIndex === index) && exportChartFix === null ? 4 : 1)}
                    segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
                    onClick={(data, index) => {
                        toggleRowSelected(index);
                    }}
                    onMouseOver={(data, index) => setTrOverIndex(index)}
                    onMouseOut={() => setTrOverIndex(null)}
                />
            </PieChartWrapper>
        </>
    );
}

export default Morosity;