import React, { useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Tooltip } from 'bootstrap/dist/js/bootstrap';
import { ToastContainer } from 'react-toastify';
import { getUser, setUser, hasCapability } from 'helpers/user';
import { getConfig, getAjaxUrl } from 'helpers/config';
import { delay } from 'helpers/generic';
import { getCommunity, setCommunity } from 'helpers/community';
import useStore from "store";

import Login from "pages/Login";
import Logout from "pages/Logout";
import ResetPassword from "pages/ResetPassword";

import { default as SuperAdminCompanies } from "pages/superadmin/Companies";
import { default as SuperAdminCompaniesForm } from "pages/superadmin/CompaniesForm";
import { default as SuperAdminUsers } from "pages/superadmin/Users";
import { default as SuperAdminUsersForm } from "pages/superadmin/UsersForm";

import { default as ManagerDashboard } from "pages/manager/Dashboard";
import { default as ManagerCompany } from "pages/manager/Company";
import { default as ManagerUsers } from "pages/manager/Users";
import { default as ManagerUsersForm } from "pages/manager/UsersForm";
import { default as ManagerCommunities } from "pages/manager/Communities";
import { default as ManagerCommunitiesForm } from "pages/manager/CommunitiesForm";
import { default as ManagerCommunityPositions } from "pages/manager/CommunityPositions";
import { default as ManagerCommunityPositionsForm } from "pages/manager/CommunityPositionsForm";
import { default as ManagerCustomFields } from "pages/manager/CustomFields";
import { default as ManagerCustomFieldsForm } from "pages/manager/CustomFieldsForm";
import { default as ManagerSectors } from "pages/manager/Sectors";
import { default as ManagerSectorsForm } from "pages/manager/SectorsForm";
import { default as ManagerOwners } from "pages/manager/Owners";
import { default as ManagerOwnersForm } from "pages/manager/OwnersForm";
import { default as ManagerProviders } from "pages/manager/Providers";
import { default as ManagerProvidersForm } from "pages/manager/ProvidersForm";
import { default as ManagerClients } from "pages/manager/Clients";
import { default as ManagerClientsForm } from "pages/manager/ClientsForm";
import { default as ManagerContacts } from "pages/manager/Contacts";
import { default as ManagerContactsForm } from "pages/manager/ContactsForm";
import { default as ManagerRenters } from "pages/manager/Renters";
import { default as ManagerRentersForm } from "pages/manager/RentersForm";
import { default as ManagerPropertiesTypes } from "pages/manager/PropertiesTypes";
import { default as ManagerPropertiesTypesForm } from "pages/manager/PropertiesTypesForm";
import { default as ManagerBuildings } from "pages/manager/Buildings";
import { default as ManagerBuildingsForm } from "pages/manager/BuildingsForm";
import { default as ManagerProperties } from "pages/manager/Properties";
import { default as ManagerPropertiesForm } from "pages/manager/PropertiesForm";
import { default as ManagerNoticesTypes } from "pages/manager/NoticesTypes";
import { default as ManagerNoticesTypesForm } from "pages/manager/NoticesTypesForm";
import { default as ManagerNoticesSources } from "pages/manager/NoticesSources";
import { default as ManagerNoticesSourcesForm } from "pages/manager/NoticesSourcesForm";
import { default as ManagerNotices } from "pages/manager/Notices";
import { default as ManagerNoticesForm } from "pages/manager/NoticesForm";
import { default as ManagerDocuments } from "pages/manager/Documents";
import { default as ManagerDocumentViewer } from "pages/manager/DocumentViewer";
import { default as ManagerTaxes } from "pages/manager/Taxes";
import { default as ManagerTaxesForm } from "pages/manager/TaxesForm";
import { default as ManagerExpensesCategories } from "pages/manager/ExpensesCategories";
import { default as ManagerExpensesCategoriesForm } from "pages/manager/ExpensesCategoriesForm";
import { default as ManagerPaymentMethods } from "pages/manager/PaymentMethods";
import { default as ManagerPaymentMethodsForm } from "pages/manager/PaymentMethodsForm";
import { default as ManagerProvidersInvoices } from "pages/manager/ProvidersInvoices";
import { default as ManagerProvidersInvoiceForm } from "pages/manager/ProvidersInvoiceForm";
import { default as ManagerProvidersInvoiceDraft } from "pages/manager/ProvidersInvoicesDraft";
import { default as ManagerEventsForm } from "pages/manager/EventsForm";
import { default as ManagerCalendar } from "pages/manager/Calendar";
import { default as ManagerEstimates } from "pages/manager/Estimates";
import { default as ManagerEstimatesForm } from "pages/manager/EstimatesForm";
import { default as ManagerContableAccounts } from "pages/manager/ContableAccounts";
import { default as ManagerContableAccountsForm } from "pages/manager/ContableAccountsForm";
import { default as ManagerQuotasConcepts } from "pages/manager/QuotasConcepts";
import { default as ManagerQuotasConceptsForm } from "pages/manager/QuotasConceptsForm";
import { default as ManagerMeters } from "pages/manager/Meters";
import { default as ManagerMetersForm } from "pages/manager/MetersForm";
import { default as ManagerMetersReadings } from "pages/manager/MetersReadings";
import { default as ManagerMetersReadingsForm } from "pages/manager/MetersReadingsForm";
import { default as ManagerTreasury } from "pages/manager/Treasury";
import { default as ManagerTreasuryForm } from "pages/manager/TreasuryForm";
import { default as ManagerReceiptsIssuances } from "pages/manager/ReceiptsIssuances";
import { default as ManagerReceiptsIssuancesNewForm } from "pages/manager/ReceiptsIssuancesNewForm";
import { default as ManagerReceipts } from "pages/manager/Receipts";
import { default as ManagerReceiptsForm } from "pages/manager/ReceiptsForm";
import { default as ManagerReceiptsManualForm } from "pages/manager/ReceiptsManualForm";
import { default as ManagerBankRemittances } from "pages/manager/BankRemittances";
import { default as ManagerBankRemittancesNewForm } from "pages/manager/BankRemittancesNewForm";
import { default as ManagerAccountingEntries } from "pages/manager/AccountingEntries";
import { default as ManagerAccountingEntriesForm } from "pages/manager/AccountingEntriesForm";
import { default as ManagerContracts } from "pages/manager/Contracts";
import { default as ManagerContractsForm } from "pages/manager/ContractsForm";
import { default as ManagerMeetings } from "pages/manager/Meetings";
import { default as ManagerMeetingsForm } from "pages/manager/MeetingsForm";
import { default as ManagerMeetingPdfTemplate } from "pages/manager/MeetingsForm/MeetingPdfTemplate";
import { default as ManagerTemplates } from "pages/manager/Templates";
import { default as ManagerTemplatesForm } from "pages/manager/TemplatesForm";
import { default as ManagerEmails } from "pages/manager/Emails";
import { default as ManagerEmailsForm } from "pages/manager/EmailsForm";
import { default as ManagerFinancialYears } from "pages/manager/FinancialYears";
import { default as ManagerBankReconciliation } from "pages/manager/BankReconciliation";
import { default as ManagerBankReconciliationMovements } from "pages/manager/BankReconciliationMovements";
import { default as ManagerIncomes } from "pages/manager/Incomes";
import { default as ManagerSearch } from "pages/manager/Search";
import { default as ManagerUsefulLinks } from "pages/manager/UsefulLinks";
import { default as ManagerUsefulLinksForm } from "pages/manager/UsefulLinksForm";
import { default as ManagerPrevisions } from "pages/manager/Previsions";
import { default as ManagerBalance } from "pages/manager/Balance";
import { default as ManagerClientsInvoicesForm } from "pages/manager/ClientsInvoicesForm";
import { default as ManagerAdministrationModels } from "pages/manager/AdministrationModels";
import { default as ManagerTreasuryStatement } from "pages/manager/TreasuryStatement";
import { default as ManagerAddressesRelations } from "pages/manager/AddressesRelations";
import { default as ManagerAddressesRelationsForm } from "pages/manager/AddressesRelationsForm";
import { default as ManagerAppOwnersBulletinBoard } from "pages/manager/AppOwnersBulletinBoard";
import { default as ManagerAppOwnersBulletinBoardForm } from "pages/manager/AppOwnersBulletinBoardForm";
import { default as ManagerBankContract } from "pages/manager/BankContract";
import { default as ManagerBankContractForm } from "pages/manager/BankContractForm";

const MainContainer = styled.div`
	
`;

function App() {
	const location = useLocation();

    const forceReload = useStore(state => state.forceReload);

	// Axios global
    axios.defaults.baseURL = getAjaxUrl();
	axios.defaults.headers.common = {
		'Authorization': getUser() ? 'Bearer ' + getConfig()?.token : null,
		'Content-Type': 'application/json',
		'Accept': 'application/json',
		'Cache-Control': 'no-cache',
		'Pragma': 'no-cache',
		'Expires': '0',
	};
    
    // Axios interceptors
    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        // Check unauthorized error or csrf token mismatch
        if ( error.response && (error.response.status === 401 || error.response.status === 419 || error.response.status === 403) ) {
            setUser(null, true);
        }

        // Default
        return Promise.reject(error);
    });

    // Get user & community and update
    useEffect(() => {
    	const updateCommunity = async () => {
			// Get selected community
	    	let community = getCommunity();
	    	if ( !community || !community.id || community.from_query_param ) return;

	    	// Get from server
	    	let community_from_server = null;
	    	let getCommunityFromServer = () => {
	    		return axios.get('/api/manager/communities/get/' + community.id).then((response) => {
					community_from_server = response.data;
			  	});
	    	}
	    	await getCommunityFromServer();

	    	// Compare and update
	    	if ( JSON.stringify(community) !== JSON.stringify(community_from_server) ) {
	    		setCommunity(community_from_server, true, false);
	    	}
    	} 
    	updateCommunity();

    	const updateLoggedUser = async () => {
			await delay(1000);

    		// Get logged user
	    	let user = getUser();
	    	if ( !user || !user.id ) return;

	    	// Get from server
	    	let user_from_server = null;
	    	let getUserFromServer = () => {
	    		return axios.get('/api/get-authuser-data').then((response) => {
					user_from_server = response.data;
			  	});
	    	}
	    	await getUserFromServer();

	    	// Compare and update
	    	if ( JSON.stringify(user) !== JSON.stringify(user_from_server) ) {
	    		setUser({...user, ...user_from_server}, true);
	    	}
    	} 
    	updateLoggedUser();
    }, [forceReload]);

	useEffect(() => {
		let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
		let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
		  	return new Tooltip(tooltipTriggerEl)
		});

		return function cleanup() {
			tooltipList.forEach((el) => el?.dispose());
		}
	});

	return (
		<MainContainer id="main-container" forceReload={forceReload}>
			{ (!getUser() && (location.pathname !== '/login' && location.pathname !== '/reset-password' )) &&
				<Navigate to="/login" />
			}

			<Routes>
				<Route path="login" element={<Login />} />
				<Route path="logout" element={<Logout />} />
				<Route path="reset-password" element={<ResetPassword />} />

				{ hasCapability('companies') && 
					<React.Fragment>
						<Route path="/" element={<Navigate to="/companies" />} /> 
						<Route path="/companies" element={<SuperAdminCompanies />} /> 
						<Route path="/companies/add" element={<SuperAdminCompaniesForm />} /> 
						<Route path="/companies/edit/:id" element={<SuperAdminCompaniesForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('users-all') && 
					<React.Fragment>
						<Route path="/users" element={<SuperAdminUsers />} /> 
						<Route path="/users/add" element={<SuperAdminUsersForm />} /> 
						<Route path="/users/edit/:id" element={<SuperAdminUsersForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('summary') && <Route path="/" element={<ManagerDashboard />} />	}

				{ hasCapability('company-users') && 
					<React.Fragment>
						<Route path="/users" element={<ManagerUsers />} /> 
						<Route path="/users/add" element={<ManagerUsersForm />} /> 
						<Route path="/users/edit/:id" element={<ManagerUsersForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('company') && 
					<React.Fragment>
						<Route path="/company" element={<ManagerCompany />} />  
					</React.Fragment>
				}

				{ hasCapability('communities') && 
					<React.Fragment>
						<Route path="/communities" element={<ManagerCommunities />} /> 
						<Route path="/communities/add" element={<ManagerCommunitiesForm />} /> 
						<Route path="/communities/edit/:id" element={<ManagerCommunitiesForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('community-positions') && 
					<React.Fragment>
						<Route path="/community-positions" element={<ManagerCommunityPositions />} /> 
						<Route path="/community-positions/add" element={<ManagerCommunityPositionsForm />} /> 
						<Route path="/community-positions/edit/:id" element={<ManagerCommunityPositionsForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('sectors') && 
					<React.Fragment>
						<Route path="/sectors" element={<ManagerSectors />} /> 
						<Route path="/sectors/add" element={<ManagerSectorsForm />} /> 
						<Route path="/sectors/edit/:id" element={<ManagerSectorsForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('providers') && 
					<React.Fragment>
						<Route path="/providers" element={<ManagerProviders />} /> 
						<Route path="/providers/add" element={<ManagerProvidersForm />} /> 
						<Route path="/providers/edit/:id" element={<ManagerProvidersForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('clients') && 
					<React.Fragment>
						<Route path="/clients" element={<ManagerClients />} /> 
						<Route path="/clients/add" element={<ManagerClientsForm />} /> 
						<Route path="/clients/edit/:id" element={<ManagerClientsForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('contacts') && 
					<React.Fragment>
						<Route path="/contacts" element={<ManagerContacts />} /> 
						<Route path="/contacts/add" element={<ManagerContactsForm />} /> 
						<Route path="/contacts/edit/:id" element={<ManagerContactsForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('custom-fields') && 
					<React.Fragment>
						<Route path="/custom-fields/:type" element={<ManagerCustomFields />} /> 
						<Route path="/custom-fields/:type/add" element={<ManagerCustomFieldsForm />} /> 
						<Route path="/custom-fields/:type/edit/:id" element={<ManagerCustomFieldsForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('properties-types') && 
					<React.Fragment>
						<Route path="/properties-types" element={<ManagerPropertiesTypes />} /> 
						<Route path="/properties-types/add" element={<ManagerPropertiesTypesForm />} /> 
						<Route path="/properties-types/edit/:id" element={<ManagerPropertiesTypesForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('owners') && 
					<React.Fragment>
						<Route path="/owners" element={<ManagerOwners />} /> 
						<Route path="/owners/add" element={<ManagerOwnersForm />} /> 
						<Route path="/owners/edit/:id" element={<ManagerOwnersForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('renters') && 
					<React.Fragment>
						<Route path="/renters" element={<ManagerRenters />} /> 
						<Route path="/renters/add" element={<ManagerRentersForm />} /> 
						<Route path="/renters/edit/:id" element={<ManagerRentersForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('buildings') && 
					<React.Fragment>
						<Route path="/buildings" element={<ManagerBuildings />} /> 
						<Route path="/buildings/add" element={<ManagerBuildingsForm />} /> 
						<Route path="/buildings/edit/:id" element={<ManagerBuildingsForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('properties') && 
					<React.Fragment>
						<Route path="/properties" element={<ManagerProperties />} /> 
						<Route path="/properties/add" element={<ManagerPropertiesForm />} /> 
						<Route path="/properties/edit/:id" element={<ManagerPropertiesForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('notices') && 
					<React.Fragment>
						<Route path="/notices" element={<ManagerNotices />} />
						<Route path="/notices/add" element={<ManagerNoticesForm />} /> 
						<Route path="/notices/edit/:id" element={<ManagerNoticesForm />} />  
					</React.Fragment>
				}

				{ hasCapability('notices-types') && 
					<React.Fragment>
						<Route path="/notices-types" element={<ManagerNoticesTypes />} /> 
						<Route path="/notices-types/add" element={<ManagerNoticesTypesForm />} /> 
						<Route path="/notices-types/edit/:id" element={<ManagerNoticesTypesForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('notices-sources') && 
					<React.Fragment>
						<Route path="/notices-sources" element={<ManagerNoticesSources />} /> 
						<Route path="/notices-sources/add" element={<ManagerNoticesSourcesForm />} /> 
						<Route path="/notices-sources/edit/:id" element={<ManagerNoticesSourcesForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('documents') && 
					<React.Fragment>
						<Route path="/documents" element={<ManagerDocuments />} /> 
						<Route path="/documents/view-file/:id" element={<ManagerDocumentViewer />} /> 
					</React.Fragment>
				}

				{ hasCapability('taxes') && 
					<React.Fragment>
						<Route path="/taxes" element={<ManagerTaxes />} /> 
						<Route path="/taxes/add" element={<ManagerTaxesForm />} /> 
						<Route path="/taxes/edit/:id" element={<ManagerTaxesForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('expenses-categories') && 
					<React.Fragment>
						<Route path="/expenses-categories" element={<ManagerExpensesCategories />} /> 
						<Route path="/expenses-categories/add" element={<ManagerExpensesCategoriesForm />} /> 
						<Route path="/expenses-categories/edit/:id" element={<ManagerExpensesCategoriesForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('payment-methods') && 
					<React.Fragment>
						<Route path="/payment-methods" element={<ManagerPaymentMethods />} /> 
						<Route path="/payment-methods/add" element={<ManagerPaymentMethodsForm />} /> 
						<Route path="/payment-methods/edit/:id" element={<ManagerPaymentMethodsForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('providers-invoices') && 
					<React.Fragment>
						<Route path="/providers-invoices" element={<ManagerProvidersInvoices />} /> 
						<Route path="/providers-invoices/add" element={<ManagerProvidersInvoiceForm />} /> 
						<Route path="/providers-invoices/edit/:id" element={<ManagerProvidersInvoiceForm />} /> 
						<Route path="/providers-invoices/draft" element={<ManagerProvidersInvoiceDraft />} /> 
					</React.Fragment>
				}

				{ hasCapability('incomes') && 
					<React.Fragment>
						<Route path="/incomes" element={<ManagerIncomes />} /> 
					</React.Fragment>
				}

				{ hasCapability('events') && 
					<React.Fragment>
						<Route path="/calendar" element={<ManagerCalendar />} /> 
						<Route path="/events/add" element={<ManagerEventsForm />} /> 
						<Route path="/events/edit/:id" element={<ManagerEventsForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('estimates') && 
					<React.Fragment>
						<Route path="/estimates" element={<ManagerEstimates />} /> 
						<Route path="/estimates/add" element={<ManagerEstimatesForm />} /> 
						<Route path="/estimates/edit/:id" element={<ManagerEstimatesForm />} /> 

						<Route path="/quotas-concepts" element={<ManagerQuotasConcepts />} /> 
						<Route path="/quotas-concepts/add" element={<ManagerQuotasConceptsForm />} /> 
						<Route path="/quotas-concepts/edit/:id" element={<ManagerQuotasConceptsForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('contableaccounts') && 
					<React.Fragment>
						<Route path="/contable-accounts" element={<ManagerContableAccounts />} /> 
						{/* <Route path="/contable-accounts/add" element={<ManagerContableAccountsForm />} />  */}
						<Route path="/contable-accounts/edit/:id" element={<ManagerContableAccountsForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('meters') && 
					<React.Fragment>
						<Route path="/meters" element={<ManagerMeters />} /> 
						<Route path="/meters/add" element={<ManagerMetersForm />} /> 
						<Route path="/meters/edit/:id" element={<ManagerMetersForm />} /> 

						<Route path="/meters-readings" element={<ManagerMetersReadings />} /> 
						<Route path="/meters-readings/add" element={<ManagerMetersReadingsForm />} /> 
						<Route path="/meters-readings/edit/:id" element={<ManagerMetersReadingsForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('treasury') && 
					<React.Fragment>
						<Route path="/treasury" element={<ManagerTreasury />} /> 
						<Route path="/treasury/add" element={<ManagerTreasuryForm />} /> 
						<Route path="/treasury/edit/:id" element={<ManagerTreasuryForm />} /> 
						<Route path="/treasury/statement/:id" element={<ManagerTreasuryStatement />} /> 
					</React.Fragment>
				}

				{ hasCapability('receiptsissuances') && 
					<React.Fragment>
						<Route path="/receiptsissuances" element={<ManagerReceiptsIssuances />} /> 
						<Route path="/receiptsissuances/new" element={<ManagerReceiptsIssuancesNewForm />} />
					</React.Fragment>
				}

				{ hasCapability('receipts') && 
					<React.Fragment>
						<Route path="/receipts" element={<ManagerReceipts />} /> 
						<Route path="/receipts/edit/:id" element={<ManagerReceiptsForm />} /> 
						<Route path="/receipts/manual-add" element={<ManagerReceiptsManualForm />} /> 

						<Route path="/previsions" element={<ManagerPrevisions />} /> 
					</React.Fragment>
				}

				{ hasCapability('bankremittances') && 
					<React.Fragment>
						<Route path="/bankremittances" element={<ManagerBankRemittances />} /> 
						<Route path="/bankremittances/new" element={<ManagerBankRemittancesNewForm />} />
					</React.Fragment>
				}

				{ hasCapability('accountingentries') && 
					<React.Fragment>
						<Route path="/accounting-entries" element={<ManagerAccountingEntries />} /> 
						<Route path="/accounting-entries/add" element={<ManagerAccountingEntriesForm />} />
						<Route path="/accounting-entries/edit/:id" element={<ManagerAccountingEntriesForm />} />
					</React.Fragment>
				}

				{ hasCapability('contracts') && 
					<React.Fragment>
						<Route path="/contracts" element={<ManagerContracts />} /> 
						<Route path="/contracts/add" element={<ManagerContractsForm />} />
						<Route path="/contracts/edit/:id" element={<ManagerContractsForm />} />
					</React.Fragment>
				}

				{ hasCapability('meetings') && 
					<React.Fragment>
						<Route path="/meetings" element={<ManagerMeetings />} /> 
						<Route path="/meetings/add" element={<ManagerMeetingsForm />} />
						<Route path="/meetings/edit/:id" element={<ManagerMeetingsForm />} />
						<Route path="/meetings/pdf/:id" element={<ManagerMeetingPdfTemplate />} />
					</React.Fragment>
				}

				{ hasCapability('templates') && 
					<React.Fragment>
						<Route path="/templates" element={<ManagerTemplates />} /> 
						<Route path="/templates/add" element={<ManagerTemplatesForm />} />
						<Route path="/templates/edit/:id" element={<ManagerTemplatesForm />} />
					</React.Fragment>
				}

				{ hasCapability('emails') && 
					<React.Fragment>
						<Route path="/emails" element={<ManagerEmails />} /> 
						<Route path="/emails/add" element={<ManagerEmailsForm />} />
						<Route path="/emails/edit/:id" element={<ManagerEmailsForm />} />
					</React.Fragment>
				}

				{ hasCapability('financialyears') && 
					<React.Fragment>
						<Route path="/financial-years" element={<ManagerFinancialYears />} /> 
					</React.Fragment>
				}

				{ hasCapability('bank-reconciliation') && 
					<React.Fragment>
						<Route path="/bank-reconciliation" element={<ManagerBankReconciliation />} /> 
						<Route path="/bank-reconciliation/:id/movements" element={<ManagerBankReconciliationMovements />} />
					</React.Fragment>
				}

				{ hasCapability('search') && 
					<Route path="/search" element={<ManagerSearch />} /> 
				}

				{ hasCapability('useful-links') && 
					<React.Fragment>
						<Route path="/useful-links" element={<ManagerUsefulLinks />} /> 
						<Route path="/useful-links/add" element={<ManagerUsefulLinksForm />} />
						<Route path="/useful-links/edit/:id" element={<ManagerUsefulLinksForm />} />
					</React.Fragment>
				}

				{ hasCapability('balance') && 
					<React.Fragment>
						<Route path="/balance" element={<ManagerBalance />} /> 
					</React.Fragment>
				}

				{ hasCapability('clients-invoices') && 
					<React.Fragment>
						<Route path="/clients-invoices/add" element={<ManagerClientsInvoicesForm />} /> 
						<Route path="/clients-invoices/edit/:id" element={<ManagerClientsInvoicesForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('administration-models') && 
					<React.Fragment>
						<Route path="/administration-models" element={<ManagerAdministrationModels />}>
							<Route path=":type" element={<ManagerAdministrationModels/>} />
						</Route>
					</React.Fragment>
				}

				{ hasCapability('addresses-relations') && 
					<React.Fragment>
						<Route path="/addresses-relations" element={<ManagerAddressesRelations />} /> 
						<Route path="/addresses-relations/add" element={<ManagerAddressesRelationsForm />} /> 
						<Route path="/addresses-relations/edit/:id" element={<ManagerAddressesRelationsForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('appowners-bulletinboard') && 
					<React.Fragment>
						<Route path="/appowners-bulletinboard" element={<ManagerAppOwnersBulletinBoard />} /> 
						<Route path="/appowners-bulletinboard/add" element={<ManagerAppOwnersBulletinBoardForm />} /> 
						<Route path="/appowners-bulletinboard/edit/:id" element={<ManagerAppOwnersBulletinBoardForm />} /> 
					</React.Fragment>
				}

				{ hasCapability('bank-contracts') && 
					<React.Fragment>
						<Route path="/bank-contracts" element={<ManagerBankContract />} /> 
						<Route path="/bank-contracts/add" element={<ManagerBankContractForm />} /> 
						<Route path="/bank-contracts/edit/:id" element={<ManagerBankContractForm />} /> 
					</React.Fragment>
				}
			</Routes>
			<ToastContainer
				position="top-right"
				autoClose={2000}
			/>
		</MainContainer>
	);
}

export default App;