import React, { useEffect } from 'react';
import axios from 'axios';
import LabeledFrame from 'components/LabeledFrame';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { NavLink } from 'react-router-dom';
import { openPopupWindow } from 'helpers/generic';
import CustomSelect from 'components/CustomSelect';
import CustomInput from 'components/CustomInput';

let axiosCancelToken = null;

export default function Petitioner(props) {
	const data = props.data;
	const setDataField = props.setDataField;
	const setData = props.setData;
	const errors = props.errors;

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	const loadCommunities = (input, callback) => {
		axios.get('/api/manager/communities/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectCommunity = (value) => {
		// Community
		let newData = {...data};
		newData.community_id = value?.id ?? null;
		newData.community = {
			id: value?.id,
			name: value?.name,
			importantowners: value?.importantowners
		}

		// Update state
		setData(newData);
	}

	const loadBuildings = (input, callback, args) => {
		axios.get('/api/manager/buildings/list', {
			params: {
				search: input,
				no_paginate: true,
				community_id: args.community?.id
			},
			cancelToken: axiosCancelToken?.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectBuilding = (value) => {
		// Building
		let newData = {...data};
		newData.building_id = value?.id ?? null;
		newData.building = {
			id: value?.id,
			name: value?.name
		};

		// If building is not parent of property, null property
		if ( value && data.property && data.property.building_id !== value.id ) {
			newData.property_id = null;
			newData.property = {id: null, name: null};
		}

		// Update state
		setData(newData);
	}

	const loadProperties = (input, callback, args) => {
		axios.get('/api/manager/properties/list', {
			params: {
				search: input,
				no_paginate: true,
				community_id: args.community?.id,
				building_id: args.building?.id,
				owner_id: args.owner?.id
			},
			cancelToken: axiosCancelToken?.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name + ' (' + el.number + ')'
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectProperty = (value) => {
		// Property
		let newData = {...data};
		newData.property_id = value?.id ?? null;
		newData.property = {
			id: value?.id,
			name: value ? value.name + ' (' + value.number + ')' : null
		}

		// Reset owner select if not owner from selected property
		if ( value && value.owners_array.findIndex(el => el.id === data.owner?.id) === -1 ) {
			newData.owner_id = null;
			newData.owner = {id: null, name: null};
		}

		// Reset renter select if not renter from selected property
		if ( value && value.renters_array.findIndex(el => el.id === data.renter?.id) === -1 ) {
			newData.renter_id = null;
			newData.renter = {id: null, name: null};
		}

		// Update state
		setData(newData);
	}

	const loadOwners = (input, callback, args) => {
		axios.get('/api/manager/owners/list', {
			params: {
				search: input,
				no_paginate: true,
				property_id: args.property?.id,
				community_id: args.community?.id
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: <div>
								{el.name}
								<div><small className="text-secondary">{el.community?.name}</small></div>
						   </div>
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectOwner = (value) => {
		let newData = {...data};
		newData.owner_id = value?.id ?? null;
		newData.owner = {
			id: value?.id,
			name: value?.name
		}

		// Community
		if ( value?.community ) {
			newData.community_id = value.community.id;
			newData.community = {
				id: value.community.id,
				name: value.community.name
			}
		}

		// Property
		if ( value?.properties[0] && !data.property_id ) {
			newData.property_id = value.properties[0].id;
			newData.property = {
				id: value.properties[0].id,
				name: value.properties[0].name
			}

			// Building
			if ( value.properties[0].building ) {
				newData.building_id = value.properties[0].building?.id;
				newData.building = {
					id: value.properties[0].building?.id,
					name: value.properties[0].building?.name
				}
			}
		}
		
		setData(newData);
	}

	const loadRenters = (input, callback, args) => {
		axios.get('/api/manager/renters/list', {
			params: {
				search: input,
				no_paginate: true,
				property_id: args.property?.id,
				community_id: args.community?.id
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectRenter = (value) => {
		let newData = {...data};
		newData.renter_id = value?.id ?? null;
		newData.renter = {
			id: value?.id,
			name: value?.name
		}

		// Community
		if ( value?.community ) {
			newData.community_id = value.community.id;
			newData.community = {
				id: value.community.id,
				name: value.community.name
			}
		}

		// Property
		if ( value?.properties[0] && !data.property_id ) {
			newData.property_id = value.properties[0].id;
			newData.property = {
				id: value.properties[0].id,
				name: value.properties[0].name
			}

			// Building
			if ( value.properties[0].building ) {
				newData.building_id = value.properties[0].building.id;
				newData.building = {
					id: value.properties[0].building.id,
					name: value.properties[0].building.name
				}
			}
		}
		
		setData(newData);
	}

	const loadProviders = (input, callback, args) => {
		axios.get('/api/manager/providers/list', {
			params: {
				search: input,
				no_paginate: true,
				community_id: args.community?.id
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.business_name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectProvider = (value) => {
		let newData = {...data};
		newData.provider_id = value?.id ?? null;
		newData.provider = {
			id: value?.id,
			name: value?.business_name
		}
		setData(newData);
	}

	const loadClients = (input, callback, args) => {
		axios.get('/api/manager/clients/list', {
			params: {
				search: input,
				no_paginate: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectClient = (value) => {
		let newData = {...data};
		newData.client_id = value?.id ?? null;
		newData.client = {
			id: value?.id,
			name: value?.name
		}
		setData(newData);
	}

	const loadContacts = (input, callback, args) => {
		axios.get('/api/manager/contacts/list', {
			params: {
				search: input,
				no_paginate: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectContact = (value) => {
		let newData = {...data};
		newData.contact_id = value?.id ?? null;
		newData.contact = {
			id: value?.id,
			name: value?.name
		}
		setData(newData);
	}

	const setExernalPetitionerField = (field, value) => {
		let newData = {...data};
		let external_petitioner = {...newData.external_petitioner} ?? {};
		external_petitioner[field] = value;
		newData.external_petitioner = external_petitioner;
		setData(newData);
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	const openPopupWindowMouseDownFix = (e) => {
		e.preventDefault();
		e.stopPropagation();
		document.activeElement.blur();
	}

	return (
		<LabeledFrame 
			label="Detalles del solicitante"
			className="mb-3"
		>
			<div className="row">
				<div className="col-md-4 col-xl-3">
					<div className="mb-2">
						<CustomSelect label="Solicitante" className="form-control form-control-sm" value={data.petitioner_type ?? ''} onChange={(e) => setDataField('petitioner_type', e.target.value)}>
							<option value=""></option>
							<option value="community">Comunidad</option>
							<option value="owner">Propietario</option>
							<option value="renter">Inquilino</option>
							<option value="provider">Proveedor</option>
							<option value="client">Cliente</option>
							<option value="contact">Contacto</option>
						</CustomSelect>

						{ errors.petitioner_type &&
							<div className="invalid-feedback d-block">{ errors.petitioner_type[0] }</div>
						}
					</div>
				</div>

				{ data.petitioner_type === 'external' && 
					<React.Fragment>
						<div className="col-md-8 col-xl-9">
							<div className="mb-2">
								<CustomInput label="Nombre" type="text" className="form-control form-control-sm" onChange={(e) => setExernalPetitionerField('name', e.target.value)} value={data.external_petitioner?.name ?? ''} />
								{ errors['external_petitioner.name'] &&
									<div className="invalid-feedback d-block">{ errors['external_petitioner.name'][0] }</div>
								}
							</div>
						</div>

						<div className="col-lg-3 d-none d-xl-block d-md-none"></div>

						<div className="col-md-12 col-xl-9">
							<div className="row">
								<div className="col-md-6">
									<div className="mb-2">
										<CustomInput label="Teléfono" type="text" className="form-control form-control-sm" onChange={(e) => setExernalPetitionerField('phone', e.target.value)} value={data.external_petitioner?.phone ?? ''} />
										{ errors['external_petitioner.phone'] &&
											<div className="invalid-feedback d-block">{ errors['external_petitioner.phone'][0] }</div>
										}
									</div>
								</div>
								<div className="col-md-6">
									<div className="mb-2">
										<CustomInput label="Email" type="text" className="form-control form-control-sm" onChange={(e) => setExernalPetitionerField('email', e.target.value)} value={data.external_petitioner?.email ?? ''} />
										{ errors['external_petitioner.email'] &&
											<div className="invalid-feedback d-block">{ errors['external_petitioner.email'][0] }</div>
										}
									</div>
								</div>	
								<div className="col-md-12">
									<div className="mb-2">
										<CustomInput label="Otros datos" type="text" className="form-control form-control-sm" onChange={(e) => setExernalPetitionerField('other_data', e.target.value)} value={data.external_petitioner?.other_data ?? ''} />
										{ errors['external_petitioner.other_data'] &&
											<div className="invalid-feedback d-block">{ errors['external_petitioner.other_data'][0] }</div>
										}
									</div>
								</div>
							</div>
						</div>
					</React.Fragment>
				}


				{ data.petitioner_type !== 'external'  && 
					<React.Fragment>
						<div className="col-md-8 col-xl-5">
							<div className="mb-2">
								<EmpoweredSelector
									load={loadCommunities}
									onChange={(value) => selectCommunity(value)}
									timeout={250}
									label={
										<div>
											{data.community?.name ?? ''}&nbsp;
											{data.community?.id &&
												<NavLink className="text-decoration-none text-reset" to={'/communities/edit/' + data.community.id + '?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
											}
										</div>
									}
									placeholder="Comunidad"
									showPlaceholderHelper={true}
									value={data.community?.id}
								/>
								{ errors.community_id &&
									<div className="invalid-feedback d-block">{ errors.community_id[0] }</div>
								}
							</div>
						</div>

						<div className="col-md-6 col-xl-4">
							<div className="mb-2">
								<EmpoweredSelector
									load={loadBuildings}
									args={{community: data.community}}
									onChange={(value) => selectBuilding(value)}
									timeout={250}
									label={
										<div>
											{data.building?.name ?? ''}&nbsp;
											{data.building?.id &&
												<NavLink className="text-decoration-none text-reset" to={'/buildings/edit/' + data.building.id + '?community_id=' + data.community?.id + '&popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
											}
										</div>
									}
									placeholder="Portal/Bloque"
									showPlaceholderHelper={true}
									value={data.building?.id}
									disabled={!data.community?.id}
								/>
								{ errors.building_id &&
									<div className="invalid-feedback d-block">{ errors.building_id[0] }</div>
								}
							</div>
						</div>

						<div className="col-lg-3 d-none d-xl-block d-md-none"></div>

						<div className="col-md-6 col-xl-3">
							<div className="mb-2">
								<EmpoweredSelector
									load={loadProperties}
									args={{building: data.building, community: data.community, owner: data.owner}}
									onChange={(value) => selectProperty(value)}
									timeout={250}
									label={
										<div>
											{data.property?.name ?? ''}&nbsp;
											{data.property?.id &&
												<NavLink className="text-decoration-none text-reset" to={'/properties/edit/' + data.property.id + '?community_id=' + data.community?.id + '&popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
											}
										</div>
									}
									placeholder="Propiedad"
									showPlaceholderHelper={true}
									value={data.property?.id}
									disabled={!data.community?.id}
								/>
								{ errors.property_id &&
									<div className="invalid-feedback d-block">{ errors.property_id[0] }</div>
								}
							</div>
						</div>

						<div className="col-md-6">
							<div className="mb-2">
								{ data.petitioner_type === 'owner' &&
									<React.Fragment>
										<EmpoweredSelector
											load={loadOwners}
											args={{community: data.community, property: data.property}}
											onChange={(value) => selectOwner(value)}
											timeout={250}
											label={
												<div>
													{data.owner?.name ?? ''}&nbsp;
													{data.owner?.id &&
														<NavLink className="text-decoration-none text-reset" to={'/owners/edit/' + data.owner.id + '?community_id=' + data.community?.id + '&popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
													}
												</div>
											}
											placeholder="Propietario"
											showPlaceholderHelper={true}
											value={data.owner?.id}
											// disabled={!data.community?.id}
										/>
										{ errors.owner_id &&
											<div className="invalid-feedback d-block">{ errors.owner_id[0] }</div>
										}
									</React.Fragment>
								}

								{ data.petitioner_type === 'renter' &&
									<React.Fragment>
										<EmpoweredSelector
											load={loadRenters}
											args={{community: data.community, property: data.property}}
											onChange={(value) => selectRenter(value)}
											timeout={250}
											label={
												<div>
													{data.renter?.name ?? ''}&nbsp;
													{data.renter?.id &&
														<NavLink className="text-decoration-none text-reset" to={'/renters/edit/' + data.renter.id + '?community_id=' + data.community?.id + '&popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
													}
												</div>
											}
											placeholder="Inquilino"
											showPlaceholderHelper={true}
											value={data.renter?.id}
											// disabled={!data.community?.id}
										/>
										{ errors.renter_id &&
											<div className="invalid-feedback d-block">{ errors.renter_id[0] }</div>
										}
									</React.Fragment>
								}

								{ data.petitioner_type === 'provider' &&
									<React.Fragment>
										<EmpoweredSelector
											load={loadProviders}
											args={{community: data.community}}
											onChange={(value) => selectProvider(value)}
											timeout={250}
											label={
												<div>
													{data.provider?.name ?? ''}&nbsp;
													{data.provider?.id &&
														<NavLink className="text-decoration-none text-reset" to={'/providers/edit/' + data.provider.id + '?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
													}
												</div>
											}
											placeholder="Proveedor"
											showPlaceholderHelper={true}
											value={data.provider?.id}
										/>
										{ errors.provider_id &&
											<div className="invalid-feedback d-block">{ errors.provider_id[0] }</div>
										}
									</React.Fragment>
								}

								{ data.petitioner_type === 'client' &&
									<React.Fragment>
										<EmpoweredSelector
											load={loadClients}
											onChange={(value) => selectClient(value)}
											timeout={250}
											label={
												<div>
													{data.client?.name ?? ''}&nbsp;
													{data.client?.id &&
														<NavLink className="text-decoration-none text-reset" to={'/clients/edit/' + data.client.id + '?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
													}
												</div>
											}
											placeholder="Cliente"
											showPlaceholderHelper={true}
											value={data.client?.id}
										/>
										{ errors.client_id &&
											<div className="invalid-feedback d-block">{ errors.client_id[0] }</div>
										}
									</React.Fragment>
								}

								{ data.petitioner_type === 'contact' &&
									<React.Fragment>
										<EmpoweredSelector
											load={loadContacts}
											onChange={(value) => selectContact(value)}
											timeout={250}
											label={
												<div>
													{data.contact?.name ?? ''}&nbsp;
													{data.contact?.id &&
														<NavLink className="text-decoration-none text-reset" to={'/contacts/edit/' + data.contact.id + '?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
													}
												</div>
											}
											placeholder="Contacto"
											showPlaceholderHelper={true}
											value={data.contact?.id}
										/>
										{ errors.contact_id &&
											<div className="invalid-feedback d-block">{ errors.contact_id[0] }</div>
										}
									</React.Fragment>
								}
							</div>
						</div>
					</React.Fragment>
				}
			</div>	
		</LabeledFrame>
	);
}