import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import styled from 'styled-components';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { CristalLoader, formatNumber } from 'helpers/generic';
import { getAjaxUrl, debugMode } from "helpers/config";
import readXlsxFile from 'read-excel-file'
import { toast } from "react-toastify";
import { getCommunity } from "helpers/community";

const ModalStyled = styled.div`
	background: rgba(0, 0, 0, 0.4);

	.modal {
		position: relative;
	}
`;

const FileWrapper = styled.div`
	width: 100%;
	padding: 20px;
	background: var(--bs-light);
	border: 1px solid var(--bs-gray-300);
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	input[type=file] {
		visibility: hidden;
		height: 0;
	}
`;

const Table = styled.table`
	font-size: 12px;
	font-weight: normal;

	td {
		line-height: 100%;

		&.with-errors {
			background: var(--bs-danger);
			color: white;

			.error {
				color: yellow;
			}
		}

		&:nth-child(3),
		&:nth-child(4),
		&:nth-child(5),
		&:nth-child(6) {
			width: 120px;
			text-align: right;

			b {
				text-decoration: underline;
			}
		}

		&:nth-child(4) {
			border-right: 5px solid rgba(var(--bs-primary-rgb), 0.5);

		}

		&:nth-child(5) {
			border-left: 5px solid rgba(var(--bs-primary-rgb), 0.5);
		}
	}
`;

let axiosCancelToken = null;

export default function ImportInitialBalancesBulkModal(props) {
	const modalRef = useRef(null);
	const fileRef = useRef(null);

	const closeCallback = props.closeCallback;

	const [file, setFile] = useState(null);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [properties, setProperties] = useState([]);
	const [errors, setErrors] = useState({});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const modal = new Modal(modalRef.current, {backdrop: false});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();
			closeCallback();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

		modal.show();

		// Fix, because bootstrap removes scroll and add padding on modal opened
		document.body.style.overflow = 'auto'; 
		document.body.style.paddingRight = '0';
		
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [closeCallback]);

	useEffect(() => {
		const loadProperties = async () => {
			setLoading(true);

			await axios.get('/api/manager/properties/list', {
				params: {
					community_id: getCommunity()?.id,
					no_paginate: true,
					with_initial_balance: true
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				setProperties([...response.data]);	
			}).catch((error) => {			
				if ( axios.isCancel(error) ) return;
			});	

			setLoading(false);
		}
		loadProperties();
	}, []);

	const loadFile = async (file) => {
		// Reset
		setFile(null);
		setData([]);

		// Check if exists the file
        if ( !file ) return false;

		// Read file and validate
		readXlsxFile(file).then((rows) => {
			let errors = {};
			rows = rows.filter((el, idx) => idx > 0);
			rows.forEach((row, rowIdx) => {
				// Column 0 - Num dep exists
				let numDepExists = properties?.filter(el => {
					let check = parseInt(el.number) === parseInt(row[0]);
					// console.log(parseInt(el.number), parseInt(row[0]));
					return check;					
				}).length > 0;
				if ( !numDepExists ) errors[rowIdx + '-' + 0] = 'No existe una propiedad con este número de departamento';		

				// Column 1 - Is Numeric
				if ( isNaN(row[1]) ) errors[rowIdx + '-' + 1] = 'Introduce un importe válido';				

				// Column 2 - Is Numeric
				if ( isNaN(row[2]) ) errors[rowIdx + '-' + 2] = 'Introduce un importe válido';				
			});
			setErrors(errors);
			
			// Set data
			setData(rows);

			// Set file
			setFile(file);
		}).catch((error) => {
			console.error(error);
			setFile(null);
			setData([]);
			toast.error('Formato de archivo no válido')
			return;
		});	
	}


	const saveData = async () => {
		setLoading(true);

		// Prepare data
		let dataPrepared = data.map(el => {
			let propertyData = properties.filter(pEl => pEl.number === el[0])[0] ?? null;
			if ( !propertyData ) return null;
			
			return ({
				id: propertyData.id,
				amount: el[1] ?? null,
				amount_receipts: el[2] ?? null,
			});
		}).filter(el => el !== null);

		document.activeElement.blur();

		await axios.post('api/manager/properties/update-initial-balance-bulk', {
			balances: dataPrepared,
			community_id: getCommunity()?.id
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Se han guardado los datos');
			
			// Hide modal
			Modal.getInstance(modalRef.current).hide();
			props.closeCallback();
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error al guardar');
		});	

		setLoading(false);
	}

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			{ loading && <CristalLoader /> }
			<div className="modal-dialog modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Importar Saldo Inicial</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<FileWrapper onClick={() => {fileRef.current.value = null; fileRef.current.click();}}>
							{ file === null ?
								<div>Pulsa para seleccionar el fichero</div>
								:
								<div className="text-center">{file.name}</div>
							}
							<input type="file" ref={fileRef} onChange={(e) => loadFile(e.target.files[0])} />
						</FileWrapper>
					
						{ data.length > 0 && 
							<>
								<div className="mt-3 text-primary">
									<small>Nota: el nuevo saldo inicial sustituira al saldo inicial existente</small>
								</div>

								<Table className="table table-sm table-bordered">
									<thead>
										<tr>
											<td>Nº Dep.</td>
											<td>Dirección</td>
											<td className="bg-orange text-white">Actual de <b>gastos</b></td>
											<td className="bg-primary text-white">Nuevo de <b>gastos</b></td>
											<td className="bg-orange text-white">Actual de <b>recibos</b></td>
											<td className="bg-primary text-white">Nuevo de <b>recibos</b></td>
										</tr>
									</thead>
									<tbody>
										{data && data.map((el, idx) => {
											let propertyData = properties.filter(pEl => pEl.number === el[0])[0] ?? null;
											
											let errorRow = null;
											let error = errors[idx + '-' + 0] ?? errors[idx + '-' + 1] ?? errors[idx + '-' + 2] ?? null;
											if ( error ) errorRow = (
												<tr className="text-danger">
													<td colSpan="100%">{error}</td>
												</tr>
											);

											let row = (
												<tr className={errorRow ? 'bg-danger text-white' : ''}>
													<td>{el[0]}</td>
													<td>{propertyData?.name ?? ''}</td>
													<td>{formatNumber(propertyData?.initial_balance ?? 0, 2)}</td>
													<td>{el[1] !== null ? formatNumber(el[1], 2) : 'sin cambios'}</td>
													<td>{formatNumber(propertyData?.initial_balance_receipts ?? 0, 2)}</td>
													<td>{el[2] !== null ? formatNumber(el[2], 2) : 'sin cambios'}</td>
												</tr>
											);
											
											return (
												<React.Fragment key={idx}>
													{row}
													{errorRow}
												</React.Fragment>
											);
										})}
									</tbody>
								</Table>
							</>
						}

						{ data.length <= 0 && 
							<div className="mt-3" style={{opacity: 0.8}}>
								<b>Ejemplo de documento excel <a href={(debugMode() ? getAjaxUrl() : '') + '/files/excel-saldo-inicial.xlsx'} target="_blank"><i className="bi bi-download"></i></a></b>
								<Table className="table table-sm table-bordered mb-0">
									<tbody>
										<tr>
											<td>Nº Dep.</td>
											<td>Saldo gastos</td>
											<td>Saldo recibos</td>
										</tr>
										<tr>
											<td>1</td>
											<td>-100</td>
											<td>100</td>
										</tr>
										<tr>
											<td>2</td>
											<td>-200.45</td>
											<td>123.45</td>
										</tr>
										<tr>
											<td>3</td>
											<td>56</td>
											<td>543</td>
										</tr>
									</tbody>
								</Table>
							</div>
						}
					</div>
					<div className="modal-footer">
						<button type="button" className={'btn btn-sm btn-primary text-white'} onClick={() => saveData()} disabled={loading || !data.length || Object.keys(errors).length > 0 }>Importar datos</button>
					</div>
				</div>
			</div>
		</ModalStyled>
	);
}