import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader, openPopupWindow } from 'helpers/generic';
import { getCommunity } from 'helpers/community';
import LabeledFrame from 'components/LabeledFrame';
import Notes from 'components/Notes';
import DocumentsPanel from 'components/DocumentsPanel';
import EmpoweredSelector from 'components/EmpoweredSelector';
import ActionsContextMenu from 'components/ActionsContextMenu';
import CustomInput from 'components/CustomInput';

let axiosCancelToken = null;

let parentWindowProxyCallback = window.opener?.PopupProxyCallback;

export default function ContractsForm(props) {
	let actionsDropdownRef = useRef(null);

	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';

	let [forceReload, setForceReload] = useState(null);
	let [data, setData] = useState({});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		window.onbeforeunload = () => {
			parentWindowProxyCallback();
		};
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				await axios.get('/api/manager/contracts/get/' + params.id, {
					params: {
						community_id: getCommunity()?.id
					},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(response.data);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		}
	}, [params.id]);

	const deleteContract = () => {
		const c = window.confirm('¿Quieres eliminar este contrato?');
		if ( !c ) return;

		axios.post('/api/manager/contracts/delete/' + data.id, {
			community_id: getCommunity()?.id
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Contrato borrado');
	    	if ( popup ) window.close();
	    	else navigate('/contracts');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (goBack = true) => {
		setErrors({});

		axios.post('/api/manager/contracts/' + (data.id ? 'edit/' + data.id : 'add'), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			setData({...response.data.contract});
			if ( goBack ) {
				if ( popup ) window.close();
				else navigate('/contracts');
			} else {
				setForceReload(Math.random());
		    	navigate('/contracts/edit/' + response.data.contract.id + (popup ? '?popup=true&community_id=' + data.community_id : ''));
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const loadProviders = (input, callback) => {
		axios.get('/api/manager/providers/list', {
			params: {
				search: input,
				community_id: getCommunity()?.id,
				no_paginate: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.business_name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectProvider = (data) => {
		setData((prev) => ({...prev, provider: data, provider_id: data?.id}));
	}

	const loadCommunities = (input, callback) => {
		axios.get('/api/manager/communities/list', {
			params: {
				search: input,
				no_paginate: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectCommunity = (data) => {
		setData((prev) => ({...prev, community: data, community_id: data?.id}));
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	const openPopupWindowMouseDownFix = (e) => {
		e.preventDefault();
		e.stopPropagation();
		document.activeElement.blur();
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de contrato</h1>
					<button onClick={() => popup ? window.close() : navigate('/contracts')} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-md-6">
											<div className="row">
												<div className="col col-static-150">
													<div className="mb-2">
														<CustomInput label="Nº documento" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('number', e.target.value)} value={data.number ?? ''} />
														{ errors.number &&
															<div className="invalid-feedback d-block">{ errors.number[0] }</div>
														}
													</div>
												</div>
												<div className="col col-static-260">
													<div className="mb-2">
														<div className="input-group">
															<EmpoweredSelector
																load={loadProviders}
																onChange={(value) => selectProvider(value)}
																timeout={250}
																label={
																	<div>
																		{data.provider?.business_name ?? ''}&nbsp;
																		{data.provider?.id &&
																			<NavLink className="text-decoration-none text-reset" to={'/providers/edit/' + data.provider.id + '?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
																		}
																	</div>	
																}
																value={data.provider?.id}
																placeholder="Proveedor"
																showPlaceholderHelper={true}
															/>
															<div className="input-group-append">
																<NavLink className="btn btn-sm btn-light2 text-secondary" to={'/providers/add?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-plus-circle-fill"></i></NavLink>
															</div>
														</div>
														{ errors.provider_id &&
															<div className="invalid-feedback d-block">{ errors.provider_id[0] }</div>
														}
													</div>
												</div>
												<div className="col col-static-200">
													<div className="mb-2">
														<EmpoweredSelector
															load={loadCommunities}
															onChange={(value) => selectCommunity(value)}
															timeout={250}
															label={
																<div>
																	{data.community?.name ?? ''}&nbsp;
																	{data.community?.id &&
																		<NavLink className="text-decoration-none text-reset" to={'/communities/edit/' + data.community.id + '?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
																	}
																</div>	
															}
															value={data.community?.id}
															placeholder="Comunidad"
															showPlaceholderHelper={true}
														/>
														{ errors.community_id &&
															<div className="invalid-feedback d-block">{ errors.community_id[0] }</div>
														}
													</div>
												</div>
												<div className="col-md-12">
													<div className="mb-2">
														<CustomInput label="Descripción" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('description', e.target.value)} value={data.description ?? ''} />
														{ errors.description &&
															<div className="invalid-feedback d-block">{ errors.description[0] }</div>
														}
													</div>
												</div>
												<div className="col-md-12">
													<div className="row">
														<div className="col col-static-130">
															<div className="mb-2">
																<CustomInput label="Fecha" type="date" className="form-control form-control-sm" onChange={(e) => setDataField('date', e.target.value)} value={data.date ?? ''} />
																{ errors.date &&
																	<div className="invalid-feedback d-block">{ errors.date[0] }</div>
																}
															</div>
														</div>
														<div className="col col-static-150">
															<div className="mb-2">
																<CustomInput label="Fecha de vencimiento" type="date" className="form-control form-control-sm" onChange={(e) => setDataField('due_date', e.target.value)} value={data.due_date ?? ''} />
																{ errors.due_date &&
																	<div className="invalid-feedback d-block">{ errors.due_date[0] }</div>
																}
															</div>
														</div>
														<div className="col col-static-150">
															<div className="mb-2">
																<CustomInput label="Fecha de aviso" type="date" className="form-control form-control-sm" onChange={(e) => setDataField('notice_date', e.target.value)} value={data.notice_date ?? ''} />
																{ errors.notice_date &&
																	<div className="invalid-feedback d-block">{ errors.notice_date[0] }</div>
																}
															</div>
														</div>
														<div className="col col-static-150">
															<div className="mb-2">
																<CustomInput label="Fecha de baja" type="date" className="form-control form-control-sm" onChange={(e) => setDataField('cancellation_date', e.target.value)} value={data.cancellation_date ?? ''} />
																{ errors.cancellation_date &&
																	<div className="invalid-feedback d-block">{ errors.cancellation_date[0] }</div>
																}
															</div>
														</div>
													</div>
												</div>
												<LabeledFrame className="mt-2 mb-3 mb-md-0" label="Instrucciones">
													<textarea className="form-control form-control-sm" rows="3" onChange={(e) => setDataField('observations', e.target.value)} value={data.observations ?? ''}></textarea>
													{ errors.observations &&
														<div className="invalid-feedback d-block">{ errors.observations[0] }</div>
													}
												</LabeledFrame>
											</div>
										</div>
										<div className="col-md-6">
											<Notes
												className="mb-3"
												notes={data.notes ?? []}
												updateParentCallback={(notes) => setData((data) => ({...data, notes: notes}))}
											/>

											{ data.id && 
												<DocumentsPanel
													className="mb-3"
													folderId={data.folder_id ?? null}
													community={{id: getCommunity()?.id, name: getCommunity()?.id}}
													reloadFolder={forceReload}
												/>
											}
										</div>
									</div>
								</div>
								<div className="card-footer" id={popup ? 'footer-fixed' : ''}>
									<div className="row">
										<div className="col-6">
											{ data.id &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteContract()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											{ data.id &&
												<div className="dropdown d-inline">
													<button ref={actionsDropdownRef} className="btn btn-sm btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
														Acciones
													</button>
													<ActionsContextMenu actionsDropdownRef={actionsDropdownRef} className="dropdown-menu">
														{ data.provider?.email_default &&
															<li><NavLink className="dropdown-item" to={'/emails/add?recipients_type=providers&recipients_ids=' + data.provider?.id} onClick={(e) => openPopupInfoWindow(e, e.target.href)}>Enviar email a proveedor</NavLink></li>
														}
														<li><hr className="dropdown-divider" /></li>
														<li><button className="dropdown-item" onClick={() => saveData(false)}>Guardar</button></li>						
														<li><button className="dropdown-item" onClick={() => saveData()}>Guardar y salir</button></li>
													</ActionsContextMenu>
												</div>
											}

											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}