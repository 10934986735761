import React, { useRef } from 'react';
import styled from 'styled-components';

const CustomSelectStyled = styled.div`
	position: relative;
    min-height: 30px;

    span.custom-select-label {
        position: absolute;
        left: 5px;
        z-index: 2;
        color: var(--bs-primary);
        user-select: none;
        font-size: 9px;
    }

    select.custom-select-input {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        max-height: auto !important;
        z-index: 1;
        padding-top: 12px;
        padding-left: 5px;
        font-size: 11px !important;
    }
`;

export default function CustomSelect(props) {
    let selectRef = useRef(null);

    let selectProps = {...props};
    if ( selectProps.hasOwnProperty('className') ) selectProps.className += ' custom-select-input';
	
	return (
		<CustomSelectStyled className="custom-select">
            {props.label && <span className="custom-select-label" onClick={() => selectRef.current.focus()}>{props.label}</span>}
			<select ref={selectRef} {...selectProps} />
		</CustomSelectStyled>
	);
}