import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useParams, Navigate, useSearchParams, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import LabeledFrame from 'components/LabeledFrame';
import CustomFieldsPanel from 'components/CustomFieldsPanel';
import DocumentsPanel from 'components/DocumentsPanel';
import { CristalLoader, openPopupWindow } from 'helpers/generic';
import { getCommunity } from 'helpers/community';
import { authUserPermission } from 'helpers/community';
import EmpoweredSelector from 'components/EmpoweredSelector';
import Owners from './Owners';
import Renters from './Renters';
import Payers from './Payers';
import Balances from './Balances';
import Notes from 'components/Notes';
import ButtonExportFichaPdf from 'components/ButtonExportFicha';
import OwnersRentersQuotesList from 'components/OwnersRentersQuotesList';
import ActionsContextMenu from 'components/ActionsContextMenu';
import CustomInput from 'components/CustomInput';

let axiosCancelToken = null;

export default function PropertiesForm(props) {
	let actionsDropdownRef = useRef(null);

	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';
	const forceCanEdit = queryParams.get('edit') === 'true';

	let community = getCommunity();
	let [data, setData] = useState({
		code: null,
		coefficient: 0.00000,
		building: {
			id: null,
			name: null
		},
		type: {
			id: null,
			name: null
		},
		street: {
			id: null,
			name: null
		},
		initial_balance: 0
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);
	let [canEdit, setCanEdit] = useState(true);
	let [forceReload, setForceReload] = useState(null);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		window.BuildingPopupProxyCallback = (building) => { 
			selectBuilding(building);
		};
	}, []);
	
	useEffect(() => {
		window.PopupProxyCallback = (person, type) => {
			if ( person.id ) {	
				let newPersons = data[type+'s']?.length ? [...data[type+'s']] : [];

				// Check if exists
				if ( newPersons.filter(el => el.id === person.id).length ) return;
 
				// Add
				newPersons = newPersons.filter(el => el.id); // this prevents empty line 
				newPersons.push({
					id: person.id,
					name: person.name,
					percent: newPersons.length === 0 ? 100 : 0
				});
				setData({...data, [type+'s']: newPersons});
			}
		}

		return function cleanup() {
			window.PopupProxyCallback = null;
		}
	}, [data]);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				await axios.get('/api/manager/properties/get/' + params.id, {
					params: {
						community_id: community?.id,
						with_payers: true,
						with_initial_balance: true
					},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData({...response.data, owners: [...response.data.owners_array], renters: [...response.data.renters_array]});
					if ( !forceCanEdit ) setCanEdit(false);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		} else {
			const getLastNumber = () => {
				axios.get('/api/manager/properties/get-last-number', {
					params: {
						community_id: community?.id
					},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(data => ({...data, number: response.data.number, code: getCommunity()?.name_short + (""+response.data.number).padStart(3, '0')}));
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});
			}
			getLastNumber();
		}
	}, [params.id, community?.id, forceCanEdit]);

	useEffect(() => {
		const generateName = async () => {
			await axios.get('/api/manager/properties/generate-name', {
				params: {
					community_id: community?.id,
					street_id: data.street?.id,
					building_id: data.building?.id,
					stairs: data.stairs,
					plant: data.plant,
					door: data.door
				},
				cancelToken: axiosCancelToken?.token
			}).then((response) => {
				setData((data) => ({...data, name: response.data}));
			}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		generateName();
	}, [community?.id, data.street, data.building, data.stairs, data.plant, data.door]);

	const loadBuildings = (input, callback) => {
		axios.get('/api/manager/buildings/list', {
			params: {
				search: input,
				no_paginate: true,
				community_id: community?.id
			},
			cancelToken: axiosCancelToken?.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const loadStreets = (input, callback) => {
		axios.get('/api/manager/communities/get/' + community?.id, {
			params: {
				community_id: community?.id
			},
			cancelToken: axiosCancelToken?.token
		}).then((response) => {
			let formatted = response.data.streets?.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const loadTypes = (input, callback) => {
		axios.get('/api/manager/properties-types/list', {
			params: {
				search: input,
				no_paginate: true,
				community_id: community?.id
			},
			cancelToken: axiosCancelToken?.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const deleteProperty = () => {
		const c = window.confirm('¿Quieres eliminar esta propiedad?');
		if ( !c ) return;

		axios.post('/api/manager/properties/delete/' + data.id, {
			community_id: community?.id
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Propiedad borrado')
	    	navigate('/properties');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (goBack = true) => {
		setErrors({});

		// Fix: find owners and renters and remove payers that doesn't exists
		let payers = data.payers ?? [];
		let owners_ids = data.owners?.map((el) => el.id);
		let renters_ids = data.renters?.map((el) => el.id);
		payers = payers.filter((el) => owners_ids.includes(el.payer_id) || renters_ids.includes(el.payer_id));
		data.payers = payers;

		data.community_id = community?.id;	

		axios.post('/api/manager/properties/' + (data.id ? 'edit/' + data.id : 'add'), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			setData({...response.data.property, owners: [...response.data.property.owners_array], renters: [...response.data.property.renters_array]});
			setForceReload(Math.random());

			if ( goBack ) {
				if ( popup ) {
					data = response.data.property; // Fix to send last data on before unload
					window.close();
				}
				else navigate('/properties');
			} else if ( !data.id ) {
		    	navigate('/properties/edit/' + response.data.property.id + '?edit=true' + (popup ? '&popup=true' : ''));
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	const openPopupWindowMouseDownFix = (e) => {
		e.preventDefault();
		e.stopPropagation();
		document.activeElement.blur();
	}

	const selectBuilding = (building) => {
		setData((prev) => ({
			...prev,
			building: {
				id: building?.id, 
				name: building?.name,
				address: building?.address
			},
			building_id: building?.id ?? null
		}));
	}

	const selectStreet = (street) => {
		setData((prev) => ({
			...prev,
			street: {
				id: street?.id, 
				name: street?.name
			},
			street_id: street?.id ?? null
		}));
	}

	const selectType = (type) => {
		setData({
			...data,
			type: {
				id: type?.id, 
				name: type?.name
			},
			property_type_id: type?.id ?? null
		});
	}




	if ( !community?.id ) return <Navigate to="/" />;

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de propiedad</h1>
					<button onClick={() => popup ? window.close() : navigate(-1)} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-md-6">
											<LabeledFrame className="mb-3" label="Datos básicos">
												<div className="row">
													<div className="col col-static-100" >
														<div className="mb-2">
															<CustomInput 
																label="Código" 
																type="text" 
																className="form-control form-control-sm"
																onChange={(e) => setDataField('code', e.target.value)} 
																value={data.code ?? ''} 
																readOnly={!canEdit} 
															/>
															{ errors.code &&
																<div className="invalid-feedback d-block">{ errors.code[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-80">
														<div className="mb-2">
															<CustomInput 
																label="Nº" 
																type="text" 
																className="form-control form-control-sm text-end" 
																onChange={(e) => setDataField('number', e.target.value)} 
																value={data.number ?? ''} 
																readOnly={!canEdit} 
															/>
															{ errors.number &&
																<div className="invalid-feedback d-block">{ errors.number[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-120">
														<div className="mb-2">
															<CustomInput 
																label="Coeficiente real" 
																type="number" 
																step="0.00001" 
																className="form-control form-control-sm text-end no-arrows" 
																onChange={(e) => setDataField('coefficient', e.target.value)} 
																value={data.coefficient ?? ''} 
																readOnly={!canEdit} 
															/>
															{ errors.coefficient &&
																<div className="invalid-feedback d-block">{ errors.coefficient[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-140">
														<div className="mb-2">
															<CustomInput 
																label="Coeficiente catastro" 
																type="number" 
																step="0.00001" 
																className="form-control form-control-sm text-end no-arrows" 
																onChange={(e) => setDataField('coefficient_cadastral', e.target.value)} 
																value={data.coefficient_cadastral ?? ''}
																readOnly={!canEdit} 
															/>
															{ errors.coefficient_cadastral &&
																<div className="invalid-feedback d-block">{ errors.coefficient_cadastral[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-100">
														<div className="mb-2">
															<CustomInput 
																label="Superficie" 
																type="number" 
																step="1" 
																className="form-control form-control-sm text-end no-arrows" 
																onChange={(e) => setDataField('surface', e.target.value)} 
																value={data.surface ?? ''} 
																readOnly={!canEdit} 
															/>
															{ errors.surface &&
																<div className="invalid-feedback d-block">{ errors.surface[0] }</div>
															}
														</div>
													</div>

													<div></div>

													<div className="col col-static-180">
														<div className="mb-2">
															<EmpoweredSelector
																load={loadStreets}
																onChange={(value) => selectStreet(value)}
																timeout={250}
																label={
																	<div>
																		{data.street?.name ?? ''}&nbsp;
																	</div>
																}
																placeholder="Calle"
																showPlaceholderHelper={true}
																value={data.street_id}
																noSearcheable={true}
																disabled={!canEdit} 
															/>
															{ errors.street_id &&
																<div className="invalid-feedback d-block">{ errors.street_id[0] }</div>
															}
														</div>
													</div>

													<div className="col col-static-200">
														<div className="mb-2">
															<div className="input-group">
																<EmpoweredSelector
																	load={loadBuildings}
																	onChange={(value) => selectBuilding(value)}
																	timeout={250}
																	label={
																		<div>
																			{data.building?.name ?? ''}&nbsp;
																			{data.building?.id &&
																				<NavLink className="text-decoration-none text-reset" to={'/buildings/edit/' + data.building.id + '?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
																			}
																		</div>
																	}
																	placeholder="Portal"
																	showPlaceholderHelper={true}
																	value={data.building?.id}
																	disabled={!canEdit} 
																/>
																<div className="input-group-append">
																	<NavLink className="btn btn-sm btn-light2 text-primary border-0" to={'/buildings/add?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-plus-circle-fill"></i></NavLink>
																</div>
															</div>
															
															{ errors.building_id &&
																<div className="invalid-feedback d-block">{ errors.building_id[0] }</div>
															}
														</div>
													</div>

													<div className="col col-static-80">
														<div className="mb-2">
															<CustomInput 
																label="Escaleras" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('stairs', e.target.value)} 
																value={data.stairs ?? ''} 
																readOnly={!canEdit} 
															/>
															{ errors.stairs &&
																<div className="invalid-feedback d-block">{ errors.stairs[0] }</div>
															}
														</div>
													</div>

													<div className="col col-static-80">
														<div className="mb-2">
															<CustomInput 
																label="Planta" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('plant', e.target.value)} 
																value={data.plant ?? ''} 
																readOnly={!canEdit} 
															/>
															{ errors.plant &&
																<div className="invalid-feedback d-block">{ errors.plant[0] }</div>
															}
														</div>
													</div>

													<div className="col col-static-80">
														<div className="mb-2">
															<CustomInput 
																label="Puerta" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('door', e.target.value)} 
																value={data.door ?? ''} 
																readOnly={!canEdit} 
															/>
															{ errors.door &&
																<div className="invalid-feedback d-block">{ errors.door[0] }</div>
															}
														</div>
													</div>

													<div className="col col-static-200">
														<div className="mb-2">
															<div className="input-group">
																<EmpoweredSelector
																	load={loadTypes}
																	onChange={(value) => selectType(value)}
																	timeout={250}
																	label={
																		<div>
																			{data.type?.name ?? ''}&nbsp;
																			{data.type?.id &&
																				<NavLink className="text-decoration-none text-reset" to={'/properties-types/edit/' + data.type?.id + '?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
																			}
																		</div>
																	}
																	placeholder="Tipo"
																	showPlaceholderHelper={true}
																	value={data.type?.id}
																	disabled={!canEdit} 
																/>
																<div className="input-group-append">
																	<NavLink className="btn btn-sm btn-light2 text-primary" to={'/properties-types/add?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-plus-circle-fill"></i></NavLink>
																</div>
															</div>
															{ errors.property_type_id &&
																<div className="invalid-feedback d-block">{ errors.property_type_id[0] }</div>
															}
														</div>
													</div>

													<div className="col col-static-345">
														<div className="mb-2">
															<CustomInput 
																label="Dirección" 
																type="text" 
																className="form-control form-control-sm" 
																value={data.name ? data.name : ''} 
																onChange={(e) => e.preventDefault()}
																readOnly={!canEdit}
															/>
															{ errors.name &&
																<div className="invalid-feedback d-block">{ errors.name[0] }</div>
															}
														</div>
													</div>

													<div className="col col-static-200">
														<div className="mb-2">
															<CustomInput 
																label="Referencia catastral" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('cadastral_reference', e.target.value)} 
																value={data.cadastral_reference ?? ''} 
																readOnly={!canEdit} 
															/>
															{ errors.cadastral_reference &&
																<div className="invalid-feedback d-block">{ errors.cadastral_reference[0] }</div>
															}
														</div>
													</div>

													<div className="col col-static-150">
														<div className="mb-2">
															<CustomInput 
																label="Fecha de compra" 
																type="date" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('buy_date', e.target.value)} 
																value={data.buy_date ?? ''} 
																readOnly={!canEdit} 
															/>
															{ errors.buy_date &&
																<div className="invalid-feedback d-block">{ errors.buy_date[0] }</div>
															}
														</div>
													</div>
												</div>	
											</LabeledFrame>

											<CustomFieldsPanel 
												className="mb-3"
												type="properties"
												fields={data.customfields_array}
												setFields={(fields) => setData((data) => ({...data, customfields_array: fields}))}
												readOnly={!canEdit}
											/>

											<LabeledFrame className="mb-3" label="Instrucciones">
												<textarea 
													className="form-control form-control-sm" 
													onChange={(e) => setDataField('observations', e.target.value)} 
													value={data.observations ?? ''}
													readOnly={!canEdit}
												/>
											</LabeledFrame>
											
											{ data.id &&
												<Balances
													propertyId={data.id}
													communityId={data.community_id}
													initialBalanceReceipts={data.initial_balance_receipts}
													initialBalanceExpenses={data.initial_balance}
													updateParentInitialBalanceReceipts={(initialBalance) => {
														setData((data) => ({...data, 'initial_balance_receipts': initialBalance}));
													}}
													updateParentInitialBalanceExpenses={(initialBalance) => {
														setData((data) => ({...data, 'initial_balance': initialBalance}));
													}}
													initialBalanceReceiptsError={errors?.initial_balance_receipts && errors.initial_balance_receipts[0] ? errors.initial_balance_receipts[0] : null}
													initialBalanceExpensesError={errors?.initial_balance && errors.initial_balance[0] ? errors.initial_balance[0] : null}
													readOnly={!canEdit}
													forceReloadParent={forceReload}
												/>
											}

											<Notes
												notes={data.notes ?? []}
												updateParentCallback={(notes) => setData((data) => ({...data, notes: notes}))}
												readOnly={!canEdit}
											/>
										</div>
										<div className="col-md-6">
											<Owners
												owners={data.owners}
												errors={errors}
												setOwners={(owners) => setData({...data, owners: owners})}
												readOnly={!canEdit}
											/>

											<Renters
												renters={data.renters}
												errors={errors}
												setRenters={(renters) => setData({...data, renters: renters})}
												readOnly={!canEdit}
											/>

											<Payers
												payers={data.payers}
												setPayers={(payers) => setData({...data, payers: payers})}
												owners={data.owners}
												renters={data.renters}
												errors={errors}
												readOnly={!canEdit}
											/>

											{ data.id &&
												<OwnersRentersQuotesList
													type={'properties'}
													id={data.id}
												/>
											}	

											{ data.id && 
												<DocumentsPanel
													className="mt-3 mb-3"
													folderId={data.folder_id ?? null}
													community={getCommunity()}
												/>
											}
										</div>
									</div>
								</div>
								<div className="card-footer" id={popup ? 'footer-fixed' : ''}>
									<div className="row">
										<div className="col-6">
											{ (data.id && authUserPermission('delete')) &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteProperty()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											{ ((authUserPermission('add') && !data.id) || (authUserPermission('edit') && data.id)) &&
												<React.Fragment>
													{ data.id &&
														<div className="dropdown d-inline">
															<button ref={actionsDropdownRef} className="btn btn-sm btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
																Acciones
															</button>
															<ActionsContextMenu actionsDropdownRef={actionsDropdownRef} className="dropdown-menu">
																<li><button className="dropdown-item" onClick={() => setCanEdit(!canEdit)}>{canEdit ? 'Desactivar edición' : 'Editar'}</button></li>
																<li><hr className="dropdown-divider" /></li>
																{ (data.owners && data.owners.length > 0) &&
																	<React.Fragment>
																		{data.owners.map((item, idx) => {
																			return (
																				<li key={idx}><NavLink className="dropdown-item" to={'/notices/add?owner_id=' + item.id + '&petitioner_type=owner&community_id=' + data.community_id + '&property_id=' + data.id} onClick={openPopupInfoWindow}>Nuevo aviso para {item.name}</NavLink></li>
																			)
																		})}
																		<li><hr className="dropdown-divider" /></li>
																	</React.Fragment>
																}
																<li><ButtonExportFichaPdf entityKey='properties' entityId={data.id} fileName={'Property ' + data.name} /></li>
																<li><hr className="dropdown-divider" /></li>
																<li><button className="dropdown-item" onClick={() => saveData(false)}>Guardar</button></li>						
																<li><button className="dropdown-item" onClick={() => saveData()}>Guardar y salir</button></li>
															</ActionsContextMenu>
														</div>
													}
													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>
												</React.Fragment>
											}							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}