import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import moment from "moment";
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { authUserPermission, getCommunity } from 'helpers/community';
import { formatNumber } from "helpers/generic";

const ModalStyled = styled.div`
	background: rgba(0, 0, 0, 0.4);

    h3 {
        display: block;
        font-size: 15px;
        text-align: center;
        margin-bottom: 0;

        span {
            display: block;
            font-size: 30px;
        }
    }

    input#amount {
        width: 200px;
        display: block;
        margin: 0 auto;
        margin-top: 20px;
        text-align: center;
        font-size: 30px !important;
        padding: auto;
        box-shadow: none !important;
        border: 1px solid var(--bs-gray-400);

        &.with-error {
            border: 1px solid var(--bs-red);
        }
    }
`;

let axiosCancelToken = null;

export default function ManualPaymentsModal(props) {
	const modalRef = useRef(null);

    const receipt = props.receipt;
	const forceReloadParent = props.forceReloadParent;
	const closeCallback = props.closeCallback;

	const [availableBalance, setAvailableBalance] = useState(0);
	const [amount, _setAmount] = useState(0);
    const setAmount = (value) => {
        if ( value > availableBalance ) value = availableBalance;
        if ( value > receipt?.pending ) value = receipt?.pending;
        if ( value < 0 ) value = 0;
        _setAmount(value);
    }

    const canPay = availableBalance > 0 && receipt?.pending > 0 && amount > 0 && amount <= receipt?.pending;

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const modal = new Modal(modalRef.current, {
			backdrop: false
		});

        const shownEvent = (e) => {
            let inputEl = modal._element.querySelector('input#amount');
            inputEl.focus();
            inputEl.select();
        }

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', shownEvent);
			modal.dispose();
			closeCallback();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);
		modal._element.addEventListener('shown.bs.modal', shownEvent);

		modal.show();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [closeCallback]);

    useEffect(() => {
        if ( !receipt ) return;

        let getAvailableBalance = async () => {
            await axios.get('/api/manager/properties/get-balances/' + receipt.property_id, {
				params: {
					community_id: getCommunity()?.id
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setAvailableBalance(response.data?.receipts?.balance ?? 0);                
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
        }
        getAvailableBalance();

    }, [receipt]);

    const savePayment = () => {
        axios.post('/api/manager/receipts/add-manual-payment/' + receipt.id, {
            amount: amount,
            community_id: getCommunity()?.id
        }, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Pago manual realizado');
            Modal.getInstance(modalRef.current).hide();
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) toast.error(error.response.data.errors[0][0]);;
		});	
    }

    const deletePayment = (id) => {
        const c = window.confirm('¿Estás seguro de que quieres eliminar este pago manual?');
        if ( !c ) return;

        axios.delete('/api/manager/receipts/' + receipt.id + '/delete-manual-payment/' + id + '?community_id=' + getCommunity()?.id, {
            cancelToken: axiosCancelToken.token
        }).then((response) => {
            toast.success('Pago manual eliminado');
            forceReloadParent();
        }
        ).catch((error) => {
            if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error, pro favor, recargue la pantalla');
        });
    }


	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Pago manual</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
                        { receipt.paid_status !== 'full' ?
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="bg-light py-2 px-3 rounded">
                                        <h3>Saldo disponible <span>{availableBalance}</span></h3>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="bg-light py-2 px-3 rounded">
                                        <h3>Importe pendiente <span>{receipt?.pending ?? 0}</span></h3>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <input type="number" className={'form-control no-arrows ' + (amount <= 0 ? 'with-error' : '')} id="amount" placeholder="Importe" value={amount} onChange={(e) => setAmount(e.target.value)} />
                                </div>
                            </div>
                            :
                            <div className="col-md-12">
                                <h3 className="text-success">Recibo pagado</h3>
                            </div>
                        }

                        { receipt?.manualpayments?.length > 0 &&
                            <table className="table table-bordered mt-4 mb-0">
                                <thead>
                                    <tr>
                                        <th className="w-50">Fecha</th>
                                        <th className="w-50 text-end">Importe</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {receipt?.manualpayments?.map((payment, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{moment(payment.created_at).format('DD-MM-YYYY')}</td>
                                                <td className="text-end">{formatNumber(payment.amount)} €</td>
                                                { (authUserPermission('delete') && payment.is_deletable) ?
                                                    <td className="bg-danger">
                                                        <button type="button" className="btn btn-sm btn-link text-white" onClick={() => deletePayment(payment.id)}><i className="bi bi-trash"></i></button>
                                                    </td> 
                                                    :
                                                    <td></td>
                                                }
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        }
					</div>
					<div className="modal-footer">
						<button type="button" disabled={!canPay} className={'btn btn-sm btn-primary text-white'} onClick={() => savePayment()}>Pagar</button>
					</div>
				</div>
			</div>
		</ModalStyled>
	);
}


