import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getUser } from 'helpers/user';
import { getRouterBasename } from 'helpers/config';
import { openPopupWindow } from 'helpers/generic';
import NotificationAudio from './notification_normal.mp3';

let axiosCancelToken = null;

let IconStyled = styled.div` 
    width: 100%;

    i {
        width: 100%;
        aspect-ratio: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background: ${props => props.color};
        border-radius: 50%;
    }
`;

let notificationAudio = new Audio(NotificationAudio);

export default function NoticesNotifications(props) {
    const userId = getUser()?.id ?? null;

    const loadingRef = useRef(false);
    const lastSoundTimeRef = useRef(null);
	const [notices, setNotices] = useState([]);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
        let noticesInterval = setInterval(async () => {
            if ( loadingRef.current ) return;
            if ( !userId ) {
                loadingRef.current = false;
                return;
            }

            loadingRef.current = true;

            await axios.get('/api/manager/notices/list', {
				params: {
					no_paginate: true,
                    not_notified: true,
                    user_id: userId,
                    status: 'pending,progress,expired'
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setNotices([...response.data]);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	

            loadingRef.current = false;
        }, 10000);

        return function cleanup() {
            clearInterval(noticesInterval);
        }
	}, [userId]);

    useEffect(() => {
        if ( !notices.length ) return;

        notices?.forEach((el) => {
            // Determine color
            let color = 'primary';
            if ( el.status === 'expired' ) color = 'danger';
            el.color = 'var(--bs-'+color+')';

            // Execute toast
    	    toast(({ closeToast }) => <CustomToast notice={el} closeToast={closeToast} />, {
                autoClose: false,
                toastId: el.id,
                closeButton: false,
                closeOnClick: false,
                icon: <IconStyled color={el.color}><i className="bi bi-bell"></i></IconStyled>,
                onOpen: () => {
                    if ( lastSoundTimeRef === null || moment().unix() - lastSoundTimeRef.current > 30 ) {
                        notificationAudio.play();
                        lastSoundTimeRef.current = moment().unix();
                    }
                },
            });
        });

    }, [notices]);

	return (
		<></>
	);
}


const CustomToastStyled = styled.div` 
    --color: ${props => props.color};  

    .toast-notice-header {
        color: var(--color);
        text-transform: uppercase;
        font-weight: 600;
    }

    button {
        padding: 0;
        position: absolute;
        top: 5px;
        right: 5px;
        border: 0;
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        font-size: 20px;
        color: var(--bs-secondary);

        &:hover {
            transform: scale(1.2);
        }
    }
`;

const CustomToast = (props) => {
    let notice = props.notice;
    let closeToast = props.closeToast;

    let excerpt = notice.title ?? '';
    if ( !excerpt ) excerpt = notice.description ? notice.description.substring(0, 100) + '...' : '';

    const updateNotifiedStatus = () => {
        return axios.post('/api/manager/notices/set-notified/' + notice.id, {}, {
            cancelToken: axiosCancelToken.token
        });
    }

    return (
        <CustomToastStyled
            color={notice.color}
            onClick={async () => {
                openPopupWindow(getRouterBasename() + '/notices/edit/' + notice.id);
                await updateNotifiedStatus();
                closeToast();
            }}
        >
            <button 
                onClick={async (e) => {
                    e.stopPropagation();
                    await updateNotifiedStatus();
                    closeToast();
                }}
            ><i className="bi bi-x"></i></button>

            <div className="toast-notice-header">
                {notice.status !== 'expired' && <>Nuevo aviso</>}
                {notice.status === 'expired' && <>Aviso vencido</>}
                &nbsp;{notice.reference}
                <div>{notice.community?.name}</div>
            </div>
            <div className="excerpt">
                {excerpt}
            </div>
        </CustomToastStyled>
    )
}