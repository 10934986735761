import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import LabeledFrame from 'components/LabeledFrame';
import { getConfig, getAjaxUrl } from 'helpers/config';
import { readableSize, downloadFile } from 'helpers/generic';

const AttachmentsStyled = styled.div`
    input[type=file] {
        height: 0;
        visibility: hidden;
        position: absolute;
        z-index: -99;
    }

    position: relative;
`;

const Attachment = styled.div`
    display: inline-block;
    margin: 8px;
    background: var(--bs-gray-200);
    border: 1px solid var(--bs-gray-400);
    border-radius: 3px;
    padding: 2px 5px;
    position: relative;

    &.clickable {
        cursor: pointer;
    }

    button {
        position: absolute;
        top: -8px;
        right: -8px;
        background: red;
        color: white;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        font-size: 13px;
    }
`;

const DropFilesMessage = styled.div`
    padding: 20px;
    text-align: center;
`;

const DroppingMessage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
`;

let axiosCancelToken = null;

export default function Attachments(props) {
    const data = props.data;
    const setData = props.setData;

    const [queryParams] = useSearchParams();
    const attach_file_id = queryParams.get('attach_file_id') ?? null;

    const [droppingFiles, setDroppingFiles] = useState(false);

    let inputFileRef = useRef(null);

    useEffect(() => {
        axiosCancelToken = axios.CancelToken.source();

        if ( !attach_file_id ) return;

        axios.get('/api/manager/documents/get-file/' + attach_file_id, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
            let file = response.data;

            setData((prev) => ({
                ...prev, 
                attach_file_id: attach_file_id,
                attachments: [...(prev.attachments ?? []), {name: file.name}]
            }));
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
    }, [attach_file_id, setData]);

    const openFileInput = () => {
        inputFileRef.current.value = null;
        inputFileRef.current.click();
    }

    const inputFileChangeEvent = (e) => {
        let files = e.target.files;
        if ( !files.length ) return;

		let filesToUpload = [];
		for (let i=0; i<files.length; i++) {
			let file = files.item(i);
			filesToUpload.push(file);
		}

        addFilesToState(filesToUpload);
    }

    const addFilesToState = (files) => {
		let newFilesToUpload = data.filesToUpload ? [...data.filesToUpload] : [];

        const maxFileSize = getConfig().upload_max_filesize;

        // Check max filesize and delete files
        let filesMaxError = false;
        files.forEach((file) => {
            if ( file.size > maxFileSize ) {
                filesMaxError = true;
                return;
            }
            newFilesToUpload.push(file);
        });

        if ( filesMaxError ) toast.error('Algunos archivos superan el tamaño permitido y no se han añadido (Máximo: ' + readableSize(maxFileSize) + ')');

        setData((prev) => ({...prev, filesToUpload: newFilesToUpload}));
    }

    const removeFileToUpload = (idx) => {
        let newFilesToUpload = [...data.filesToUpload];
        newFilesToUpload.splice(idx, 1);
        setData((prev) => ({...prev, filesToUpload: newFilesToUpload}));
    }

    const removeAttachment = (idx) => {
        let remove_attachments = data.remove_attachments ? [...data.remove_attachments] : [];
        remove_attachments.push(idx);
        setData((prev) => ({...prev, remove_attachments: remove_attachments}));
    }

    const dragOverHandler = (e) => {
		e.preventDefault();

		setDroppingFiles(true);
	}

    const dropHandler = (e) => {
		e.preventDefault();

		setDroppingFiles(false);

		let filesToUpload = [];

		if (e.dataTransfer.items) {
		    // Usar la interfaz DataTransferItemList para acceder a el/los archivos)
		    for (let i = 0; i < e.dataTransfer.items.length; i++) {
		      	// Si los elementos arrastrados no son ficheros, rechazarlos
		      	if (e.dataTransfer.items[i].kind === 'file') {
		        	let file = e.dataTransfer.items[i].getAsFile();
		        	filesToUpload.push(file);
		      	}
		    }
	  	} else {
		    // Usar la interfaz DataTransfer para acceder a el/los archivos
		    for (let i = 0; i < e.dataTransfer.files.length; i++) {
		    	let file = e.dataTransfer.files[i];
		    	filesToUpload.push(file);
		    }
	  	}

	  	// Pasar el evento a removeDragData para limpiar
	  	removeDragData(e)

       addFilesToState(filesToUpload);
	}

	const removeDragData = (e) => {
		if (e.dataTransfer.items) { // Use DataTransferItemList interface to remove the drag data
		    e.dataTransfer.items.clear();
		} else { // Use DataTransfer interface to remove the drag data
			e.dataTransfer.clearData();
		}
	}

    const downloadAttachment = async (idx) => {
        downloadFile(getAjaxUrl() + 'api/manager/emails/download-attachment/' + data.id + '/' + idx + '?community_id=' + data.community_id);
    }

	return (
        <AttachmentsStyled
            onDrop={(e) => dropHandler(e)}
			onDragOver={(e) => dragOverHandler(e)}
        >
            <LabeledFrame 
                label="Adjuntos"
                buttons={
                    <button className="btn-unstyled text-white" onClick={openFileInput}><i className="bi bi-upload"></i>&nbsp;&nbsp;Nuevo</button>
                }
                buttonsBackground="primary"
            >
                {data.attachments && data.attachments.map((el, idx) => {
                    if ( data.remove_attachments?.includes(idx) ) return null;

                    return (
                        <Attachment key={idx} onClick={() => downloadAttachment(idx)} className="clickable">
                            <i className="bi bi-sd-card-fill text-primary"></i>
                            &nbsp;
                            {el.name}
                            <button onClick={() => removeAttachment(idx)}><i className="bi bi-x"></i></button>
                        </Attachment>
                    );
                })}

                {data.filesToUpload?.length > 0 && data.filesToUpload.map((item, idx) => {
                    return (
                        <Attachment key={idx}>
                            <i className="bi bi-upload"></i>
                            &nbsp;
                            {item.name}
                            <button onClick={() => removeFileToUpload(idx)}><i className="bi bi-x"></i></button>
                        </Attachment>
                    );
                })}

                { (!data.filesToUpload || data.filesToUpload.length === 0) && <DropFilesMessage>Arrastra aquí los archivos que quieras adjuntar</DropFilesMessage>}

                { droppingFiles && <DroppingMessage>Suelta aquí los archivos que quieres subir</DroppingMessage>}
            </LabeledFrame>
            <input type="file" ref={inputFileRef} className="hidden" onChange={inputFileChangeEvent} multiple />
        </AttachmentsStyled>
	);
}