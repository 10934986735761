import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import TreeRow from './TreeRow';
import FolderModal from './FolderModal';

const ModalStyled = styled.div`
	background: rgba(0, 0, 0, 0.4);
`;

let axiosCancelToken = null;

export default function CopyMoveModal(props) {
	const modalRef = useRef(null);

	const [folder, setFolder] = useState(null);
	const community = {
		id: props.file ? props.file.community?.id : props.community?.id,
		name: props.file ? props.file.community?.name : props.community?.name,
		folder_id: props.file ? props.file.community?.folder_id : props.community?.folder_id,
	};
	const [selectedFolderId, setSelectedFolderId] = useState(null);
	const [createFolderModal, setCreateFolderModal] = useState(false);
	const [forceReload, setForceReload] = useState(false);

	const files = props.files;
	const action = props.action;
	const closeCallback = props.closeCallback;

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const modal = new Modal(modalRef.current, {
			backdrop: false
		});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();
			closeCallback();
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

		modal.show();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [closeCallback]);

	const openCreateFolderModal = () => {
		setCreateFolderModal(true);
	}

	const doAction = async () => {
		let moveUrl = '/api/manager/documents/change-file-folder/';
		let copyurl = '/api/manager/documents/copy-file-folder/';

		let url = action === 'move' ? moveUrl : copyurl;

		for await (const file of files) {
			await axios.post(url + file.id, {
				folder_id: folder.id,
			}, {
				cancelToken: axiosCancelToken.token
			})
			.then((response) => {
			})
			.catch((error) => {
				if ( axios.isCancel(error) ) return true;
				toast.error('Ha ocurrido un error');
			});	
		}

		closeCallback(files.length > 0 ? true : false);
	}

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">{action === 'move' ? 'Mover' : 'Copiar'} documento</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<TreeRow 
							communityFolderTreeEnabled={true}
							folder={{
								id: 			-1,
								name: 			'Documentos',
								code: 			'documents',
								has_content: 	true
							}}
							openFolder={(data) => {
								setFolder(data);
								setSelectedFolderId(data?.id ?? null);
							}}
							selectedFolderId={selectedFolderId}
							community={community}
							level={0}
							reload={forceReload}
						/>
					</div>
					<div className="modal-footer justify-content-between">
						<button type="button" className="btn btn-sm btn-secondary text-white" disabled={!folder} onClick={() => openCreateFolderModal()}>Nueva carpeta</button>
						<button type="button" className="btn btn-sm btn-primary text-white" disabled={!folder} onClick={() => doAction()}>{action === 'move' ? 'Mover' : 'Guardar'}</button>
					</div>
				</div>
			</div>

			{createFolderModal &&
				<FolderModal 
					closeCallback={() => {
						setCreateFolderModal(false);
						setForceReload(Math.random());
					}}
					folder={{}}
					parentFolder={folder}
					community={community}
				/>
			}
		</ModalStyled>
	);
}


