import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { jsonToArray } from 'helpers/generic';

const ModalStyled = styled.div`
	background: rgba(0, 0, 0, 0.4);
`;

export default function HideShowModal(props) {
	const modalRef = useRef(null);

    let closeCallback = props.closeCallback;
    let type = props.type;
    let fields = props.fields;

	const [selectedFields, setSelectedFields] = useState([]);

    useEffect(() => {
		const modal = new Modal(modalRef.current, {
			backdrop: false
		});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();

            closeCallback();

            // Fix, because bootstrap removes scroll and add padding on modal opened
            document.body.style.overflow = 'auto'; 
            document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);
		modal.show();
	}, [closeCallback]);

    useEffect(() => {
        if ( !selectedFields.length ) return;

        saveToLocalStorage(type, selectedFields);
	}, [type, selectedFields]);

    useEffect(() => {
        let selectedFields = getFromLocalStorage(type, fields);
		setSelectedFields(selectedFields);
	}, [type, fields]);

    const toggleSelectedField = (key, status) => {
		let newSelectedFields = [...selectedFields];

		if ( status ) {
			newSelectedFields.push(key);
		} else {
			newSelectedFields.splice(newSelectedFields.indexOf(key), 1);
		}

		setSelectedFields(newSelectedFields);
	}

    const hideModal = () => {
		Modal.getInstance(modalRef.current).hide();
    }

	return (
        <ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Mostrar u ocultar detalles</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
                        {jsonToArray(fields).map((el, idx) => {
                            let checked = selectedFields ? selectedFields.includes(el.key) : false;
                            return (
                                <label key={idx} className="badge bg-light text-dark fw-normal d-inline-flex align-items-center m-2 user-select-none border">
									<input type="checkbox" className="me-1" checked={checked} onChange={(e) => selectedFields.length <= 1 && checked ? null : toggleSelectedField(el.key, e.target.checked)} />
									{el.value}
								</label>
                            )
                        })}
					</div>
					<div className="modal-footer">
						<button className="btn btn-sm btn-primary text-white" onClick={() => hideModal()}>Aceptar</button>
					</div>
				</div>
			</div>
		</ModalStyled>
	);
}

export const getFromLocalStorage = (type, defaultFields) => {
    let localStorage = window.localStorage;
    let selectedFieldsStored = localStorage.getItem('balance_hideshowfields-' + type);
    try {
        selectedFieldsStored = JSON.parse(selectedFieldsStored);
    } catch(e) {
        selectedFieldsStored = [];
    }
    // console.log('read', type, selectedFieldsStored)
    if ( !selectedFieldsStored && defaultFields ) selectedFieldsStored = jsonToArray(defaultFields).map(el => el.key); // Default all selected
    return selectedFieldsStored ?? [];
}

export const saveToLocalStorage = (type, selectedFields) => {
    let localStorage = window.localStorage;
    // console.log('save', type, selectedFields)
    // localStorage.setItem('balance_hideshowfields-' + type, '');
    localStorage.setItem('balance_hideshowfields-' + type, JSON.stringify(selectedFields));
}