import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Sortable from 'sortablejs';
import LabeledFrame from 'components/LabeledFrame';
import TitleModal from './TitleModal';
import GroupModal from './GroupModal';
import ExpenseModal from './ExpenseModal';
import { formatNumber } from 'helpers/generic';

const Table = styled.div`
	font-size: 13px;

	.sortable-container {
		padding: 0;
	}

	.tr {
		display: flex;


		&:has(.tr-columns-wrapper) {
			flex-direction: column;
		}

		.tr-columns-wrapper {
			display: flex;
			padding: 0;
		}

		.td, .th {
			display: inline-flex;
			padding: 3px;

			&:nth-child(1) {
				width: 75%;
				min-width: 400px;
				max-width: 75%;
			}

			&:nth-child(2) {
				width: 10%;
				min-width: 100px;
				max-width: 10%;

				justify-content: center;
			}

			&:nth-child(3) {
				width: 10%;
				min-width: 120px;
				max-width: 10%;

				&.th {
					justify-content: space-between;
				}

				&.td {
					justify-content: end;
				}
			}

			&:nth-child(4) {
				width: 5%;
				min-width: 60px;
				max-width: 5%;
				justify-content: center;
			}
		}
	}

	.thead {
		.tr {
			.th {
				font-weight: 500;
				vertical-align: middle;

				border-top: 1px solid var(--bs-gray-300);
				border-left: 1px solid var(--bs-gray-300);
				border-bottom: 2px solid var(--bs-primary);

				&:last-of-type {
					border-right: 1px solid var(--bs-gray-300);
				}

				&:nth-child(1) {
					display: flex;
					justify-content: space-between;

					button {
						font-size: 10px;
						padding: 2px 4px;

						/* @media (max-width: 768px) {
							& {
								position: inherit;
								margin: 0 auto;
								display: block;
							}
						} */
					}
				}
			}
		}
	}

	.tbody {
		.tr {
			&.group {
				font-weight: 500;

				& > .tr-columns-wrapper {

					.td {
						background: var(--bs-gray-400);
					}
				}

				.td {
					border-top: 1px solid var(--bs-gray-300);
					border-left: 1px solid var(--bs-gray-300);

					&:last-of-type {
						border-right: 1px solid var(--bs-gray-300);
					}

					&:nth-child(1) {
						position: relative;

						button {
							position: absolute;
							right: 3px;
							font-size: 10px;
							padding: 2px 4px;

							/* @media (max-width: 768px) {
								& {
									position: inherit;
									margin: 0 auto;
									display: block;
								}
							} */
						}
					}

					&:nth-child(3) {
						font-weight: 500;
					}
				}
			}

			&.title {
				& > .tr-columns-wrapper {
					.td {
						background: var(--bs-gray-200);
					}
				}

				.td {
					&:nth-child(1) {
						padding-left: 30px;
						position: relative;

						button {
							position: absolute;
							right: 3px;
							font-size: 10px;
							padding: 2px 4px;

							/* @media (max-width: 768px) {
								& {
									position: inherit;
									margin: 0 auto;
									display: block;
								}
							} */
						}
					}

					&:nth-child(3) {
						font-weight: 500;
					}
				}
			}

			&.expense {
				.td {
					&:nth-child(1) {
						padding-left: 60px;

						/* @media (max-width: 768px) {
							& {
								padding-left: 15px;
							}
						} */
					}
				}
			}

			.td {
				vertical-align: middle;

				.number {
					cursor: move;
					cursor: -webkit-grabbing;
				}

				&:nth-child(2) {
					text-align: center;
				}

				&:nth-child(3) {
					text-align: right;
				}

				&:nth-child(4) {
					text-align: center;

					i {
						font-size: 15px;
					}	
				}
			}
		}
	}

	.tfoot {
		.tr {
			.td {
				border-top: 2px solid var(--bs-primary);
				border-bottom: 1px solid var(--bs-gray-300);
				border-left: 1px solid var(--bs-gray-300);

				&:last-of-type {
					border-right: 1px solid var(--bs-gray-300);
				}

				&:nth-child(3) {
					text-align: right;
					font-weight: 600;
				}
			}
		}
	}
`;

const AmountPercentChanger = styled.div`
	background: white !important;
	color: #333 !important;

	label {
		font-size: 13px;
		line-height: 13px;
		text-align: center;
		display: block;
		margin-bottom: 10px;
	}

	input[type=number] {
		margin-bottom: 10px;
		text-align: center;
	}

	button {
		display: block;
		margin: 0 auto;
		padding: 2px 10px;
	}
`;

let axiosCancelToken = null;

let sortableInstances = [];

export default function GroupsExpenses(props) {
	const groups = props.groups;
	const _setGroups = props.setGroups;
	const errors = props.errors;
	const receiptsTotal = props.receiptsTotal ?? 0;

	const setGroups = (data) => {
		_setGroups(data);
		groupsRef.current = data;
	}

	let groupsRef = useRef([]);
	if ( groupsRef.current.length === 0 && groups.length > 0 ) groupsRef.current = groups;

	let previousBankTotal = props.previousBankTotal ?? 0;
	previousBankTotal = parseFloat(previousBankTotal);
	if ( isNaN(previousBankTotal) ) previousBankTotal = 0;

	const amountPercentChangerDropdownRef = useRef(null);

	let [amountPercentChange, setAmountPercentChange] = useState(null);
 
	let [groupModal, setGroupModal] = useState({
		visible: false,
		idx: null,
		group: null
	});

	let [titleModal, setTitleModal] = useState({
		groupIdx: null,

		visible: false,
		idx: null,
		title: null
	});

	let [expenseModal, setExpenseModal] = useState({
		group: null,
		groupIdx: null,

		title: null,
		titleIdx: null,

		visible: false,
		idx: null,
		expense: null
	});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( !groups.length || sortableInstances.length ) return;

		let containers = document.querySelectorAll('.sortable-container');

		containers.forEach((el) => {
			let sortable = Sortable.create(el, {
				handle: '.number',
				animation: 150,
				direction: 'vertical',
				onEnd: (e) => {
					let item = e.item;

					let explode = item.id.split('-');
					let type = explode[0];
					let groupIdx = explode[1];
					let titleIdx = explode[2];
					let expenseIdx = explode[3];

					let newData = [...groupsRef.current];

					if ( type === 'group' ) {
						let moved = newData.splice(groupIdx, 1)[0];
						newData.splice(e.newIndex, 0, moved);
						newData.forEach((el, idx) => {
							el.number = idx + 1;
						});
					}

					if ( type === 'title' ) {
						let titles = newData[groupIdx].titles;
						let moved = titles.splice(titleIdx, 1)[0];

						titles.splice(e.newIndex, 0, moved);
						titles.forEach((el, idx) => {
							el.number = idx + 1;
						});

						newData[groupIdx].titles = titles;
					}

					if ( type === 'expense' ) {
						let expenses = newData[groupIdx].titles[titleIdx].expenses;
						let moved = expenses.splice(expenseIdx, 1)[0];

						expenses.splice(e.newIndex, 0, moved);
						expenses.forEach((el, idx) => {
							el.number = idx + 1;
						});

						newData[groupIdx].titles[titleIdx].expenses = expenses;
					}

					setGroups(newData);
				}
			});
			sortableInstances.push(sortable);
		});

		return () => {
			sortableInstances.forEach((sortable) => {
				sortable.destroy();
			});
			sortableInstances = [];
		}
	}, [groups]);


	const setGroupData = (idx, data) => {
		let newGroups = [...groups];
		if ( idx !== null ) {
			newGroups[idx] = {...data};
		} else {
			newGroups.push({...data});
		}
		setGroups(newGroups);
	}

	const deleteGroup = (idx) => {
		const c = window.confirm('¿Seguro que quieres eliminar este grupo?');
		if ( !c ) return;

		// Delete
		let newGroups = [...groups];
		newGroups.splice(idx, 1);
		setGroups(newGroups);
	}

	const setTitleData = (groupIdx, idx, data) => {
		let newGroups = [...groups];
		let group = newGroups[groupIdx]; // Use reference

		let titles = group.titles ? [...group.titles] : [];

		if ( idx !== null ) {
			titles[idx] = {...data};
		} else {
			titles.push({...data});
		}
		newGroups[groupIdx].titles = [...titles];

		setGroups([...newGroups]);
	}

	const deleteTitle = (groupIdx, idx) => {
		const c = window.confirm('¿Seguro que quieres eliminar esta familia?');
		if ( !c ) return;

		// Delete
		let newGroups = [...groups];
		newGroups[groupIdx].titles.splice(idx, 1);
		setGroups(newGroups);
	}

	const setExpenseData = (groupIdx, titleIdx, idx, data) => {
		let newGroups = [...groups];
		let title = newGroups[groupIdx].titles[titleIdx]; // Use reference

		let expenses = title.expenses ? [...title.expenses] : [];
		if ( idx !== null ) {
			expenses[idx] = {...data};
		} else {
			expenses.push({...data});
		}
		title.expenses = [...expenses];

		setGroups([...newGroups]);
	}

	const deleteExpense = (groupIdx, titleIdx, idx) => {
		const c = window.confirm('¿Seguro que quieres eliminar este gasto?');
		if ( !c ) return;

		// Delete
		let newGroups = [...groups];
		newGroups[groupIdx].titles[titleIdx].expenses.splice(idx, 1);
		setGroups(newGroups);
	}

	const applyAmountPercent = () => {
		let newGroups = [...groups];

		// Update using reference
		newGroups.forEach(group => {
			group.titles.forEach(title => {
				title.expenses.forEach(expense => {
					expense.amount += expense.amount * (amountPercentChange / 100);
				});
			});
		});

		setGroups([...newGroups]);
		setAmountPercentChange(null);

		amountPercentChangerDropdownRef.current.querySelectorAll('.show').forEach(el => el.classList.remove('show'));
	}

	let estimateTotal = 0;
	let groupsSorted = groups.sort((a, b) => {
		if (a.number < b.number) return -1;
		if (a.number > b.number) return 1;
		return 0;
	});

	return (
		<React.Fragment>
			<LabeledFrame 
				label="Grupos de reparto y gastos"
				buttonsBackground="primary"
			>
				<div className="table-responsive">
				<Table className="mb-0">
					<div className="thead">
						<div className="tr">
							<div className="th">
								<span>Saldo inicial:&nbsp;{formatNumber(previousBankTotal, 2)} €</span>
								<button 
									className="btn btn-sm btn-outline-primary" 
									onClick={() => setGroupModal({visible: true, idx: null, group: null})}
								>
									<i className="bi bi-plus-circle-fill"></i> grupo
								</button>
							</div>
							<div className="th">Reparto</div>
							<div className="th">
								<div className="dropdown d-inline-block float-start" ref={amountPercentChangerDropdownRef}>
									<button className="btn btn-sm btn-link p-0 m-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
										<i className="bi bi-arrow-down-up"></i>
									</button>
									<ul className="dropdown-menu">
										<li>
											<AmountPercentChanger className="dropdown-item" onClick={(e) => e.stopPropagation()}>
												<label>Introduce el % de<br />subida o bajada</label>
												<input type="number" className="form-control form-control-sm no-arrows" onChange={(e) => setAmountPercentChange(e.target.value)} value={amountPercentChange ?? ''} />
												<button className="btn btn-sm btn-primary text-white" onClick={(e) => applyAmountPercent()}>aplicar</button>
											</AmountPercentChanger>
										</li>
									</ul>
								</div>
								Importe
							</div>
							<div className="th"></div>
						</div>
					</div>
					<div className="sortable-container tbody" id="sortable_wrapper">
						{groupsSorted.map((el, idx) => {
							// Calc totals
							let groupTotalAmount = 0;
							el.titles?.forEach(title => {
								title?.expenses?.forEach(expense => {
									groupTotalAmount += expense.amount;
								});
							});
							estimateTotal += groupTotalAmount;

							// Get errors
							let groupErrors = [];
							for(let errIdx in errors) {
								if ( errIdx.indexOf('groups.' + idx + '.') !== -1 && errIdx.indexOf('titles.') === -1 && errIdx.indexOf('.expenses') === -1 ) {
									groupErrors.push(errors[errIdx]);
								}
							}

							// Prepare titles sorted
							let titlesSorted = el.titles?.sort((a, b) => {
								if (a.number < b.number) return -1;
								if (a.number > b.number) return 1;
								return 0;
							});

							return (
								<div className="tr group" key={'group-'+el.id} id={'group-'+idx}>
									<div className="tr-columns-wrapper">
										<div className="td">
											<button 
												className="btn btn-sm btn-outline-green" 
												onClick={() => setTitleModal({groupIdx: idx, visible: true, idx: null, title: null})}
											>
												<i className="bi bi-plus-circle"></i> familia
											</button>
											<div className="d-flex align-items-center">
												<span className="number badge bg-light text-secondary border-1 me-1">{el.number}</span>
												{el.denomination}
											</div>
											{groupErrors.length > 0 && 
												<div className="invalid-feedback d-block">
													{groupErrors.map((errEl, errIdx) => <div key={errIdx}>{errEl}</div>)}
												</div>
											}
										</div>
										<div className="td">
											{el.distribution_type === 'coefficient' && <span>Coeficiente</span>}
											{el.distribution_type === 'equal' && <span>Partes iguales</span>}
											{el.distribution_type === 'consumption' && <span>Consumos</span>}
										</div>
										<div className="td">{formatNumber(groupTotalAmount, 2)} €</div>
										<div className="td">
											<button className="btn btn-sm btn-link text-primary p-0 m-0 px-1" onClick={() => setGroupModal({visible: true, idx: idx, group: el})}><i className="bi bi-pencil"></i></button>
											<button className={'btn btn-sm btn-link text-danger p-0 m-0 px-1 ' + (el.titles?.length > 0 ? 'disabled' : '')} onClick={() => deleteGroup(idx)}><i className="bi bi-trash"></i></button>
										</div>
									</div>
									<div className="sortable-container sortable-title-container">
										{titlesSorted?.map((tEl, tIdx) => {
											// Calc totals
											let titleTotalAmount = 0;
											tEl.expenses?.forEach((etEl) => titleTotalAmount += etEl.amount);

											// Prepare title errors
											let titleErrors = [];
											for(let errIdx in errors) {
												if ( errIdx.indexOf('groups.' + idx + '.titles.' + tIdx + '.') !== -1 && errIdx.indexOf('.expenses') === -1 ) {
													titleErrors.push(errors[errIdx]);
												}
											}

											// Sort expenses
											let expensesSorted = tEl.expenses?.sort((a, b) => {
												if (a.number < b.number) return -1;
												if (a.number > b.number) return 1;
												return 0;
											});

											return (
												<div className="title tr" key={'title-'+el.id+'-'+tEl.id} id={'title-'+idx+'-'+tIdx}>
													<div className="tr-columns-wrapper">
														<div className="td">
															<button 
																className="btn btn-sm btn-outline-orange" 
																onClick={() => setExpenseModal({visible: true, groupIdx: idx, group: el, titleIdx: tIdx, title: tEl, idx: null, expense: null})}
															>
																<i className="bi bi-plus-circle"></i> gasto
															</button>
															<div className="d-flex align-items-center">
																<span className="number badge bg-light text-secondary border-1 me-1">{tEl.number}</span>
																{tEl.denomination}
															</div>
															{titleErrors.length > 0 && 
																<div className="invalid-feedback d-block">
																	{titleErrors.map((errEl, errIdx) => <div key={errIdx}>{errEl}</div>)}
																</div>
															}
														</div>
														<div className="td"></div>
														<div className="td">{formatNumber(titleTotalAmount, 2)} €</div>
														<div className="td">
															<button className="btn btn-sm btn-link text-primary p-0 m-0 px-1" onClick={() => setTitleModal({visible: true, idx: tIdx, title: tEl, groupIdx: idx})}><i className="bi bi-pencil"></i></button>
															<button className={'btn btn-sm btn-link text-danger p-0 m-0 px-1 ' + (tEl.expenses?.length > 0 ? 'disabled' : '')} onClick={() => deleteTitle(idx, tIdx)}><i className="bi bi-trash"></i></button>
														</div>
													</div>
													<div className="sortable-container sortable-expense-container">
														{expensesSorted?.map((eEl, eIdx) => {
															let expenseErrors = [];
															for(let errIdx in errors) {
																if ( errIdx.indexOf('groups.' + idx + '.titles.' + tIdx + '.expenses.' + eIdx) !== -1 ) {
																	expenseErrors.push(errors[errIdx]);
																}
															}

															return (
																<div className="tr expense" key={'expense-'+el.id+'-'+tEl.id+'-'+eEl.id} id={'expense-'+idx+'-'+tIdx+'-'+eIdx}>
																	<div className="td">
																		<div className="d-flex w-100 align-items-center justify-content-between">
																			<div>
																				<span className="number badge bg-light text-secondary border-1 me-1">{eEl.number}</span>
																				{eEl.denomination}
																			</div>

																			<small className="badge bg-light text-secondary mr-auto d-flex align-items-center fw-normal">{eEl.quotaconcept?.name}</small>
																		</div>
																		{expenseErrors.length > 0 && 
																			<div className="invalid-feedback d-block">
																				{expenseErrors.map((errEl, errIdx) => <div key={errIdx}>{errEl}</div>)}
																			</div>
																		}
																	</div>
																	<div className="td"></div>
																	<div className="td">{eEl.amount ? formatNumber(eEl.amount, 2) : 0} €</div>
																	<div className="td">
																		<button className="btn btn-sm btn-link text-primary p-0 m-0 px-1" onClick={() => setExpenseModal({visible: true, groupIdx: idx, group: el, titleIdx: tIdx, title: tEl, idx: eIdx, expense: eEl})}><i className="bi bi-pencil"></i></button>
																		<button className="btn btn-sm btn-link text-danger p-0 m-0 px-1" onClick={() => deleteExpense(idx, tIdx, eIdx)} disabled={!eEl.is_deletable}><i className="bi bi-trash"></i></button>
																	</div>
																</div>
															);
														})}
													</div>
												</div>
											);
										})}
									</div>
								</div>
							);
						})}

						{/* {groupsSorted.map((el, idx) => {
							let trs = [];

							// ---- Group Tr -------------------------------------------------------------------------
								let groupTotalAmount = 0;
								el.titles?.forEach(title => {
									title?.expenses?.forEach(expense => {
										groupTotalAmount += expense.amount;
									});
								});
								estimateTotal += groupTotalAmount;

								// Get errors
								let groupErrors = [];
								for(let errIdx in errors) {
									if ( errIdx.indexOf('groups.' + idx + '.') !== -1 && errIdx.indexOf('titles.') === -1 && errIdx.indexOf('.expenses') === -1 ) {
										groupErrors.push(errors[errIdx]);
									}
								}

								trs.push(
									<div className="sortable-group-container">
										<div className="tr" key={'group-'+idx} id={'group-'+idx}>
											<div className="td">
												<button 
													className="btn btn-sm btn-outline-green" 
													onClick={() => setTitleModal({groupIdx: idx, visible: true, idx: null, title: null})}
												>
													<i className="bi bi-plus-circle"></i> familia
												</button>
												<div>
													<span className="number badge bg-light text-secondary border-1 me-1">{el.number}</span>
													{el.denomination}
												</div>
												{groupErrors.length > 0 && 
													<div className="invalid-feedback d-block">
														{groupErrors.map((errEl, errIdx) => <div key={errIdx}>{errEl}</div>)}
													</div>
												}
											</div>
											<div className="td">
												{el.distribution_type === 'coefficient' && <span>Coeficiente</span>}
												{el.distribution_type === 'equal' && <span>Partes iguales</span>}
												{el.distribution_type === 'consumption' && <span>Consumos</span>}
											</div>
											<div className="td">{formatNumber(groupTotalAmount, 2)} €</div>
											<div className="td">
												<button className="btn btn-sm btn-link text-primary p-0 m-0 px-1" onClick={() => setGroupModal({visible: true, idx: idx, group: el})}><i className="bi bi-pencil"></i></button>
												<button className={'btn btn-sm btn-link text-danger p-0 m-0 px-1 ' + (el.titles?.length > 0 ? 'disabled' : '')} onClick={() => deleteGroup(idx)}><i className="bi bi-trash"></i></button>
											</div>
										</div>
										<div className="sortable-title-container">
											
										</div>
									</div>
								);
							//  --------------------------------------------------------------------------------------

							// ---- Titles Trs -----------------------------------------------------------------------
								let titlesSorted = el.titles?.sort((a, b) => {
									if (a.number < b.number) return -1;
									if (a.number > b.number) return 1;
									return 0;
								});
							
								titlesSorted?.map((tEl, tIdx) => {
									// Title Tr
									let titleTotalAmount = 0;
									tEl.expenses?.forEach((etEl) => titleTotalAmount += etEl.amount);
									
									// Get errors
									let titleErrors = [];
									for(let errIdx in errors) {
										if ( errIdx.indexOf('groups.' + idx + '.titles.' + tIdx + '.') !== -1 && errIdx.indexOf('.expenses') === -1 ) {
											titleErrors.push(errors[errIdx]);
										}
									}

									trs.push(
										<div className="title tr" key={'title-'+idx+'-'+tIdx} id={'title-'+idx+'-'+tIdx}>
											<div className="td">
												<button 
													className="btn btn-sm btn-outline-orange" 
													onClick={() => setExpenseModal({visible: true, groupIdx: idx, group: el, titleIdx: tIdx, title: tEl, idx: null, expense: null})}
												>
													<i className="bi bi-plus-circle"></i> gasto
												</button>
												<div>
													<span className="number badge bg-light text-secondary border-1 me-1">{tEl.number}</span>
													{tEl.denomination}
												</div>
												{titleErrors.length > 0 && 
													<div className="invalid-feedback d-block">
														{titleErrors.map((errEl, errIdx) => <div key={errIdx}>{errEl}</div>)}
													</div>
												}
											</div>
											<div className="td"></div>
											<div className="td">{formatNumber(titleTotalAmount, 2)} €</div>
											<div className="td">
												<button className="btn btn-sm btn-link text-primary p-0 m-0 px-1" onClick={() => setTitleModal({visible: true, idx: tIdx, title: tEl, groupIdx: idx})}><i className="bi bi-pencil"></i></button>
												<button className={'btn btn-sm btn-link text-danger p-0 m-0 px-1 ' + (tEl.expenses?.length > 0 ? 'disabled' : '')} onClick={() => deleteTitle(idx, tIdx)}><i className="bi bi-trash"></i></button>
											</div>
										</div>
									);


									// ---- Expenses trs ---------------------------------------------------------------------
										let expensesSorted = tEl.expenses?.sort((a, b) => {
											if (a.number < b.number) return -1;
											if (a.number > b.number) return 1;
											return 0;
										});
										expensesSorted?.forEach((eEl, eIdx) => {
											// Get errors
											let expenseErrors = [];
											for(let errIdx in errors) {
												if ( errIdx.indexOf('groups.' + idx + '.titles.' + tIdx + '.expenses.' + eIdx) !== -1 ) {
													expenseErrors.push(errors[errIdx]);
												}
											}

											trs.push(
												<div className="tr expense" key={'expense-'+idx+'-'+tIdx+'-'+eIdx} id={'expense-'+idx+'-'+tIdx+'-'+eIdx}>
													<div className="td">
														<div className="d-block d-md-flex justify-content-between">
															<div>
																<span className="number badge bg-light text-secondary border-1 me-1">{eEl.number}</span>
																{eEl.denomination}
															</div>

															<small className="badge bg-light text-secondary mr-auto d-flex align-items-center fw-normal">{eEl.quotaconcept?.name}</small>
														</div>
														{expenseErrors.length > 0 && 
															<div className="invalid-feedback d-block">
																{expenseErrors.map((errEl, errIdx) => <div key={errIdx}>{errEl}</div>)}
															</div>
														}
													</div>
													<div className="td"></div>
													<div className="td">{eEl.amount ? formatNumber(eEl.amount, 2) : 0} €</div>
													<div className="td">
														<button className="btn btn-sm btn-link text-primary p-0 m-0 px-1" onClick={() => setExpenseModal({visible: true, groupIdx: idx, group: el, titleIdx: tIdx, title: tEl, idx: eIdx, expense: eEl})}><i className="bi bi-pencil"></i></button>
														<button className="btn btn-sm btn-link text-danger p-0 m-0 px-1" onClick={() => deleteExpense(idx, tIdx, eIdx)} disabled={!eEl.is_deletable}><i className="bi bi-trash"></i></button>
													</div>
												</div>
											);
										});
									//  --------------------------------------------------------------------------------------

								});
							//  --------------------------------------------------------------------------------------

							return trs;
						})} */}
						{ !groups.length &&
							<div className="tr">
								<div className="td" colSpan="100%">Todavía no has añadido información</div>
							</div>
						}
					</div>
					<div className="tfoot">
						<div className="tr">
							<div className="td">
								Saldo final:&nbsp;<b>{formatNumber(previousBankTotal + parseFloat(receiptsTotal ?? 0) - parseFloat(estimateTotal ?? 0), 2)} €</b>
							</div>
							<div className="td"></div>
							<div className="td">{formatNumber(estimateTotal, 2)} €</div>
							<div className="td"></div>
						</div>
					</div>
				</Table>
				</div>
			</LabeledFrame>
			{ groupModal.visible && 
				<GroupModal 
					idx={groupModal.idx}
					group={groupModal.group} 
					groupsCount={groups.length ?? 0}
					setGroup={(idx, data) => setGroupData(idx, data)}
					closeCallback={() => {
						setGroupModal({
							visible: false,
							idx: null,
							group: null
						});
					}}
				/>
			}
			{ titleModal.visible && 
				<TitleModal 
					idx={titleModal.idx}
					groupIdx={titleModal.groupIdx}
					title={titleModal.title} 
					titlesCount={groups[titleModal.groupIdx]?.titles?.length ?? 0}
					setTitle={(groupIdx, idx, data) => setTitleData(groupIdx, idx, data)}
					closeCallback={() => {
						setTitleModal({
							group: null,
							groupIdx: null,

							visible: false,
							idx: null,
							title: null
						});
					}}
				/>
			}
			{ expenseModal.visible && 
				<ExpenseModal 
					title={expenseModal.title}
					titleIdx={expenseModal.titleIdx}
					group={expenseModal.group} 
					groupIdx={expenseModal.groupIdx}
					idx={expenseModal.idx}
					expense={expenseModal.expense} 
					expensesCount={groups[expenseModal.groupIdx]?.titles[expenseModal.titleIdx]?.expenses?.length ?? 0}
					setExpense={(groupIdx, titleIdx, idx, data) => setExpenseData(groupIdx, titleIdx, idx, data)}
					closeCallback={() => {
						setExpenseModal({
							group: null,
							groupIdx: null,

							title: null,
							titleIdx: null,

							visible: false,
							idx: null,
							expense: null
						});
					}}
				/>
			}
		</React.Fragment>
	);
}