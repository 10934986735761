import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import styled from 'styled-components';
import randomatic from 'randomatic';
import { NavLink } from 'react-router-dom';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { CristalLoader, openPopupWindow } from "helpers/generic";
import { getCommunity } from 'helpers/community';
import { toast } from 'react-toastify';

const ModalStyled = styled.div`
	background: rgba(0, 0, 0, 0.4);
`;

let axiosCancelToken = null;

export default function NewPropertyModal(props) {
	const modalRef = useRef(null);

	const closeCallback = props.closeCallback;

	let community = getCommunity();

	let [data, setData] = useState({
		code: null,
		coefficient: 0.00000,
		street: {
			id: null,
			name: null
		},
		building: {
			id: null,
			name: null
		},
		type: {
			id: null,
			name: null
		}
	});
	let [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const modal = new Modal(modalRef.current, {
			backdrop: false
		});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();
			closeCallback();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

		modal.show();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [closeCallback]);

	useEffect(() => {
		axios.get('/api/manager/properties/get-last-number', {
			params: {
				community_id: community?.id
			},
			cancelToken: axiosCancelToken?.token
		}).then((response) => {
			setData((data) => ({...data, number: response.data.number, code: getCommunity()?.name_short + (""+response.data.number).padStart(3, '0')}));
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}, [community?.id]);

	useEffect(() => {
		const generateName = async () => {
			await axios.get('/api/manager/properties/generate-name', {
				params: {
					community_id: community?.id,
					street_id: data.street?.id,
					building_id: data.building?.id,
					stairs: data.stairs,
					plant: data.plant,
					door: data.door
				},
				cancelToken: axiosCancelToken?.token
			}).then((response) => {
				setData((data) => ({...data, name: response.data}));
			}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		generateName();
	}, [community?.id, data.street, data.building, data.stairs, data.plant, data.door]);

	const loadStreets = (input, callback) => {
		axios.get('/api/manager/communities/get/' + community?.id, {
			params: {
				community_id: community?.id
			},
			cancelToken: axiosCancelToken?.token
		}).then((response) => {
			let formatted = response.data.streets.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const loadBuildings = (input, callback) => {
		axios.get('/api/manager/buildings/list', {
			params: {
				search: input,
				no_paginate: true,
				community_id: community?.id
			},
			cancelToken: axiosCancelToken?.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const selectBuilding = (building) => {
		setData({
			...data,
			building: {
				id: building?.id, 
				name: building?.name
			},
			building_id: building?.id
		});
	}

	const selectStreet = (street) => {
		setData({
			...data,
			street: {
				id: street?.id, 
				name: street?.name
			},
			street_id: street?.id
		});
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	const openPopupWindowMouseDownFix = (e) => {
		e.preventDefault();
		e.stopPropagation();
		document.activeElement.blur();
	}


	const saveData = async () => {
		setLoading(true);
		setErrors({});

		data.community_id = community?.id;
		data.coefficient_cadastral = data.coefficient;

		await axios.post('/api/manager/properties/' + (data.id ? 'edit/' + data.id : 'add'), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			Modal.getInstance(modalRef.current).hide();
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	

		setLoading(false);
	}

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Añadir nueva propiedad</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<div className="row">
							<div className="col-md-3">
								<div className="mb-2">
									<label>Código</label>
									<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('code', e.target.value)} value={data.code ?? ''} />
									{ errors.code &&
										<div className="invalid-feedback d-block">{ errors.code[0] }</div>
									}
								</div>
							</div>
							<div className="col-md-3">
								<div className="mb-2">
									<label>Nº Dep.</label>
									<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('number', e.target.value)} value={data.number ?? ''} />
									{ errors.number &&
										<div className="invalid-feedback d-block">{ errors.number[0] }</div>
									}
								</div>
							</div>
							<div className="col-md-3">
								<div className="mb-2">
									<label>Coeficiente</label>
									<input type="number" step="0.00001" className="form-control form-control-sm text-end no-arrows" onChange={(e) => setDataField('coefficient', e.target.value)} value={data.coefficient ?? ''} />
									{ errors.coefficient &&
										<div className="invalid-feedback d-block">{ errors.coefficient[0] }</div>
									}
								</div>
							</div>
							<div className="col-md-3">
								<div className="mb-2">
									<div>
										<label className="me-2">Calle</label>
									</div>
									<EmpoweredSelector
										load={loadStreets}
										onChange={(value) => selectStreet(value)}
										timeout={250}
										label={
											<div>
												{data.street?.name ?? ''}&nbsp;
											</div>
										}
										placeholder="Seleccionar"
										value={data.street?.id}
										noSearcheable={true}
									/>
									{ errors.street_id &&
										<div className="invalid-feedback d-block">{ errors.street_id[0] }</div>
									}
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-2">
									<div>
										<label className="me-2">Bloque/Portal</label>
										<NavLink className="text-decoration-none text-reset text-primary float-end" to={'/buildings/add?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><span className="text-primary" style={{fontSize: '11px'}}><i className="bi bi-plus-circle-fill"></i> Nuevo</span></NavLink>
									</div>
									<EmpoweredSelector
										load={loadBuildings}
										onChange={(value) => selectBuilding(value)}
										timeout={250}
										label={
											<div>
												{data.building?.name ?? ''}&nbsp;
												{data.building?.id &&
													<NavLink className="text-decoration-none text-reset" to={'/buildings/edit/' + data.building.id + '?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
												}
											</div>
										}
										placeholder="Seleccionar"
										value={data.building?.id}
									/>
									{ errors.building_id &&
										<div className="invalid-feedback d-block">{ errors.building_id[0] }</div>
									}
								</div>
							</div>
							<div className="col-md-3">
								<div className="mb-2">
									<label>Escaleras</label>
									<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('stairs', e.target.value)} value={data.stairs ?? ''} />
									{ errors.stairs &&
										<div className="invalid-feedback d-block">{ errors.stairs[0] }</div>
									}
								</div>
							</div>

							<div className="col-md-3">
								<div className="mb-2">
									<label>Planta</label>
									<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('plant', e.target.value)} value={data.plant ?? ''} />
									{ errors.plant &&
										<div className="invalid-feedback d-block">{ errors.plant[0] }</div>
									}
								</div>
							</div>

							<div className="col-md-3">
								<div className="mb-2">
									<label>Puerta</label>
									<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('door', e.target.value)} value={data.door ?? ''} />
									{ errors.door &&
										<div className="invalid-feedback d-block">{ errors.door[0] }</div>
									}
								</div>
							</div>

							<div className="col-md-12">
								<div className="mb-2">
									<label>Dirección</label>
									<input type="text" className="form-control form-control-sm" value={data.name ? data.name : ''} readOnly />
									{ errors.name &&
										<div className="invalid-feedback d-block">{ errors.name[0] }</div>
									}
								</div>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className={'btn btn-sm btn-primary text-white'} onClick={() => saveData()}>Guardar</button>
					</div>
				</div>
			</div>
			{ loading && <CristalLoader /> }
		</ModalStyled>
	);
}


