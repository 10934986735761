import React, { useEffect, useMemo } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import LabeledFrame from 'components/LabeledFrame';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { openPopupWindow } from 'helpers/generic';
import { getRouterBasename } from 'helpers/config';

const SectorsWrapper = styled.div`
	.empowered-selector {

		.empowered-selector_label {
			background: none;
			border: none;
			font-size: 11px;
			padding: 1px !important;
			min-height: auto !important;
			margin: 0;
			color: var(--bs-primary);

			.dropdown-toggle-icon {
				display: none;
			}
		}

		.dropdown-menu {
			right: -11px;
			border-top: 1px solid var(--bs-gray-300);
		}
	}
`;

const SectorsContainer = styled.div`

`;

const Sector = styled.div`
	position: relative;
	display: inline-block;
	margin: 2px 5px;
	padding: 1px 10px;
	background: var(--bs-light);
	font-size: 12px;
	border-radius: 3px;

	&:hover {
		background: var(--bs-gray-400);
	}

	button {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: -5px;
		right: -5px;
		background: var(--bs-red);
		color: white;
		border-radius: 50%;
		width: 10px;
		height: 12px;
		font-size: 10px;
		border: 0;

		&:hover {
			transform: scale(1.2);
		}
	}

	&.has-errors {
		background: rgba(var(--bs-danger-rgb), 0.5);
	}
`;

let axiosCancelToken = null;

export default function Sectors(props) {
	let sectors = useMemo(() => props.sectors ?? [], [props.sectors]);
	let setSectors = props.setSectors;
	let errors = props.errors ?? [];
	let readOnly = props.readOnly;

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		window.PopupProxyCallbackSector = (newSector) => {
            if ( newSector?.id ) {
				addSector(newSector);
			}
		}

		return function cleanup() {
			window.PopupProxyCallbackSector = null;
		}
	}, []);

	const openNewSectorPopupWindow = () => {
		openPopupWindow(getRouterBasename() + '/sectors/add?popup=true');
	}
 
	const addSector = (data) => {
		if ( data === -99 ) {
			openNewSectorPopupWindow()
			return;
		}

		let s = [...sectors];
		s.push({
			id: data.id,
			name: data.name,
			order: data.order
		});
		setSectors(s);
	}

	const removeSector = (idx) => {
		let c = window.confirm('¿Seguro que quieres eliminar este sector?');
		if ( !c ) return false;

		let s = [...sectors];
		s.splice(idx, 1);
		setSectors(s);
	}

	const loadSectors = (input, callback) => {
		axios.get('/api/manager/sectors/list', {
			params: {
				search: input,
				no_paginate: true,
				except_ids: sectors.map(el => el.id)
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			formatted.unshift({
				value: -99,
				label: <div className="text-center">
							<button className="btn btn-primary btn-sm text-white p-0 px-2">Crear sector</button>
						</div>
			})
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	let rows = [];
	sectors
		.sort((a,b) => { 
			if ( a.order < b.order ) return -1;
			if ( a.order > b.order ) return 1;
			return 0;
		})
		.forEach((el, idx) => {
			rows.push(
				<Sector key={idx} className={errors['sectors.' + idx + '.id'] ? 'has-errors' : ''}>
					<button disabled={readOnly} className="btn btn-sm" onClick={() => removeSector(idx)}><i className="bi bi-x"></i></button>
					{el.name}
				</Sector>
			);
		});

	return (
		<SectorsWrapper>
			<LabeledFrame 
				className="mb-2" 
				label="Sectores"
				buttons={
					!readOnly ?
						<EmpoweredSelector
							load={loadSectors}
							onChange={(value) => addSector(value)}
							timeout={250}
							label={<span><i className="bi bi-plus-circle-fill"></i> Asociar</span>}
							value={-1}
							noRemovable={true}
						/>
						:
						null
				}
			>
				{ rows.length > 0 &&
					<SectorsContainer>
						{rows}
					</SectorsContainer>
				}
				{ errors && errors['sectors'] &&
					<div className="invalid-feedback d-block">
						{errors['sectors'][0]}
					</div>
				}
			</LabeledFrame>
		</SectorsWrapper>
	);
}