import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import randomatic from 'randomatic';
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import LabeledFrame from 'components/LabeledFrame';
import CustomFieldsPanel from 'components/CustomFieldsPanel';
import { CristalLoader, openPopupWindow } from 'helpers/generic';
import useStore from "store";
import { authUserPermission } from 'helpers/community';
import PermissionsTable from './PermissionsTable';
import Streets from './Streets';
import ImportantOwners from 'components/ImportantOwners';
import Notes from 'components/Notes';
import DocumentsPanel from 'components/DocumentsPanel';
import { readableSize } from 'helpers/generic';
import Contracts from 'pages/manager/NoticesForm/Contracts';
import MapPanel from './MapPanel';
import ButtonExportFichaPdf from 'components/ButtonExportFicha';
import AddressesPanel from 'components/AddressesPanel';
import ActionsContextMenu from 'components/ActionsContextMenu';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';


const ImageWrapper = styled.div`
	width: 100%;
	text-align: center;

	.image-square {
		position: relative;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		background: var(--bs-gray-200);	
		width: 100%;
		max-width: 100px;
		height: 100px;
		cursor: pointer;
		border-radius: 3px;

		img {
			display: block;
			width: 100%;
		}

		span {
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			font-size: 10px;
		}

		i {
			position: absolute;
			top: 5px;
			right: 5px;
			display: inline-flex;
			padding: 2px 5px;
			color: white;
			background: var(--bs-danger);
			border-radius: 3px;
			font-size: 11px;

			&:hover {
				opacity: 0.9;
				transform: translateY(-1px);
			}
		}

		input[type=file] {
			width: 0;
			height: 0;
			opacity: 0;
		}
	}
`;



let axiosCancelToken = null;

export default function CommunitiesForm(props) {
	let actionsDropdownRef = useRef(null);

	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';
	const forceCanEdit = queryParams.get('edit') === 'true';

	const logoFileInput = useRef(null);

	const config = useStore(state => state.config);

	let [data, setData] = useState({
		code: randomatic('A0', 5),
		type: 'owners',
		status: 1,
		permissions: {},
		streets: []
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);
	let [logo, setLogo] = useState(null);
	let [treasuries, setTreasuries] = useState([]);
	let [reloadTreasuries, setReloadTreasuries] = useState(null);
	let [canEdit, setCanEdit] = useState(true);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				await axios.get('/api/manager/communities/get/' + params.id, {
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(response.data);
		    		setLogo(response.data.logo_base64_url);
					if ( !forceCanEdit ) setCanEdit(false);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		} else {
			const getNextCode = async () => {
				await axios.get('/api/manager/communities/get-next-code', {
					cancelToken: axiosCancelToken.token
				}).then((response) => {
					setData((prev) => ({...prev, name_short: response.data}));
				}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});
			}
			getNextCode();
		}
	}, [params.id, forceCanEdit]);

	useEffect(() => {
		if ( params.id ) {
			const getTreasuries = async () => {
				await axios.get('/api/manager/treasury/list', {
					params: {
						community_id: params.id,
						no_paginate: true,
						type: 'bankaccount'
					},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
					setTreasuries(response.data);
				  }).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});
			}
			getTreasuries();
		}

		window.ProxyPopupCallbackTreasuries = () => {
			setReloadTreasuries(Math.random());
		}
	}, [reloadTreasuries, params.id]);

	const deleteCommunity = () => {
		const c = window.confirm('¿Quieres eliminar esta comunidad?');
		if ( !c ) return;

		axios.delete('/api/manager/communities/delete/' + data.id, {}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Comunidad borrada')
	    	navigate('/communities');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (goBack = true) => {
		setErrors({});

		axios.post('/api/manager/communities/save' + (data.id ? '/' + data.id : ''), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			setData({...response.data.community});
		    setLogo(response.data.community.logo_base64_url);

			if ( goBack ) {
				if ( popup ) window.close();
				else navigate('/communities');
			} else {
		    	navigate('/communities/edit/' + response.data.community.id + '?edit=true' + (popup ? '&popup=true' : ''));
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const setPermissions = (permissions) => {
		setData({...data, permissions: permissions});
	}

	const openLogoFileInput = () => {
		logoFileInput.current.value = null;
		logoFileInput.current.click();
	}

	const loadLogo = async (file) => {
		// Reset
		setDataField('logo_base64_url', null);
		setLogo(null);

		// Check if exists the file
        if ( !file ) return false;

        // Check extension
        var allowed = 'png jpg jpeg gif bmp';
        var extension = file.type.replace('image/', '');
        if ( !extension.length || allowed.indexOf(extension) === -1 ) {
            toast.error('Extensión no válida. Solo se permiten ficheros: png, jpg, gif y bmp');
            return false;
        }

        // Check size
        if ( file.size > config.upload_max_filesize ) {
            toast.error('El tamaño del fichero es superior al permitido ('+readableSize(config.upload_max_filesize)+')');
            return false;
        }

        // Read image and show
        let base64image = '';
        await new Promise((resolve) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(base64image = reader.result);
			reader.readAsDataURL(file);
		});
		setLogo(base64image);

        // Set data
        setDataField('logo_base64_url', base64image);
	}

	const removeLogo = (e) => {
		e.stopPropagation();
		setDataField('logo_base64_url', 'remove');
		setLogo(null);
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();
		// e.stopPropagation();

		openPopupWindow(e.currentTarget.href);
	}

	const openLogo = async (e) => {
		let base64src = e.currentTarget.src;
		let blob = await fetch(base64src).then(res => res.blob());
		const blobUrl = URL.createObjectURL(blob);
		openPopupWindow(blobUrl);
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de comunidad</h1>
					<button onClick={() => popup ? window.close() : navigate(-1)} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-lg-7">
											<LabeledFrame className="mb-3" label="Datos básicos">
												<div className="row">
													<div className="col-md-10">
														<div className="row">
															{/* <div className="col-lg-6">
																<div className="mb-2">
																	<label>Código</label>
																	<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('code', e.target.value)} value={data.code ?? ''} />
																	{ errors.code &&
																		<div className="invalid-feedback d-block">{ errors.code[0] }</div>
																	}
																</div>
															</div> */}
															<div className="col col-static-120">
																<div className="mb-2">
																	<CustomSelect 
																		label="Tipo" 
																		className="form-control form-control-sm" 
																		onChange={(e) => setDataField('type', e.target.value)} 
																		value={data.type ?? ''}
																		disabled={!canEdit} 
																	>
																		<option value="owners">Propietarios</option>
																		<option value="goods">Bienes</option>
																	</CustomSelect>
																	{ errors.type &&
																		<div className="invalid-feedback d-block">{ errors.type[0] }</div>
																	}
																</div>
															</div>
															<div className="col col-static-120">
																<div className="mb-2">
																	<CustomSelect 
																		label="Activa" 
																		className="form-control form-control-sm"
																		onChange={(e) => setDataField('status', e.target.value)} 
																		value={data.status ?? ''}
																		disabled={!canEdit} 
																	>
																		<option value="0">No</option>
																		<option value="1">Si</option>
																	</CustomSelect>
																</div>	
															</div>
															<div className="col col-static-120">
																<CustomInput 
																	label="Código" 
																	type="text" 
																	className="form-control form-control-sm" 
																	onChange={(e) => setDataField('name_short', e.target.value)} 
																	value={data.name_short ?? ''} 
																	readOnly={!canEdit} 
																/>	
																{ errors.name_short &&
																	<div className="invalid-feedback d-block">{ errors.name_short[0] }</div>
																}
															</div>
															<div className="col col-static-200">
																<CustomInput 
																	label="Nombre" 
																	type="text" 
																	className="form-control form-control-sm" 
																	onChange={(e) => setDataField('name', e.target.value)} 
																	value={data.name ?? ''} 
																	readOnly={!canEdit} 
																/>
																{ errors.name &&
																	<div className="invalid-feedback d-block">{ errors.name[0] }</div>
																}
															</div>
														</div>
													</div>
													<div className="col col-static-110">
														<ImageWrapper>
															{ errors.logo_base64_url &&
																<div className="invalid-feedback d-block">{ errors.logo_base64_url[0] }</div>
															}
															<div className="image-square" onClick={() => !logo && canEdit ? openLogoFileInput() : ''}>
																<input type="file" ref={logoFileInput} onChange={(e) => loadLogo(e.target.files[0])} />

																{ logo ? 
																	<React.Fragment>
																		<img src={logo} alt="logo" onClick={openLogo} />
																		{ canEdit && 
																			<i onClick={(e) => removeLogo(e)}>Eliminar logo</i>
																		}
																	</React.Fragment> 
																	: 
																	
																	<span className="text-center">Seleccionar</span> 
																}
															</div>
														</ImageWrapper>
													</div>	
												</div>												
											</LabeledFrame>

											<LabeledFrame className="mb-3" label="Datos fiscales">
												<div className="row">
													<div className="col col-static-300">
														<div className="mb-2">
															<CustomInput 
																label="Razón social" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('business_name', e.target.value)} 
																value={data.business_name ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.business_name &&
																<div className="invalid-feedback d-block">{ errors.business_name[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-120">
														<div className="mb-2">
															<CustomInput 
																label="CIF" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('vatnumber', e.target.value)} 
																value={data.vatnumber ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.vatnumber &&
																<div className="invalid-feedback d-block">{ errors.vatnumber[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-140">
														<div className="mb-2">
															<CustomInput 
																label="Año de creación" 
																type="date" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('date_created', e.target.value)} 
																value={data.date_created ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.date_created &&
																<div className="invalid-feedback d-block">{ errors.date_created[0] }</div>
															}
														</div>
													</div>
													

													<div className="col col-static-300">
														<div className="mb-2">
															<CustomInput 
																label="Dirección" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('address', e.target.value)} 
																value={data.address ?? ''}
																readOnly={!canEdit}
															/>
															{ errors.address &&
																<div className="invalid-feedback d-block">{ errors.address[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-120">
														<div className="mb-2">
															<CustomInput 
																label="C.P." 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('postalcode', e.target.value)} 
																value={data.postalcode ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.postalcode &&
																<div className="invalid-feedback d-block">{ errors.postalcode[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-200">
														<div className="mb-2">
															<CustomInput 
																label="Ciudad" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('city', e.target.value)} 
																value={data.city ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.city &&
																<div className="invalid-feedback d-block">{ errors.city[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-180">
														<div className="mb-2">
															<CustomInput 
																label="Provincia" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('province', e.target.value)} 
																value={data.province ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.province &&
																<div className="invalid-feedback d-block">{ errors.province[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-120">
														<div className="mb-2">
															<CustomInput 
																label="País" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('country', e.target.value)} 
																value={data.country ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.country &&
																<div className="invalid-feedback d-block">{ errors.country[0] }</div>
															}
														</div>
													</div>
												</div>
											</LabeledFrame>

											<Streets
												data={data.streets ?? []}
												setData={(newStreets) => setData((prev) => ({...prev, streets: newStreets}))}
												errors={errors}
												readOnly={!canEdit}
											/>

											<LabeledFrame className="mb-3" label="Datos catastrales">
												<div className="row">
													<div className="col col-static-230">
														<div className="mb-2">
															<CustomInput 
																label="Referencia catastral" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('cadastral_reference', e.target.value)} 
																value={data.cadastral_reference ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.cadastral_reference &&
																<div className="invalid-feedback d-block">{ errors.cadastral_reference[0] }</div>
															}
														</div>
													</div>										
													<div className="col col-static-150">
														<div className="mb-2">
															<CustomInput 
																label="Año de construccion" 
																type="number" 
																className="form-control form-control-sm no-arrows" 
																min="0" 
																max="9999" 
																step="1" 
																onChange={(e) => setDataField('construction_year', e.target.value)} 
																value={data.construction_year ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.construction_year &&
																<div className="invalid-feedback d-block">{ errors.construction_year[0] }</div>
															}
														</div>
													</div>										
												</div>										
											</LabeledFrame>	

											{ (data.id && data.importantowners && data.importantowners.length > 0) && 
												<ImportantOwners owners={data.importantowners} />
											}

											{ (data.id && data.importantowners && data.importantowners.length > 0) && 
												<Contracts community_id={data?.id} />
											}
											
											{ data.id &&
												<LabeledFrame 
													className="mb-3" 
													label="Cuentas bancarias"
													buttons={
														canEdit ?
															<NavLink to="/treasury/add?popup=true" className="btn-unstyled text-primary" onClick={openPopupInfoWindow}>
																<i className="bi bi-plus-circle-fill"></i>
															</NavLink>
														:
														null
													}
												>
													<table className="table table-sm mb-0" style={{fontSize: '13px'}}>
														<thead>
															<tr>
																<th>Cuenta</th>
																<th>Iban</th>
																<th>Página web</th>
																<th></th>
															</tr>
														</thead>
														<tbody>
															{treasuries?.map((el, idx) => {
																return (
																	<tr key={idx}>
																		<td>{el.name}</td>
																		<td>{el.data?.iban}</td>
																		<td>
																			{el.data?.website &&
																				<a href={el.data.website} target="_blank" rel="noreferrer">{el.data.website.substr(0, 30)}...</a>
																			}
																		</td>
																		<td className="text-center"><NavLink to={'/treasury/edit/' + el.id + '?popup=true'} onClick={openPopupInfoWindow}><i className="bi bi-eye-fill"></i></NavLink></td>
																	</tr>
																);
															})}
															{treasuries?.length === 0 && <tr><td colSpan="100%">No hay cuentas bancarias</td></tr>}
														</tbody>
													</table>
												</LabeledFrame>
											}
										</div>
										<div className="col-lg-5">
											<CustomFieldsPanel 
												className="mb-3"
												type="communities"
												fields={data.customfields_array}
												setFields={(fields) => setData((data) => ({...data, customfields_array: fields}))}
												readOnly={!canEdit}
											/>

											<AddressesPanel	
												addresses={data.addresses}
												properties={data.properties}
												errors={errors}
												setAddresses={(addresses) => setData({...data, addresses: addresses})}
												showRelationField={true}
												deleteAll={true}
												readOnly={!canEdit}
											/>

											<LabeledFrame className="mb-3" label="Usuarios y permisos">
												<PermissionsTable readOnly={!canEdit} permissions={data.permissions} setPermissions={(data) => setPermissions(data)} />			
											</LabeledFrame>	

											<LabeledFrame className="mb-3" label="Instrucciones">
												<textarea readOnly={!canEdit} className="form-control form-control-sm" onChange={(e) => setDataField('observations', e.target.value)} value={data.observations ?? ''}></textarea>
											</LabeledFrame>

											<Notes
												className="mb-3"
												notes={data.notes ?? []}
												updateParentCallback={(notes) => setData((data) => ({...data, notes: notes}))}
												readOnly={!canEdit}
											/>

											{ data.id && 
												<DocumentsPanel
													className="mb-3"
													folderId={data.folder_id ?? null}
													community={{id: data.id, name: data.name}}
												/>
											}

											<LabeledFrame className="mb-3" label="Ubicación">
												<MapPanel
													position={data.position ?? null}
													setPosition={(position) => setData((data) => ({...data, position: position}))}
												/>
											</LabeledFrame>
										</div>
									</div>
								</div>
								<div className="card-footer" id={popup ? 'footer-fixed' : ''}>
									<div className="row">
										<div className="col-6">
											{ (data.id && authUserPermission('delete') ) &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteCommunity()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											{ authUserPermission('edit') &&
												<React.Fragment>
													{ data.id &&
														<div className="dropdown d-inline">
															<button ref={actionsDropdownRef} className="btn btn-sm btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
																Acciones
															</button>
															<ActionsContextMenu actionsDropdownRef={actionsDropdownRef} className="dropdown-menu">
																<li><button className="dropdown-item" onClick={() => setCanEdit(!canEdit)}>{canEdit ? 'Desactivar edición' : 'Editar'}</button></li>
																<li><hr className="dropdown-divider" /></li>

																<li><NavLink className="dropdown-item" to={'/notices?community_id=' + data.id} onClick={openPopupInfoWindow}>Ver avisos</NavLink></li>
																<li><NavLink className="dropdown-item" to={'/notices/add?community_id=' + data.id + '&petitioner_type=community'} onClick={openPopupInfoWindow}>Crear aviso</NavLink></li>
																<li><ButtonExportFichaPdf entityKey='communities' entityId={data.id} fileName={'Comunidad ' + data.name} /></li>

																<li><hr className="dropdown-divider" /></li>
																<li><button className="dropdown-item" onClick={() => saveData(false)}>Guardar</button></li>						
																<li><button className="dropdown-item" onClick={() => saveData()}>Guardar y salir</button></li>
															</ActionsContextMenu>
														</div>
													}
													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>	
												</React.Fragment>
											}						
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}