import React from 'react';
import styled from 'styled-components';

const Th = styled.th`
	cursor: pointer;
	white-space: nowrap;
	user-select: none;
	color: ${props => props.active ? 'var(--bs-primary)' : ''};

	& > div.th-sortable-inner {
		display: inline-flex;
		justify-content: space-between;
		align-items: center;

		& > i {
			font-style: normal;
			margin-right: auto;
			margin-left: 10px;
			color: ${props => props.active ? '' : 'var(--bs-gray-400)'};
			width: 8px;
			max-width: 8px;
			min-width: 8px;
		}
	}
`;

export default function ThSortable(props) {
	let disabled = props.disabled ?? false;
	let active = props.active ?? true;
	let onClick = props.onClick;
	let style = props.style ?? {};
	let direction = props.direction;

	return (
		<Th 
			onClick={() => !disabled ? onClick() : null} 
			style={style}
			active={!disabled ? active : null}
		>
			<div className="th-sortable-inner">
				{ props.children }
				{ !disabled &&
					<>
						<i active={active.toString()}>
							{ !active &&
								'⇅'
							}
							{ active && 
								<React.Fragment>
									{ direction === 'asc' && '↑' } 
									{ direction === 'desc' && '↓' } 
								</React.Fragment>
							}
						</i>
					</>
				}
			</div>
		</Th>
	);
}