import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader, jsonToArray } from 'helpers/generic';
import { getConfig } from 'helpers/config';
import EmpoweredSelector from 'components/EmpoweredSelector';
import TextEditor from 'components/TextEditor';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';

let axiosCancelToken = null;

export default function TemplatesForm(props) {
	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';
	const type = queryParams.get('type');
	const typeLocked = queryParams.get('type_locked') === 'true';

	let [data, setData] = useState({
		default: false,
		deletable: true
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				await axios.get('/api/manager/templates/get/' + params.id, {
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(response.data);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		}
	}, [params.id]);

	useEffect(() => {
		setData((prev) => ({...prev, type: type}));
	}, [type]);

	const loadCommunities = (input, callback) => {
		axios.get('/api/manager/communities/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const deleteTemplate = () => {
		const c = window.confirm('¿Quieres eliminar esta plantilla?');
		if ( !c ) return;

		axios.post('/api/manager/templates/delete/' + data.id, {}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Plantilla borrada')
	    	navigate('/templates');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (goBack = true) => {
		setErrors({});

		axios.post('/api/manager/templates/' + (data.id ? 'edit/' + data.id : 'add'), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			setData({...response.data.template});

			if ( goBack ) {
				if ( popup ) window.close();
				else navigate('/templates');
			} else {
		    	navigate('/templates/edit/' + response.data.template.id + (popup ? '?popup=true' : ''));
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de plantilla</h1>
					<button onClick={() => popup ? window.close() : navigate('/templates')} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar': 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="row">
											<div className="col-lg-6">
												<div className="mb-2">
													<CustomInput label="Título" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('title', e.target.value)} value={data.title ?? ''} />
													{ errors.title &&
														<div className="invalid-feedback d-block">{ errors.title[0] }</div>
													}
												</div>
											</div>
											<div className="col-lg-3">
												<div className="mb-2">
													<CustomSelect label="Tipo" className="form-control form-control-sm" onChange={(e) => setDataField('type', e.target.value)} value={data.type ?? ''} disabled={typeLocked}>
														<option value=""></option>
														{jsonToArray(getConfig().templatestypes).map((el, idx) => {
															return (<option key={idx} value={el.key}>{el.value}</option>);
														})}
													</CustomSelect>
													{ errors.type &&
														<div className="invalid-feedback d-block">{ errors.type[0] }</div>
													}
												</div>
											</div>
											<div className="col-lg-3">
												<div className="mb-2">
													<EmpoweredSelector
														load={loadCommunities}
														onChange={(value) => setData((prev) => ({...prev, community: value, community_id: value?.id}))}
														timeout={250}
														label={data.community?.name ?? ''}
														placeholder="Comunidad"
														showPlaceholderHelper={true}
														value={data.community?.id}
													/>
													{ errors.community_id &&
														<div className="invalid-feedback d-block">{ errors.community_id[0] }</div>
													}
												</div>
											</div>
											<div className="col-lg-12">
												<div className="mt-3">
													<TextEditor
														text={data.content}
														setText={(newValue) => setData((prev) => ({...prev, content: newValue}))}
														type={data.type}
													/>
													{ errors.content &&
														<div className="invalid-feedback d-block">{ errors.content[0] }</div>
													}
												</div>
											</div>
										</div>	
									</div>
								</div>
								<div className="card-footer" id={popup ? 'footer-fixed' : ''}>
									<div className="row">
										<div className="col-4">
											{ data.id &&
												<React.Fragment>
													{ data.is_deletable === true &&
														<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteTemplate()}>Eliminar</button>							
													}
												</React.Fragment>
											}
										</div>
										<div className="col-8 text-end">
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}