import moment from 'moment';
import LabeledFrame from 'components/LabeledFrame';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';

export default function Basic(props) {
	let data = props.data;
	let setData = props.setData;
	let setDataField = props.setDataField;
	let errors = props.errors;

	const setFirstAnnouncementFix = (value) => {
		let second_announcement = moment('1970-01-01 ' + value).add(30, 'minutes').format('HH:mm');
		setData((prev) => ({...prev, first_announcement: value, second_announcement: second_announcement}));
	}

	return (
		<LabeledFrame className="mb-3 mb-md-0" label="Junta">
			<div className="row">
				<div className="col-md-8">
					<div className="mb-2">
						<CustomInput label="Título" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('title', e.target.value)} value={data.title ?? ''} />
						{ errors.title &&
							<div className="invalid-feedback d-block">{ errors.title[0] }</div>
						}
					</div>
				</div>
				<div className="col-md-4">
					<div className="mb-2">
						<CustomSelect label="tipo" className="form-control form-control-sm" onChange={(e) => setDataField('type', e.target.value)} value={data.type ?? ''}>
							<option value="ordinary">Ordinaria</option>
							<option value="extraordinary">Extraordinaria</option>
						</CustomSelect>
						{ errors.type &&
							<div className="invalid-feedback d-block">{ errors.type[0] }</div>
						}
					</div>
				</div>
				<div className="col-md-4">
					<div className="mb-2">
						<CustomInput label="Fecha" type="date" className="form-control form-control-sm" onChange={(e) => setDataField('date', e.target.value)} value={data.date ?? ''} />
						{ errors.date &&
							<div className="invalid-feedback d-block">{ errors.date[0] }</div>
						}
					</div>
				</div>
				<div className="col-md-4">
					<div className="mb-2">
						<CustomInput label="1ª convocatoria" type="time" className="form-control form-control-sm" onChange={(e) => setFirstAnnouncementFix(e.target.value)} value={data.first_announcement ?? ''} />
						{ errors.first_announcement &&
							<div className="invalid-feedback d-block">{ errors.first_announcement[0] }</div>
						}
					</div>
				</div>
				<div className="col-md-4">
					<div className="mb-2">
						<CustomInput label="2ª convocatoria" type="time" className="form-control form-control-sm" onChange={(e) => setDataField('second_announcement', e.target.value)} value={data.second_announcement ?? ''} />
						{ errors.second_announcement &&
							<div className="invalid-feedback d-block">{ errors.second_announcement[0] }</div>
						}
					</div>
				</div>
				<div className="col-md-4">
					<div className="mb-2">
						<CustomSelect label="Convocada por" className="form-control form-control-sm" onChange={(e) => setDataField('summoner', e.target.value)} value={data.summoner ?? ''}>
							<option value="president">Presidente</option>
							<option value="administrator">Administrador</option>
						</CustomSelect>
						{ errors.summoner &&
							<div className="invalid-feedback d-block">{ errors.summoner[0] }</div>
						}
					</div>
				</div>
				<div className="col-md-8">
					<div className="mb-2">
						<CustomInput label="Lugar" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('place', e.target.value)} value={data.place ?? ''} />
						{ errors.place &&
							<div className="invalid-feedback d-block">{ errors.place[0] }</div>
						}
					</div>
				</div>
				<div className="col-md-4">
					<div className="mb-2">
						<CustomInput label="Finalización" type="time" className="form-control form-control-sm" onChange={(e) => setDataField('finished_at', e.target.value)} value={data.finished_at ?? ''} />
						{ errors.finished_at &&
							<div className="invalid-feedback d-block">{ errors.finished_at[0] }</div>
						}
					</div>
				</div>
				<div className="col-md-8">
					<div className="mb-2">
						<CustomInput label="Secretario" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('secretary', e.target.value)} value={data.secretary ?? ''} />
						{ errors.secretary &&
							<div className="invalid-feedback d-block">{ errors.secretary[0] }</div>
						}
					</div>
				</div>
				<div className="col-md-4">
					<div className="mb-2">
						<CustomInput label="Fecha envío convocatoria" type="date" className="form-control form-control-sm" onChange={(e) => setDataField('announcement_sent_date', e.target.value)} value={data.announcement_sent_date ?? ''} />
						{ errors.announcement_sent_date &&
							<div className="invalid-feedback d-block">{ errors.announcement_sent_date[0] }</div>
						}
					</div>
				</div>
				<div className="col-md-4">
					<div className="mb-2">
						<CustomInput label="Fecha envío acta" type="date" className="form-control form-control-sm" onChange={(e) => setDataField('act_sent_date', e.target.value)} value={data.act_sent_date ?? ''} />
						{ errors.act_sent_date &&
							<div className="invalid-feedback d-block">{ errors.act_sent_date[0] }</div>
						}
					</div>
				</div>
			</div>
		</LabeledFrame>
	);
}