import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import styled from 'styled-components';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import CustomInput from 'components/CustomInput';
import { getCommunity } from 'helpers/community';
import EmpoweredSelector from 'components/EmpoweredSelector';

const ModalStyled = styled.div`
	background: rgba(0, 0, 0, 0.4);
`;

let axiosCancelToken = null;

export default function TitleModal(props) {
	const modalRef = useRef(null);

	const groupIdx = props.groupIdx;
	const idx = props.idx;
	const title = props.title;
	const setTitle = props.setTitle;
	const titlesCount = props.titlesCount;
	const closeCallback = props.closeCallback;

	const [number, setNumber] = useState(title?.number ?? titlesCount+1);
	const [denomination, setDenomination] = useState(title?.denomination ?? '');
	const [contableAccount, _setContableAccount] = useState(title?.account);
	const setContableAccount = (value) => {
		_setContableAccount(value);
		setDenomination(value?.name);
	}

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const modal = new Modal(modalRef.current, {
			backdrop: false
		});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();
			closeCallback();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

		modal.show();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [closeCallback]);	

	const saveData = () => {
		let titleData = {}
		titleData.number = number;
		titleData.denomination = denomination;
		titleData.account = contableAccount;
		titleData.contableaccount_id = contableAccount.id;

		setTitle(groupIdx, idx, {...title, ...titleData});

		// Hide modal
		const modal = Modal.getInstance(modalRef.current);
		modal.hide();
	}	

	const getContableAccountList = async (input, callback) => {
		let contableaccounts = await axios.get('/api/manager/contable-accounts/list', {
			params: {
				community_id: getCommunity()?.id,
				no_paginate: true,
				parent_entity: 'estimategroup-expenses',
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			return response.data;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	

		let titles = [];

		if ( contableaccounts && contableaccounts.length ) {
			contableaccounts.forEach(el => {
				let clickable =  el.code.length >= 5
	
				titles.push({
					value: el, 
					label:  <div onClick={(e) => !clickable ? e.stopPropagation() : null}>
								<b className="me-1">{el.code}</b>  
								<span>{el.name}</span>
							</div>
				});
			});
		}

		if (input) {
			titles = titles.filter(item => {
				return item.value.name.toLowerCase().indexOf(input.toLowerCase()) !== -1;
			});
		}

		callback(titles);
	}

	// Set parent_id to add button to simplify user usage
	// useEffect(() => {
	// 	const getData = async() => {
	// 		let result = await loadContableAccountsFix(null, null, 'estimategroup-expenses');
	// 		if ( result && result.data && result.data[0] ) setBtnAddParentId(result.data[0].id);
	// 	}
	// 	getData();
	// }, []);

	const setDenominationFromSelector = (value) => {
		if ( !value ) return;
		setDenomination(value);
	}

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Título</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<div className="row">
							<div className="col-md-1">
								<div className="mb-2">
									<CustomInput label="Nº" type="number" className="form-control form-control-sm no-arrows" value={number ?? ''} onChange={(e) => setNumber(e.target.value)} />
								</div>
							</div>
							<div className="col-md-5">
								<div className="mb-2">
									<EmpoweredSelector
										load={getContableAccountList}
										onChange={(value) => {
											if (value.code.length < 5) return;

											setContableAccount(value)
										}}
										timeout={250}
										label={contableAccount?.name ?? ''}
										placeholder="Cuenta contable"
										showPlaceholderHelper={true}
										value={contableAccount?.id ?? null}
										dropDownMenuWidth="200%"
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-2">
									<CustomInput label="Denominación" type="text" className="form-control form-control-sm no-arrows" value={denomination ?? ''} onChange={(e) => setDenomination(e.target.value)} />
								</div>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className={'btn btn-sm btn-primary text-white ' + (!contableAccount?.id ? 'disabled' : '')} onClick={() => saveData()}>Aceptar</button>
					</div>
				</div>
			</div>
		</ModalStyled>
	);
}


