import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import styled from 'styled-components';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import readXlsxFile from 'read-excel-file'
import { toast } from "react-toastify";
import FieldSmallComment from "components/FieldSmallComment";

const ModalStyled = styled.div`
	background: rgba(0, 0, 0, 0.4);

	.modal {
		position: relative;
	}
`;

const FileWrapper = styled.div`
	width: 100%;
	padding: 20px;
	background: var(--bs-light);
	border: 1px solid var(--bs-gray-300);
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	input[type=file] {
		visibility: hidden;
		height: 0;
	}
`;

const Table = styled.table`
	font-size: 12px;
	font-weight: normal;
`;

let axiosCancelToken = null;

export default function ImportExcelModal(props) {
	const modalRef = useRef(null);
	const fileRef = useRef(null);

	const closeCallback = props.closeCallback;
	const saveCallback = props.saveCallback;

	const [file, setFile] = useState(null);
	const [data, setData] = useState([]);
	const [errors, setErrors] = useState(null);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const modal = new Modal(modalRef.current, {backdrop: false});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();
			closeCallback();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

		modal.show();

		// Fix, because bootstrap removes scroll and add padding on modal opened
		document.body.style.overflow = 'auto'; 
		document.body.style.paddingRight = '0';
		
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [closeCallback]);

	const loadFile = async (file) => {
		// Reset
		setFile(null);
		setData([]);

		// Check if exists the file
        if ( !file ) return false;

		// Read file
		readXlsxFile(file).then((rows) => {
			let data = [...rows].filter((el, idx) => !isNaN(el[0]) && !isNaN(el[1])).map((el, idx) => {
				return {
					number: el[0],
					amount: el[1]
				};
			});

			// Validate 
			let errors = null;
			if ( !data.length ) errors = 'No se han encontrado cuotas';
			setErrors(errors);
			
			// Set data
			setData(data);

			// Set file
			setFile(file);
		}).catch((error) => {
			console.error(error);
			setFile(null);
			setData([]);
			toast.error('Formato de archivo no válido')
			return;
		});	
	}


	const saveData = async () => {
		saveCallback(data);
	}

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Importar Excel</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<FileWrapper onClick={() => {fileRef.current.value = null; fileRef.current.click();}}>
							{ file === null ?
								<div>Pulsa para seleccionar el fichero</div>
								:
								<div className="text-center">{file.name}</div>
							}
							<input type="file" ref={fileRef} onChange={(e) => loadFile(e.target.files[0])} />
						</FileWrapper>

						{ data.length <= 0 &&
							<FieldSmallComment className="mt-3">NOTA: el fichero excel debe contener dos columnas. La primera columna es el nº de propiedad y la segunda es el importe de la cuota.</FieldSmallComment>
						}
					
						{ data.length > 0 && 
							<Table className="table table-sm table-bordered mt-3">
								<thead>
									<tr>
										<td>Nº Propiedad</td>
										<td>Cuota</td>
									</tr>
								</thead>
								<tbody>
									{data && data.map((el, idx) => {
										return (
											<tr key={idx}>
												<td>{el.number}</td>
												<td>{el.amount}</td>
											</tr>
										)
									})}
								</tbody>
							</Table>
						}

						{ errors &&
							<FieldSmallComment className="mt-3 text-danger">{errors}</FieldSmallComment>
						}
					</div>
					<div className="modal-footer">
						<button type="button" className={'btn btn-sm btn-primary text-white'} onClick={() => saveData()} disabled={!data.length || errors }>Importar datos</button>
					</div>
				</div>
			</div>
		</ModalStyled>
	);
}