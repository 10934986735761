import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const Table = styled.table`
	width: 100%;
	font-size: 13px;
	vertical-align: middle;

	tr {

		&:hover {
			background: var(--bs-light);
		}
	}

	td,th {
		cursor: pointer;

		&:nth-child(1) {
			width: 20px;
			white-space: nowrap;
			color: var(--bs-primary);
		}
		
		&:nth-child(2) {
			font-size: 10px;
			color: #333;
		}
	}
`;

let axiosCancelToken = null;

export default function UsefulLinks() {
	let [links, setLinks] = useState([]);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);


	useEffect(() => {
		const getLinks = async () => {
			await axios.get('/api/manager/useful-links/list', {
				params: {
					no_paginate: true,
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				setLinks([...response.data]);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getLinks();
	}, []);

	let linksLeftColumn = links && links.length > 0 ? links.filter((el, idx) => idx+1 <= Math.ceil(links.length/2)) : [];
	let linksRightColumn = links && links.length > 0 ? links.filter((el, idx) => idx+1 > Math.ceil(links.length/2)) : [];

	return (
		<React.Fragment>
			{ links.length > 0 &&
				<div className="card border-0 shadow-sm mb-3">
					<div className="card-header bg-white p-3">
						<div className="row">
							<div className="col-md-12 mb-2 mt-2 mb-md-0 mt-md-0">
								<h5 className="card-title mb-0 text-primary d-flex h-100 align-items-center justify-content-center">Páginas más utilizadas</h5>
							</div>
						</div>
					</div>
					<div className="card-body">
						{ links.length > 0 &&
							<div className="row">
								<div className="col-md-6">
									<Table className="table table-sm table-bordered mb-0">
										<tbody>
											{ linksLeftColumn.map((el, idx) => {
												return (
													<tr key={idx} onClick={() => window.open(el.url)}>
														<td>{el.name}</td>
													</tr>
												);
											})}
										</tbody>
									</Table>	
								</div>
								<div className="col-md-6">
									{ linksRightColumn.length > 0 && 
										<Table className="table table-sm table-bordered mb-0">
											<tbody>
												{ linksRightColumn.map((el, idx) => {
													return (
														<tr key={idx} onClick={() => window.open(el.url)}>
															<td>{el.name}</td>
														</tr>
													);
												})}
											</tbody>
										</Table>	
									}
								</div>
							</div>
						}
					</div>
				</div>
			}
		</React.Fragment>
	);
}


