import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import LabeledFrame from 'components/LabeledFrame';
import EmpoweredSelector from 'components/EmpoweredSelector';
import FieldSmallComment from 'components/FieldSmallComment';
import { openPopupWindow, getPetitionerFormatted } from 'helpers/generic';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';
import CustomTextarea from 'components/CustomTextarea';

const PetitionerSelectRow = styled.div`
	position: relative;

	.name {

	}

	.type {
		background: var(--bs-secondary);
		color: white;
		padding: 2px 8px;
		font-size: 10px;
		border-radius: 3px;
		display: inline-block;
		margin-right: 5px;
	}

	.community {
		&:empty {
			display: none;
		}

		background: var(--bs-primary);
		color: white;
		padding: 2px 8px;
		font-size: 10px;
		border-radius: 3px;
		display: inline-block;
	}
`;

const StatusSelectorStyled = styled.div`
	color: white;

	.custom-select {
		span.custom-select-label {
			color: white;
		}

		select {
			color: white;
			
			option {
				color: white;

				&[value=pending] {
					background: var(--bs-primary);
				}

				&[value=progress] {
					background: var(--bs-orange);
				}

				&[value=closed] {
					background: var(--bs-success);
				}

				&[value=expired] {
					background: var(--bs-danger);
				}
			}
			
		}
	}

	.status-pending {
		background: var(--bs-primary);		
	}

	.status-progress {
		background: var(--bs-orange);		
	}

	.status-closed {
		background: var(--bs-success);		
	}

	.status-expired {
		background: var(--bs-danger);		
	}
`;

let axiosCancelToken = null;

export default function Basics(props) {
	const data = props.data;
	const dataRef = useRef(props.data);
	const setData = props.setData;
	const setDataField = props.setDataField;
	const errors = props.errors;

	const [expirationDatesHistorial, setExpirationDatesHistorial] = useState([]);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( !data.id ) return;

		axios.get('/api/manager/notices/expiration-dates-historial/' + data.id, {
			params: {},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			setExpirationDatesHistorial(response.data);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}, [data.id, data.updated_at]);

	useEffect(() => {
		dataRef.current = data;
	}, [data]);


	const loadTypes = (input, callback) => {
		axios.get('/api/manager/notices-types/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectType = (value) => {
		let newData = {...data};
		newData.notice_type_id = value?.id ?? null;
		newData.type = {
			id: value?.id,
			name: value?.name
		}
		setData(newData);
	}

	const loadSources = (input, callback) => {
		axios.get('/api/manager/notices-sources/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectSource = (value) => {
		let newData = {...data};
		newData.notice_source_id = value?.id ?? null;
		newData.source = {
			id: value?.id,
			name: value?.name
		}
		setData(newData);
	}

	const loadUsers = (input, callback) => {
		axios.get('/api/manager/users/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectUser = (value) => {
		let newData = {...data};
		newData.user_id = value?.id ?? null;
		newData.user = {
			id: value?.id,
			name: value?.name
		}
		setData(newData);
	}

	const setExpiresDate = (e, amount, unit, fullDate) => {
		e.preventDefault();

		let date = null;
		if ( amount && unit ) date =  moment().add(amount, unit).format('YYYY-MM-DDTHH:mm');
		if ( fullDate ) date = fullDate;

		let newData = {...data};
		newData.expires_at = date;

		// Check date to set status
		if ( moment().isBefore(date) ) {
			newData.status = 'progress';
		} else {
			newData.status = 'expired';
		}

		setData(newData);
	}

	const loadPetitioners = (input, callback, args) => {
		axios.get('/api/manager/notices/petitioners-list', {
			params: {
				search: input,
				community_id: args.community_id
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label:  <PetitionerSelectRow>
								<div className="name">{el.name}</div>
								<div className="type">{getPetitionerFormatted(el.petitioner_type)}</div>
								<div className="community">{el.community?.name}</div>
						 	</PetitionerSelectRow>
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const loadCommunities = (input, callback) => {
		axios.get('/api/manager/communities/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectCommunity = (value) => {
		// Community
		let newData = {...data};
		newData.community_id = value?.id ?? null;
		newData.community = {
			id: value?.id,
			name: value?.name,
			importantowners: value?.importantowners
		}

		// Update state
		setData(newData);
	}

	const selectPetitioner = useCallback((value) => {
		let newData = {...dataRef.current};

		// Reset fields to prevent validation errors
		newData.owner_id = null;
		newData.renter_id = null;
		newData.provider_id = null;
		newData.client_id = null;
		newData.contact_id = null;

		// Set data
		newData.petitioner_type = value?.petitioner_type ?? 'owner';
		newData[newData.petitioner_type + '_id'] = value?.id ?? null;
		newData[newData.petitioner_type] = {
			id: value?.id,
			name: value?.name
		}
		if ( newData.petitioner_type === 'provider' ) newData[newData.petitioner_type].business_name = value?.business_name;

		// Community
		if ( value?.community ) {
			newData.community_id = value.community.id;
			newData.community = value.community;
		} else {
			newData.community_id = null;
			newData.community = {
				id: null,
				name: null
			};
		}

		// Property
		if ( value?.properties && value?.properties[0] ) {
			newData.property_id = value.properties[0].id;
			newData.property = {
				id: value.properties[0].id,
				name: value.properties[0].name
			}

			// Building
			if ( value.properties[0].building ) {
				newData.building_id = value.properties[0].building?.id;
				newData.building = {
					id: value.properties[0].building?.id,
					name: value.properties[0].building?.name
				}
			}
		} else {
			newData.property = {
				id: null,
				name: null
			}
			newData.property_id = null;

			newData.building = {
				id: null,
				name: null
			}
			newData.building_id = null;
		}

		setData(newData);
	}, []);

	const openPopupInfoWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	const openPopupWindowMouseDownFix = (e) => {
		e.preventDefault();
		e.stopPropagation();
		document.activeElement.blur();
	}

	useEffect(() => {
		window.PopupProxyCallback = (petitioner, type) => {
			if ( petitioner.id ) {
				petitioner[type + '_id'] = petitioner?.id;
				petitioner.petitioner_type = type;
				petitioner.community = data.community;
				// console.log(petitioner);
				selectPetitioner(petitioner);
			}
		}

		return function cleanup() {
			window.PopupProxyCallback = null;
		}
	}, [data.community, selectPetitioner]);

	return (
		<LabeledFrame 
			label="Datos básicos" 
			labelSecondary={
				<div style={{color: 'white'}}>
					{data.opener?.name ? <>Creado por <u>{data.opener.name}</u></> : null}
					{data.opened_at ? ' el ' + moment(data.opened_at).format('DD-MM-YYYY') + ' a las ' + moment(data.opened_at).format('HH:mm') : ''}
				</div>
			}
			labelSecondaryBackground={'primary'}
			buttons={<span className="text-white fw-bold">Referencia: {data.reference ?? '-'}</span>}
			buttonsBackground="primary"
			className="mb-3"
		>
			<div className="row">
				<div className="col-md-3">
					<div className="mb-2">
						<EmpoweredSelector
							load={(input, callback) => loadSources(input, callback)}
							onChange={(value) => selectSource(value)}
							timeout={250}
							label={data.source?.name ?? ''}
							placeholder="Procedencia"
							showPlaceholderHelper={true}
							value={data.source?.id}
						/>
						{ errors.notice_source_id &&
							<div className="invalid-feedback d-block">{ errors.notice_source_id[0] }</div>
						}
					</div>
				</div>
				<div className="col-md-3">
					<div className="mb-2">
						<EmpoweredSelector
							load={(input, callback) => loadTypes(input, callback)}
							onChange={(value) => selectType(value)}
							timeout={250}
							label={data.type?.name ?? ''}
							placeholder="Tipo"
							showPlaceholderHelper={true}
							value={data.type?.id}
						/>
						{ errors.notice_type_id &&
							<div className="invalid-feedback d-block">{ errors.notice_type_id[0] }</div>
						}
					</div>
				</div>

				<div className="col-md-6">
					<div className="mb-2">
						<EmpoweredSelector
							load={loadCommunities}
							onChange={(value) => selectCommunity(value)}
							timeout={250}
							label={
								<div>
									{data.community?.name ?? ''}&nbsp;
									{data.community?.id &&
										<NavLink className="text-decoration-none text-reset" to={'/communities/edit/' + data.community.id + '?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
									}
								</div>
							}
							placeholder="Comunidad"
							showPlaceholderHelper={true}
							value={data.community?.id}
						/>
						{ errors.community_id &&
							<div className="invalid-feedback d-block">{ errors.community_id[0] }</div>
						}
					</div>
				</div>

				<div className="col-md-12">
					<div className="mb-2">
						<div className="input-group">
							<EmpoweredSelector
								load={loadPetitioners}
								args={{community_id: data.community_id}}
								onChange={(value) => selectPetitioner(value)}
								timeout={250}
								label={
									<div>
										{data[data.petitioner_type]?.name ?? ''}&nbsp;
										{data[data.petitioner_type]?.id &&
											<NavLink className="text-decoration-none text-reset" to={'/'+data.petitioner_type+'s/edit/' + data[data.petitioner_type].id + '?community_id=' + data.community?.id + '&popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
										}
									</div>
								}
								placeholder="Solicitante"
								showPlaceholderHelper={true}
								value={data[data.petitioner_type]?.id}
							/>

							<div className="input-group-append">
								<React.Fragment>
									<button className="btn btn-sm btn-light2 text-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-plus-circle-fill"></i></button>
									<ul className="dropdown-menu dropdown-menu-end">
										<li><NavLink className={'dropdown-item ' + (!data.community_id ? 'disabled' : '')} to={'/owners/add?popup=true&community_id=' + data.community_id} onClick={openPopupInfoWindow}>Propietario</NavLink></li>
										<li><NavLink className={'dropdown-item ' + (!data.community_id ? 'disabled' : '')} to={'/renters/add?popup=true&community_id=' + data.community_id} onClick={openPopupInfoWindow}>Inquilino</NavLink></li>
										<li><NavLink className="dropdown-item" to={'/providers/add?popup=true&community_id=' + data.community_id} onClick={openPopupInfoWindow}>Proveedor</NavLink></li>
										<li><NavLink className={'dropdown-item ' + (!data.community_id ? 'disabled' : '')} to={'/clients/add?popup=true&community_id=' + data.community_id} onClick={openPopupInfoWindow}>Cliente</NavLink></li>
										<li><NavLink className="dropdown-item" to={'/contacts/add?popup=true&community_id=' + data.community_id} onClick={openPopupInfoWindow}>Contacto</NavLink></li>
									</ul>
								</React.Fragment>
							</div>
							
						</div>
					</div>
				</div>

				{/* <div className="col-md-6">
					<div className="mb-2">
						<label>Título del aviso</label>
						<textarea rows="3" className="form-control form-control-sm" onChange={(e) => setDataField('description', e.target.value)} value={data.description ?? ''} />
						{ errors.description &&
							<div className="invalid-feedback d-block">{ errors.description[0] }</div>
						}
					</div>
				</div> */}

				<div className="col-md-12">
					<div className="mb-2">
						<CustomInput label="Título" className="form-control form-control-sm" onChange={(e) => setDataField('title', e.target.value)} value={data.title ?? ''} />
						{ errors.title &&
							<div className="invalid-feedback d-block">{ errors.title[0] }</div>
						}
					</div>
				</div>

				<div className="col-md-12">
					<div className="mb-2">
						<CustomTextarea label="Detalle" rows="3" className="form-control form-control-sm" onChange={(e) => setDataField('observations', e.target.value)} value={data.observations ?? ''} />
						{ errors.observations &&
							<div className="invalid-feedback d-block">{ errors.observations[0] }</div>
						}
					</div>
				</div>

				<div className="col-md-12">
					<div className="mb-2">
						<CustomTextarea label="Instrucciones" rows="3" className="form-control form-control-sm" onChange={(e) => setDataField('instructions', e.target.value)} value={data.instructions ?? ''} />
						{ errors.instructions &&
							<div className="invalid-feedback d-block">{ errors.instructions[0] }</div>
						}
					</div>
				</div>

				<div className="col-md-4">
					<div className="mb-2">
						<CustomSelect label="Prioridad" className="form-control form-control-sm" value={data.priority} onChange={(e) => setDataField('priority', e.target.value)}>
							<option value="3">Alta</option>
							<option value="2">Normal</option>
							<option value="1">Baja</option>
						</CustomSelect>
					</div>
				</div>

				<div className="col-md-4">
					<div className="mb-2">
						<CustomSelect label="Origen" className="form-control form-control-sm" value={data.character} onChange={(e) => setDataField('character', e.target.value)}>
							<option value="community">Comunitario</option>
							<option value="private">Privativo</option>
							<option value="external">Externo</option>
						</CustomSelect>
					</div>
				</div>

				<div className="col-md-4">
					<div className="mb-2">
						<EmpoweredSelector
							load={(input, callback, args) => loadUsers(input, callback, args)}
							onChange={(value) => selectUser(value)}
							timeout={250}
							label={data.user?.name ?? ''}
							placeholder="Asignado a"
							showPlaceholderHelper={true}
							value={data.user?.id}
						/>
						{/* { data.opener?.id &&
							<FieldSmallComment className="text-primary">Creado por <u>{data.opener.name}</u></FieldSmallComment>
						} */}
						{ errors.user_id &&
							<div className="invalid-feedback d-block">{ errors.user_id[0] }</div>
						}
					</div>
				</div>

				<div className="col-md-4">
					<div className="mb-2">
						<StatusSelectorStyled>
							<CustomSelect label="Estado" className={'form-control form-control-sm status-' + (data.status ?? '')} onChange={(e) => setDataField('status', e.target.value)} value={data.status ?? ''}>
								<option value="pending">Pendiente</option>
								<option value="progress">En curso</option>
								<option value="expired">Vencido</option>
								<option value="closed">Terminado</option>
							</CustomSelect>
						</StatusSelectorStyled>
						{ errors.status &&
							<div className="invalid-feedback d-block">{ errors.status[0] }</div>
						}
					</div>
				</div>

				<div className="col-md-4">
					<div className="mb-2">
						<CustomInput 
							label="Fecha de inicio" 
							type="datetime-local" 
							className="form-control form-control-sm" 
							value={data.init_at ? moment(data.init_at).format('YYYY-MM-DDTHH:mm') : ''} 
							onChange={(e) => setDataField('init_at', e.target.value)} 
						/>
					</div>
				</div>

				<div className="col-md-4">
					<div className="mb-2">
						<div className="input-group input-group-sm">
							<CustomInput label="Fecha de vencimiento" type="datetime-local" className="form-control form-control-sm" value={data.expires_at ? moment(data.expires_at).format('YYYY-MM-DDTHH:mm') : ''} onChange={(e) => setExpiresDate(e, null, null, e.target.value)} />
							<button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
							<ul className="dropdown-menu dropdown-menu-end">
								<li><a className="dropdown-item" href="." onClick={(e) => setExpiresDate(e, 15, 'minutes')}>15 minutos</a></li>
								<li><a className="dropdown-item" href="." onClick={(e) => setExpiresDate(e, 30, 'minutes')}>30 minutos</a></li>
								<li><a className="dropdown-item" href="." onClick={(e) => setExpiresDate(e, 45, 'minutes')}>45 minutos</a></li>
								<li><a className="dropdown-item" href="." onClick={(e) => setExpiresDate(e, 1, 'hours')}>1 hora</a></li>
								<li><a className="dropdown-item" href="." onClick={(e) => setExpiresDate(e, 2, 'hours')}>2 hora</a></li>
								<li><a className="dropdown-item" href="." onClick={(e) => setExpiresDate(e, 4, 'hours')}>4 horas</a></li>
								<li><a className="dropdown-item" href="." onClick={(e) => setExpiresDate(e, 8, 'hours')}>8 horas</a></li>
								<li><a className="dropdown-item" href="." onClick={(e) => setExpiresDate(e, 12, 'hours')}>12 horas</a></li>
								<li><a className="dropdown-item" href="." onClick={(e) => setExpiresDate(e, 24, 'hours')}>24 horas</a></li>
							</ul>
							{(data.id && expirationDatesHistorial?.length > 0) &&
								<div className="input-group-append">
									<button className="btn btn-sm btn-light2 text-primary" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-clock-fill"></i></button>
									<ul className="dropdown-menu dropdown-menu-end">
										{expirationDatesHistorial?.map((el, idx) => {
											return (
												<li className="dropdown-item" key={idx}>{moment(el.date).format('DD-MM-YYYY HH:mm')}</li>
											);
										})}
									</ul>
								</div>
							}
						</div>
						{ errors.expires_at &&
							<div className="invalid-feedback d-block">{ errors.expires_at[0] }</div>
						}
					</div>
				</div>
			</div>	
		</LabeledFrame>
	);
}