import React, { useEffect } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import Diary from './Diary';
import Notices from './Notices';
import ProviderInvoicesNoFiles from './ProviderInvoicesNoFiles';
import BankReconciliationPending from './BankReconciliationPending';
import UsefulLinks from './UsefulLinks';
import CommunitySituation from './CommunitySituation';
import { getCommunity } from 'helpers/community';
import { openPopupWindow } from 'helpers/generic';

const StyledDashboard = styled.section` 

	.card-footer {
		position: static;
	}
`;

let axiosCancelToken = null;

export default function Dashboard() {
	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	const openPopupInfoWindow = (e) => {
		e.preventDefault();
		e.stopPropagation();

		openPopupWindow(e.currentTarget.href);
	}

	return (
		<LoggedLayout>
			<StyledDashboard>
				<div className="page-title">
					<h1>
						Dashboard&nbsp;
						{ getCommunity()?.id &&
							<React.Fragment>
								de 
								<span className="badge bg-white text-primary mx-2">{getCommunity()?.name}</span>
								<span className="badge bg-white text-primary">{getCommunity()?.vatnumber}</span>
							</React.Fragment>
						}
					</h1>
				</div>

				<div className="page-content">
					<div className="row">
						<div className="col-md-6">
							{ getCommunity()?.id &&
								<React.Fragment>
									<NavLink className="btn btn-sm btn-outline-primary bg-white me-2" to={'/communities/edit/' + getCommunity()?.id}>Comunidad</NavLink>	
									<NavLink className="btn btn-sm btn-outline-primary bg-white" to={'/documents/?folder_id=' + getCommunity()?.folder_id} onClick={openPopupInfoWindow}>Documentación</NavLink>		
								</React.Fragment>
							}
						</div>
						<div className="col-md-6"></div>

						<div className="col-md-6 mb-2 mt-2">
							<Notices />
							<div className="mt-3">
								<ProviderInvoicesNoFiles />
							</div>
							<div className="mt-3">
								<BankReconciliationPending />
							</div>
						</div>
						<div className="col-md-6 mb-2 mt-2">
							<UsefulLinks />
							{ getCommunity()?.id &&
								<CommunitySituation />
							}
							<Diary />
						</div>
					</div>
				</div>
			</StyledDashboard>
		</LoggedLayout>
	);
}


