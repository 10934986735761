import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import styled from 'styled-components';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { getCommunity } from 'helpers/community';
import { loader } from 'helpers/generic';
import Filters from "./Filters";

const ModalStyled = styled.div`

`;

const TableOwners = styled.table`
	font-size: 13px;

	tr {
		&.disabled {
			display: none;
		}
	}

	th {
		font-weight: 600;

		label {
			font-size: unset;
			color: unset;
			user-select: none;
			cursor: pointer;
			display: block !important;
			text-align: center;
			width: 100%;

			&:hover {
				color: var(--bs-primary);
			}
		}
	}

	th,
	td {
		vertical-align: middle;

		&:nth-child(1) {
			position: relative;
		}

		&:nth-child(2) {
			width: 200px;
			white-space: nowrap;
			
			& > div:last-of-type {
				display: flex;
				flex-direction: column;
				align-items: end;

				label {
					display: flex;
					align-items: center;
					font-size: 10px;

					input {
						margin-left: 5px;
						font-size: 12px;
						margin-top: -2px;
					}
				}

				.representative-inputs {
					display: flex;
					align-items: end;
					flex-direction: column;
					width: 100%;

					input {
						height: auto;
						min-height: auto;	
						padding: 0px 5px;
						font-size: 12px;
						box-shadow: none;

						&:first-of-type {
							margin-bottom: 5px;
						}
					}
				}
			}

			.owner-row {
				white-space: nowrap;
				display: flex;
				align-items: center;
				font-size: 11px;

				span {
					margin-left: 5px;
					margin-right: 5px;
					font-size: 9px;
				}

				input {
					margin-top: 0;
				}

				&:not(:last-of-type) {
					/* margin-bottom: 5px; */
				}

				&.disabled {
					opacity: 0.5;
				}
			}
		}

		&:nth-child(3),
		&:nth-child(4),
		&:nth-child(5),
		&:nth-child(6),
		&:nth-child(7) {
			width: 50px;
			white-space: nowrap;
			text-align: center;
		}

		&:nth-child(3) {
			width: 60px;
		}


	}
`;

let axiosCancelToken = null;

export default function PersonsModal(props) {
	const modalRef = useRef(null);

	const closeCallback = props.closeCallback;
	const data = props.data;
	const setDataField = props.setDataField;
	const savedSelectionRef = useRef(data.properties);

	const [showLoader, setShowLoader] = useState(false);
	const [properties, setProperties] = useState([]);
	const [selection, _setSelection] = useState([]);
	const setSelection = (data) => {
		_setSelection(data);
		updateData(data);
	}
	const [filters, setFilters] = useState({});

	let [modalObj, setModalObj] = useState(null);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
		
		const modal = Modal.getOrCreateInstance(modalRef.current, {
			backdrop: 'static',
			keyboard: false
		});
		modal.show();
		setModalObj(modal);

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getProperties = async () => {
			if ( !Object.keys(filters).length ) return; // Prevent load if filters not ready

			setShowLoader(true);

			let params = {
				community_id: getCommunity()?.id,
				no_paginate: true,
				sort: 'number',
				direction: 'asc',
				with_balances: true,
			};
			if ( filters.types.length ) params.types_ids = filters.types;
			if ( filters.streets.length ) params.streets_ids = filters.streets;
			if ( filters.buildings.length ) params.buildings_ids = filters.buildings;
			if ( filters.stairs.length ) params.stairs_fix = filters.stairs;
			if ( filters.plants.length ) params.plants = filters.plants;
			if ( filters.doors.length ) params.doors = filters.doors;

			await axios.get('/api/manager/properties/list', {
				params: params,
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setProperties([...response.data]);

		    	// Prepare initial selection
		    	let prepareSelection = response.data.map((el) => {
		    		return {
		    			property_id: el.id,
						coefficient: parseFloat(el.coefficient),
						balance: parseFloat(el.receiptscontableaccount?.balance ?? 0),
						owners: el.owners.map(
		    				oEl => ({
		    					owner_id: oEl.id,
		    					name: oEl.name,
								vatnumber: oEl.vatnumber,
		    					checked: false
		    				})
		    			),
		    			representative: null,
		    			summoned: false,
		    			attend: false,
		    			vote: false
		    		};
			    });

		    	// Set checks automatically if meeting is new (has no id)
		    	if ( !savedSelectionRef.current || !savedSelectionRef.current.length ) {
			    	prepareSelection.forEach((el) => {
			    		// Owners
			    		el.owners.forEach((owner) => {
			    			let selectedInAnotherProperty = prepareSelection.filter((fEl) => {
			    				let ownerSelected = fEl.owners.filter(pEl => pEl.owner_id === owner.owner_id && fEl.property_id !== el.property_id && pEl.checked).length > 0;
			    				return ownerSelected ? true : false;
			    			}).length;
			    			if ( !selectedInAnotherProperty ) owner.checked = true;
			    		});

			    		// Summoned
			    		el.summoned = el.owners.filter(pEl => pEl.checked).length > 0;

			    		// Vote
			    		el.vote = el.owners.filter(pEl => pEl.checked).length > 0 && el.balance >= 0;    		
			    	});
			    }

					    	// Mix with saved selection
		    	if ( savedSelectionRef.current?.length > 0 ) {
		    		savedSelectionRef.current.forEach(el => {
		    			let idx = prepareSelection.findIndex(pEl => pEl.property_id === el.property_id);
		    			if ( idx === -1 ) return;

		    			prepareSelection[idx].id = el.id;
		    			prepareSelection[idx].representative = el.representative;
		    			prepareSelection[idx].summoned = el.summoned;
		    			prepareSelection[idx].attend = el.attend;
		    			prepareSelection[idx].vote = el.vote;

						let ownerIdx = prepareSelection[idx].owners.findIndex(oEl => oEl.owner_id === el.owner_id);
						if ( ownerIdx !== -1 ) prepareSelection[idx].owners[ownerIdx].checked = true;
		    		});
		    	}

		    	setSelection(prepareSelection);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});

			setShowLoader(false);
		}
		getProperties();
	}, [filters]);

	const setField = (idx, field, value) => {
		let newSelection = [...selection];
		newSelection[idx][field] = value;

		// Handle dependant checks
			if ( field === 'summoned' && value === false ) {
				newSelection[idx].attend = null;
				newSelection[idx].vote = null;
			}
			if ( field === 'summoned' && value === true ) {
				newSelection[idx].vote = newSelection[idx].balance >= 0;
			}

		setSelection(newSelection);
	}

	const updateData = (data) => {
		// Transform data to database style
		let toSave = data.map(el => {
			return {
				id: el.id,
				property_id: el.property_id,
				property: properties.filter(pEl => pEl.id === el.property_id)[0] ?? null,
				representative: el.representative,
				summoned: el.summoned ?? false,
				attend: el.attend ?? false,
				vote: el.vote ?? false,
				owner_id: el.owners.filter(oEl => oEl.checked)[0]?.owner_id ?? null,
				owner: el.owners.filter(oEl => oEl.checked)[0] ?? null,
			};
		});

		// Update state
		setDataField('properties', toSave);
		setDataField('filters', filters);
	}

	// This functions checks if owner is selected on a property different than propertyId
	const isOwnerSelected = (ownerId, propertyId) => {
		return selection.filter((el) => {
			let ownerChecked = el.owners.filter(oEl => oEl.owner_id === ownerId && oEl.checked ).length > 0 ? true : false;
			if ( el.property_id !== propertyId && ownerChecked ) return true;
			return false;
		}).length > 0 ? true : false;
	}

	const toggleOwner = (idx, ownerIdx, status) => {
		let newSelection = [...selection];
		let newOwners = [...selection[idx]?.owners ?? []];

		if ( status ) { // If going to active a owner, first uncheck all from the property
			newOwners.forEach(el => el.checked = false);
			setField(idx, 'representative', false);
		}

		if ( status && selection[idx] && isOwnerSelected(selection[idx].owners[ownerIdx].owner_id, selection[idx].property_id) ) return; // Check if this owner is active in another property
		newOwners[ownerIdx].checked = status;
		newSelection[idx].owners = newOwners;

		// handle dependant checks
		if ( !status && newOwners.filter(el => el.checked).length === 0 ) {
			newSelection[idx].summoned = null;
			newSelection[idx].attend = null;
			newSelection[idx].vote = null;
		}

		setSelection(newSelection);
	}

	const toggleRepresentative = (idx, status) => {
		setField(idx, 'representative', status);

		if ( status ) { // If going to be active, first uncheck all owners from the property
			let newSelection = [...selection];
			let newOwners = [...selection[idx]?.owners ?? []];
			newOwners.forEach(el => el.checked = false);
			setSelection(newSelection);
		}
	}

	const setRepresentativeField = (idx, field, value) => {
		let newRepresentative = selection[idx].representative ? {...selection[idx].representative} : {name: '', vatnumber: ''};
		newRepresentative[field] = value;
		setField(idx, 'representative', newRepresentative);
	}

	const hideModal = async (saveData = true) => {
		// Hide modal
		modalObj.hide();
		modalObj.dispose();

		closeCallback(saveData);

		// Fix
		document.body.style.overflow = 'auto'; 
		document.body.style.paddingRight = '0';
	}

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-xl">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Convocados y asistentes</h5>
						<button type="button" className="btn-close" onClick={() => hideModal(false)} aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<div className="filters mb-3">
							<Filters 
								filters={data.filters}
								community={getCommunity()}
								onChange={(filters) => {
									setFilters({...filters});
								}}
							/>
						</div>

						<div className="text-center">
							{ showLoader && loader }
						</div>

						{ !showLoader &&
							<>
								<div className="table-responsive">
									<TableOwners className="table table-sm table-bordered mb-0">
										<thead>
											<tr>
												<th>Propiedad</th>
												<th>Propietarios</th>
												<th>%</th>
												<th>Saldo</th>
												<th>Convocado</th>
												<th>Vota</th>
												<th>Asiste</th>
											</tr>
										</thead>
										<tbody>
											{properties?.map((el, idx) => {
												let propertySelection = selection.filter((sEl) => sEl.property_id === el.id)[0] ?? null;
												let hasOwnerSelected = propertySelection?.owners?.filter(oEl => oEl.checked).length > 0 ? true : false;

												return (
													<tr key={idx}>
														<td>({el.number}) {el.name}</td>
														<td>
															{el.owners.map((oEl, oIdx) => {
																let ownerChecked = propertySelection?.owners.filter(osEl => osEl.owner_id === oEl.id)[0].checked ?? false;
																let ownerDisabled = isOwnerSelected(oEl.id, el.id);
														
																return (
																	<div className={'owner-row ' + (ownerDisabled ? 'disabled' : '')} key={oIdx}>
																		<input 
																			type="checkbox" 
																			className="form-check-input me-1" 
																			checked={ownerChecked ? true : false} 
																			onChange={(e) => toggleOwner(idx, oIdx, e.target.checked)} 
																			disabled={ownerDisabled}
																		/>
																		{oEl.name} <span>{oEl.vatnumber}</span>
																	</div>
																);
															})}

															<div>
																<label>
																	Añadir representante
																	<input 
																		type="checkbox" 
																		className="form-check-input" 
																		checked={propertySelection?.representative ? true : false} 
																		onChange={(e) => toggleRepresentative(idx, e.target.checked)} 
																	/>
																</label>

																{ propertySelection?.representative &&
																	<div className="representative-inputs">
																		<input 
																			type="text" 
																			className="form-control form-control-sm"
																			value={propertySelection?.representative?.name ?? ''} 
																			onChange={(e) => setRepresentativeField(idx, 'name', e.target.value)} 
																			placeholder="Nombre"
																		/>
																		<input 
																			type="text" 
																			className="form-control form-control-sm"
																			value={propertySelection?.representative?.vatnumber ?? ''} 
																			onChange={(e) => setRepresentativeField(idx, 'vatnumber', e.target.value)} 
																			placeholder="Dni"
																		/>
																	</div>
																}
															</div>
														</td>
														<td>
															{ (propertySelection?.coefficient ?? 0).toFixed(2) } %
														</td>
														<td>
															{ (propertySelection?.balance ?? 0).toFixed(2) }
														</td>
														<td>
															<input 
																type="checkbox" 
																className="form-check-input" 
																checked={(hasOwnerSelected || propertySelection?.representative) && propertySelection?.summoned ? true : false} 
																onChange={(e) => setField(idx, 'summoned', e.target.checked)} 
																disabled={!hasOwnerSelected && !propertySelection?.representative}
															/>
														</td>
														<td>
															<input 
																type="checkbox" 
																className="form-check-input" 
																checked={propertySelection?.vote && propertySelection?.summoned ? true : false} 
																onChange={(e) => setField(idx, 'vote', e.target.checked)} 
																disabled={propertySelection?.summoned ? false : true}
															/>
														</td>
														<td>
															<input 
																type="checkbox" 
																className="form-check-input" 
																checked={propertySelection?.attend && propertySelection?.summoned ? true : false} 
																onChange={(e) => setField(idx, 'attend', e.target.checked)} 
																disabled={propertySelection?.summoned ? false : true}
															/>
														</td>
													</tr>
												);
											})}
											{properties.length <= 0 &&
												<tr>
													<td colSpan="100%">
														No se han encontrado propiedades
													</td>
												</tr>
											}
										</tbody>
									</TableOwners>
								</div>
							</>
						}
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-sm btn-primary text-white" onClick={() => hideModal(true)}>Aceptar</button>
					</div>
				</div>
			</div>
		</ModalStyled>
	);
}


