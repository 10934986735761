import React, { useState, useEffect } from 'react';
import { Navigate, NavLink, useParams } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import LoggedLayout from "layouts/LoggedLayout";
import TrSkeleton from "components/TrSkeleton";
import { getCommunity } from 'helpers/community';
import { getRouterBasename } from 'helpers/config';
import { formatNumber, jsonToArray, openPopupWindow } from 'helpers/generic';
import ReconciliationModal from './ReconciliationModal';
import CounterPartsModal from './CounterPartsModal';

const Table = styled.table`

	td {
		padding: 2px 5px;
		font-size: 13px;

		.badge {
			padding: 0.2em 0.45em;
			cursor: pointer;

			&:hover {
				box-shadow: 0px 0px 5px white;
			}
		}

		&.red, .red {
			color: red !important;
			font-weight: bold;
		}

		&.green, .green {
			color: green !important;
			font-weight: bold;
		}

		&.reconciliation-btn-wrapper {
			vertical-align: middle;
			width: 35px;
			min-width: 35px;
			max-width: 35px;

			button {
				&:hover {
					transform: scale(1.2);
				}

				&:active {
					transform: scale(1.3);
					color: var(--bs-secondary);
				}
			}
		}
	}

	tr {
		&:first-of-type {
			border-top-color: transparent;
		}

		&:last-of-type {
			border-bottom-color: transparent;
		}
		
		& > th:first-of-type,
		& > td:first-of-type {
			border-left-color: transparent;
		}

		& > th:last-of-type,
		& > td:last-of-type {
			border-right-color: transparent;
		}

		& > th,
		& > td {
			&:nth-child(1),
			&:nth-child(6) {
				min-width: 90px;
				max-width: 90px;
			}

			&:nth-child(2),
			&:nth-child(7) {
				min-width: 300px;
			}
		}

		&.left{
			&:hover {
				td {
					&:nth-child(1),
					&:nth-child(2),
					&:nth-child(3),
					&:nth-child(4) {
						background-color: var(--bs-gray-400);
						cursor: pointer;
					}
				}
			}

			&.selected {
				td {
					&:nth-child(1),
					&:nth-child(2),
					&:nth-child(3),
					&:nth-child(4) {
						background-color: var(--bs-success);
						cursor: pointer;
					}
				}
			}
		}

		&.right {
			&:hover {
				td {
					&:nth-child(6),
					&:nth-child(7),
					&:nth-child(8) {
						background-color: var(--bs-gray-400);
						cursor: pointer;
					}
				}
			}

			&.selected {
				td {
					&:nth-child(6),
					&:nth-child(7),
					&:nth-child(8) {
						background-color: var(--bs-success);
						cursor: pointer;
					}
				}
			}
		}

		&.reconciliated {
			td {
				background: rgba(var(--bs-primary-rgb), 0.3);
				position: relative;

				.reconciliations {
					display: flex;
					flex-direction: column;
					width: 100%;
					height: 100%;

					& > div {
						min-height: 50%;
						line-height: 120%;
						padding: 2px 5px;
						border-bottom: 1px solid var(--bs-gray-200);

						&:last-of-type {
							border-bottom: 1px solid transparent;
						}
					}

					.providerinvoice-counterparts {
						padding-left: 18px;
						font-size: 9px;
						line-height: 100%;

						div {
							&:first-of-type {
								margin-top: 3px;
							}

							&:not(:last-of-type) {
								margin-bottom: 5px;
							}
						}
					}
				}
			}
		}

		&.partial {
			td {
				background-color: rgba(var(--bs-indigo-rgb), 0.4);

				&:nth-child(6),
				&:nth-child(7),
				&:nth-child(8) {
					cursor: pointer;

				}
			}

			&.partial_selected {
				td {
					&:nth-child(6),
					&:nth-child(7),
					&:nth-child(8) {
						background-color: var(--bs-success);
						cursor: pointer;
					}
				}
			}
		}

		&.hidden {
			display: none;
		}
	}

	th,td {
		user-select: none;

		&:nth-child(1),
		&:nth-child(6) {
			max-width: 100px;
		}

		&:nth-child(2),
		&:nth-child(7) {
			max-width: 300px;
		}

		&:nth-child(3),
		&:nth-child(8) {
			max-width: 150px;
			text-align: right;
		}

		&:nth-child(4) {
			text-align: right;
		}

		&:nth-child(5) {
			/* width: 50px; */
			background: var(--bs-gray-200);
		}
	}
`;

const ButtonReconciliation = styled.button`
	position: fixed;
	display: flex;
	align-items: center;
	bottom: 20px;
	left: 50%;
	width: 250px;
	margin-left: -125px;
	z-index: 99;
	color: white;
	font-size: 16px;
	line-height: 18px;
	text-transform: uppercase;
	box-shadow: 1px 1px 10px rgba(0,0,0,0.2);
`;

let axiosCancelToken = null;

let switchToPreventMultipleRefreshingAtSameTime = false;

export default function Treasury() {
	let community = getCommunity();
	let { id } = useParams();

	let [forceReload, setForceReload] = useState(null);
	let [treasury, setTreasury] = useState(undefined);
	let [movements, setMovements] = useState(undefined);
	let [reconciliationModalOpened, setReconciliationModalOpened] = useState(false);
	let [counterPartModalOpened, setCounterPartModalOpened] = useState(false);
	let [selectedMovements, setSelectedMovements] = useState([]);
	let [dateFrom, _setDateFrom] = useState(moment().startOf('year').format('YYYY-MM-DD'));
	let setDateFrom = (val) => {
		_setDateFrom(val);
		setMovements(undefined);
	}
	let [dateTo, _setDateTo] = useState(moment().endOf('year').format('YYYY-MM-DD'));
	let setDateTo = (val) => {
		_setDateTo(val);
		setMovements(undefined);
	}
	let [loading, setLoading] = useState(false);
	let [showNoReconciliatedOnly, setShowNoReconciliatedOnly] = useState(false);
	let [expensesIncomesFilter, setExpensesincomesFilter] = useState('all');
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [search, setSearch] = useState('');

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			await axios.get('/api/manager/bank-reconciliation/' + id + '/movements-list', {
				params: {
					community_id: community?.id,
					date_from: dateFrom,
					date_to: dateTo
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setMovements({...response.data});

				let skeletonRows = Object.keys(response.data).length;
				if ( skeletonRows < 5 ) skeletonRows = 5;
		    	setSkeletonRows(skeletonRows);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
			await axios.get('/api/manager/treasury/get/' + id, {
				params: {
					community_id: community?.id,
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setTreasury({...response.data});
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
			setLoading(false);
		}
		getData();
	}, [id, dateFrom, dateTo, community?.id, forceReload]);

	// Execute height fix
	useEffect(() => {
		setTimeout(() => {
			let elementsToFixHeight = document.querySelectorAll('[data-heightfixto]');
			elementsToFixHeight.forEach((el) => {
				let idx = el.dataset.heightfixto;

				// Find the FROM element
				let fromEl = document.querySelector('[data-heightfixfrom="' + idx + '"]');
				if ( !fromEl ) return;

				el.style.height = fromEl.offsetHeight + 'px';
			});
		}, 100);
	}, [movements]);

	const addYears = (qty) => {
		let newDateTo = moment(dateTo).add(qty, 'years').format('YYYY-MM-DD');
		let newDateFrom = moment(dateFrom).add(qty, 'years').format('YYYY-MM-DD');

		setDateFrom(newDateFrom);
		setDateTo(newDateTo);
	}

	const selectMovement = (movement) => {
		let newSelectedMovements = [...selectedMovements];
		let selectedIdx = newSelectedMovements.findIndex((el) => el.class === movement.class && el.id === movement.id);
		if ( selectedIdx !== -1 ) newSelectedMovements.splice(selectedIdx, 1);
		else newSelectedMovements.push(movement);
		setSelectedMovements(newSelectedMovements); 
	}

	const removeReconciliation = (value) => {
		let c = window.confirm('¿Seguro que quieres desconciliar estos movimientos?');
		if ( !c ) return;

		let id = value.reconciliation[0].bank_reconciliation_id;

		axios.post('/api/manager/bank-reconciliation/delete/' + id, {
            community_id: getCommunity()?.id,
        }, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.warning('Movimientos desconciliados');

			// Get dates to reupdate
			let dates = [];
			dates.push(value.date);
			value.reconciliation?.forEach(el => {
				if ( el.entity?.date ) dates.push(el.entity.date);
			});

			// Reupdate
			refreshRowsFromDates(dates);

			// setForceReload(Math.random());
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const showReconciliationButton = (() => {
		let left = 0;
		let right = 0;

		let onlyReceiptsSelected = selectedMovements.filter(el => el.class.indexOf('BankMovement') === -1).every(el => el.class.indexOf('Receipt') !== -1);
		let onlyBankRemittancesSelected = selectedMovements.filter(el => el.class.indexOf('BankMovement') === -1).every(el => el.class.indexOf('BankRemittance') !== -1);
		let receiptsReunificateFirstPropertyId = null;
		let receiptsToReunificate = 0;
		let partialReceiptsSelected = false;
		let receiptsFromDifferentPropertiesButSameOwner = 0;
		let receiptsFromDifferentPropertiesButSameOwnerIds = [];
		let onlyProviderInvoicesSelected = selectedMovements.filter(el => el.class.indexOf('BankMovement') === -1).every(el => el.class.indexOf('ProviderInvoice') !== -1);
		let providerInvoicesFromSameProviderId = null;
		let partialInvoicesSelected = selectedMovements.filter(el => el.class.indexOf('BankMovement') === -1).some(el => el.class.indexOf('ProviderInvoice') !== -1 && el.partial);

		selectedMovements.forEach((el) => {
			if ( el.class === 'App\\Models\\BankMovement' ) left += 1;
			else right += 1;

			if ( el.class === 'App\\Models\\Receipt' ) {
				if ( el.partial ) partialReceiptsSelected = true;

				// Checks for reunification receipts
				if ( receiptsReunificateFirstPropertyId && receiptsReunificateFirstPropertyId === el.property_id ) receiptsToReunificate++;
				else receiptsToReunificate = 0;
				if ( !receiptsReunificateFirstPropertyId ) receiptsReunificateFirstPropertyId = el.property_id;

				// Checks for multiple receipts selected and different properties
				if ( receiptsFromDifferentPropertiesButSameOwnerIds.length === 0 ) {
					receiptsFromDifferentPropertiesButSameOwnerIds = [...el.owners_ids];
					receiptsFromDifferentPropertiesButSameOwner++;
				} else {
					let hasOwner = el.owners_ids?.some(v => receiptsFromDifferentPropertiesButSameOwnerIds.includes(v));
					if ( hasOwner ) receiptsFromDifferentPropertiesButSameOwner++;
				}
			}

			if ( el.class === 'App\\Models\\ProviderInvoice' ) {
				if ( !providerInvoicesFromSameProviderId ) providerInvoicesFromSameProviderId = el.provider_id;
				else if ( providerInvoicesFromSameProviderId !== el.provider_id ) providerInvoicesFromSameProviderId = null;
			}
		});

		let allReceiptsWithCommonOwner = receiptsFromDifferentPropertiesButSameOwner > 0 && receiptsFromDifferentPropertiesButSameOwner === selectedMovements.filter(el => el.class.indexOf('Receipt') !== -1).length;

		return 	(left === 1 && right === 0) || 
			  	(left === 1 && right === 1) || 
				(left === 1 && right > 0 && onlyReceiptsSelected && receiptsToReunificate > 0 && !partialReceiptsSelected) || 
				(left === 1 && right > 0 && onlyReceiptsSelected && allReceiptsWithCommonOwner && !partialReceiptsSelected) ||
				(left >= 1 && right === 1 && onlyBankRemittancesSelected) || 
				(left >= 1 && right >= 1 && onlyProviderInvoicesSelected && providerInvoicesFromSameProviderId !== null && !partialInvoicesSelected )
		;
	})();

	const openEntity = async (e, entity, otherData = null) => {
		e.stopPropagation();

		if ( entity.class.indexOf('ProviderInvoice') !== -1 ) openPopupWindow(getRouterBasename() + '/providers-invoices/edit/' + entity.id + '?popup=true&hidden_sections=delete_button');
		if ( entity.class.indexOf('Receipt') !== -1 ) openPopupWindow(getRouterBasename() + '/receipts/edit/' + entity.id + '?popup=true');
		if ( entity.class.indexOf('BankRemittance') !== -1 ) {
			openPopupWindow(getRouterBasename() + '/bankremittances?popup=true&id=' + entity.id);
		}
		if ( entity.class.indexOf('ClientInvoice') !== -1 ) openPopupWindow(getRouterBasename() + '/clients-invoices/edit/' + entity.id + '?popup=true');
		if ( entity.class.indexOf('EstimateGroupExpense') !== -1 ) {
			if ( otherData.is_deletable ) {
				setCounterPartModalOpened(otherData);
			} else {
				let w = openPopupWindow();
				await axios.get('/api/manager/estimates/get-id-by-groupexpense/' + entity.id, {
					params: {
						community_id: getCommunity()?.id,
					},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
					w.location.href = getRouterBasename() + '/estimates/edit/' + response.data.id + '?popup=true';
				}).catch((error) => {
					if ( axios.isCancel(error) ) return;
					toast.error('Ha ocurrido un error');
				});	
			}
		}

	}

	const isHiddenBySearchRow = (el) => {
		return search.length > 0 && (
			el.title.toLowerCase().indexOf(search.toLowerCase()) === -1
			&&
			el.amount.toString().indexOf(search) === -1
			&&
			moment(el.date).format('DD-MM-YYYY').indexOf(search) === -1
		);
	}

	const isHiddenByReconciliated = (el) => {
		return showNoReconciliatedOnly && el.reconciliation;
	}

	const isHiddenByExpensesIncomesFilter = (el) => {
		if ( expensesIncomesFilter === 'expenses' ) return el.amount >= 0;
		if ( expensesIncomesFilter === 'incomes' ) return el.amount < 0;
		return false;
	}

	const refreshRowsFromDates = async (dates) => {
		if ( switchToPreventMultipleRefreshingAtSameTime ) return;

		switchToPreventMultipleRefreshingAtSameTime = true;

		// Remove duplicated dates
		dates = [...new Set(dates)];

		console.log('refreshrowFromDates', dates)

		// Loop dates and get movements from every date
		let newMovementsRows = {};
		for await ( let date of dates ) {
			await axios.get('/api/manager/bank-reconciliation/' + id + '/movements-list', {
				params: {
					community_id: community?.id,
					date_from: date,
					date_to: date
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				let newMovementsRow = response.data;
				newMovementsRows[date] = newMovementsRow[date] ?? [];
			}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		
		// Mix with movements and update state
		let newMovements = {...movements};
		for ( let date in newMovementsRows ) {
			newMovements[date] = newMovementsRows[date];
		}
		setMovements(newMovements);

		switchToPreventMultipleRefreshingAtSameTime = false;
	}

	if ( !community?.id ) return <Navigate to="/" />;

	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>
						Conciliación bancaria para&nbsp;
						<small><i>{ treasury?.name }</i></small>
					</h1>
					
					<NavLink to="/bank-reconciliation" className="btn btn-sm btn-light ms-auto">Volver</NavLink>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">	
									<div className="input-group input-group-sm">
										<span className="input-group-text">
											Fechas
										</span>
										<input type="date" className="form-control form-control-sm" placeholder="Desde" value={dateFrom ?? ''} onChange={(e) => setDateFrom(e.target.value)} disabled={loading} />
										<input type="date" className="form-control form-control-sm" placeholder="Hasta" value={dateTo ?? ''} onChange={(e) => setDateTo(e.target.value)} disabled={loading} />
									</div>
								</div>
								<div className="col-md-1 mb-2 mt-2 mb-md-0 mt-md-0">
									<div className="input-group">
										<button className="btn btn-sm btn-light" onClick={(e) => addYears(-1)} disabled={loading}><i className="bi bi-arrow-left"></i></button>
										<button className="btn btn-sm btn-light" onClick={(e) => addYears(1)} disabled={loading}><i className="bi bi-arrow-right"></i></button>
									</div>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<div className="d-inline-flex sbold me-5">Saldo real: { formatNumber(treasury?.real_balance, 2) } €</div>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<input type="search" className="form-control form-control-sm" style={{minHeight: 'unset'}} placeholder="Buscar" onChange={(e) => setSearch(e.target.value)} />
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<select value={expensesIncomesFilter} onChange={(e) => setExpensesincomesFilter(e.target.value)} className="form-control form-control-sm">
										<option value="all">Mostrar gastos e ingresos</option>
										<option value="expenses">Sólo gastos</option>
										<option value="incomes">Sólo Ingresos</option>
									</select>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0 text-center">
									<div className="form-check form-switch mb-0 h-100 d-inline-flex align-items-center">
										<input className="form-check-input mb-1 me-2" type="checkbox" checked={showNoReconciliatedOnly} onChange={(e) => setShowNoReconciliatedOnly(e.target.checked)} />
										<label className="form-check-label">Mostrar no conciliados</label>
									</div>
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded table-bordered">
									<thead>
										<tr>
											<th>Fecha</th>
											<th>Concepto</th>
											<th>Importe</th>
											<th>Saldo</th>
											<th></th>
											<th>Fecha</th>
											<th>Concepto</th>
											<th>Importe</th>
										</tr>
									</thead>
									<tbody>
										{ (movements && Object.keys(movements).length > 0) &&
											jsonToArray(movements).map((movementsRow, idx) => {
												return ( 
													<React.Fragment key={idx}>
														{jsonToArray(movementsRow.value).map((movement, movementIdx) => {
															let value = movement.value;
															let position = value.class === 'App\\Models\\BankMovement' ? 'left' : 'right';
															let selected = selectedMovements.findIndex((el) => el.class === value.class && el.id === value.id) !== -1;
															let partial = null;
															let partial_selected = false;
															let cannot_unlink = false;

															if ( movement.value.is_deletable === false ) cannot_unlink = true;

															if ( value.reconciliation ) {
																position = null;

																if ( value.reconciliation.length === 1 && value.reconciliation[0].entity && value.reconciliation[0].entity.partial ) {
																	partial = true;
																	partial_selected = selectedMovements.findIndex((el) => el.class === value.reconciliation[0].entity.class && el.id === value.reconciliation[0].entity.id) !== -1;
																}

																for(let vrIdx in value.reconciliation) {
																	let vrItem = value.reconciliation[vrIdx];
																	if ( vrItem.entity.cannot_unlink ) {
																		cannot_unlink = true;
																		break;
																	}
																}
															}


															// ---- Check if hidden ------------------------------------------------------------------
																let isHidden = isHiddenBySearchRow({
																	title: value.concept,
																	amount: value.amount,
																	date: value.date
																});

																// Check on associated reconciliations
																if ( isHidden && value.reconciliation && value.reconciliation.length > 0 ) {
																	for(let i=0; i<value.reconciliation.length; i++) {
																		let vr = value.reconciliation[i];
																		isHidden = isHiddenBySearchRow({
																			title: vr.entity.concept,
																			amount: vr.entity.amount,
																			date: vr.entity.date
																		});
																		if ( !isHidden ) break;
																	}
																}

																// Check if is hidden by no reconciliated
																if ( !isHidden ) {
																	isHidden = isHiddenByReconciliated(value);
																}

																// Check if is hidden expensesIncomesFilter
																if ( !isHidden ) {
																	isHidden = isHiddenByExpensesIncomesFilter(value);
																}
															//  --------------------------------------------------------------------------------------

															return (
																<tr key={movementIdx} className={(position ? position : '') + ' ' + (selected ? 'selected' : '') + ' ' + (value.reconciliation ? 'reconciliated' : '') + ' ' + (partial ? 'partial' : '') + ' ' + (partial_selected ? 'partial_selected' : '') + ' ' + (isHidden ? 'hidden' : '')}>
																	{ (!value.reconciliation || value.reconciliation.length === 0) ? // Can select to reconciliate
																		<React.Fragment>
																			<td onClick={(e) => position === 'left' ? selectMovement(movement.value) : e.preventDefault()}>
																				{ position === 'left' &&
																					moment(value.date).format('DD-MM-YYYY')
																				}
																			</td>
																			<td onClick={(e) => position === 'left' ? selectMovement(movement.value) : e.preventDefault()}>
																				{ position === 'left' &&
																					value.concept
																				}
																			</td>
																			<td className={value.amount < 0 ? 'red' : 'green'} onClick={(e) => position === 'left' ? selectMovement(movement.value) : e.preventDefault()}>
																				{ position === 'left' &&
																					formatNumber(value.amount)
																				}
																			</td>
																		</React.Fragment>
																		: // Is reconciliated
																		<React.Fragment>
																			<td>{moment(value.date).format('DD-MM-YYYY')}</td>
																			<td>
																				{value.concept}
																			</td>
																			<td className={value.amount < 0 ? 'red' : 'green'}>{formatNumber(value.amount)}</td>
																		</React.Fragment>
																	}

																	<td className={'bankbalance ' + (value.balance < 0 ? 'red' : 'green')} onClick={(e) => position === 'left' ? selectMovement(movement.value) : e.preventDefault()}>
																		{value.balance !== undefined ? formatNumber(value.balance, 2) : ''}
																	</td>
																	
																	<td className="text-center reconciliation-btn-wrapper">
																		{ (value.reconciliation && value.reconciliation.length > 0 && !cannot_unlink) &&
																			<button className="btn-unstyled" onClick={() => removeReconciliation(value)}><i className="bi bi-arrow-left-right"></i></button>
																		}
																		{ cannot_unlink &&
																			<button className="btn-unstyled pe-none" style={{cursor: 'pointer'}}><i className="bi bi-lock"></i></button>
																		}
																		{ (selected && showReconciliationButton) &&
																			<button className="btn-unstyled" onClick={() => setReconciliationModalOpened(true)}><i className="bi bi-arrow-up-circle-fill"></i></button>
																		}
																	</td>

																	{ (!value.reconciliation || value.reconciliation.length === 0) ? // Can select to reconciliate
																		<React.Fragment>
																			<td onClick={(e) => position === 'right' ? selectMovement(value) : e.preventDefault()}>
																				{ position === 'right' &&
																					moment(value.date).format('DD-MM-YYYY')
																				}
																			</td>
																			<td onClick={(e) => position === 'right' ? selectMovement(value) : e.preventDefault()}>
																				{ position === 'right' &&
																					<React.Fragment>
																						<span onClick={(e) => openEntity(e, value)} className={'badge ' + value.concept_color}>{value.concept_type}</span>
																						&nbsp;
																						{(value.class.indexOf('ProviderInvoice') !== -1 && !value.accounted) &&
																							<span className="badge badge-rounded bg-danger me-2">&nbsp;</span>
																						}
																						{value.concept}
																					</React.Fragment>
																				}
																			</td>
																			<td className={value.amount < 0 ? 'red' : 'green'} onClick={(e) => position === 'right' ? selectMovement(movementsRow.key, movement.key) : e.preventDefault()}>
																				{ position === 'right' &&
																					formatNumber(value.amount)
																				}
																			</td>
																		</React.Fragment>
																		: // Is reconciliated
																		<React.Fragment>
																			<td onClick={(e) => partial ? selectMovement(value.reconciliation[0].entity) : e.preventDefault()}>{moment(value.reconciliation[0].entity.date).format('DD-MM-YYYY')}</td>
																			<td onClick={(e) => partial ? selectMovement(value.reconciliation[0].entity) : e.preventDefault()} className="p-0">
																				<div className="reconciliations">
																					{ value.reconciliation.map((reconciliation, reconciliationIdx) => {
																						return (
																							<div 
																								key={reconciliationIdx}
																								onClick={(e) => partial ? selectMovement(reconciliation.entity) : e.preventDefault()}
																								data-heightfixfrom={value.id + '|' + reconciliationIdx}
																							>
																								<span className={'badge ' + reconciliation.entity.concept_color} onClick={(e) => openEntity(e, reconciliation.entity, value)}>{reconciliation.entity.concept_type}</span>&nbsp;
																								{(reconciliation.entity.class.indexOf('ProviderInvoice') !== -1 && !reconciliation.entity.accounted) &&
																									<span className="badge badge-rounded bg-danger me-2">&nbsp;</span>
																								}
																								{reconciliation.entity.concept ?? '-'}
																								{reconciliation.entity.class.indexOf('ProviderInvoice') !== -1 &&
																									<div className="providerinvoice-counterparts">
																										{reconciliation.entity.counterparts.map((counterpart, counterpartIdx) => {
																											return (
																												<div key={'pi' + reconciliation.entity.id + '-' + counterpartIdx}>
																													{counterpart.name}
																												</div>
																											);
																										})}
																									</div>
																								}
																							</div>
																						)
																					})}
																				</div>
																			</td>
																			<td onClick={(e) => partial ? selectMovement(value.reconciliation[0].entity) : e.preventDefault()} className="p-0">
																				<div className="reconciliations">
																					{ value.reconciliation.map((reconciliation, reconciliationIdx) => {
																						return (
																							<div 
																								key={reconciliationIdx} 
																								onClick={(e) => partial ? selectMovement(reconciliation.entity) : e.preventDefault()}
																								className={value.amount < 0 ? 'red' : 'green'}
																								data-heightfixto={value.id + '|' + reconciliationIdx}
																							>
																								{formatNumber(reconciliation.entity.amount)}
																							</div>
																						)
																					})}
																				</div>
																			</td>
																		</React.Fragment>
																	}
																</tr>
															);
														})}
													</React.Fragment>
												);
											})
										}

										{ (movements && !Object.keys(movements).length) && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ movements === undefined && <TrSkeleton rows={skeletonRows} columns={8} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								
							</div>
						</div>
					</div>
				</div>
				{ showReconciliationButton && 
					<ButtonReconciliation className="btn btn-primary" onClick={() => setReconciliationModalOpened(true)}>
						<i className="bi bi-arrow-left-right"></i>
						Conciliar movimientos seleccionados
					</ButtonReconciliation>
				}
			</section>

			{ reconciliationModalOpened && 
				<ReconciliationModal 
					movements={selectedMovements}
					closeCallback={(unselectSelectedMovements, showSkeletonOnReload) => {
						if ( unselectSelectedMovements ) setSelectedMovements([]);
						// if ( showSkeletonOnReload ) setMovements(undefined);


						// setForceReload(Math.random());
						let dates = selectedMovements.map(item => item.date);
						refreshRowsFromDates(dates);

						setReconciliationModalOpened(false);
					}}
				/>
			}

			{ counterPartModalOpened && 
				<CounterPartsModal 
					data={counterPartModalOpened}
					closeCallback={(showSkeletonOnReload) => {
						// setForceReload(Math.random());

						let dates = [counterPartModalOpened.date];
						refreshRowsFromDates(dates);

						setCounterPartModalOpened(false);

						if ( showSkeletonOnReload ) setMovements(undefined);
					}}
				/>
			}
		</LoggedLayout>
	);
}