import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import randomatic from 'randomatic';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader, openPopupWindow } from 'helpers/generic';
import { getCommunity, authUserPermission } from 'helpers/community';
import LabeledFrame from 'components/LabeledFrame';
import ActionsContextMenu from 'components/ActionsContextMenu';
import CustomInput from 'components/CustomInput';
import CustomTextarea from 'components/CustomTextarea';
import CustomSelect from 'components/CustomSelect';

let axiosCancelToken = null;

let parentProxyPopupCallbackTreasuries = window.opener?.ProxyPopupCallbackTreasuries;

export default function TreasuryForm(props) {
	let actionsDropdownRef = useRef(null);

	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';

	let [data, setData] = useState({
		code: randomatic('A0', 5),
		type: 'bankaccount',
	});
	let [currentFinancialYear, setCurrentFinancialYear] = useState(null);
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				await axios.get('/api/manager/treasury/get/' + params.id, {
					params: {
						community_id: getCommunity()?.id
					},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(response.data);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		}

		const getActualFinancialYear = async () => {
			await axios.get('/api/manager/financial-years/list', {
				params: {
					community_id: getCommunity()?.id
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setCurrentFinancialYear(response.data.data[0]?.year ?? null);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}
		getActualFinancialYear();
	}, [params.id]);

	useEffect(() => {
		window.onbeforeunload = () => {
			if ( parentProxyPopupCallbackTreasuries ) parentProxyPopupCallbackTreasuries();
		}
	}, []);

	const deleteTreasury = () => {
		const c = window.confirm('¿Quieres eliminar este elemento?');
		if ( !c ) return;

		axios.post('/api/manager/treasury/delete/' + data.id, {
			community_id: getCommunity()?.id,
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Elemento borrado')
	    	navigate('/treasury');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (goBack = true) => {
		setErrors({});

		data.community_id = getCommunity()?.id;

		axios.post('/api/manager/treasury/' + (data.id ? 'edit/' + data.id : 'add'), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			setData({...response.data.treasury});
			if ( goBack ) {
				if ( popup ) window.close();
				else navigate('/treasury');
			} else {
		    	navigate('/treasury/edit/' + response.data.treasury.id + (popup ? '?popup=true' : ''));
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const setDataDataField = (field, value) => {
		let newData = {...data.data};
		newData[field] = value;
		setData({...data, data: newData});

		// if ( field === 'iban' ) loadEntityAndSwift(value);
	}

	const loadEntityAndSwift = (iban) => {
		axios.get('/api/manager/treasury/get-entity-swift', {
			params: {
				iban: iban,
				community_id: getCommunity()?.id
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let accData = {...data.data} ?? {};

			if ( response.data.entity && response.data.swift ) {
				accData.entity = response.data.entity;
				accData.swift = response.data.swift;
				accData.iban = iban;
				setData({...data, data: accData});
			}

	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error al obtener la entidad y el swift');
		});	
	}

	const getBankByIban = async () => {
		let iban = data.data?.iban;

		setLoading(true);

		await axios.get('/api/manager/bankshelper/get-bank-by-iban', {
			params: {
				iban: iban
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let accData = {...data.data} ?? {};

			accData.owner = getCommunity()?.business_name;
			accData.entity = response.data?.name ?? accData.entity;
			accData.swift = response.data?.bic ?? accData.swift;
			accData.iban = iban;
			if ( !accData.suffix?.length ) accData.suffix = '000';
			accData.identifier = generateIdentifier(accData.suffix);

			setData({...data, data: accData});
		}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});

		setLoading(false);
	}

	const generateIdentifier = (suffix) => {
		// Format:ES ZZ XXX AAAAAAAAA https://github.com/jacano1969/sepa-1

		let cif = getCommunity().vatnumber;
		if ( !cif ) return;

		// Calc control digits
		let stringToCalc = cif + 'ES00';
		let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
		for(let i=0; i<stringToCalc.length-1; i++) {
			let charPosition = chars.indexOf(stringToCalc[i]);
			if ( charPosition === -1 ) continue;
			stringToCalc = stringToCalc.replace(stringToCalc[i], charPosition + 10);
		}
		let module = parseInt(stringToCalc) % 97;
		let controldigits = 98 - module;
		if ( controldigits < 10 ) controldigits = '0' + controldigits;

		let identifier = 'ES' + controldigits + suffix + cif;

		return identifier;
	}
	generateIdentifier();

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de tesorería</h1>
					<button onClick={() => popup ? window.close() : navigate('/treasury')} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col col-static-100">
											<div className="mb-2">
												<CustomInput label="Código" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('code', e.target.value)} value={data.code ?? ''} />
												{ errors.code &&
													<div className="invalid-feedback d-block">{ errors.code[0] }</div>
												}
											</div>
										</div>
										<div className="col col-static-200">
											<div className="mb-2">
												<CustomInput label="Nombre" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('name', e.target.value)} value={data.name ?? ''} />
												{ errors.name &&
													<div className="invalid-feedback d-block">{ errors.name[0] }</div>
												}
											</div>
										</div>
										<div className="col col-static-180">
											<div className="mb-2">
												<CustomSelect label="Tipo" className="form-control form-control-sm" onChange={(e) => setDataField('type', e.target.value)} value={data.type ?? ''}>
													<option value="bankaccount">Cuenta bancaria</option>
													<option value="cash">Caja / efectivo</option>
												</CustomSelect>
												{ errors.type &&
													<div className="invalid-feedback d-block">{ errors.type[0] }</div>
												}
											</div>
										</div>
										<div className="col col-static-100">
											<div className="mb-2">
												<CustomSelect label="Por defecto" className="form-control form-control-sm" onChange={(e) => setDataField('default', e.target.value)} value={data.default ?? ''}>
													<option value="0">No</option>
													<option value="1">Si</option>
												</CustomSelect>
												{ errors.default &&
													<div className="invalid-feedback d-block">{ errors.default[0] }</div>
												}
											</div>
										</div>
										<div className="col col-static-150">
											<div className="mb-2">
												<CustomInput label={'Saldo inicial ' + currentFinancialYear} type="number" className="form-control form-control-sm no-arrows text-end" onChange={(e) => setDataField('initial_balance', e.target.value)} value={data.initial_balance ?? ''} />
												{ errors.initial_balance &&
													<div className="invalid-feedback d-block">{ errors.initial_balance[0] }</div>
												}
											</div>
										</div>

										{ data.type === 'bankaccount' &&
											<div className="col-lg-12">
												<LabeledFrame label="Datos de la cuenta bancaria" className="mt-2">
													<div className="row">
														<div className="col col-static-200">
															<div className="mb-2">
																<CustomInput
																	label="Titular" 
																	type="text" 
																	className="form-control form-control-sm" 
																	onChange={(e) => setDataDataField('owner', e.target.value)}
																	value={data.data?.owner ?? ''} 
																/>
																{ errors['data.owner'] &&
																	<div className="invalid-feedback d-block">{ errors['data.owner'][0] }</div>
																}
															</div>
														</div>
														<div className="col col-static-230">
															<div className="mb-2">
																<div className="input-group">
																	<CustomInput
																		label="Iban"
																		type="text" 
																		className="form-control form-control-sm" 
																		onChange={(e) => setDataDataField('iban', e.target.value)}
																		value={data.data?.iban ?? ''} 
																	/>
																	<button className="btn btn-light" onClick={getBankByIban} disabled={!data.data?.iban?.length}><i className="bi bi-arrow-clockwise"></i></button>
																</div>
																
																{ errors['data.iban'] &&
																	<div className="invalid-feedback d-block">{ errors['data.iban'][0] }</div>
																}
															</div>
														</div>
														<div className="col col-static-180">
															<div className="mb-2">
																<CustomInput label="Entidad" type="text" className="form-control form-control-sm" onChange={(e) => setDataDataField('entity', e.target.value)} value={data.data?.entity ?? ''} />
																{ errors['data.entity'] &&
																	<div className="invalid-feedback d-block">{ errors['data.entity'][0] }</div>
																}
															</div>
														</div>
														<div className="col col-static-130">
															<div className="mb-2">
																<CustomInput label="Swift / Bic" type="text" className="form-control form-control-sm" onChange={(e) => setDataDataField('swift', e.target.value)} value={data.data?.swift ?? ''} />
																{ errors['data.swift'] &&
																	<div className="invalid-feedback d-block">{ errors['data.swift'][0] }</div>
																}
															</div>
														</div>
														<div className="col col-static-80">
															<div className="mb-2">
																<CustomInput label="Sufijo" type="text" className="form-control form-control-sm" onChange={(e) => setDataDataField('suffix', e.target.value)} value={data.data?.suffix ?? ''} />
																{ errors['data.suffix'] &&
																	<div className="invalid-feedback d-block">{ errors['data.suffix'][0] }</div>
																}
															</div>
														</div>
														<div className="col col-static-150">
															<div className="mb-2">
																<CustomInput label="Identificador" type="text" className="form-control form-control-sm" onChange={(e) => setDataDataField('identifier', e.target.value)} value={data.data?.identifier ?? ''} />
																{ errors['data.identifier'] &&
																	<div className="invalid-feedback d-block">{ errors['data.identifier'][0] }</div>
																}
															</div>
														</div>
													</div>
												</LabeledFrame>
												<LabeledFrame label="Datos de la sucursal" className="mt-2">
													<div className="row">
														<div className="col col-static-200">
															<div className="mb-2">
																<CustomInput
																	label="Persona de contacto" 
																	type="text" 
																	className="form-control form-control-sm" 
																	onChange={(e) => setDataDataField('contact_person', e.target.value)}
																	value={data.data?.contact_person ?? ''} 
																/>
																{ errors['data.contact_person'] &&
																	<div className="invalid-feedback d-block">{ errors['data.contact_person'][0] }</div>
																}
															</div>
														</div>
														<div className="col col-static-230">
															<div className="mb-2">
																<CustomInput label="Dirección" type="text" className="form-control form-control-sm" onChange={(e) => setDataDataField('address', e.target.value)} value={data.data?.address ?? ''} />
																{ errors['data.address'] &&
																	<div className="invalid-feedback d-block">{ errors['data.address'][0] }</div>
																}
															</div>
														</div>
														<div className="col col-static-110">
															<div className="mb-2">
																<CustomInput label="Código postal" type="text" className="form-control form-control-sm" onChange={(e) => setDataDataField('postalcode', e.target.value)} value={data.data?.postalcode ?? ''} />
																{ errors['data.postalcode'] &&
																	<div className="invalid-feedback d-block">{ errors['data.postalcode'][0] }</div>
																}
															</div>
														</div>
														<div className="col col-static-200">
															<div className="mb-2">
																<CustomInput label="Ciudad" type="text" className="form-control form-control-sm" onChange={(e) => setDataDataField('city', e.target.value)} value={data.data?.city ?? ''} />
																{ errors['data.city'] &&
																	<div className="invalid-feedback d-block">{ errors['data.city'][0] }</div>
																}
															</div>
														</div>
														<div className="col col-static-120">
															<div className="mb-2">
																<CustomInput label="Teléfono" type="text" className="form-control form-control-sm" onChange={(e) => setDataDataField('phone', e.target.value)} value={data.data?.phone ?? ''} />
																{ errors['data.phone'] &&
																	<div className="invalid-feedback d-block">{ errors['data.phone'][0] }</div>
																}
															</div>
														</div>
														<div className="col col-static-200">
															<div className="mb-2">
																<CustomInput label="Email" type="text" className="form-control form-control-sm" onChange={(e) => setDataDataField('email', e.target.value)} value={data.data?.email ?? ''} />
																{ errors['data.email'] &&
																	<div className="invalid-feedback d-block">{ errors['data.email'][0] }</div>
																}
															</div>
														</div>
														<div className="col col-static-280">
															<div className="mb-2">
																<div className="input-group">
																	<CustomInput label="Página web" type="text" className="form-control form-control-sm" onChange={(e) => setDataDataField('website', e.target.value)} value={data.data?.website ?? ''} />
																	<div className="input-group-append">
																		<button className="btn btn-sm btn-primary text-white" onClick={() => window.open(data.data?.website)}><i className="bi bi-link"></i></button>
																	</div>
																</div>
																
																{ errors['data.website'] &&
																	<div className="invalid-feedback d-block">{ errors['data.website'][0] }</div>
																}
															</div>
														</div>
														<div className="col col-static-400">
															<div className="mb-2">
																<CustomTextarea label="Instrucciones" className="form-control form-control-sm" onChange={(e) => setDataDataField('observations', e.target.value)} value={data.data?.observations ?? ''} />
																{ errors['data.observations'] &&
																	<div className="invalid-feedback d-block">{ errors['data.observations'][0] }</div>
																}
															</div>
														</div>
														
													</div>
												</LabeledFrame>
											</div>
										}
									</div>
								</div>
								<div className="card-footer" id={popup ? 'footer-fixed' : ''}>
									<div className="row">
										<div className="col-4">
											{ (data.id && data.is_deletable && authUserPermission('delete')) &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteTreasury()}>Eliminar</button>							
											}
										</div>
										<div className="col-8 text-end">
											{ ((authUserPermission('add') && !data.id) || (authUserPermission('edit') && data.id)) &&
												<React.Fragment>
													{ data.id &&
														<div className="dropdown d-inline">
															<button ref={actionsDropdownRef} className="btn btn-sm btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
																Acciones
															</button>
															<ActionsContextMenu actionsDropdownRef={actionsDropdownRef} className="dropdown-menu">
																<li><NavLink className="dropdown-item" to={'/treasury/statement/' + data.id} onClick={(e) => {openPopupWindow(e.target.href); e.preventDefault();}}>Extracto</NavLink></li>

																<li><hr className="dropdown-divider" /></li>
																<li><button className="dropdown-item" onClick={() => saveData(false)}>Guardar</button></li>						
																<li><button className="dropdown-item" onClick={() => saveData()}>Guardar y salir</button></li>
															</ActionsContextMenu>
														</div>
													}
													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>
												</React.Fragment>
											}					
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}