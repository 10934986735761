import React, { useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'react-toastify';
import { loader } from 'helpers/generic';
import { getCommunity } from 'helpers/community';

const Loader = styled.div`
    width: 200px;
    margin: 0 auto;
    margin-top: 20px;
`;

let axiosCancelToken = null;

export default function NewClientInvoice(props) {
    let movements = useMemo(() => [...props.movements], [props.movements]);

    let hideModalFix = props.hideModal;
    let hideModal = useCallback(() => {
        hideModalFix();
    }, [hideModalFix]);

    useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

    const saveData = useCallback((invoice) => {
        let newMovements = [...movements];
        newMovements.push({
            amount: invoice.total,
            class: 'App\\Models\\ClientInvoice',
            concept: invoice.client?.name + ' ' + invoice.number,
            date: invoice.date_reception,
            id: invoice.id
        });

        axios.post('/api/manager/bank-reconciliation/save', {
            type: 'client-invoice',
            community_id: getCommunity()?.id,
            movements: newMovements
        }, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Movimientos conciliados');
			hideModal(true, true);
	  	}).catch((error) => {
			hideModal(true);

			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error al guardar');
		});
    }, [movements, hideModal]);

    useEffect(() => {
		window.PopupProxyCallback = (newInvoice) => {
            if ( newInvoice.id ) saveData(newInvoice);
            else hideModal();
		}

		return function cleanup() {
			window.PopupProxyCallback = null;
		}
	}, [saveData, hideModal]);

	return (
		<div className="row">
            <div className="col-md-12 text-center">
                Esperando la creación de la factura en la ventana abierta... 
                <Loader>{loader}</Loader>
            </div>
        </div>
	);
}


