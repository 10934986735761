import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { getCommunity } from 'helpers/community';
import { formatNumber, loader } from 'helpers/generic';

const TableMovements = styled.table`
    font-size: 12px;
    
    thead {

        tr {

            th {
                font-weight: 600;
            }
        }
    }

    th, 
    td {
        &:nth-child(1) {
            width: 80px;
        }

        &:nth-child(3) {
            width: 100px;
            text-align: right;
        }
    }
`;

const TableInvoice = styled.table`
    font-size: 12px;
    
    thead {
        tr {
            th {
                font-weight: 600;
            }
        }
    }

    th, 
    td {
        &:nth-child(1) {
            width: 80px;
        }

        &:nth-child(3),
        &:nth-child(4) {
            width: 100px;
            text-align: right;
        }
    }
`;

let axiosCancelToken = null;

export default function ClientInvoice(props) {
    let hideModal = props.hideModal;

    let [loading, setLoading] = useState(false);
    let [client, setClient] = useState(undefined);
    let [invoice, setInvoice] = useState(undefined);
    let entity = props.movements.filter((item) => item.class.indexOf('ClientInvoice') !== -1)[0] ?? null; 

    useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

        return function cleanup() {
            axiosCancelToken.cancel();
        }
    }, []);

    useEffect(() => {
        axios.get('/api/manager/clients-invoices/get/' + entity.id, {
			params: {
				community_id: getCommunity()?.id,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
            setInvoice({...response.data});
			setClient({...response.data.client});
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
    }, [entity]);

    const saveData = async () => {
        setLoading(true);

        await axios.post('/api/manager/bank-reconciliation/save', {
            type: 'client-invoice',
            community_id: getCommunity()?.id,
            movements: props.movements
        }, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Movimientos conciliados');
			hideModal(true, true);
	  	}).catch((error) => {
			hideModal(true);

			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error al guardar');
		});	

        setLoading(false);
    }

    let bankMovements = props.movements.filter((item, idx) => item.class.indexOf('ClientInvoice') === -1);
    let totalBankMovements = 0;

	return (
		<div className="row">
            { invoice?.id &&   
                <React.Fragment>
                    <div className="col-lg-6">
                        <div className="mb-3">
                            <label className="sbold">Cliente</label>
                            <div className="small">{client?.name}</div>
                        </div>
                    </div>
                
                    <div className="col-md-12 mb-3">
                        <label className="sbold">Movimientos bancarios a conciliar</label>
                        <TableMovements className="table table-sm table-bordered mb-0">
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Concepto</th>
                                    <th>Importe</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bankMovements.map((item, idx) => {
                                    totalBankMovements += item.amount;
                                    return (
                                        <tr key={idx}>
                                            <td>{moment(item.date).format('DD-MM-YYYY')}</td>
                                            <td>{item.concept ?? ''}</td>
                                            <td>{formatNumber(item.amount ?? 0)}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                            <tfoot>
                                { bankMovements.length > 1 &&
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td className="sbold">{formatNumber(totalBankMovements, 2)}</td>
                                    </tr>
                                }
                                { Math.abs(totalBankMovements) < invoice.total &&
                                    <tr>
                                        <td colSpan="100%" className="text-center text-danger">Parcialmente pagada</td>
                                    </tr>
                                }
                            </tfoot>
                        </TableMovements>
                    </div>
                
                    <div className="col-md-12">
                        <label className="sbold">Factura de cliente a conciliar</label>
                        <TableInvoice className="table table-sm table-bordered mb-0">
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Nº</th>
                                    <th>Importe</th>
                                    <th>Pendiente</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{invoice.date ? moment(invoice?.date).format('DD-MM-YYYY') : '-'}</td>
                                    <td>{invoice?.number ?? '-'}</td>
                                    <td>{formatNumber(invoice.total ?? 0)}</td>
                                    <td>{formatNumber(invoice.pending ?? 0)}</td>
                                </tr>
                            </tbody>
                        </TableInvoice>
                    </div>
                </React.Fragment>
            }

            { (invoice === undefined) && <div className="col-md-12"><div style={{width: '200px', margin: '0 auto'}} className="text-center">{loader}</div></div> }

            <div className="text-end mt-3">
				<button type="button" className="btn btn-primary text-white" onClick={() => saveData()} disabled={loading}>{loading ? 'cargando...' : 'Confirmar conciliación'}</button>
            </div>
        </div>
	);
}


