import React, { useState, useEffect } from 'react';
import { formatNumber, ucfirst } from 'helpers/generic';
import styled from 'styled-components';
import { PieChart } from 'react-minimal-pie-chart';

const TableStyled = styled.table`

    tbody {
        tr {
            cursor: pointer;

            &.opened {
                font-weight: bold;
            }

            &.mouse-over {
                background: var(--bs-light);
            }

            &.pie-segment-over {
                background: rgba(var(--bs-primary-rgb), 0.2);
            }

            &.mouse-over.pie-segment-over {
                background: rgba(var(--bs-primary-rgb), 0.3);
            }

            &.income {
                display: none;
                font-size: 11px;

                td {

                    &:first-child {
                        padding-left: 25px;
                    }
                }

                &.visible {
                    display: table-row;
                }
            }
        }
    }
`;

const PieChartWrapper = styled.div`
    position: sticky;
    top: ${props => props.stickyTop}px;
`;

const IncomesPending = (props) => {
    const incomes = props.data?.pending_incomes ?? {};
    const colors = props.data?.colors?.pending_incomes ?? {};
    const exportChartFix = props.exportChartFix;

    let [incomesRowSelected, setIncomesRowSelected] = useState({});
    let [pageTitleBottom, setPageTitleBottom] = useState(0);
    let [trOverIndex, setTrOverIndex] = useState(null);

    let incomesData =[
        {
            key: 'receipts_pending',
            name: 'Recibos pendientes de cobro',
            amount: incomes?.receipts_pending ?? 0,
            color: colors['receipts_pending'] ?? 'var(--bs-light)'
        },
        {
            key: 'receipts_refunded',
            name: 'Devoluciones de recibos',
            amount: incomes?.receipts_refunded ?? 0,
            color: colors['receipts_refunded'] ?? 'var(--bs-light)'
        },
    ];

    const chartData = incomesData.map((entry, i) => {
        let row = {
            title: entry.name,
            value: Math.abs(entry.amount),
            color: colors[entry.key] ?? 'var(--bs-light)'
        }

        let color = row.color;
        if ( Object.keys(incomesRowSelected).length > 0 && exportChartFix === null) {
            if ( incomesRowSelected[i] ) {
                color = 'var(--bs-primary)';
            } else {
                if ( trOverIndex === i ) {
                    color = 'var(--bs-secondary)';
                } else {
                    color = 'var(--bs-gray-400)';
                }
            }
        } else {
            if ( trOverIndex === i ) {
                color = 'var(--bs-secondary)';
            }
        }
        
        return {
            ...row,
            color: color
        };
    });

    useEffect(() => {
        let pageTitleBottom = document.querySelector('.page-title').getBoundingClientRect().bottom;
        setPageTitleBottom(pageTitleBottom);
    }, []);
    
    const toggleIncomesRowSelected = (idx) => {
        let newIncomesRowSelected = {...incomesRowSelected};
        newIncomesRowSelected[idx] ? delete newIncomesRowSelected[idx] : newIncomesRowSelected[idx] = true;
        setIncomesRowSelected(newIncomesRowSelected);
    }

    return (
        <>
            <div className="col-md-8 mt-3">
                <TableStyled id="movements" className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>INGRESOS PENDIENTES</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {incomesData.map((el, idx) => {
                            return (
                                <tr 
                                    key={idx}
                                    className={(incomesRowSelected[idx] ? 'pie-segment-over opened' : '') + ' ' + (trOverIndex === idx ? 'mouse-over' : '')} 
                                    onClick={() => {
                                        toggleIncomesRowSelected(idx)
                                    }}
                                    onMouseOver={() => setTrOverIndex(idx)}
                                    onMouseOut={() => setTrOverIndex(null)}
                                >
                                    <td>
                                        <div className="d-flex align-items-center">
                                            {colors[el.key] &&
                                                <span className="badge rounded-pill me-2" style={{backgroundColor: colors[el.key]}}>&nbsp;</span>
                                            }
                                            {el.name}
                                        </div>    
                                    </td>
                                    <td></td>
                                    <td>{formatNumber(el.amount ?? 0, 2, true)} €</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </TableStyled>
            </div>

            <PieChartWrapper className="col-md-4 mt-3" stickyTop={pageTitleBottom} data-piecharwrapper="pending_incomes">
                <PieChart
                    id="pending_incomes-chart"
                    data={chartData}
                    labelStyle={{
                        fontSize: '3.5px'
                    }}
                    radius={45}
                    segmentsShift={(index) => ((incomesRowSelected[index] || trOverIndex === index) && exportChartFix === null ? 4 : 1)}
                    segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
                    onClick={(data, index) => {
                        toggleIncomesRowSelected(index);
                    }}
                    onMouseOver={(data, index) => setTrOverIndex(index)}
                    onMouseOut={() => setTrOverIndex(null)}
                />
            </PieChartWrapper>
        </>
    );
}

export default IncomesPending;