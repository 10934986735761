import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { getCommunity } from 'helpers/community';
import { formatNumber, loader } from 'helpers/generic';

const TableMovements = styled.table`
    font-size: 12px;
    
    thead {

        tr {

            th {
                font-weight: 600;
            }
        }
    }

    th, 
    td {
        &:nth-child(1) {
            width: 80px;
        }

        &:nth-child(3) {
            width: 100px;
            text-align: right;
        }
    }
`;

const TableReceipt = styled.table`
    font-size: 12px;
    
    thead {
        tr {
            th {
                font-weight: 600;
            }
        }
    }

    th, 
    td {
        &:nth-child(1) {
            width: 80px;
        }

        &:nth-child(3),
        &:nth-child(4) {
            width: 100px;
            text-align: right;
        }
    }
`;

let axiosCancelToken = null;

export default function Receipt(props) {
    let hideModal = props.hideModal;

    let [loading, setLoading] = useState(false);
    let [receipts, setReceipts] = useState([]);
    let [reunificate, setReunificate] = useState(false);
    let [error, setError] = useState(undefined);

    let bankMovements = props.movements.filter((item, idx) => item.class.indexOf('Receipt') === -1);
    let bankMovementsNegative = props.movements.filter((item, idx) => item.class.indexOf('Receipt') === -1 && item.amount < 0);
    let totalBankMovements = parseFloat(bankMovements.reduce((carry, el) => carry += parseFloat(el.amount), 0).toFixed(2));

    let receiptEntities = useMemo(() => props.movements.filter((item) => item.class.indexOf('Receipt') !== -1), [props.movements]); 
    let receiptsTotal = receipts.reduce((carry, el) => carry += parseFloat(el.amount), 0);
    let receiptsPending = parseFloat(receipts.reduce((carry, el) => carry += parseFloat(el.pending), 0).toFixed(2));

    let canReunificate = receipts.filter(receipt => receipt.property_id === receipts[0].property_id).length === receipts.length && receipts.length > 1;
    let receiptsFromDifferentPropertiesButSameOwner = 0;
    let receiptsFromDifferentPropertiesButSameOwnerIds = [];
    receiptEntities.forEach(el => {
        if ( receiptsFromDifferentPropertiesButSameOwnerIds.length === 0 ) {
            receiptsFromDifferentPropertiesButSameOwnerIds = [...el.owners_ids];
            receiptsFromDifferentPropertiesButSameOwner++;
        } else {
            let hasOwner = el.owners_ids?.some(v => receiptsFromDifferentPropertiesButSameOwnerIds.includes(v));
            if ( hasOwner ) receiptsFromDifferentPropertiesButSameOwner++;
        }
    });
    let allReceiptsWithCommonOwner = receiptsFromDifferentPropertiesButSameOwner === receiptEntities.length;

    let buttonDisabled = bankMovementsNegative.length > 0 || (receiptEntities.length > 1 && totalBankMovements < receiptsPending && !reunificate); // No negative movements || If more than one receipt, then the total amount must be equal or higher to the total amount of the receipts unless it's reunificated

    useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

        return function cleanup() {
            axiosCancelToken.cancel();
        }
    }, []);

    useEffect(() => {
        let getReceipts = async () => {
            let receipts = [];
            for await (let entity of receiptEntities) {
                await axios.get('/api/manager/receipts/get/' + entity.id, {
                    params: {
                        community_id: getCommunity()?.id,
                        with_manual_payment_fields: true
                    },
                    cancelToken: axiosCancelToken.token
                }).then((response) => {
                    receipts.push({...response.data});
                }).catch((error) => {
                    if ( axios.isCancel(error) ) return;
                });	
            }
            setReceipts(receipts);
        }
        getReceipts();
    }, [receiptEntities]);

    const saveData = async () => {
        setError(undefined);
        setLoading(true);

        await axios.post('/api/manager/bank-reconciliation/save', {
            type: 'receipt',
            community_id: getCommunity()?.id,
            movements: props.movements,
            reunificate: reunificate
        }, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Movimientos conciliados');
			hideModal(true, true);
	  	}).catch((error) => {
			hideModal(true);

			if ( axios.isCancel(error) ) return;
            if ( error.response.data.errors ) setError(error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]);
			toast.error('Ha ocurrido un error al guardar');
		});	

        setLoading(false);
    }

	return (
		<div className="row">
            { receipts.length > 0 &&   
                <React.Fragment>
                    {/* <div className="col-lg-6">
                        <div className="mb-3">
                            <label className="sbold">Pagador</label>
                            <div className="small">{receipt.payerable?.name} - {receipt.property?.building?.name} {receipt.property?.name}</div>
                        </div>
                    </div> */}
                
                    <div className="col-md-12 mb-3">
                        <label className="sbold">Movimientos bancarios a conciliar</label>
                        <TableMovements className="table table-sm table-bordered mb-0">
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Concepto</th>
                                    <th>Importe</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bankMovements.map((item, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>{moment(item.date).format('DD-MM-YYYY')}</td>
                                            <td>{item.concept ?? ''}</td>
                                            <td>{formatNumber(item.amount ?? 0)}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                            <tfoot>
                                { bankMovements.length > 1 &&
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td className="sbold">{formatNumber(totalBankMovements, 2)}</td>
                                    </tr>
                                }
                                { (receiptEntities.length > 1 && totalBankMovements < receiptsPending && !reunificate) && 
                                    <tr>
                                        <td colSpan="100%" className="text-center text-danger">Importe insuficiente</td>
                                    </tr>
                                }
                                { (receiptEntities.length === 1 && totalBankMovements < receiptsPending) &&
                                    <tr>
                                        <td colSpan="100%" className="text-center text-danger">Parcialmente pagado</td>
                                    </tr>
                                }
                            </tfoot>
                        </TableMovements>
                    </div>
                
                    <div className="col-md-12">
                        <label className="sbold">Recibos a conciliar</label>
                        <TableReceipt className="table table-sm table-bordered mb-0">
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Nº</th>
                                    <th>Importe</th>
                                    <th>Pendiente</th>
                                </tr>
                            </thead>
                            <tbody>
                                {receipts.map((receipt, rIdx) => {
                                    return (
                                        <tr key={'r'+rIdx}>
                                            <td>{receipt.due_date ? moment(receipt?.due_date).format('DD-MM-YYYY') : '-'}</td>
                                            <td>{receipt?.number_full ?? '-'}</td>
                                            <td>{formatNumber(receipt.amount ?? 0)}</td>
                                            <td>{formatNumber(receipt.pending ?? 0)}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                            <tfoot>
                                { receiptEntities.length > 1 &&
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td className="sbold">{formatNumber(receiptsTotal, 2)}</td>
                                        <td className="sbold">{formatNumber(receiptsPending, 2)}</td>
                                    </tr>
                                }
                                { (canReunificate && allReceiptsWithCommonOwner) &&
                                    <>
                                        <tr>
                                            <td colSpan="100%" className="text-center">
                                                <div className="d-inline-flex align-items-center">¿Reunificar? <input type="checkbox" className="ms-2" checked={reunificate} onChange={(e) => setReunificate(e.target.checked)} /></div>

                                                { reunificate &&
                                                    <div className="text-center text-primary fw-bold">
                                                        Se van a reunificar todos los recibos seleccionados en un solo recibo
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    </>
                                }

                                { bankMovementsNegative.length > 0 &&   
                                    <tr>
                                        <td colSpan="100%" className="text-center text-danger">
                                            No se puede conciliar un movimiento negativo con un recibo
                                        </td>
                                    </tr>
                                }
                                { error &&
                                    <tr>
                                        <td colSpan="100%" className="text-center text-danger">
                                            {error}
                                        </td>
                                    </tr>
                                }
                            </tfoot>
                        </TableReceipt>
                    </div>
                </React.Fragment>
            }

            { (receipts.length === 0) && <div className="col-md-12"><div style={{width: '200px', margin: '0 auto'}} className="text-center">{loader}</div></div> }

            <div className="text-end mt-3">
				<button type="button" className="btn btn-primary text-white" onClick={() => saveData()} disabled={buttonDisabled || loading}>{loading ? 'cargando...' : 'Confirmar conciliación'}</button>
            </div>
        </div>
	);
}