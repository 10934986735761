import React from 'react';
import styled from 'styled-components';
import LabeledFrame from 'components/LabeledFrame';

const RecipientsStyled = styled.div`
    
`;

const RecipientRow = styled.label`
    display: block;
    margin: 5px;
    user-select: none;
    position: relative;

    &.with-errors {

        input[type=email] {
            border: 1px solid var(--bs-red);
            background: rgba(var(--bs-danger-rgb), 0.1);
        }
    }

    button {
        position: absolute;
        top: -7.5px;
        right: -7.5px;
        width: 15px;
        aspect-ratio: 1;
        background: var(--bs-red);
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        padding: 0;
        margin: 0;
        border: 0;
    }

    .invalid-feedback {
        font-size: 11px;
    }
`;

export default function ExternalRecipients(props) {
    const data = props.data;
    const setData = props.setData;
    const errors = props.errors;

    const recipients = data.recipients_external ?? [];

    const setRecipients = (recipients) => {
        setData((prev) => ({...prev, recipients_external: recipients}));
    };

    const addRecipient = () => {
        let newRecipients = [...recipients];
        newRecipients.push('');
        setRecipients(newRecipients);
    }

    const updateRecipient = (idx, email) => {
        let newRecipients = [...recipients];
        newRecipients[idx] = email;
        setRecipients(newRecipients);   
    }

    const deleteRecipient = (idx) => {
        let newRecipients = [...recipients];
        newRecipients.splice(idx, 1);
        setRecipients(newRecipients);
    }

	return (
        <RecipientsStyled>
            <LabeledFrame 
                label="Enviar copia"
                buttons={
                    <button className="btn-unstyled text-white p-0 m-0" onClick={addRecipient}><i className="bi bi-plus-circle"></i> Añadir</button>
                }
				buttonsBackground={'primary'}
            >
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            { recipients.map((recipient, idx) => {
                                if ( idx === 0 && recipients.length === 0 ) return (
                                    <small key="nohay">No hay destinatarios</small>
                                );

                                return (
                                    <div key={idx} className="col-md-4">
                                        <RecipientRow key={idx} className={errors['recipients_external.' + idx] ? 'with-errors' : ''}>
                                            <input type="email" className="form-control form-control-sm" value={recipient ?? ''} onChange={(e) => updateRecipient(idx, e.target.value)} placeholder="Introduce un email" disabled={data.status !== 'draft'} />
                                            {data.status === 'draft' && <button onClick={() => deleteRecipient(idx)}><i className="bi bi-x"></i></button>}
                                            {errors['recipients_external.' + idx] &&
                                                <div className="invalid-feedback d-block">{ errors['recipients_external.' + idx][0] }</div>
                                            }
                                        </RecipientRow>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </LabeledFrame>
        </RecipientsStyled>
	);
}