import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import LabeledFrame from 'components/LabeledFrame';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { getCommunity } from 'helpers/community';
import { openPopupWindow } from 'helpers/generic';

let axiosCancelToken = null;

export default function MeetingDocs(props) {
    const [template, setTemplate] = useState(null);

    useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

    const loadTemplates = (input, callback) => {
		axios.get('/api/manager/templates/list', {
			params: {
				community_id: getCommunity()?.id,
                without_community: true,
				search: input,
                type: 'meetings',
                no_paginate: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.title
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const openPopupWindowFix = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href, 'template');
	}

	const openPopupWindowMouseDownFix = (e) => {
		e.preventDefault();
		e.stopPropagation();
		document.activeElement.blur();
	}

	return (
		<React.Fragment>
			<LabeledFrame 
                className="mt-3"
				label="Generar documentos de junta desde plantilla"
				buttons={
					<NavLink className="btn-unstyled text-decoration-none text-white" to={'/templates/add/?popup=true&type=meetings&type_locked=true'} onClick={openPopupWindowFix}><i className="bi bi-plus-circle"></i> Nueva plantilla</NavLink>
				}
				buttonsBackground={'primary'}
			>
                <div className="input-group" style={{flexWrap: 'unset'}}>
                    <EmpoweredSelector
                        load={loadTemplates}
                        onChange={(value) => setTemplate(value)}
                        timeout={250}
                        label={
							template?.id ?
								<div className="d-flex justify-space-between">
									{template.title}
									<NavLink to={'/templates/edit/' + template.id + '?popup=true'} onClick={openPopupWindowFix} onMouseDown={openPopupWindowMouseDownFix}><i className="bi bi-eye ms-2"></i></NavLink>
								</div>
							: null
						}
                        placeholder="Seleccionar plantilla"
                        value={template?.id ?? null}
                        className="w-100"
                    />
                    <NavLink 
                        style={{width: '80px', minWidth: '80px'}} 
                        className={'btn btn-primary btn-sm text-white' + (!template?.id ? ' disabled ' : '')}
                        to={'/meetings/pdf/' + props.data.id + '?template_id=' + template?.id}
                        onClick={(e) => {
                            e.preventDefault();
                            openPopupWindow(e.target.href);
                        }}
                    >Generar</NavLink>
                </div>
			</LabeledFrame>
		</React.Fragment>
	);
}