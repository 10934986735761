import React, { useEffect, useState, useRef, useMemo } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import LabeledFrame from 'components/LabeledFrame';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { getUser } from 'helpers/user';
import { getPetitionerFormatted, ucfirst, openPopupWindow } from 'helpers/generic';

const EditActionForm = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
	background: var(--bs-gray-200);
	padding: 10px;
	border-radius: 3px;
	position: relative;

	.user {
		color: var(--bs-primary);
		font-weight: 600;
		margin-top: -3px;
		margin-bottom: 5px;
	}

	.type {
		position: absolute;
		top: 5px;
		right: 10px;
		font-weight: bold;
		font-size: 12px;
		text-transform: uppercase;
		color: var(${props => props.type === 'action' ? '--bs-primary' : '--bs-orange'});
	}

	.replyTo {
		font-style: italic;
	}

	textarea {
		margin-bottom: 10px;
	}

	.bottom {

		.input-group {
			width: 100%;
			margin-bottom: 10px;
			
			.input-group-text {
				font-size: 12px;
				height: 100%;
			}
		}

		.buttons {
			display: flex;

			button {
				padding: 2px 10px;
				width: 100%;

				&:not(:last-of-type) {
					margin-right: 10px;
				}
			}
		}

		@media (max-width: 992px) {
			display: block;

			.buttons {
				display: block;
				width: 100%;
				padding: 0;

				button {
					width: 100%;

					&:not(:last-of-type) {
						margin-bottom: 5px;
					}
				}
			}
		}
	}
`;

const ActionsList = styled.div`
	
`;


const PetitionerSelectRow = styled.div`
	position: relative;

	.name {

	}

	.type {
		background: var(--bs-secondary);
		color: white;
		padding: 2px 8px;
		font-size: 10px;
		border-radius: 3px;
		display: inline-block;
		margin-right: 5px;
		position: static;
	}

	.community {
		&:empty {
			display: none;
		}

		background: var(--bs-primary);
		color: white;
		padding: 2px 8px;
		font-size: 10px;
		border-radius: 3px;
		display: inline-block;
		position: static;
	}
`;

const Action = styled.div`
	position: relative;
	text-align: ${props => props.type === 'action' ? 'left' : 'right'};
	padding-left: 15px;
	background: ${props => props.highlight ? 'var(--bs-gray-400)' : ''};

	&:not(:last-of-type) {
		padding-bottom: 10px;
		margin-bottom: 10px;
		border-bottom: 1px solid var(--bs-gray-500);
	}

	&:hover {
		.buttons {
			display: flex;
		}
	}

	.buttons {
		display: none;
		position: absolute;
		top: 0;
		right: ${props => props.type === 'action' ? '0' : ''};
		left: ${props => props.type === 'report' ? '0' : ''};

		.btn {
			padding: 1px 4px;
			border-radius: 50%;

			&:hover {
				transform: scale(1.2);
				background: var(--bs-gray-200);
			}
		}

		&.disabled {
			opacity: 0.4;
			pointer-events: none;
		}
	}

	.date {
		font-size: 10px;
	}

	.user {
		color: var(${props => props.type === 'action' ? '--bs-primary' : '--bs-orange'});
		font-weight: 600;
	}

	.number {
		position: absolute;
		left: -5px;
		top: 50%;
		margin-top: -4px;
		width: 15px;
		height: 15px;
		border-radius: 50%;
		background: var(${props => props.type === 'action' ? '--bs-primary' : '--bs-orange'});
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 10px;
	}

	.petitioner {
		font-style: italic;
	}

	.replyOf {
		user-select: none;
		border: 1px solid lightgray;
		border-radius: 5px;
		padding: 5px;
		display: inline-flex;
		flex-direction: column;
		margin-top: 10px;
		margin-bottom: 5px;
		box-shadow: 0px 2px 3px rgba(1,1,1,0.1);
		
		.inner {
			border-left: 3px solid var(--bs-primary);
			padding-left: 10px;

			.top {
				span {
					display: inline-flex;
					margin-right: 20px;
				}

				u {
					text-decoration: none;
					font-size: 10px;
					color: gray;
				}
			}
		}
		
	}

	.description {
		font-size: 13px;
	}
`;

let axiosCancelToken = null;

export default function Actions(props) {
	const emptyAction = {
		date: null,
		description: '',
		type: 'action',
		number: 0,

		// Users fields are needed to show full data on actions that are not saved on database yet
		user_id: getUser()?.id,
		user: getUser() ?? {}
	};

	const data = props.data;
	const setData = props.setData;

	let actions = useMemo(() => data.actions ? [...data.actions] : [], [data.actions]);
	actions.sort((a,b) => { 
		const aDate = moment(a.date);
		const bDate = moment(b.date);

		if ( aDate.isBefore(bDate) ) return 1;
		if ( aDate.isAfter(bDate) ) return -1;
		return 0;
	});

	const [showEditActionForm, _setShowEditActionForm] = useState(null);
	const setShowEditActionForm = (value) => {
		_setShowEditActionForm(value);
		if ( !value ) setNewUser(null);
	}
	const [highlightReplyOf, setHighlightReplyOf] = useState(null);
	const [newUser, setNewUser] = useState(null);

	const descriptionRef = useRef(null);
	const dateRef = useRef(null);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	// Add a first action on new notice
	useEffect(() => {
		if ( !data.id && !actions.length ) {
			let newData = {...data};
			actions.push({...emptyAction});
			newData.actions = [...actions];
			setData(newData);

			setShowEditActionForm(0);
		}

		if ( data.id ) setShowEditActionForm(null);
	}, [data.id]); //eslint-disable-line

	// Fix: if notice is saved without actions, later we can't click con "addAction" button again
	useEffect(() => {
		if ( !actions.length ) setShowEditActionForm(null);
	}, [actions]);

	const addAction = (type, parentNumber) => {
		// Add
		let newData = {...data};
		let newAction = {
			...emptyAction, 
			type: type
		};

		if ( type === 'action' ) newAction.number = data.actions.filter(el => el.type === 'action').length;
		else delete newAction.number;

		if ( parentNumber !== undefined ) {
			newAction.parent_id = data.actions.filter(el => el.type === 'action' && el.number === parentNumber)[0]?.id
			newAction.parent_number = parentNumber;
			newAction.parent = data.actions.filter(el => el.type === 'action' && el.number === parentNumber)[0] ?? null;
		}
		
		actions.unshift(newAction);
		newData.actions = actions;
		setData(newData);

		// Show form
		setShowEditActionForm(0);
	}

	const saveAction = (idx, status) => {
		// Get
		let due_date = dateRef.current.value;
		let description = descriptionRef.current.value;

		// Check
		if ( !description.length ) return false;

		// Save
		let newData = {...data};
		actions[idx].date = moment().format('YYYY-MM-DD HH:mm');
		actions[idx].description = description;
		if ( due_date ) newData.expires_at = due_date;
		// newData.status = actions[idx].type === 'action' ? 'pending' : 'progress';
		newData.status = 'progress';
		newData.actions = actions;
		if ( status == 'closed' ) {
			newData.status = 'closed';
			newData.expires_at = null;
		}
		if ( status == 'pending' ) {
			newData.status = 'pending';
		}

		newData.autosave_fix = true; // Needed to save to DB on parent


		setData(newData);

		// Close form
		setShowEditActionForm(null);
	}

	const deleteAction = (idx) => {
		let newData = {...data};
		if ( actions[idx].id ) actions[idx].remove = true;
		else actions.splice(idx, 1);
		newData.actions = actions;
		setData(newData);

		// Hide form if is showed
		setShowEditActionForm(null);
	}

	const setExpiresDate = (e, amount, unit) => {
		e.preventDefault();

		let date = moment().add(amount, unit).format('YYYY-MM-DDTHH:mm');
		dateRef.current.value = date;
	}

	const loadPetitioners = (input, callback) => {
		axios.get('/api/manager/notices/petitioners-list', {
			params: {
				search: input
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label:  <PetitionerSelectRow>
								<div className="name">{el.name}</div>
								<div className="type">{getPetitionerFormatted(el.petitioner_type)}</div>
								<div className="community">{el.community?.name}</div>
						 	</PetitionerSelectRow>
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const loadUsers = (input, callback) => {
		axios.get('/api/manager/users/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectPetitioner = (idx, petitioner) => {
		// Add
		let newData = {...data};
		newData.actions[idx].petitionerable = petitioner;
		newData.actions[idx].petitionerable_type = petitioner ? 'App\\Models\\' + ucfirst(petitioner.petitioner_type) : null;
		newData.actions[idx].petitionerable_id = petitioner?.id;
		newData.actions[idx].petitioner_type = petitioner?.petitioner_type;
		setData(newData);
	}

	const selectUser = (value) => {
		let newData = {...data};
		newData.user_id = value?.id ?? null;
		newData.user = {
			id: value?.id,
			name: value?.name
		}
		setData(newData);
		setNewUser(value);
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	const openPopupWindowMouseDownFix = (e) => {
		e.preventDefault();
		e.stopPropagation();
		document.activeElement.blur();
	}

	return (
		<LabeledFrame 
			className="mb-3"
			label="Registro de acciones" 
			buttons={
				<React.Fragment>
					<button className="btn-unstyled text-white" onClick={() => showEditActionForm === null ? addAction('action') : null}>
						<i className="bi bi-plus-circle-fill"></i> Nueva acción
					</button>
					<span className="text-white mx-2">|</span>
					<button className="btn-unstyled text-white" onClick={() => showEditActionForm === null ? addAction('report') : null}>
						<i className="bi bi-plus-circle-fill"></i> Nuevo reporte
					</button>
				</React.Fragment>
			}
			buttonsBackground={'primary'}
		>	
			{ actions?.length > 0 &&
				<ActionsList>
					{actions.map((el, idx) => {
						if ( el.remove ) return false;

						return (
							<React.Fragment key={idx}>
								{ 
									showEditActionForm === idx ?
										<EditActionForm type={el.type}>
											<div className="user">{el.user?.name}</div>
											<div className="type">{el.type === 'action' ? 'Acción' : 'Reporte'}</div>
											{ el.parent &&
												<div className="replyTo">Respuesta a #{el.parent.number}</div>
											}
											<textarea className="form-control form-control-sm" ref={descriptionRef} defaultValue={el.description ?? ''} placeholder={'Describe la acción realizada'}></textarea>
											<div className="bottom">
												<div className="row">
													<div className="col-md-6">
														<div className="input-group input-group-sm">
															<span className="input-group-text">Vencimiento</span>
															<input type="datetime-local" className="form-control form-control-sm" ref={dateRef} />
															<button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
															<ul className="dropdown-menu dropdown-menu-end">
																<li><a className="dropdown-item" href="." onClick={(e) => setExpiresDate(e, 15, 'minutes')}>15 minutos</a></li>
																<li><a className="dropdown-item" href="." onClick={(e) => setExpiresDate(e, 30, 'minutes')}>30 minutos</a></li>
																<li><a className="dropdown-item" href="." onClick={(e) => setExpiresDate(e, 45, 'minutes')}>45 minutos</a></li>
																<li><a className="dropdown-item" href="." onClick={(e) => setExpiresDate(e, 1, 'hours')}>1 hora</a></li>
																<li><a className="dropdown-item" href="." onClick={(e) => setExpiresDate(e, 2, 'hours')}>2 hora</a></li>
																<li><a className="dropdown-item" href="." onClick={(e) => setExpiresDate(e, 4, 'hours')}>4 horas</a></li>
																<li><a className="dropdown-item" href="." onClick={(e) => setExpiresDate(e, 8, 'hours')}>8 horas</a></li>
																<li><a className="dropdown-item" href="." onClick={(e) => setExpiresDate(e, 12, 'hours')}>12 horas</a></li>
																<li><a className="dropdown-item" href="." onClick={(e) => setExpiresDate(e, 24, 'hours')}>24 horas</a></li>
															</ul>
														</div>
													</div>
													<div className="col-md-6">
														{/* <EmpoweredSelector
															load={loadPetitioners}
															onChange={(value) => selectPetitioner(idx, value)}
															timeout={250}
															label={
																el.petitionerable?.id ?
																	<div>
																		{el.petitionerable.name}
																		<NavLink className="text-decoration-none text-reset" to={'/'+el.petitionerable.petitioner_type+'s/edit/' + el.petitionerable.id + '?community_id=' + data.community?.id + '&popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}>&nbsp;<i className="bi bi-eye text-primary"></i></NavLink>
																	</div>
																	:
																	'- Solicitante -'
															}
															value={el.petitionerable?.id ?? null}
														/> */}

														<EmpoweredSelector
															load={loadUsers}
															onChange={(value) => selectUser(value)}
															timeout={250}
															label={newUser?.name ?? 'Asignar a'}
														/>
													</div>
												</div>											
												<div className="buttons">
													{ (idx === 0 && !el.description.length) &&
														<button className="btn btn-sm btn-secondary text-white" onClick={() => deleteAction(idx)}>Cancelar</button>
													}
													<button className="btn btn-sm btn-orange text-white" onClick={() => saveAction(idx)}>En curso</button>
													{ (!el.id) &&
														<>
															<button className="btn btn-sm btn-primary text-white" onClick={() => saveAction(idx, 'pending')}>Pendiente</button>
															<button className="btn btn-sm btn-success text-white" onClick={() => saveAction(idx, 'closed')}>Terminar</button>
														</>
													}
												</div>
											</div>
										</EditActionForm>
									:
										<Action type={el.type} highlight={highlightReplyOf === el.number && el.number !== null}>
											<div className={'buttons ' + (el.user_id !== getUser()?.id ? 'disabled ' : '')}>
												{ el.type === 'action' &&
													<button className="btn btn-sm btn-link me-3 text-dark" onClick={() => addAction('report', el.number)}><i className="bi bi-reply-fill"></i></button>
												}
												<button className="btn btn-sm btn-link me-3" onClick={() => setShowEditActionForm(idx)}><i className="bi bi-pencil"></i></button>
												<button className="btn btn-sm btn-link text-danger" onClick={() => deleteAction(idx)}><i className="bi bi-trash"></i></button>
											</div>
											
											<div className="date">{moment(el.date).format('DD-MM-YYYY')} a las {moment(el.date).format('HH:mm')}</div>
											<div className="user">{el.user?.name}</div>
											{ (el.type === 'action' || (el.type === 'report' && el.parent_number !== null)) && 
												<div className={'number ' + (el.type)}>{el.number}{el.parent?.number}</div>
											}
											{ el.petitionerable && <div className="petitioner">Solicitante: {el.petitionerable?.name}</div> }
											{ (el.type === 'report' && el.parent) && 
												<div 
													className="replyOf" 
													onClick={() => setHighlightReplyOf(highlightReplyOf ? null : el.parent_number)}
													onMouseEnter={() => setHighlightReplyOf(el.parent_number)}
													onMouseLeave={() => setHighlightReplyOf(null)}
												>
													<div className="inner">
														<div className="top">
															<span>{el.parent.user?.name}</span>
															<u>{moment(el.parent.date).format('DD-MM-YYYY')} a las {moment(el.parent.date).format('HH:mm')}</u>
														</div>
														{el.parent.description}
													</div>
												</div> 
											}
											<div className="description">{el.description}</div>
										</Action>
								}
							</React.Fragment>
						);
					})}
				</ActionsList>
			}
		</LabeledFrame>
	);
}