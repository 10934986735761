import React, { useState, useRef, useEffect } from "react";
import styled from 'styled-components';
import moment from "moment";
import axios from "axios";
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { CristalLoader } from "helpers/generic";
import { getCommunity } from "helpers/community";

const ModalStyled = styled.div`
    .modal {
	    background: rgba(0, 0, 0, 0.4);

        .modal-body {
            overflow-y: auto;
            max-height: 400px;

            table {
                width: 100%;

                thead {
                    
                    tr {

                        th {

                            &:nth-child(1) {
                                width: 140px;
                            }
                        }
                    }
                }

                tbody {

                    tr {

                        &.with-border {
                            
                            &:not(:last-of-type) {

                                td {
                                    border-bottom: 1px solid var(--bs-primary);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

let axiosCancelToken = null;

export default function BankAggregatorLogModal(props) {
	const modalRef = useRef(null);

    const bankcontract = props.bankcontract;
    const treasury = props.treasury;
    const closeCallback = props.closeCallback;

    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);
    
    useEffect(() => {
        const modal = new Modal(modalRef.current, {
			backdrop: false,

		});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';

            closeCallback();
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

        modal.show();
    }, [closeCallback]);

    useEffect(() => {
        let getLog = async () => {
            let type = null;
            let id = null;

            if ( bankcontract ) {
                type = 'bankcontract';
                id = bankcontract.id;
            }

            if ( treasury ) {
                type = 'treasury';
                id = treasury.id;
            }
 
            setLoading(true);
            await axios.get('/api/manager/bank-aggregator/get-log-list/' + type + '/' + id, {
				params: {
                    community_id: getCommunity()?.id
                },
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				setLogs([...response.data]);
			}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
            setLoading(false);
        }
        getLog();
    }, [treasury, bankcontract]);

    const closeModal = () => {
        const modal = Modal.getInstance(modalRef.current);
		modal.hide();
    }

    const backdropCloseModal = (e) => {
        if ( e.target === modalRef.current ) closeModal();
    }

	return (
        <ModalStyled>
            {loading && <CristalLoader />}
            <div className="modal" tabIndex="-1" ref={modalRef} onClick={(e) => backdropCloseModal(e)}>
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Registro de sincronización</h5>
                            <button type="button" className="btn-close" onClick={() => closeModal()} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <table className="table table-sm table-bordered">
                                { !loading && logs.length > 0 &&
                                    <thead>
                                        <tr>
                                            <th>Fecha</th>
                                            <th>Mensaje</th>
                                        </tr>
                                    </thead>
                                }
                                <tbody>
                                    {logs.length > 0 &&
                                        <>
                                            {logs.map((el, idx) => {
                                                // Set border or not
                                                let withBorder = false;
                                                if ( el.message.indexOf('iniciada') !== -1 ) {
                                                    withBorder = true;
                                                }

                                                return (
                                                    <tr key={idx} className={withBorder ? 'with-border' : ''}>
                                                        <td>{moment(el.created_at).format('DD-MM-YYYY HH:mm:ss')}</td>
                                                        <td>{el.message}</td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    }
                                    { !loading && logs.length == 0 &&
                                        <tr><td colSpan="100%">No se han encontrado registros</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </ModalStyled>
	);
}