import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import axios from 'axios';
import randomatic from 'randomatic';
import LabeledFrame from 'components/LabeledFrame';
import { jsonToArray } from 'helpers/generic';

const BankAccountsWrapper =  styled.div`

`;

const BankAccount = styled.div`
	&:not(:last-of-type) {
		margin-bottom: 10px;
	}

	.btn-remove {
		padding: 0;
		line-height: 5px;
		color: var(--bs-danger);
		border: 0;
		background: transparent;
	}
`;

const InputWrapper = styled.div`
	position: relative;
	height: 100%;
	border-radius: 3px;

	&.has-errors {
		background: #ffdddd;
	}

	label {
		position: absolute;
		top: 5px;
		left: 5px;
		font-size: 10px;
		line-height: 10px;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: -1px;
		color: var(--bs-gray-600);
	}

	input {
		background: transparent !important;
		border: 0;
		padding: 5px;
		padding-top: 20px;
		padding-bottom: 0;
		height: auto;
		font-size: 13px;
		outline: 0;
		box-shadow: none !important;
	}

	div {
		color: var(--bs-danger);
		font-size: 9px;
		padding: 5px;
		padding-top: 0;
	}
`;

let axiosCancelToken = null;

export default function BankAccountsPanel(props) {
	const accounts = props.bankaccounts ?? [];
	const setAccounts = props.setBankaccounts;
	const errors = props.errors;
	let readOnly = props.readOnly;

	let lastRow = useRef(null);
	let lastRowNumber = useRef(null);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( lastRow.current ) {
			lastRow.current.focus();
			lastRow.current.select();
			lastRow.current = null;
		}
	}, [accounts]);

	const addAccount = () => {
		let acc = [...accounts];
		acc.push({
			id: null,
			swift: null,
			mandate_date: moment().format('YYYY-MM-DD'),
			mandate_identifier: randomatic('A0', 5, { exclude: 'Ññ'}),
			iban: null,
			description: null,
			active: true
		});
		setAccounts(acc);

		lastRowNumber.current = acc.length - 1; // Needed to focus
	}

	const removeAccount = (idx) => {
		let c = window.confirm('¿Seguro que quieres eliminar esta cuenta bancaria?');
		if ( !c ) return false;

		setAccountField(idx, 'remove', true);
	}

	const setAccountField = async (idx, field, value) => {
		let acc = [...accounts];
		acc[idx][field] = value;

		if ( field === 'iban' ) {
			let bank = await getBankByIban(value);
			if ( bank?.bic ) acc[idx]['swift'] = bank.bic;
		}


		setAccounts(acc);

	}

	const getBankByIban = async (iban) => {
		return axios.get('/api/manager/bankshelper/get-bank-by-iban', {
			params: {
				iban: iban
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});
	}

	return (
		<LabeledFrame 
			className="mb-3" 
			label="Cuentas bancarias"
			buttons={
				!readOnly ?
					<button className="btn-unstyled text-primary" onClick={() => addAccount()}><i className="bi bi-plus-circle-fill"></i></button>
					:
					null
			}
		>
			<BankAccountsWrapper>
				{accounts.length > 0 &&
					accounts.map((el, idx) => {
						if ( el.remove ) return null;

						let accErrors = new Map (
											jsonToArray(errors)
											.filter((errEl) => errEl.key.indexOf('bankaccounts.' + idx) !== -1)
											.map((errEl) => ([errEl.key.replace('bankaccounts.'+idx+'.', ''), errEl.value[0]]))
										);
						
						return (
							<BankAccount key={idx}>
								<LabeledFrame
									label={
										<div>
											Cuenta bancaria {idx+1} &nbsp;
											<button onClick={() => removeAccount(idx)} className={"btn btn-remove " + (readOnly ? 'disabled' : '')} disabled={readOnly}><i className="bi bi-x-circle-fill"></i></button>
										</div>
									}
									background="gray-300"
									color="gray-600"
								>
									<div className="row">
										<div className="col-md-3 mb-1">
											<InputWrapper className={accErrors.has('swift') ? 'has-errors' : ''}>
												<label>Swift / Bic</label>
												<input readOnly={readOnly} type="text" className="form-control form-control-sm" value={el.swift ?? ''} placeholder="Escribir aquí" onChange={(e) => setAccountField(idx, 'swift', e.target.value)} />
												{ accErrors.has('swift') && 
													<div>{ accErrors.get('swift') }</div>
												}
											</InputWrapper>
										</div>
										<div className="col-md-5 mb-1">
											<InputWrapper className={accErrors.has('iban') ? 'has-errors' : ''}>
												<label>Iban</label>
												<input readOnly={readOnly} type="text" className="form-control form-control-sm" value={el.iban ?? ''} placeholder="Escribir aquí" onChange={(e) => setAccountField(idx, 'iban', e.target.value)} />
												{ accErrors.has('iban') && 
													<div>{ accErrors.get('iban') }</div>
												}
											</InputWrapper>
										</div>
										<div className="col-md-4">
											<InputWrapper className={accErrors.has('description') ? 'has-errors' : ''}>
												<label>Descripción</label>
												<input readOnly={readOnly} type="text" className="form-control form-control-sm" value={el.description ?? ''} placeholder="Escribir aquí" onChange={(e) => setAccountField(idx, 'description', e.target.value)} />
												{ accErrors.has('description') && 
													<div>{ accErrors.get('description') }</div>
												}
											</InputWrapper>
										</div>
										<div className="col-md-3 mb-1">
											<InputWrapper className={accErrors.has('mandate_identifier') ? 'has-errors' : ''}>
												<label>Identificador del mandato</label>
												<input readOnly={readOnly} type="text" className="form-control form-control-sm" value={el.mandate_identifier ?? ''} placeholder="Escribir aquí" onChange={(e) => setAccountField(idx, 'mandate_identifier', e.target.value)} />
												{ accErrors.has('mandate_identifier') && 
													<div>{ accErrors.get('mandate_identifier') }</div>
												}
											</InputWrapper>
										</div>
										<div className="col-md-5 mb-1">
											<InputWrapper className={accErrors.has('mandate_date') ? 'has-errors' : ''}>
												<label>Fecha del mandato</label>
												<input readOnly={readOnly} type="date" className="form-control form-control-sm" value={el.mandate_date ?? ''} onChange={(e) => setAccountField(idx, 'mandate_date', e.target.value)} />
												{ accErrors.has('mandate_date') && 
													<div>{ accErrors.get('mandate_date') }</div>
												}
											</InputWrapper>
										</div>
										<div className="col-md-3 mb-1">
											<div className={'px-1 ' + (accErrors.has('active') ? 'has-errors' : '')}>
												<label>Activa</label>
												<div className="form-check form-switch">
													<input disabled={readOnly} className="form-check-input" type="checkbox" checked={el.active ? true : false} onChange={(e) => setAccountField(idx, 'active', e.target.checked)} />
												</div>
												{ accErrors.has('active') && 
													<div>{ accErrors.get('active') }</div>
												}
											</div>
										</div>
									</div>
								</LabeledFrame>
							</BankAccount>
						);
					})
				}
				{accounts.filter(el => !el.remove).length === 0 &&
					<div>No hay cuentas bancarias</div>
				}
			</BankAccountsWrapper>
		</LabeledFrame>
	);
}