import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getCommunity } from 'helpers/community';
import { formatNumber } from 'helpers/generic';
import EmpoweredSelector from 'components/EmpoweredSelector';
import CounterPartsSelector from 'components/CounterPartsSelector';
import ReactDOMServer from 'react-dom/server';

const TableMovements = styled.table`
    font-size: 12px;
    
    thead {

        tr {

            th {
                font-weight: 600;
            }
        }
    }

    th, 
    td {
        &:nth-child(1) {
            width: 80px;
        }

        &:nth-child(3) {
            width: 100px;
            text-align: right;
        }
    }
`;

const Table = styled.table`
	font-size: 13px;

	thead {
		th {
			font-weight: 500;
		}
	}

	td, th {
        &:nth-child(1) {
            width: 180px;
			max-width: 180px;
			min-width: 180px;
        }

        &:nth-child(3) {
			width: 100px;
			max-width: 100px;
			min-width: 100px;
			text-align: right;
		}

		&:nth-child(4) {
			width: 100px;
			max-width: 100px;
			min-width: 100px;

			position: relative;
			text-align: right;

			input {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 0;
				text-align: right;
				padding-right: 5px;
				padding-left: 5px;
			}
		}

		&:nth-child(5) {
			width: 40px;
			text-align: center;
		}
	}

	td {
		&:nth-child(1),
		&:nth-child(2) {
			padding: 0;

			.empowered-selector {

				.empowered-selector_label {
					border: 0;
					background: transparent;
				}

				.empowered-selector_menu {
					box-shadow: 0px 2px 5px var(--bs-gray-300);
				}
			}
		}

		&.has-errors {
			background: var(--bs-danger);

			.empowered-selector_label,
			input {
				background: var(--bs-danger);
				color: white;
			}
		}
	}
`;

let axiosCancelToken = null;

export default function ReceiptsRefunds(props) {
    let hideModal = props.hideModal;
    let entity = props.movements.filter((item) => item.class.indexOf('BankMovement') !== -1)[0] ?? null; 

    let [loading, setLoading] = useState(false);
    let [expensesCounterPart, setExpensesCounterPart] = useState(null);
    let [receipts, setReceipts] = useState([]);
    let [error, setError] = useState(null);

    useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

    const saveData = async () => {
        setLoading(true);
        await axios.post('/api/manager/bank-reconciliation/save', {
            type: 'receipt-refunds',
            community_id: getCommunity()?.id,
            bankmovement_id: entity.id,
            receipts: receipts.map(el => ({property_id: el.property_id, receipt_id: el.receipt_id, expenses: el.expenses})),
            estimate_expense_id: expensesCounterPart?.id
        }, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Movimientos conciliados');
            hideModal(true, true);
	  	}).catch((error) => {
			hideModal(true);

			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors && error.response.data.errors['bankmovement_id'] ) setError(error.response.data.errors['bankmovement_id'][0] ?? '');
			toast.error('Ha ocurrido un error al guardar');
		});
        setLoading(false);
    }

    const loadProperties = (input, callback, args) => {
		axios.get('/api/manager/properties/list', {
			params: {
				community_id: getCommunity()?.id,
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = [];
			response.data.forEach((property) => {
				formatted.push({
                    value: property,
                    label: property?.name
                });
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

    const loadReceipts = (input, callback, args) => {
		axios.get('/api/manager/receipts/list', {
			params: {
				community_id: getCommunity()?.id,
				search: input,
				no_paginate: true,
                property_id: args.property_id,
                paid_status: 'full',
                no_refunded: true,
                date_from: moment().subtract(1, 'years').startOf('year').startOf('month').format('YYYY-MM-DD')
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = [];
			response.data.forEach((receipt) => {
                if ( args.ids && args.ids.includes(receipt.id) ) return;

				formatted.push({
                    value: receipt,
                    label:  <div>
                                {receipt.number_full} - {moment(receipt.due_date).format('MMMM YYYY').toUpperCase()}
                                {receipt.payerable && <div><small>{receipt.payerable.name}</small></div>}
                            </div>
                });
			});
			callback(formatted);
	  	}).catch((error) => {
            console.log(error);
			if ( axios.isCancel(error) ) return;
		});	
	}

    const loadEstimateGroupsExpenses = (input, callback) => {
		axios.get('/api/manager/estimates/list', {
			params: {
				community_id: getCommunity()?.id,
				// search: input,
				no_paginate: true,
				status: 1,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = [];
			response.data.forEach((estimate) => {
				estimate.titles.forEach((title) => {
                    title.groups.forEach((group) => {
                        group.expenses.forEach((expense) => {
                            formatted.push({
                                value:{
                                    ...expense,
                                    estimategroup: {...group},
                                    title: {...title},
                                    estimate: {...estimate}
                                },
                                label: 
                                    <React.Fragment>
                                        {'P. ' + (estimate.type === 'ordinary' ? 'Ordinario' : 'Extraordinario') + ' ' + estimate.number_full}
                                        <i className="bi bi-arrow-right mx-1"></i>
                                        {title.denomination}
                                        <i className="bi bi-arrow-right mx-1"></i>
                                        {group.denomination}
                                        <i className="bi bi-arrow-right mx-1"></i>
                                        {expense.denomination}
                                    </React.Fragment>
                            });
                        })
                    });
				});
			});

            formatted = formatted.filter(el => {
				let div = document.createElement('div');
				div.innerHTML = ReactDOMServer.renderToString(el.label);
				let text = (div.textContent || div.innerText || '').toLowerCase();
				
				let inputSplit = input.split(' ');
				let coincidence = false;
				for(let cIdx in inputSplit) {
					let term = inputSplit[cIdx].toLowerCase();
					coincidence = text.indexOf(term) !== -1;
				}

				return coincidence;
			});

			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

    const addItem = useCallback(() => {
		let newReceipts = receipts ? [...receipts] : [];
		newReceipts.push({
            property_id: null,
            property: null,
			receipt_id: null,
            receipt: null,
            expenses: ''
		});
		setReceipts(newReceipts);
	}, [receipts]);

	const removeItem = (idx) => {
		let newReceipts = [...receipts];
		newReceipts.splice(idx, 1);
		setReceipts(newReceipts);
	}

    const setExpenses = (idx, value) => {
		let newReceipts = [...receipts];
		newReceipts[idx].expenses = value;
		setReceipts(newReceipts);
	}

    const selectProperty = (idx, value) => {
		let newReceipts = [...receipts];
		newReceipts[idx].property = value;
		newReceipts[idx].property_id = value?.id;
		newReceipts[idx].receipt = null;
		newReceipts[idx].receipt_id = null;
		setReceipts(newReceipts);
	}

    const selectReceipt = (idx, value) => {
		let newReceipts = [...receipts];
		newReceipts[idx].receipt = value;
		newReceipts[idx].receipt_id = value?.id;
		setReceipts(newReceipts);
	}

    useEffect(() => {
        if ( receipts.length <= 0 ) {
            addItem();
        }
    }, [receipts, addItem])

    let receiptsAmount = receipts.reduce((carry, item) => carry += parseFloat(item.receipt?.amount ?? 0), 0);
    let canReconciliate = receipts.filter(el => el.receipt_id && el.property_id ).length === receipts.length;

	return (
		<div className="row">
            <div className="col-md-12 mb-3">
                <label className="sbold">Movimiento bancario a conciliar</label>
                <TableMovements className="table table-sm table-bordered mb-0">
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Concepto</th>
                            <th>Importe</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{moment(entity.date).format('DD-MM-YYYY')}</td>
                            <td>{entity.concept ?? ''}</td>
                            <td>{formatNumber(entity.amount ?? 0)}</td>
                        </tr>
                    </tbody>
                </TableMovements>
            </div>

            <div className="col-md-12">
                <label className="sbold">Recibos a devolver</label>
                <Table className="table table-sm table-bordered mb-0">
                    <thead>
                        <tr>
                            <th>Propiedad</th>
                            <th>Recibo</th>
                            <th>Importe</th>
                            <th>Gastos</th>
                            <th>
                                <button className="btn-unstyled" onClick={() => addItem()}>
                                    <i className="bi bi-plus-circle-fill text-primary"></i>
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        { receipts && receipts.map((el, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>
                                        <EmpoweredSelector
                                            load={(input, callback, args) => loadProperties(input, callback, args)}
                                            args={{ids: receipts.map(el => el.property?.id)}}
                                            onChange={(value) => selectProperty(idx, value)}
                                            timeout={250}
                                            label={el.property ? el.property?.name : ''}
                                            placeholder="Seleccionar"
                                            value={el.property?.id}
                                            // noSearcheable={true}
                                        />
                                    </td>
                                    <td>
                                        <EmpoweredSelector
                                            load={(input, callback, args) => loadReceipts(input, callback, args)}
                                            args={{property_id: el.property_id, ids: receipts.map(el => el.receipt_id)}}
                                            onChange={(value) => selectReceipt(idx, value)}
                                            timeout={250}
                                            // label={el.receipt ? el.receipt.number_full : ''}
                                            label={
                                                el.receipt ? 
                                                    <div>
                                                        {el.receipt.number_full} - {moment(el.receipt.due_date).format('MMMM YYYY').toUpperCase()}
                                                        {el.receipt.payerable && <div><small>{el.receipt.payerable.name}</small></div>}
                                                    </div>
                                                :
                                                ''
                                            }
                                            placeholder="Seleccionar"
                                            value={el.receipt?.id}
                                            noSearcheable={true}
                                            disabled={!el.property_id}
                                        />
                                    </td>
                                    <td>{formatNumber(el.receipt?.amount ?? 0)}</td>
                                    <td /*className={(errors['counterparts.'+idx+'.amount'] ? 'has-errors' : '')} */>
                                        <input 
                                            type="number" 
                                            className="no-arrows" 
                                            value={el.expenses ?? ''} 
                                            onChange={(e) => setExpenses(idx, e.target.value)} 
                                            min={0} 
                                            max={9999.99} 
                                            disabled={!el?.receipt_id}
                                            placeholder={'Escribe aquí'}
                                        />
                                    </td>
                                    <td>
                                        <button className={'btn-unstyled text-danger'} onClick={() => removeItem(idx)}><i className="bi bi-x-circle-fill"></i></button>
                                    </td>
                                </tr>
                            );
                        })}
                        { (!receipts || !receipts.length) && <tr><td colSpan="100%" className="p-2">No se han seleccionado recibos para devolver</td></tr> }
                    </tbody>
                    { error &&
                        <tfoot>
                            <tr>
                                <td colSpan="100%" className="text-center text-danger">
                                    {error}
                                </td>
                            </tr>
                        </tfoot>
                    }
                </Table>
            </div>
            <div className="col-md-6">
                <div className="mt-2">
                    <label className="sbold">Contrapartida de gastos bancarios</label>
                    <CounterPartsSelector 
                        communityId={getCommunity()?.id}
                        expense={expensesCounterPart}
                        onChange={(value) => setExpensesCounterPart(value)}
                    />
                </div>
            </div>
            
            <div className="col-md-12">
                <div className="text-end mt-3">
                    <button type="button" className="btn btn-primary text-white" disabled={!canReconciliate || loading} onClick={() => saveData()}>{loading ? 'cargando...' : 'Confirmar conciliación'}</button>
                </div>
            </div>
        </div>
	);
}


