import React, { useRef, useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import LabeledFrame from 'components/LabeledFrame';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { getCommunity } from 'helpers/community';
import { openPopupWindow } from 'helpers/generic';

const Table = styled.table`
	width: 100%;
	
	td,th {
		font-size: 12px;
		line-height: 12px;

		&:nth-child(1) {

		}
		&:nth-child(2),
		&:nth-child(3) {
			width: 10%;
			position: relative;
			text-align: right;

			input {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				text-align: center;
				border: 0;
			}
		}
		&:nth-child(4) {
			width: 35px;

			button {
				display: block;
				width: 100%;
				color: var(--bs-red);
				font-size: 14px;
				background: transparent;
				border: none;
			}
		}
	}

	th {
		font-weight: 600;
		padding: 8px;
		white-space: nowrap;
	}

	td {
		&:first-of-type {
			padding: 0;

			.empowered-selector {

				.empowered-selector_label {
					border: 0;

					&:not(.disabled) {
						background: transparent;
					}
				}

				.empowered-selector_menu {
					box-shadow: 0px 2px 5px var(--bs-gray-300);
				}
			}
		}

		&.has-errors {
			background: var(--bs-danger);

			.empowered-selector_label,
			input[type=number] {
				color: white;
			}
		}
	}
`;

const PropertyRow = styled.tr`
	
`;

let axiosCancelToken = null;

export default function Properties(props) {
	let properties = useMemo(() => props.properties ?? [], [props.properties]);
	let setProperties = props.setProperties;
	let errors = props.errors ?? [];
	let readOnly = props.readOnly ?? false;

	let lastRow = useRef(null);
	let lastRowNumber = useRef(null);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( lastRow.current ) {
			lastRow.current.focus();
			lastRow.current.select();
			lastRow.current = null;
		}
	}, [properties]);
 
	const addProperty = () => {
		let pp = [...properties];
		pp.push({
			id: null,
			name: null,
			percent: pp.length <= 0 ? 100 : 0
		});
		setProperties(pp);

		lastRowNumber.current = pp.length - 1; // Needed to focus
	}

	const removeProperty = (idx) => {
		let c = window.confirm('¿Seguro que quieres eliminar esta propiedad?');
		if ( !c ) return false;

		let pp = [...properties];
		pp.splice(idx, 1);
		setProperties(pp);
	}

	const loadProperties = (input, callback) => {
		axios.get('/api/manager/properties/list', {
			params: {
				search: input,
				no_paginate: true,
				community_id: getCommunity()?.id,
				except_ids: properties.map(el => el.id)
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name + ' (' + el.number + ')'
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectProperty = (idx, data) => {
		let pp = [...properties];
		pp[idx].id = data?.id;
		pp[idx].name = data ? data.name + ' (' + data.number + ')' : '';
		setProperties(pp);
	}

	const setPercent = (idx, value) => {
		let pp = [...properties];
		value = parseFloat(value);
		if ( isNaN(value) ) value = '';
		pp[idx].percent = value;
		setProperties(pp);
	}

	const openPropertyWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	const openPropertyWindowMouseDownFix = (e) => {
		e.preventDefault();
		e.stopPropagation();
		document.activeElement.blur();
	}

	let rows = [];
	properties.forEach((el, idx) => {
		rows.push(
			<PropertyRow key={idx}>
				<td className={errors['properties.' + idx + '.id'] ? 'has-errors' : ''}>
					<EmpoweredSelector
						load={(input, callback) => loadProperties(input, callback)}
						onChange={(value) => selectProperty(idx, value)}
						timeout={250}
						label={
							<div>
								{el.name ?? ''}&nbsp;
								{el.id &&
									<NavLink className="text-decoration-none text-reset" to={'/properties/edit/' + el.id + '?popup=true'} onClick={(e) => openPropertyWindow(e)} onMouseDown={(e) => openPropertyWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
								}
							</div>
						}
						placeholder="Seleccionar"
						value={el.id}
						disabled={readOnly}
					/>
				</td>
				<td className={errors['properties.' + idx + '.percent'] ? 'has-errors' : ''}>
					<input 
						ref={lastRowNumber.current === idx ? lastRow : null} 
						step="0.01" 
						type="number" 
						className="no-arrows" 
						value={el.percent ?? ''} 
						onFocus={(e) => e.target.select()} 
						onChange={(e) => setPercent(idx, e.target.value)} 
						readOnly={readOnly}
					/>
				</td>
				<td>
					<input 
						type="number" 
						className={'text-' + (el.receiptscontableaccount?.balance >= 0  ? 'success' : 'danger')} 
						value={el.receiptscontableaccount?.balance ?? 0} 
						readOnly 
					/>
				</td>
				<td>
					<button className={'btn btn-sm p-0 ' + (readOnly ? 'disabled' : '')} onClick={() => removeProperty(idx)}><i className="bi bi-x-circle-fill"></i></button>
				</td>
			</PropertyRow>
		);
	});

	return (
		<LabeledFrame 
			className="mb-3" 
			label="Propiedades"
			buttons={
				!readOnly ? 
					<button className="btn-unstyled text-primary" onClick={() => addProperty()}><i className="bi bi-plus-circle-fill"></i> Asociar</button>
					:
					null
			}
		>
			{ rows.length > 0 &&
				<React.Fragment>
					<Table className="table table-sm table-bordered mb-0">
						<thead>
							<tr>
								<th>Propiedad</th>
								<th>% participación</th>
								<th>Saldo recibos</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{rows}
						</tbody>
					</Table>
				</React.Fragment>
			}
		</LabeledFrame>
	);
}