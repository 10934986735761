import React, { useState, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'react-toastify';
import { readableSize } from 'helpers/generic';

const BulkUploaderHelper = (props) => {
    // Validate
    if ( props.files.constructor !== Array ) throw new Error('Invalid argument: files must be an array');
    if ( props.files.length === 0 ) throw new Error('Invalid argument: files array is empty');
    
    return (
        <>
            {createPortal(
                <UploadModal 
                    files={props.files} 
                    close={props.close}
                    community={props.community}
                />,
                document.getElementById('root')   
            )}
        </>
    );
}

export default BulkUploaderHelper;


//--------------------------------------------------------------------------------------//
//                                   UPLOADING MODAL                                    //
//--------------------------------------------------------------------------------------//

const StyledModal = styled.dialog`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
    border: 0;

    .modal-inner {
        display: flex;
        flex-direction: column;
        min-width: 300px;
        max-width: 500px;
        max-height: 100%;
        padding: 10px;
        border-radius: 5px;
        background: white;

        .files-container {
            overflow-y: auto;
	        height: 100%;

            .file {
                padding: 5px;
                background: var(--bs-gray-200);
                border-radius: 3px;
                font-size: 13px;
                line-height: 16px;
                margin-bottom: 10px;
            }
        }

        .progress {
            position: relative;
            margin-bottom: 10px;
            height: 20px;

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                font-size: 11px;
            }
        }
    }
`;

let axiosCancelToken = null;

const UploadModal = (props) => {
    const files = props.files;
    const community = props.community;
    const close = useCallback(() => props.close(), []);

	const [progress, setProgress] = useState(0);

    useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

    useEffect(() => {
        const upload = async () => {
            // Prepare form data
            let formData = new FormData();
            files.forEach(file => {
                formData.append('files[]', file);
            });
            if ( community?.id ) formData.append('community_id', community?.id);
    
            // Upload
            axios.post('/api/manager/providers-invoices/upload-bulk-files', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data' 
                },
                cancelToken: axiosCancelToken.token,
                onUploadProgress: (e) => {
                    let percentCompleted = Math.round((e.loaded * 100) / e.total);
                    setProgress(percentCompleted);
                }
            })
            .then((response) => {
                toast.success('Archivos subidos correctamente');
                close();
            })
            .catch((error) => {
                if ( axios.isCancel(error) ) return;
            });
        }
        upload();

    }, [files, community, close])

    const cancelUpload = () => {
        close();
    }

    return (
        <StyledModal open={true}>
            <div className="modal-inner">
                <div className="files-container">
					{ files.map((el, idx) => {
						return (
							<div className="file" key={idx}>
								<div className="name"><span className="sbold">Nombre:</span> {el.name}</div>
								<div className="size"><span className="sbold">Tamaño:</span> {readableSize(el.size)}</div>
							</div>
						);
					})}
				</div>

                <div className="progress">
                    <span style={{color: progress > 50 ? 'white' : ''}}>{progress + '%'}</span>
                    <div className="progress-bar" style={{width: progress + '%'}}></div>
                </div>

                <button className={'btn btn-danger btn-sm text-white'} onClick={() => cancelUpload()}>Cancelar</button>
            </div>
        </StyledModal>
    )
}