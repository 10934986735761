import React, { useEffect, useRef } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import LabeledFrame from 'components/LabeledFrame';
import CustomInput from 'components/CustomInput';

const StreetsStyled = styled.div`
	width: 100%;
`;

let axiosCancelToken = null;

export default function Streets(props) {
	let lastRow = useRef(null);
	let lastRowNumber = useRef(null);

	let streets = props.data;
	let setStreets = props.setData;
	let errors = props.errors;
	let readOnly = props.readOnly ?? false;

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( lastRow.current ) {
			lastRow.current.focus();
			lastRow.current = null;
		}
	}, [streets]);

	const addStreet = () => {
		setStreets([...streets, {id: null, name: ''}]);

		lastRowNumber.current = streets.length; // Needed to focus
	}

	const updateStreet = (idx, value) => {
		let newStreets = [...streets];
		newStreets[idx].name = value;
		setStreets(newStreets);
	}

	const removeStreet = (idx) => {
		let street = streets[idx];
		if ( street.id ) {
			let c = window.confirm('¿Seguro que quieres borrar la calle? Las propiedades asociadas quedarán con el campo "calle" vacío');
			if ( !c ) return;
		}

		let newStreets = [...streets];
		newStreets.splice(idx, 1);
		setStreets(newStreets);
	}

	return (
		<StreetsStyled>
			<LabeledFrame 
				className="mb-3" 
				label="Calles"
				buttons={
					!readOnly ?
						<button className="btn btn-unstyled text-primary" onClick={addStreet}><i className="bi bi-plus-circle-fill"></i></button>
						:
						null
				}
			>
				<div className="row">
					{streets?.map((el, idx) => {
						return (
							<div key={idx} className="col-md-6 mb-2">
								<div className="input-group input-group-sm">
									<CustomInput readOnly={readOnly} ref={idx === lastRowNumber.current ? lastRow : null} type="text" className="form-control form-control-sm" onChange={(e) => updateStreet(idx, e.target.value)} value={el.name} placeholder={'Nombre de la calle'} />
									<div className="input-group-append">
										<button disabled={readOnly} class="btn btn-danger btn-sm" onClick={() => removeStreet(idx)}><i className="bi bi-x text-white"></i></button>
									</div>
								</div>
								{ errors['streets.' + idx + '.name'] &&
									<div className="invalid-feedback d-block">{ errors['streets.' + idx + '.name'][0] }</div>
								}
							</div>
						)
					})}
				</div>
			</LabeledFrame>
		</StreetsStyled>	
	);
}