import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { NavLink, useSearchParams } from 'react-router-dom';
import EmpoweredSelector from 'components/EmpoweredSelector';
import Paginator from 'components/Paginator';
import { openPopupWindow, formatNumber } from 'helpers/generic';
import { getCommunity } from 'helpers/community';

const Table = styled.table`
	width: 100%;
	font-size: 13px;

	td,th {
		&:nth-child(2) {
			text-align: right;
		}
	}
`;

let axiosCancelToken = null;

export default function ProviderInvoicesNoFiles() {
	const [queryParams] = useSearchParams();

	let [providerInvoices, setProviderInvoices] = useState({});
	let [forceReload, setForceReload] = useState(null);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setProviderInvoices({...providerInvoices, data: undefined});
		_setPage(page);
	}
	let [community, setCommunity] = useState({
		id: getCommunity()?.id,
		name: getCommunity()?.name
	});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	// Proxy for popup window to update when fields are changed on popup
	useEffect(() => {
		window.PopupProxyCallbackProviderInvoices = () => {
			setForceReload(Math.random());
		}
	}, []);

	useEffect(() => {
		const getProviderInvoices = async () => {
			await axios.get('/api/manager/providers-invoices/list', {
				params: {
					community_id: community?.id,
					no_file: true,
					page: page,
					paginate: 10
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				setProviderInvoices({...response.data});
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getProviderInvoices();
	}, [forceReload, community?.id, page]);

	const loadCommunities = (input, callback) => {
		axios.get('/api/manager/communities/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();
		e.stopPropagation();

		openPopupWindow(e.currentTarget.href);
	}

	return (
		<div className="card border-0 shadow-sm">
			<div className="card-header bg-white p-3">
				<div className="row">
					<div className="col-md-6 mb-2 mt-2 mb-md-0 mt-md-0">
						<h5 className="card-title mb-0 text-primary d-flex h-100 align-items-center justify-content-center">Gastos sin documento asociado</h5>
					</div>
					<div className="col-md-1 mb-2 mt-2 mb-md-0 mt-md-0">
					</div>	
					<div className="col-md-5 mb-2 mt-2 mb-md-0 mt-md-0">
						<EmpoweredSelector
							load={loadCommunities}
							onChange={(value) => setCommunity({id: value?.id, name: value?.name})}
							timeout={250}
							label={community?.name ?? ''}
							placeholder="Todas las comunidades"
							value={community?.id}
						/>
					</div>
				</div>
			</div>
			<div className="card-body">
				{ providerInvoices.data?.length > 0 &&
					<div className="row">
						<div className="col-md-12">
							<Table className="table table-sm table-bordered mb-0">
								<thead>
									<tr>
										<th className="fw-normal">Proveedor</th>
										<th className="fw-normal">Importe</th>
									</tr>
								</thead>
								<tbody>
									{ providerInvoices.data?.map((el, idx) => {
										return (
											<tr key={idx}>
												<td>
													<NavLink to={'/providers-invoices/edit/' + el.id + '?popup=true'} onClick={(e) => openPopupInfoWindow(e)} className="text-decoration-none">
														{el.provider?.name} <i className="bi bi-eye-fill"></i>
													</NavLink>
												</td>
												<td>{el.total ? formatNumber(el.total) + ' €' : '-'}</td>
											</tr>
										);
									})}
								</tbody>
							</Table>	
						</div>
					</div>
				}
				{ providerInvoices.data?.length <= 0 && 'No hay documentos pendientes'}
			</div>
			<div className="card-footer p-3">
				<div className="row">
					<div className="col md-6">
						
					</div>
					<div className="col md-6 d-flex justify-content-end">
						<div className="d-inline-block">
							<Paginator
								min={1}
								current={providerInvoices?.current_page}
								max={providerInvoices?.last_page}
								changeCallback={(page) => setPage(page)}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}


