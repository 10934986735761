import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'react-toastify';
import { viewReceiptPdf } from 'helpers/receipts';
import { loader, formatNumber, openPopupWindow, downloadFile } from 'helpers/generic';
import { getCommunity } from 'helpers/community';
import { authUserPermission } from 'helpers/community';

const TableReceipts = styled.table`
	font-size: 13px;

	tbody {
		border-top: 0 !important;
	}

	td, th {
		&:nth-child(1) {
			width: 20px;
			white-space: nowrap;
		}

		&:nth-child(2) {
			width: 150px;
		}

		&:nth-child(4) {
			width: 300px;
		}

		&:nth-child(5) {
			width: 80px;
			white-space: nowrap;
			text-align: right;
		}

		&:nth-child(6) {
			width: 50px;
			vertical-align: middle;

			a {
				&:not(:last-of-type) {
					margin-right: 8px;
				}
			}
		}
	}
`;

let axiosCancelToken = null;

const Details = (props) => {
    let remittanceId = props.remittanceId;
    let forceReload = props.forceReload;

    let [loading, setLoading] = useState(false);
    let [remittance, setRemittance] = useState(null);
	let [fileGenerationError, setFileGenerationError] = useState(null);

    useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

    useEffect(() => {
        let getRemittance = async () => {
            setLoading(true);
            await axios.get('/api/manager/bankremittances/get/' + remittanceId, {
				params: {
					community_id: getCommunity()?.id
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				setRemittance({...response.data});
                setLoading(false);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
                setLoading(false);
			});
        }
        getRemittance();
	}, [remittanceId]);

    const openPopupInfoWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	const viewReceiptPdfClick = (e, receipt_id) => {
		e.preventDefault();

		viewReceiptPdf(receipt_id);
	}

	const deleteBankRemittance = (remittance_id) => {
		const c = window.confirm('¿Seguro que quieres eliminar esta remesa?');
		if ( !c ) return false;

		axios.post('/api/manager/bankremittances/delete/' + remittance_id, {
			community_id: getCommunity()?.id
		}, {
			cancelToken: axiosCancelToken.token,
		}).then((response) => {
			toast.info('Remesa bancaria eliminada');
            forceReload();
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const downloadBankRemittanceFile = async (remittance_id) => {
		setFileGenerationError(null);

		let result = await downloadFile('/api/manager/bankremittances/generate-xml/' + remittance_id + '?community_id=' + getCommunity()?.id);
			
		if ( result !== true ) {
			let resultText = await result.response.data.text();
			let jsonResult = JSON.parse(resultText);
			if ( jsonResult.error ) {
				setFileGenerationError(jsonResult.error);
				toast.error('No se ha podido generar el fichero');
			}
		}
	}

	const toggleCompleted = async (id, status) => {
		axios.post('/api/manager/bankremittances/toggle-completed/' + id, {
			community_id: getCommunity()?.id,
			status: status ? 1 : 0
		}, {
			cancelToken: axiosCancelToken.token,
		}).then((response) => {
			let newState = {...remittance};
			newState.completed = status ? 1 : 0;
			setRemittance(newState);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}
    
    return (
        <>
            { loading && loader }
            { remittance &&
                <>
                    <div className="col-md-4">
                        <div><b className="sbold">Fecha</b>: {moment(remittance.date).format('DD-MM-YYYY')}</div>
                        <div><b className="sbold">Fecha de pago</b>: {moment(remittance.payment_date).format('DD-MM-YYYY')}</div>
                        <div>
                            <b className="sbold">Banco</b>: 
                            &nbsp;{remittance.treasury?.name ?? '-'}&nbsp;
                            <NavLink to={'/treasury/edit/' + remittance.treasury.id + '?popup=true'} onClick={openPopupInfoWindow}><i className="bi bi-eye"></i></NavLink>
                        </div>
                        <div><b className="sbold">Concepto</b>: {remittance.concept ?? '-'}</div>
                    </div>
                    <div className="col-md-4">
                        <div><b className="sbold">Nº de recibos</b>: {remittance.receipts_count}</div>
                        <div><b className="sbold">Total</b>: {formatNumber(remittance.total)} €</div>
                    </div>
                    <div className="col-md-4 text-end">
                        { fileGenerationError &&
                            <div className="text-danger small text-start">{fileGenerationError}</div>
                        }
                        <button className="btn btn-sm btn-primary text-white mb-3" onClick={() => downloadBankRemittanceFile(remittance.id)}><small>Descargar fichero banco</small></button>																					
                        <div></div>
                        { authUserPermission('edit') &&
                            <div className="form-check form-switch mb-3 d-inline-flex align-items-center">
                                <input className="form-check-input mb-1 me-2" type="checkbox" checked={remittance.completed ?? false} onChange={(e) => toggleCompleted(remittance.id, e.target.checked)} />
                                <label className="form-check-label">Enviada al banco</label>
                            </div>
                        }
                        <div></div>
                        <button className="btn btn-sm btn-link text-danger" onClick={() => deleteBankRemittance(remittance.id)}><small>Eliminar remesa</small></button>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="table-responsive">
                            <TableReceipts className="table table-sm table-bordered mb-2">
                                <thead>
                                    <tr>
                                        <th>Nº</th>
                                        <th>Emisión</th>
                                        <th>Propiedad</th>
                                        <th>Pagador</th>
                                        <th>Total</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {remittance.receipts?.map((rEl, rIdx) => {
                                        return (
                                            <tr key={'r'+rIdx}>
                                                <td>{rEl.number_full}</td>
                                                <td>
                                                    {rEl.issuance?.entity_name}&nbsp;
                                                    <span className="badge bg-light text-secondary">Nº: {rEl.issuance?.number}</span>
                                                </td>
                                                <td>
                                                    { rEl.property?.id &&
                                                        <>
                                                            {rEl.property.name}&nbsp;
                                                            <NavLink to={'/properties/edit/' + rEl.property.id + '?popup=true'} onClick={openPopupInfoWindow}><i className="bi bi-eye"></i></NavLink>
                                                        </>
                                                    }
                                                </td>
                                                <td>
                                                    { rEl.payerable?.id ?
                                                        <>
                                                            {rEl.payerable?.name}&nbsp;
                                                            { rEl.payerable?.id &&
                                                                <NavLink to={'/'+rEl.payer_type+'s/edit/' + rEl.payerable_id + '?popup=true'} onClick={openPopupInfoWindow}><i className="bi bi-eye"></i></NavLink>
                                                            }
                                                        </>
                                                        :
                                                        <div className="text-danger">
                                                            No tiene pagador
                                                        </div>
                                                    }

                                                    { rEl.refunded_at &&
                                                        <>
                                                            <div className="text-danger small">
                                                                DEVUELTO <i className="bi bi-dash-circle-fill"></i>

                                                                { rEl.parent ?
                                                                    <span>
                                                                        &nbsp;<i className="bi bi-arrow-right"></i> {rEl.parent.number_full}
                                                                    </span>
                                                                    :
                                                                    <span>
                                                                        &nbsp; nuevo recibo no generado
                                                                    </span>
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                </td>
                                                <td>{formatNumber(rEl.amount)} €</td>
                                                <td className="text-center">
                                                    <NavLink to={'/receipts/edit/' + rEl.id} onClick={openPopupInfoWindow}><i className="bi bi-pencil"></i></NavLink>
                                                    <a href="/" onClick={(e) => viewReceiptPdfClick(e, rEl.id)}><i className="bi bi-printer text-secondary"></i></a>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    { remittance.receipts?.length === 0 &&
                                        <tr>
                                            <td colSpan="100%" className="text-start">
                                                No hay recibos
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </TableReceipts>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default Details;