import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader } from 'helpers/generic';
import { getCommunity } from 'helpers/community';
import LabeledFrame from 'components/LabeledFrame';
import Notes from 'components/Notes';
import DocumentsPanel from 'components/DocumentsPanel';
import GroupsExpenses from './GroupsExpenses';
import QuotasConfig from './QuotasConfig';
import QuotasList from './QuotasList';
import ButtonExportFichaPdf from 'components/ButtonExportFicha';
import ActionsContextMenu from 'components/ActionsContextMenu';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';

let axiosCancelToken = null;

export default function EstimatesForm(props) {
	let actionsDropdownRef = useRef(null);

	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';
	const copy_from_id = queryParams.get('copy_from_id');

	let [data, setData] = useState({
		type: 'ordinary',
		status: 0,
		date: moment().format('YYYY-MM-DD'),
		groups: [],
		receipts_amount_calculation: 'estimate',
		receipts_issues_qty: 12,
		receipts_round_decimals: 0,
		receipts_round_direction: 'up',
		properties_fixed_quotas: []
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);

	let [receiptsTotalBridgeFix, setReceiptsTotalBridgeFix] = useState(0);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);

				await axios.get('/api/manager/estimates/get/' + params.id, {
					cancelToken: axiosCancelToken.token,
					params: {
						community_id: getCommunity()?.id
					}
				}).then((response) => {
			    	setData(data => ({...data, ...response.data, number: response.data.number_full}));
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		} else {
			const getLastNumber = () => {
				axios.get('/api/manager/estimates/get-last-number', {
					cancelToken: axiosCancelToken.token,
					params: {
						community_id: getCommunity()?.id,
						year: moment(data.date).format('YYYY')
					}
				}).then((response) => {
					let number = response.data.number;
					number += '/' + moment(data.date).format('YYYY');
			    	setData(data => ({...data, number: number}));
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});
			}
			getLastNumber();

			const copyFromEstimate = () => {
				// Get data
				axios.get('/api/manager/estimates/get/' + copy_from_id, {
					cancelToken: axiosCancelToken.token,
					params: {
						community_id: getCommunity()?.id
					}
				}).then((response) => {
					let groups = response.data.groups ?? null;
					if ( !groups ) return;

					// Copy removing id
					groups.forEach((el) => {
						el.id = null;
						el.estimate_id = null;
						if ( el.titles ) el.titles.forEach(title => {
							title.id = null;
							title.estimategroup_id = null;

							if ( title.expenses ) title.expenses.forEach(expense => {
								expense.id = null;
								expense.estimategroup_id = null;
								expense.title_id = null;
								expense.is_deletable = true;
							});
						});
					});
					setData((data) => ({...data, groups: [...groups]}));
				}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});
			}
			if ( copy_from_id ) copyFromEstimate();
			else setData((data) => ({...data, groups: []}));
		}
	}, [params.id, data.type, data.date, copy_from_id]);

	const deleteEstimate = () => {
		const c = window.confirm('¿Quieres eliminar este presupuesto?');
		if ( !c ) return;

		axios.delete('/api/manager/estimates/delete/' + data.id + '?community_id=' + getCommunity()?.id, {}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Presupuesto borrado')
	    	navigate('/estimates');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = async (goBack = true) => {
		setErrors({});

		let toSave = {...data};
		toSave.community_id = getCommunity()?.id;

		setLoading(true);

		await axios.post('/api/manager/estimates/' + (data.id ? 'edit/' + data.id : 'add'), toSave, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
	    	setData({...data, ...response.data.estimate, number: response.data.estimate.number_full});
			if ( goBack ) navigate('/estimates');
			else {
		    	navigate('/estimates/edit/' + response.data.estimate.id);
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	

		setLoading(false);
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de presupuesto</h1>
					<button  onClick={() => popup ? window.close() : navigate('/estimates')} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-lg-6">
											<LabeledFrame 
												label="Datos básicos" 
												className="mb-3 mb-md-0"
												buttons={<div className="text-white sbold">{data.number}</div>}
												buttonsBackground="primary"
											>
												<div className="row">
													<div className="col-lg-4">
														<div className="mb-2">
															<CustomSelect label="Tipo" className="form-control form-control-sm" onChange={(e) => setDataField('type', e.target.value)} value={data.type ?? ''} disabled={data.id}>
																<option value="ordinary">Ordinario</option>
																<option value="extraordinary">Extraordinario</option>
															</CustomSelect>
															{ errors.type &&
																<div className="invalid-feedback d-block">{ errors.type[0] }</div>
															}
														</div>
													</div>
													<div className="col-lg-4">
														<div className="mb-2">
															<CustomInput label="Fecha de inicio" type="date" className="form-control form-control-sm" onChange={(e) => setDataField('date', e.target.value)} value={data.date ? moment(data.date).format('YYYY-MM-DD') : ''} disabled={data.id} />
															{ errors.date &&
																<div className="invalid-feedback d-block">{ errors.status[0] }</div>
															}
														</div>
													</div>
													<div className="col-lg-4">
														<div className="mb-2">
															<CustomSelect label="Estado" className="form-control form-control-sm" onChange={(e) => setDataField('status', e.target.value)} value={data.status ?? ''}>
																<option value="0">{data.type === 'ordinary' ? 'No vigente' : 'No aprobado'}</option>
																<option value="1">{data.type === 'ordinary' ? 'Vigente' : 'Aprobado'}</option>
															</CustomSelect>
															{ errors.status &&
																<div className="invalid-feedback d-block">{ errors.status[0] }</div>
															}
														</div>
													</div>
													<div className="col-lg-4">
														<div className="mb-2">
															<CustomInput label="Fecha de aprobación" type="date" className="form-control form-control-sm" onChange={(e) => setDataField('approval_date', e.target.value)} value={data.approval_date ? moment(data.approval_date).format('YYYY-MM-DD') : ''} />
															{ errors.approval_date &&
																<div className="invalid-feedback d-block">{ errors.status[0] }</div>
															}
														</div>
													</div>
													<div className="col-lg-8">
														<div className="">
															<CustomInput label="Descripción" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('description', e.target.value)} value={data.description ?? ''} />
															{ errors.description &&
																<div className="invalid-feedback d-block">{ errors.description[0] }</div>
															}
														</div>
													</div>
													<div className="col-lg-4">
														<div className="mb-2">
															<CustomInput label="Saldo bancario anterior" type="number" step="0.01" className="form-control form-control-sm" onChange={(e) => setDataField('previous_bank_total', e.target.value)} value={data.previous_bank_total ?? ''} />
															{ errors.previous_bank_total &&
																<div className="invalid-feedback d-block">{ errors.previous_bank_total[0] }</div>
															}
														</div>
													</div>
												</div>
											</LabeledFrame>
										</div>
										<div className="col-lg-6">
											<QuotasConfig
												data={data}
												setData={setData}
												setDataField={setDataField}
												errors={errors}
											/>
										</div>
										<div className="col-lg-12 mt-3 mb-3">
											<GroupsExpenses
												groups={data.groups ?? []}
												setGroups={(groups) => setData((data) => ({...data, groups: groups}))}
												previousBankTotal={data.previous_bank_total}
												receiptsTotal={receiptsTotalBridgeFix}
												errors={errors}
											/>
										</div>
										<div className="col-lg-12 mb-3">
											<QuotasList
												data={data}
												setData={setData}
												errors={errors}
												totalCallback={(total) => setReceiptsTotalBridgeFix(total)}
											/>											
										</div>
										<div className="col-md-6">
											<LabeledFrame className="mb-3" label="Instrucciones">
												<textarea className="form-control form-control-sm" rows="3" onChange={(e) => setDataField('observations', e.target.value)} value={data.observations ?? ''}></textarea>
												{ errors.observations &&
													<div className="invalid-feedback d-block">{ errors.observations[0] }</div>
												}
											</LabeledFrame>

											<Notes
												className="mb-3"
												notes={data.notes ?? []}
												updateParentCallback={(notes) => setData((data) => ({...data, notes: notes}))}
											/>
										</div>
										<div className="col-md-6">
											{ data.id && 
												<DocumentsPanel
													className="mb-3"
													folderId={data.folder_id ?? null}
													community={getCommunity()}
												/>
											}
										</div>
									</div>	
								</div>
								<div className="card-footer" id="footer-fixed">
									<div className="row">
										<div className="col-6">
											{ (data.id && data.is_deletable) &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteEstimate()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											{ data.id &&
												<div className="dropdown d-inline">
													<button ref={actionsDropdownRef} className="btn btn-sm btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
														Acciones
													</button>
													<ActionsContextMenu actionsDropdownRef={actionsDropdownRef} className="dropdown-menu">
														<li><ButtonExportFichaPdf entityKey='estimates' entityId={data.id} fileName={'Presupuesto ' + data.number_full} /></li>

														<li><hr className="dropdown-divider" /></li>
														<li><button className="dropdown-item" onClick={() => saveData(false)} disabled={loading}>Guardar</button></li>						
														<li><button className="dropdown-item" onClick={() => saveData()} disabled={loading}>Guardar y salir</button></li>
													</ActionsContextMenu>
												</div>
											}

											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)} disabled={loading}>Guardar</button>							
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()} disabled={loading}>Guardar y salir</button>							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}