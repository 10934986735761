import React, { useState } from 'react';
import styled from 'styled-components';
import ReasonVotesModal from './ReasonVotesModal';
import ReasonTextFieldModal from './ReasonTextFieldModal';

const ReasonStyled = styled.div`
	border: 1px solid var(--bs-orange);
	border-radius: 3px;
	padding: 10px;
	font-size: 13px;
	display: flex;
	position: relative;

	&:not(:last-of-type) {
		margin-bottom: 10px;
	}

	.checkbox {
		display: flex;
		align-items: center;
		margin-left: -5px;
		margin-right: 5px;
		width: 10px;

		input {
			cursor: pointer;
		}
	}

	.number {
		position: absolute;
		top: -5px;
		left: -5px;
		background: var(--bs-orange);
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 15px;
		height: 15px;
		color: white;
		font-size: 10px;
		cursor: move;
		cursor: -webkit-grabbing;
	}

	.reason_wrapper {
		width: calc(100% - 40px);
		padding-right: 10px;
		position: relative;

 		.column_wrapper {
			font-family: Helvetica !important;
			width: 50%;
			max-width: 50%;
			padding: 5px;
			cursor: pointer;

			&.not-allowed {
				cursor: not-allowed;
			}

			&:hover {
				outline: 4px solid var(--bs-blue-light);
				border-radius: 5px;

				&:active {
					background: var(--bs-gray-300);
				}
			}

			@media (max-width: 992px) {
				&:not(:last-of-type) {
 					margin-bottom: 10px;
				}

 				width: 100%;
 				max-width: 100%;
	 		}

			&:first-of-type {
				margin-right: 10px;
			}

			b {
	 			font-weight: 500;
	 		}

			.html {
				font-size: 11px;
				p {
					margin-bottom: 0px;
				}
			}
		}

		.textareas_wrapper {
 			display: flex;

 			@media (max-width: 992px) {
	 			display: block;
	 		}	 	

	 		.preview {
	 			height: 20px;
	 			overflow: hidden;
	 			text-overflow: ellipsis;
	 			white-space: nowrap;

	 			&:hover {
	 				cursor: pointer;
	 				background: var(--bs-gray-100);
	 			}
	 		}
 		}
	}

	.buttons {
		width: 100px;
		min-width: 100px;
		max-width: 100px;
		border-left: 1px solid var(--bs-gray-200);
		padding-left: 10px;
		text-align: center;

		button {
			margin-bottom: 10px;
		}
	}
	
`;

export default function Reason(props) {
	let data = props.data;

	let [reasonVotesModalOpened, setReasonVotesModalOpened] = useState(false);
	let [reasonDescriptionModalOpened, setReasonDescriptionModalOpened] = useState(false);
	let [reasonResultModalOpened, setReasonResultModalOpened] = useState(false);

	return (
		<ReasonStyled>
			<div className="checkbox">
				<input type="checkbox" checked={props.checked ?? false} onChange={(e) => props.setChecked(e.target.checked)} disabled={!props.isCheckable} />
			</div>
			<div className="number">{props.number}</div>
			<div className="reason_wrapper">
				<div className="textareas_wrapper">
					<div className={'column_wrapper ' + (!props.descriptionEditable ? 'not-allowed' : '')} onClick={() => props.descriptionEditable ? setReasonDescriptionModalOpened(true) : null}>
						<b>Descripción</b>
						<div className="html" dangerouslySetInnerHTML={{__html: (data.description && data.description.length > 0) ? data.description : '-'}}></div> 
					</div>
					<div className={'column_wrapper ' + (!props.resultEditable ? 'not-allowed' : '')} onClick={() => props.resultEditable ? setReasonResultModalOpened(true) : null}>
						<b>Acuerdos</b>
						<div className="html" dangerouslySetInnerHTML={{__html: (data.result && data.result.length > 0) ? data.result : '-'}}></div> 
					</div>
				</div>
			</div>
			<div className="buttons">
				<button className="btn btn-sm btn-secondary" onClick={() => setReasonVotesModalOpened(true)}>
					Votaciones
				</button>
			</div>
			{ reasonVotesModalOpened && 
				<ReasonVotesModal 
					data={props.data}
					number={props.number}
					setField={props.setField}
					delete={props.delete}
					properties={props.properties}
					closeCallback={async (saveData) => {
						if ( saveData ) await props.saveData();
						setReasonVotesModalOpened(false);
					}}
				/>
			}
			{ reasonDescriptionModalOpened && 
				<ReasonTextFieldModal 
					number={props.number}
					fieldName={'Descripción'}
					text={props.data.description}
					setText={(text) => props.setField('description', text)}
					delete={props.delete}
					templateType="meeting-description"
					closeCallback={async (saveData) => {
						if ( saveData ) await props.saveData();
						setReasonDescriptionModalOpened(false);
					}}
				/>
			}
			{ reasonResultModalOpened && 
				<ReasonTextFieldModal 
					number={props.number}
					fieldName={'Acuerdos'}
					text={props.data.result}
					setText={(text) => props.setField('result', text)}
					templateType="meeting-result"
					delete={props.delete}
					closeCallback={async (saveData) => {
						if ( saveData ) await props.saveData();
						setReasonResultModalOpened(false);
					}}
				/>
			}
		</ReasonStyled>
	);
}