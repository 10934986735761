import React, { useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import LabeledFrame from 'components/LabeledFrame';
import CounterPartsSelector from 'components/CounterPartsSelector';

const Table = styled.table`
	font-size: 13px;

	thead {
		th {
			font-weight: 500;
		}
	}

	td, th {
		&:nth-child(2) {
			width: 100px;
			max-width: 100px;
			min-width: 100px;

			position: relative;
			text-align: right;

			input {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 0;
				text-align: right;
				padding-right: 5px;
				padding-left: 5px;
			}
		}
	}

	td {
		&:first-of-type {
			padding: 0;
		}

		&.has-errors {
			background: var(--bs-danger);

			input {
				outline: none;
				background: transparent;
				color: white;
			}
		}
	}
`;

const TableWrapper = styled.div`
	position: relative;

	.remove_accountingentry_message {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: rgba(255,255,255,0.7);
		z-index: 2;
		font-size: 15px;
		font-weight: bold;
	}
`;

let axiosCancelToken = null;

export default function CounterParts(props) {
	const data = props.data;
	const setData = props.setData;
	const errors = props.errors;
	const saveData = props.saveData;
	let readOnly = props.readOnly ?? false;

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	const addItem = () => {
		let counterparts = data.counterparts ? [...data.counterparts] : [];
		counterparts.push({
			estimategroup_id: null,
			amount: counterparts.length === 0 ? data.total : ''
		});
		setData({...data, counterparts: counterparts});
	}

	const removeItem = (idx) => {
		let counterparts = [...data.counterparts];
		counterparts.splice(idx, 1);
		setData({...data, counterparts: counterparts});
	}

	const setDataField = (idx, field, value) => {
		let counterparts = [...data.counterparts];
		counterparts[idx][field] = value;
		setData({...data, counterparts: counterparts});
	}

	const selectEstimateGroupExpense = (idx, value) => {
		let counterparts = [...data.counterparts];
		counterparts[idx].estimategroupexpense = null;
		if ( value ) {
			counterparts[idx].estimategroupexpense = {
				id: value?.id,
				denomination: value?.denomination,
				estimategroup: {
					denomination: value?.estimategroup.denomination,
					// title: {
					// 	denomination: value?.title.denomination
					// },
					estimate: {
						// type: value?.estimate.type,
						// number_full: value?.estimate.number_full
						type: value?.estimategroup.estimate.type,
						number_full: value?.estimategroup.estimate.number_full
					}
				},
				title: {
					denomination: value?.title.denomination
				},
			};
		}
		counterparts[idx].estimategroupexpense_id = value?.id;
		setData({...data, counterparts: counterparts});
	}

	const removeAccountingEntry = (status) => {
		let newData = {...data};
		newData.remove_accountingentry = status;
		setData(newData);
	}

	return (
		<LabeledFrame label={'Contrapartidas'}>
			<TableWrapper>
				<Table className="table table-sm table-bordered mb-0">
					<thead>
						<tr>
							<th>Contrapartida</th>
							<th>Importe</th>
						</tr>
					</thead>
					<tbody>
						{ data.counterparts && data.counterparts.map((el, idx) => {
							return (
								<tr key={idx}>
									<td className={(errors['counterparts.'+idx+'.estimategroup_id'] ? 'has-errors' : '')}>
										<CounterPartsSelector 
											communityId={data.community_id}
											expense={el.estimategroupexpense}
											onChange={(value) => selectEstimateGroupExpense(idx, value)}
											disabled={readOnly || el.provider_invoice_id}
										/>
									</td>
									<td className={(errors['counterparts.'+idx+'.amount'] ? 'has-errors' : '')}>
										<input readOnly={readOnly || el.provider_invoice_id} type="number" className="no-arrows" value={el.amount ?? ''} onChange={(e) => setDataField(idx, 'amount', e.target.value)} min={-99999.99} max={99999.99} />
									</td>
								</tr>
							);
						})}
						{ (!data.counterparts || !data.counterparts.length) && <tr><td colSpan="100%" className="p-2">No se han añadido contrapartidas</td></tr> }
					</tbody>
				</Table>
			</TableWrapper>
		</LabeledFrame>
	);
}