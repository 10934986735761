import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader } from 'helpers/generic';
import { getCommunity } from 'helpers/community';

let axiosCancelToken = null;

let parentWindowProxyCallback = window.opener?.PopupProxyCallback;

export default function AppOwnersBulletinBoardForm(props) {
	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';

	let [forceReload, setForceReload] = useState(null);
	let [data, setData] = useState({});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		window.onbeforeunload = () => {
			parentWindowProxyCallback();
		};
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				await axios.get('/api/manager/appowners-bulletinboard/get/' + params.id, {
					params: {
						community_id: getCommunity()?.id
					},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(response.data);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		}
	}, [params.id]);

	const deleteAnnouncement = () => {
		const c = window.confirm('¿Quieres eliminar este anuncio?');
		if ( !c ) return;

		axios.post('/api/manager/appowners-bulletinboard/delete/' + data.id, {
			community_id: getCommunity()?.id
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Anuncio borrado');
	    	if ( popup ) window.close();
	    	else navigate('/appowners-bulletinboard');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (goBack = true) => {
		setErrors({});

		data.community_id = getCommunity()?.id;

		axios.post('/api/manager/appowners-bulletinboard/' + (data.id ? 'edit/' + data.id : 'add'), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			setData({...response.data.announcement});
			if ( goBack ) {
				if ( popup ) window.close();
				else navigate('/appowners-bulletinboard');
			} else {
				setForceReload(Math.random());
		    	navigate('/appowners-bulletinboard/edit/' + response.data.announcement.id + (popup ? '?popup=true&community_id=' + data.community_id : ''));
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de anuncio</h1>
					<button onClick={() => popup ? window.close() : navigate('/appowners-bulletinboard')} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-6">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col col-static-200">
											<div className="mb-2">
												<label>Desde</label>
												<input type="date" className="form-control form-control-sm" onChange={(e) => setDataField('date_from', e.target.value)} value={data.date_from ?? ''} />
												{ errors.date_from &&
													<div className="invalid-feedback d-block">{ errors.date_from[0] }</div>
												}
											</div>
										</div>
										<div className="col col-static-200">
											<div className="mb-2">
												<label>Hasta</label>
												<input type="date" className="form-control form-control-sm" onChange={(e) => setDataField('date_to', e.target.value)} value={data.date_to ?? ''} />
												{ errors.date_to &&
													<div className="invalid-feedback d-block">{ errors.date_to[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-12">
											<div className="mb-2">
												<label>Descripción</label>
												<textarea className="form-control form-control-sm" onChange={(e) => setDataField('description', e.target.value)} value={data.description ?? ''}></textarea>
												{ errors.description &&
													<div className="invalid-feedback d-block">{ errors.description[0] }</div>
												}
											</div>
										</div>
									</div>
								</div>
								<div className="card-footer" id={popup ? 'footer-fixed' : ''}>
									<div className="row">
										<div className="col-6">
											{ data.id &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteAnnouncement()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}