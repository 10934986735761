import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import PopupLayout from 'layouts/PopupLayout';
import { debugMode } from 'helpers/config';
import { CristalLoader } from 'helpers/generic';
import { getCommunity } from 'helpers/community';

const ViewerContainer = styled.div`
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--bs-light);
	overflow: auto;
`;

const Iframe = styled.iframe`
	display: none;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	border: 0;
	position: absolute;
	top: 0;
	left: 0;
`;

const Image = styled.img`
	display: none;
	margin: 0 auto;
	cursor: zoom-in;
	width: ${props => props.width * props.zoom}px;
`;

const Error = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 99999;
	background: white;
	text-align: center;
`;

let axiosCancelToken = null;

export default function DocumentViewer() {
	const params = useParams();
	const iframeRef = useRef(null);
	const imageRef = useRef(null);
	const iframeLoaded = useRef(false);

	const [loading, setLoading] = useState(null);
	const [error, setError] = useState(null);

	const [imageZoom, setImageZoom] = useState(1);
	const [imageInitialWidth, setImageInitialWidth] = useState(0);

	const id = params.id;

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		setLoading(true);

		axios.get('/api/manager/documents/generate-temporary-url/' + id, {
			params: {
				community_id: getCommunity()?.id
			},
			cancelToken: axiosCancelToken.token,
		}).then((response) => {
			if ( response.data.container === 'image' ) { // Show image
				// Preload image
				let image = document.createElement('img');
				image.onload = (e) => {
					setImageInitialWidth(e.target.width);
					setLoading(false);
				}
				image.src = response.data.url;

				// Show element
				imageRef.current.style.display = 'block';
				imageRef.current.src = image.src;
			} 

			if ( response.data.container === 'iframe-local' ) { // Iframe local (for pdfs)
				iframeRef.current.style.display = 'block';
				iframeRef.current.onload = (e) => {
					iframeLoaded.current = true;
					setLoading(false);
				}
				iframeRef.current.src = response.data.url;
			}

			if ( response.data.container === 'iframe-gdocs' ) { // Iframe local (for pdfs)
				// Get temporary url
				let temporaryUrl = response.data.url;
				if ( debugMode() ) temporaryUrl = 'https://fitsmallbusiness.com/wp-content/uploads/2022/03/FitSmallBusiness-EXCEL-TEST-TEST.xlsx';

				// Prepare url
				const url = 'https://docs.google.com/viewerng/viewer?url=' + temporaryUrl + '&embedded=true';

				// Show element
				iframeRef.current.style.display = 'block';
				iframeRef.current.onload = (e) => {
					iframeLoaded.current = true;
					setLoading(false);
				}
				iframeRef.current.src = url;

				// Check if iframe were not loaded
				const check = () => {
					setTimeout(() => {
						if ( !iframeLoaded.current ) {
							iframeRef.current.src = '';
							iframeRef.current.src = url;
							check();
							console.log('checked');
						}
					}, 5000);
				}
				check();
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			setError('Ha ocurrido un error');
		});
	
	}, [id]);

	useEffect(() => {
		const keyEventCallback = (e) => {
			if ( e.key === 'Escape' ) window.close();
		}
		document.addEventListener('keydown', keyEventCallback);

		return function cleanup() {
			document.removeEventListener('keydown', keyEventCallback);
		}
	}, []);


	return (
		<PopupLayout>
			<ViewerContainer>
				{ loading && <CristalLoader /> }
				<Iframe ref={iframeRef}></Iframe>
				<Image 
					ref={imageRef} 
					zoom={imageZoom}
					width={imageInitialWidth} 
					onClick={() => setImageZoom(imageZoom < 2 ? imageZoom + 0.5 : 1)}
				/>
			</ViewerContainer>
			{ error && 
				<Error>
					<div>
						<h1 className="text-danger">Error</h1>
						<p>{error}</p>
						<button className="btn btn-primary text-white" onClick={() => window.close()}>Cerrar ventana</button>
					</div>
				</Error>
			}
		</PopupLayout>
	);
}


