import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader, formatNumber } from 'helpers/generic';
import { getCommunity, authUserPermission } from 'helpers/community';
import EmpoweredSelector from 'components/EmpoweredSelector';
import RefundModal from './RefundModal';
import ManualPaymentsModal from './ManualPaymentsModal';
import CustomInput from 'components/CustomInput';

const SmallTable = styled.table`
	font-size: 13px;

	td, th {
		position: relative;

		&:nth-child(1) {
			text-align: left;
		}

		&:nth-child(2) {
			text-align: right;
			width: 80px;

			input[type=number] {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				font-size: 12px;
				text-align: right;
				background: transparent;
				border: 0;
			}
		}
	}
`;

let axiosCancelToken = null;

let parentWindowProxyCallback = window.opener?.PopupProxyCallback;

export default function ReceiptsForm(props) {
	const params = useParams();

	let [forceReload, setForceReload] = useState(null);
	let [data, setData] = useState({});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);
	let [modalRefundOpened, setModalRefundOpened] = useState(false);
	let [modalManualPaymentsOpened, setModalManualPaymentsOpened] = useState(false);

	let initialDataRef = useRef(null);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				await axios.get('/api/manager/receipts/get/' + params.id, {
					params: {
						community_id: getCommunity()?.id,
						with_manual_payment_fields: true
					},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(prev => {

						let newData = {
							...prev, 
							...response.data, 
							quotasconcepts: response.data.quotasconcepts?.map(el => {
								return {id: el.id, amount: el.pivot.amount, name: el.name};
							})
						};

						initialDataRef.current = newData;

						return newData;
			    	});
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		}
	}, [params.id, forceReload]);

	useEffect(() => {
		window.onbeforeunload = () => {
			parentWindowProxyCallback();
		};
	}, []);

	const loadPayerables = (input, callback) => {
		axios.get('/api/manager/receipts/get-payerables-list', {
			params: {
				community_id: getCommunity()?.id,
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label:  <div>
								{el.name}
								<div><small>{el.type.indexOf('Owner') !== -1 ? 'Propietario' : 'Inquilino'}</small></div>
							</div>
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectPayerable = (payerable) => {
		setData((prev) => ({
			...prev,
			payerable: payerable,
			payerable_id: payerable?.id,
			payerable_type: payerable?.type,
			bankaccount: null,
			bankaccount_id: null
		}));
	}

	const loadBankAccounts = (input, callback, args) => {
		let formatted = args.payerable?.bankaccounts?.map((el) => {
			return {
				value: el, 
				label: el.iban
			};
		});
		if ( args.payments && args.payments.length > 0 ) formatted = [];
		formatted.unshift({
			value: {
				id: -1,
				iban: 'Pago al contado'
			},
			label: 'Pago al contado'
		})
		callback(formatted);
	}

	const selectBankAccount = (bankaccount) => {
		setData((prev) => ({
			...prev,
			bankaccount: bankaccount,
			bankaccount_id: bankaccount?.id,
		}));
	}

	const saveData = async (goBack = true) => {
		setErrors({});

		data.community_id = getCommunity()?.id;

		return axios.post('/api/manager/receipts/save/' + data.id, data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			setForceReload(Math.random());
			if ( goBack ) window.close();
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const setConceptAmount = (idx, value) => {
		let newQuotasConcepts = [...data.quotasconcepts];
		newQuotasConcepts[idx].amount = value;
		setDataField('quotasconcepts', [...newQuotasConcepts]);
	}

	const openManualPaymentsModal = async () => {
		// Ask for confirm if initial data differs from actual data
		if ( JSON.stringify(initialDataRef.current) !== JSON.stringify(data) && data.paid_status !== 'full' ) {
			let c = window.confirm('Hay cambios sin guardar. ¿Quieres guardar los cambios antes de continuar?');
			if ( !c ) return;

			await saveData(false);
		}

		setModalManualPaymentsOpened(true);		
	}

	const totalRefunds = data?.refunds?.reduce((carry, item) => {
		return carry += parseFloat(item.refund_data?.expenses ?? 0);
	}, 0);

	return (
		<React.Fragment>
			<PopupLayout>
				{ loading && <CristalLoader /> }

				<section>
					<div className="page-title">
						<h1>Ficha de recibo</h1>
						<button onClick={() => window.close()} className="btn btn-sm btn-light ms-auto">Cerrar</button>
					</div>

					<div className="page-content">
						<div className="row justify-content-center">
							<div className="col-lg-12">
								<div className="card border-0 shadow-sm">
									<div className="card-body">
										<div className="row">
											<div className="col-lg-3">
												<div className="mb-2">
													<div className="input-group input-group-sm">
												  		<CustomInput label="Origen / Emisión" type="text" className="form-control form-control-sm" value={data.issuance?.entity_name ?? ''} readOnly />
												  		<span className="input-group-text">Nº {data.issuance?.number ?? ''}</span>
													</div>												
												</div>
											</div>
											<div className="col-lg-3">
												<div className="mb-2 text-end">
													<CustomInput label="Nº recibo" type="text" className="form-control form-control-sm text-end" value={data.number ?? ''} readOnly />
												</div>
											</div>
											<div className="col-lg-3">
												<div className="mb-2">
													<CustomInput label="Fecha de emisión" type="date" className="form-control form-control-sm" value={data.issuance?.date ?? ''} readOnly />
												</div>
											</div>
											<div className="col-lg-3">
												<div className="mb-2">
													<CustomInput label="Fecha de vencimiento/cobro" type="date" className="form-control form-control-sm" onChange={(e) => setDataField('due_date', e.target.value)} value={data.due_date ?? ''} />
													{ errors.due_date &&
														<div className="invalid-feedback d-block">{ errors.due_date[0] }</div>
													}
												</div>
											</div>
											<div className="col-lg-3">
												<div className="mb-2">
													<EmpoweredSelector
														load={loadPayerables}
														onChange={(value) => selectPayerable(value)}
														timeout={250}
														label={data.payerable?.name ?? ''}
														value={data.payerable?.id}
														placeholder="Pagador"
														showPlaceholderHelper={true}
													/>
													{ errors.payerable_id &&
														<div className="invalid-feedback d-block">{ errors.payerable_id[0] }</div>
													}
												</div>
											</div>
											<div className="col-lg-3">
												<div className="mb-2">
													<EmpoweredSelector
														load={loadBankAccounts}
														args={{payerable: data.payerable, payments: data.payments}}
														onChange={(value) => selectBankAccount(value)}
														timeout={250}
														label={data.bankaccount?.iban ?? 'Pago al contado'}
														value={data.bankaccount?.id ?? -1}
														placeholder="Forma de pago"
														showPlaceholderHelper={true}
													/>
													{ errors.bankaccount_id &&
														<div className="invalid-feedback d-block">{ errors.bankaccount_id[0] }</div>
													}
												</div>
											</div>
											<div className="col-lg-3">
												<div className="mb-2 text-end">
													<CustomInput label="Importe" type="number" className="form-control form-control-sm no-arrows text-end" onChange={(e) => setDataField('amount', e.target.value)} value={data.amount ?? ''} />
													{ errors.amount &&
														<div className="invalid-feedback d-block">{ errors.amount[0] }</div>
													}
												</div>
											</div>
											<div className="col-lg-3">
												<div className="mb-2 text-end">
													<SmallTable className="table table-sm table-bordered mb-0 mt-2">
														<thead>
															<tr>
																<td colSpan="2" className="text-center sbold">Conceptos</td>
															</tr>
														</thead>
														<tbody>
															{data.quotasconcepts?.map((el, idx) => {
																return (
																	<tr key={idx}>
																		<td>{el.name}</td>
																		<td>
																			<input type="number" className={'form-control form-control-sm no-arrows ' + (errors['quotasconcepts.'+idx+'.amount'] ? 'text-white bg-danger' : '')} value={el.amount ?? ''} onChange={(e) => setConceptAmount(idx, e.target.value)} />
																		</td>
																	</tr>
																);
															})}
															{ (
																data.refunds && 
																data.refunds.length > 0 &&
																totalRefunds > 0
															  ) &&
																<tr style={{fontSize: '12px'}}>
																	<td colSpan="2" className="text-center text-danger">Se incluyen {formatNumber(totalRefunds, 2)}€ de gastos por devolución</td>
																</tr>
															}
														</tbody>
														{ data.quotasconcepts?.reduce((carry, item) => carry += parseFloat(item.amount), 0).toFixed(2) !== parseFloat(data.amount).toFixed(2) &&
															<tfoot>
																<tr>
																	<td colSpan="100%" className="text-danger small sbold">
																		El importe del recibo no corresponde con el importe total de los conceptos: {data.quotasconcepts?.reduce((carry, item) => carry += parseFloat(item.amount), 0)} €
																	</td>
																</tr>
															</tfoot>
														}
													</SmallTable>
												</div>
											</div>

											<div className="col-md-12"></div>

											{ data.bankremittance?.id &&
												<div className="col-lg-3">
													<div className="mb-2">
														<label className="text-primary">Recibo remesado <i className="bi bi-check-circle-fill"></i></label>
														<div className="form-control form-control-sm">
															Nº: {data.bankremittance.number} / Fecha: {moment(data.bankremittance.date).format('DD-MM-YYYY')}
														</div>
													</div>
												</div>
											}

											{ (data.payments && data.payments.length > 0) &&
												<div className="col-lg-3">
													<div className="mb-2">
														<label className="text-primary">Recibo conciliado <i className="bi bi-check-circle-fill"></i></label>
														<SmallTable className="table table-sm table-bordered mb-0 mt-2">
															<thead>
																<tr>
																	<td colSpan="3" className="text-center sbold">Pagos</td>
																</tr>
															</thead>
															<tbody>
																{data.payments?.map((el, idx) => {
																	return (
																		<tr key={idx}>
																			<td>
																				{ el.type === 'bankreconciliation' ? 'Conciliación bancaria' : ''}
																				{ el.type === 'manual-payment' ? 'Pago manual' : ''}
																			</td>
																			<td>{moment(el.date).format('DD-MM-YYYY')}</td>
																			<td>
																				{formatNumber(el.amount)} €
																			</td>
																		</tr>
																	);
																})}
															</tbody>
															{ data.paid_status === 'partial' &&
																<tfoot>
																	<tr>
																		<td colSpan="100%" className="text-indigo text-center small sbold">
																			Pago parcial
																		</td>
																	</tr>
																</tfoot>
															}
														</SmallTable>
													</div>
												</div>
											}

											
											<div className="col-lg-3">
												{ data.refunded_at &&
													<div className="mb-2">
														<label className="text-danger">Recibo devuelto <i className="bi bi-dash-circle-fill"></i></label>
														<div className="form-control form-control-sm">
															<div>Fecha: {moment(data.refunded_at).format('DD-MM-YYYY')}</div>
															{ data.refund_data.expenses &&
																<div>Gastos bancarios: {formatNumber(data.refund_data.expenses)} €</div>
															}
														</div>
													</div>
												}
											</div>

											<div className="col-lg-3">
												
											</div>

											{ (data.refunds && data.refunds.length > 0) &&
												<div className="col-lg-3">
													<div className="mb-2">
														<SmallTable className="table table-sm table-bordered mb-0 mt-2">
															<thead>
																<tr>
																	<td colSpan="3" className="text-center sbold">
																		Recibos {data.is_reunificated_parent && 'reunificados'} {data.is_refund_parent && 'devueltos'} relacionados
																	</td>
																</tr>
															</thead>
															<tbody>
																{data.refunds?.map((el, idx) => {
																	return (
																		<tr key={idx}>
																			<td style={{width: '100px'}}>{el.number_full}</td>
																			<td style={{width: 'auto'}} className="text-start">{moment(el.due_date).format('DD-MM-YYYY')}</td>
																			<td className="text-end">
																				{formatNumber(el.amount)} €
																				{el.refund_data?.expenses &&
																					<div><small>Gastos: {formatNumber(el.refund_data.expenses)} €</small></div>
																				}
																			</td>
																		</tr>
																	);
																})}
															</tbody>
														</SmallTable>
													</div>
												</div>
											}
										</div>
									</div>
									<div className="card-footer" id={'footer-fixed'}>
										<div className="row">
											<div className="col-4">
												{ authUserPermission('add') &&
													<button className="btn btn-sm btn-warning d-inline" disabled={data.paid_status === 'full' && data.manualpayments?.length === 0} onClick={() => openManualPaymentsModal()}>Pago manual</button>	
												}						
											</div>
											<div className="col-8 text-end">
												<button className="btn btn-sm btn-primary text-white d-inline ms-3" disabled={data.paid_status === 'full'} onClick={() => saveData(false)}>Guardar</button>							
												<button className="btn btn-sm btn-primary text-white d-inline ms-3" disabled={data.paid_status === 'full'} onClick={() => saveData()}>Guardar y salir</button>					
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</PopupLayout>
			{ modalRefundOpened &&
				<RefundModal 
					receipt={data}
					closeCallback={() => {
						setModalRefundOpened(false);
						setForceReload(Math.random());
					}}
				/>
			}
			{ modalManualPaymentsOpened &&
				<ManualPaymentsModal 
					receipt={data}
					closeCallback={() => {
						setModalManualPaymentsOpened(false);
						setForceReload(Math.random());
					}}
					forceReloadParent={() => setForceReload(Math.random())}
				/>
			}
		</React.Fragment>
	);
}