import React, { useState, useEffect } from 'react';
import { NavLink, Navigate } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import TrSkeleton from 'components/TrSkeleton';
import { getCommunity } from 'helpers/community';
import { formatNumber } from 'helpers/generic';

const Table = styled.table`
	th,td {
		font-size: 14px;

		&:last-of-type {
			width: 40px;
		}

		&:nth-child(1) {
			b {
				font-weight: 600;
				margin-right: 5px;
				font-size: 12px;
				line-height: 14px;
			}
		}

		&:nth-child(2) {
			text-align: right;
			width: 100px;
		}
	}
`;

let axiosCancelToken = null;

export default function ContableAccounts() {
	let [financialYears, setFinancialYears] = useState([]);
	let [financialYear, setFinancialYear] = useState(null);
	let [accounts, setAccounts] = useState(undefined);
	let [skeletonRows, setSkeletonRows] = useState(5);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( !financialYear ) return;
		const getData = async() => {
			// Contable accounts
			setAccounts(undefined);
			await axios.get('/api/manager/contable-accounts/list-ordered', {
				params: {
					community_id: getCommunity()?.id,
					append_balance: true,
					financial_year: financialYear
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setAccounts([...response.data]);
		    	setSkeletonRows(response.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getData();
	}, [financialYear]);

	useEffect(() => {
		const getFinancialYear = async() => {
			return axios.get('/api/manager/contable-accounts/financialyears-list', {
				params: {
					community_id: getCommunity()?.id
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setFinancialYears([...response.data]);
				setFinancialYear(response.data[0] ?? null);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getFinancialYear();
	}, []);

	if ( !getCommunity()?.id ) return <Navigate to="/" />;

	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Cuentas contables</h1>

					{/* <NavLink to="/contable-accounts/add" className="btn btn-sm btn-light ms-auto">Nueva cuenta</NavLink> */}
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" value={financialYear ?? ''} onChange={(e) => setFinancialYear(e.target.value)}>
										<option disabled>- Ejercicios -</option>
										{financialYears.map((el, idx) => {
											return (<option key={idx} value={el}>{el}</option>);
										})}
									</select>
								</div>
								<div className="col-md-5 mb-2 mt-2 mb-md-0 mt-md-0">
									
								</div>
								<div className="col-md-4 mb-2 mt-2 mb-md-0 mt-md-0 text-end">
									<NavLink className="btn btn-sm btn-light" to="/accounting-entries">Ver asientos contables</NavLink>
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<th>Cuenta</th>
											{/*<th>Debe</th>*/}
											{/*<th>Haber</th>*/}
											<th>Saldo</th>
											<th style={{width: '40px'}}></th>
										</tr>
									</thead>
									<tbody>
										{ accounts?.length > 0 &&
											accounts.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr>
															<td style={{paddingLeft: (el.level * 15) + 'px'}}>
																<b>{el.code}</b> {el.name ?? '-'}
															</td>
															{/*<td>{formatNumber(el.debe)}</td>*/}
															{/*<td>{formatNumber(el.haber)}</td>*/}
															<td>{formatNumber(el.balance)}</td>
															<td>
																<NavLink className="btn-table" to={'/contable-accounts/edit/' + el.id}><i className="bi bi-pencil"></i></NavLink>
															</td>
														</tr>
													</React.Fragment>
												);
											})
										}

										{ accounts && !accounts.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ accounts === undefined && <TrSkeleton rows={skeletonRows} columns={5} /> }
									</tbody>
								</Table>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}


