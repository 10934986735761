import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import styled from 'styled-components';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { CristalLoader } from "helpers/generic";
import { getCommunity } from "helpers/community";
import TrSkeleton from "components/TrSkeleton";

const ModalStyled = styled.div`
	background: rgba(0, 0, 0, 0.4);
`;

const Address = styled.div`
    display: flex;
    align-items: center;
    background: var(--bs-gray-100);
    padding: 5px;
    border-radius: 5px;
	cursor: pointer;
	user-select: none;

    input[type=checkbox] {
        margin-right: 10px;
    }

    &:not(:last-of-type) {
        margin-bottom: 5px;
    }
`;

let axiosCancelToken = null;

export default function ExportModal(props) {
	const modalRef = useRef(null);

	const closeCallback = props.closeCallback;

	const [owners, setOwners] = useState(null);
	const [selectedAddresses, setSelectedAddresses] = useState({});
	const [loading, setLoading] = useState(false);
	const [withoutEmailChecked, _setWithoutEmailChecked] = useState(true);
	const setWithoutEmailChecked = (status) => {
		_setWithoutEmailChecked(status);
		setSelectedAddresses({});
	}

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const modal = new Modal(modalRef.current, {
			backdrop: false
		});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();
			closeCallback();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

		modal.show();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [closeCallback]);

    useEffect(() => {
        const getOwners = async () => {
			setLoading(true);

			await axios.get('/api/manager/owners/list', {
				params: {
					community_id: getCommunity()?.id,
					no_paginate: true
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setOwners([...response.data]);

                let preselectedAddresses = {};
                response.data.forEach((owner) => {
                    owner.addresses?.forEach((address) => {
                        if ( (address.type === 'both' || address.type === 'correspondence') && address.email_default?.length === 0 ) preselectedAddresses[owner.id] = address.id;
                    });
                });
                setSelectedAddresses(preselectedAddresses);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	

			setLoading(false);
		}

		getOwners();
    }, []);



	const downloadFile = async () => {	
		setLoading(true);

		await axios.post('api/manager/owners/labels-pdf?community_id=' + getCommunity()?.id, {
            addresses: selectedAddresses
        }, {
			responseType: 'blob',
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'etiquetas.pdf');
			document.body.appendChild(link);
			link.click();
			link.parentElement.removeChild(link);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});
		
		Modal.getInstance(modalRef.current).hide();
		setLoading(false);
	}

    const toggleSelectedAddress = (owner_id, address_id) => {
        let newSelectedAddresses = {...selectedAddresses};

        let owner = newSelectedAddresses[owner_id] ?? undefined;
        if ( owner === undefined || owner !== address_id ) newSelectedAddresses[owner_id] = address_id;
        else delete newSelectedAddresses[owner_id];

        setSelectedAddresses(newSelectedAddresses);
    }

	const selectAll = () => {
		let newSelectedAddresses = {};

		let filteredOwners = owners?.filter(el => {
			if ( withoutEmailChecked ) return el.email_default === null || el.email_default === '' || el.email_default.length === 0;
			return true;
		});

		if ( Object.keys(selectedAddresses).length !== filteredOwners.length ) {
			filteredOwners?.forEach((owner) => {
				owner.addresses?.forEach((address) => {
					if ( address.type === 'both' || address.type === 'correspondence' ) newSelectedAddresses[owner.id] = address.id;
				});
			});
		}

		setSelectedAddresses(newSelectedAddresses);
	}

	let filteredOwners = owners?.filter(el => {
		if ( withoutEmailChecked ) return el.email_default === null || el.email_default === '' || el.email_default.length === 0;
		return true;
	});

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Imprimir etiquetas</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<div className="row mb-2">
							<div className="col-md-6">
								<label className="d-flex align-items-center user-select-none">
									<input type="checkbox" checked={withoutEmailChecked} onChange={(e) => setWithoutEmailChecked(e.target.checked)} className="me-2" />
									Excluir propietarios con email
								</label>
							</div>
							<div className="col-md-6 text-end">
								<button className="btn btn-sm btn-light py-0" disabled={loading} onClick={selectAll}>
									{filteredOwners?.length === Object.keys(selectedAddresses ?? {}).length ? 'Deseleccionar' : 'Seleccionar'} todos
								</button>
							</div>
						</div>
						<table className="table table-sm table-bordered mb-0">
                            <thead>
                                <tr>
                                    <th>Propietario</th>
                                    <th>Dirección</th>
                                </tr>
                            </thead>
                            <tbody>
                                { !loading && 
									filteredOwners?.map((el, idx) => {
										return (
											<tr key={idx}>
												<td>{el.name}</td>
												<td>
													{el.addresses?.map((addrEl, addrIdx) => {
														return (
															<Address key={addrIdx} onClick={(e) => toggleSelectedAddress(el.id, addrEl.id)}>
																<input type="checkbox" checked={selectedAddresses[el.id] === addrEl.id} onChange={(e) => e.stopPropagation()} />
																<div>
																	{addrEl.address}, {addrEl.city}, {addrEl.postalcode}, {addrEl.country}
																</div>
															</Address>
														);
													})}
													{el.addresses?.length === 0 && 'No tiene dirección'}
												</td>
											</tr>
										)
									})
								}
								{filteredOwners !== null && filteredOwners?.length === 0 && <tr><td colSpan="2">No hay propietarios</td></tr>}
								{loading && <TrSkeleton rows={5} columns={2} /> }
                            </tbody>
                        </table>
					</div>
					<div className="modal-footer">
                        <button type="button" className={'btn btn-sm btn-primary text-white'} onClick={() => downloadFile()} disabled={Object.keys(selectedAddresses).length === 0}>Descargar PDF</button>
					</div>
				</div>
			</div>
			{ loading && <CristalLoader /> }
		</ModalStyled>
	);
}


