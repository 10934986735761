import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader } from 'helpers/generic';
import { getCommunity } from 'helpers/community';
import DocumentsPanel from 'components/DocumentsPanel';
import Basic from './Basic';
import Reasons from './Reasons';
import Observations from './Observations';
import PersonsPanel from './PersonsPanel';
import MeetingDocs from './MeetingDocs';
import moment from 'moment';
import styled from 'styled-components';

const DocumentsPanelWrapperStyled = styled.div`
	tr {
		&[data-code="meetings-docs"] {
			/* display: none; */
		}
	}
`;

let axiosCancelToken = null;

let parentWindowProxyCallback = window.opener?.PopupProxyCallback;

export default function MeetingsForm(props) {
	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';

	let [reloadFolder, setReloadFolder] = useState(null);
	let [data, setData] = useState({
		type: 'ordinary'
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);
	let [checkedReasons, setCheckedReasons] = useState([]);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				await axios.get('/api/manager/meetings/get/' + params.id, {
					params: {
						community_id: getCommunity()?.id
					},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(response.data);
					setCheckedReasons([]);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		}
	}, [params.id]);

	useEffect(() => {
		window.onbeforeunload = () => {
			parentWindowProxyCallback();
		};

		window.ReloadDocumentsCallback = (showMsgOk) => {
			setReloadFolder(Math.random());
			if ( showMsgOk ) toast.success('El documento se ha guardado en el gestor documental');
		}
	}, []);

	const deleteMeeting = () => {
		const c = window.confirm('¿Quieres eliminar esta junta?');
		if ( !c ) return;

		axios.post('/api/manager/meetings/delete/' + data.id, {
			community_id: getCommunity()?.id
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Junta borrada');
	    	navigate('/meetings');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (goBack = true) => {
		setErrors({});

		data.community_id = getCommunity()?.id;

		return axios.post('/api/manager/meetings/' + (data.id ? 'edit/' + data.id : 'add'), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			setData({...response.data.meeting, notSaved: false});
			if ( goBack ) {
				if ( popup ) window.close();
				else navigate('/meetings');
			} else {
		    	navigate('/meetings/edit/' + response.data.meeting.id + (popup ? '?popup=true' : ''));
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData((data) => ({...data, [field]: value}));
	}

	const generateNotices = async () => {
		const c = window.confirm('Vas a generar ' + (checkedReasons.length) + ' tareas. ¿Seguro que quieres continuar?.');
		if ( !c ) return false;

		setLoading(true);

		await axios.post('/api/manager/meetings/generate-notices/' + data.id, {
			community_id: data.community_id,
			reasons_ids: data.reasons.filter((el) => checkedReasons.includes(el.uid)).map((el) => el.id)
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Tareas generadas');
			setData({...data, notices_generated_at: response.data.date, notSaved: true});
			setCheckedReasons([]);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error(error.response?.data.error ?? 'Ha ocurrido un error al generar las tareas');
		});	

		setLoading(false);
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de junta</h1>
					<button onClick={() => popup ? window.close() : navigate('/meetings')} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-md-6">
											<Basic 
												data={data}
												setData={setData}
												setDataField={setDataField}
												errors={errors}
											/>
										</div>
										<div className="col-md-6">
											<PersonsPanel 
												data={data}
												setData={setData}
												setDataField={setDataField}
												errors={errors}
												saveData={() => saveData(false)}
											/>
											{ data.id && 
												<MeetingDocs
													data={data}
												/>
											}
										</div>
										<div className="col-md-12 mb-3 mt-2">
											<Reasons 
												data={data}
												setData={setData}
												setDataField={setDataField}
												errors={errors}
												saveData={() => saveData(false)}
												checkedReasons={checkedReasons}
												setCheckedReasons={setCheckedReasons}
											/>
										</div>
										<div className="col-md-12 mb-3">
											<div className="row">
												<Observations 
													data={data}
													setData={setData}
													setDataField={setDataField}
													errors={errors}
												/>
											</div>
										</div>
										<div className="col-md-6">
											{ data.id && 
												<DocumentsPanelWrapperStyled>
													<DocumentsPanel
														className="mb-3"
														folderId={data.folder_id ?? null}
														community={getCommunity()}
														reloadFolder={reloadFolder}
														title={'Documentos de convocatorias y actas'}
													/>
												</DocumentsPanelWrapperStyled>
											}
										</div>
										<div className="col-md-6">
											{ data.subfolder1_id && 
												<DocumentsPanel
													className="mb-3"
													folderId={data.subfolder1_id ?? null}
													community={getCommunity()}
													reloadFolder={reloadFolder}
													title={'Documentos para la junta'}
												/>
											}
										</div>
									</div>
								</div>
								<div className="card-footer" id={popup ? 'footer-fixed' : ''}>
									<div className="row">
										<div className="col-6">
											{ data.id &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteMeeting()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											{ data?.id &&
												<React.Fragment>
													{ data?.notices_generated_at &&
														<small>Tareas generadas el {moment(data.notices_generated_at).format('DD-MM-YYYY HH:mm')}</small>
													}
													{ data.notSaved &&
														<small className="text-danger ms-3">Hay cambios sin guardar</small>
													}
													<button className="btn btn-sm btn-secondary text-white d-inline ms-3" onClick={() => generateNotices()} disabled={data.notSaved || checkedReasons.length === 0}>
														Generar tareas
													</button>	
												</React.Fragment>
											}						
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}