import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import CustomInput from 'components/CustomInput';
import { getCommunity } from "helpers/community";
import { Modal } from 'bootstrap/dist/js/bootstrap';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { CristalLoader } from "helpers/generic";

let axiosCancelToken = null;

const AddTitleModalStyled = styled.div`

    & > button {
        padding: 0;
        line-height: 0;
        border: 0;
        background: transparent;
        color: var(--bs-primary);
    }

    .modal {
	    background: rgba(0, 0, 0, 0.4);

        .modal-dialog {
            /* margin-top: 100px; */
        }
    }

`;

export default function AddTitleModal(props) {
	const modalRef = useRef(null);

    let community_id = props.communityId ?? null;
    let group_id = props.groupId ?? null;
    let closeCallback = props.closeCallback ?? null;

    let [open, setOpen] = useState(false);
    let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);

    const [denomination, setDenomination] = useState(null);
	const [contableAccount, _setContableAccount] = useState(null);
	const setContableAccount = (value) => {
		_setContableAccount(value);
		setDenomination(value?.name);
	}

    useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

        return function cleanup() {
            axiosCancelToken.cancel();
        }
    }, []);

    useEffect(() => {
		if ( !open ) return;

        const modal = new Modal(modalRef.current, {
			backdrop: false
		});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();

            setOpen(false);
            setContableAccount(null);
            setDenomination(null);

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

        modal.show();
    }, [open]);

    const getContableAccountList = async (input, callback) => {
		let contableaccounts = await axios.get('/api/manager/contable-accounts/list', {
			params: {
				community_id: getCommunity()?.id,
				no_paginate: true,
				parent_entity: 'estimategroup-expenses',
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			return response.data;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	

		let titles = [];

		if ( contableaccounts && contableaccounts.length ) {
			contableaccounts.forEach(el => {
				if ( el.code.length > 5 ) return;
	
				titles.push({
					value: el, 
					label:  <div>
								<b className="me-1">{el.code}</b>  
								<span>{el.name}</span>
							</div>
				});
			});
		}

		if (input) {
			titles = titles.filter(item => {
				return item.value.name.toLowerCase().indexOf(input) !== -1;
			});
		}

		callback(titles);
	}

    const saveData = async () => {
        setLoading(true);

        await axios.post('/api/manager/estimates/add-title-to-group/' + group_id, {
            community_id: community_id,
            denomination: denomination,
            contableaccount_id: contableAccount?.id
        }, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');

	    	closeModal();
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	

        setLoading(false);
    }

    const closeModal = () => {
        const modal = Modal.getInstance(modalRef.current);
		modal.hide();

        closeCallback();
    }

	return (
        <AddTitleModalStyled>
            <button onClick={() => setOpen(true)}><i className="bi bi-plus-circle-fill"></i></button>
      
            <div className="modal" tabIndex="-1" ref={modalRef}>
                {loading && <CristalLoader />}

                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Añadir nueva familia</h5>
                            <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <EmpoweredSelector
                                            load={getContableAccountList}
                                            onChange={(value) => {
                                                setContableAccount(value)
                                            }}
                                            timeout={250}
                                            label={contableAccount?.name ?? ''}
                                            placeholder="Cuenta contable"
                                            showPlaceholderHelper={true}
                                            value={contableAccount?.id ?? null}
                                            dropDownMenuWidth="200%"
                                        />
                                        { errors.contableaccount_id &&
                                            <div className="invalid-feedback d-block">{ errors.contableaccount_id[0] }</div>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <CustomInput label="Denominación" type="text" className="form-control form-control-sm no-arrows" value={denomination ?? ''} onChange={(e) => setDenomination(e.target.value)} />
                                        { errors.denomination &&
                                            <div className="invalid-feedback d-block">{ errors.denomination[0] }</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className={'btn btn-sm btn-primary text-white ' + ((!contableAccount?.id || !denomination?.length) ? 'disabled' : '')} onClick={() => saveData()}>Aceptar</button>
                        </div>
                    </div>
                </div>
            </div>
        </AddTitleModalStyled>
	);
}