import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { formatNumber, jsonToArray } from 'helpers/generic';


const ShortStyled = styled.div`
    padding: 20px;

    h1 {
        text-align: center;
        font-size: 25px;
        display: block;
        margin-bottom: 20px;
    }

    h2 {
        text-align: right;
        font-size: 18px;

        b {
            font-weight: 600;
        }
    }

    table#movements {
        font-size: 13px;

        td {
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                width: 50px;
                text-align: right;
                white-space: nowrap;
            }
        }

        tbody, thead, tfoot {
            border-top-color: #dee2e6;
        }

        thead {
            &:first-of-type {
                background: var(--bs-primary);
                color: white;
            }
        }

        tfoot {
            font-weight: 600;
            background: var(--bs-gray-200);
        }

        tr {
            &.subtotal {
                font-weight: 600;
                background: var(--bs-gray-100);


                &:not(:last-of-type) {
                    
                }

                td {
                    text-align: right;
                }
            }

            &.bg-orange {
                background: rgba(var(--bs-orange-rgb), 0.5);
            }  
        }
    }
`;

export default function Long(props) {
	let data = props.data;
	let year = props.year;
	let visibleSections = props.visibleSections;

	return (
		<ShortStyled>
            <h1 className="title">BALANCE EJERCICIO FISCAL {year}</h1>

            { (!visibleSections || !visibleSections.length || (visibleSections.length > 0 && visibleSections.indexOf('initial_balance_receipts') !== -1)) &&
                <table id="movements" className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>SALDO INICIAL DE RECIBOS A { moment(year + '-01-01').format('DD-MM-YYYY') }</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.initial_balance && data?.initial_balance?.map((el, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{el.name}</td>
                                    <td>{formatNumber(el.amount_receipts ?? 0, 2)} €</td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td>{formatNumber(data?.initial_balance?.reduce((carry, item) => carry += parseFloat(item.amount_receipts), 0), 2)} €</td>
                        </tr>
                    </tfoot>
                </table>
            }

            { (!visibleSections || !visibleSections.length || (visibleSections.length > 0 && visibleSections.indexOf('initial_balance_expenses') !== -1)) &&
                <table id="movements" className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>SALDO INICIAL DE GASTOS A { moment(year + '-01-01').format('DD-MM-YYYY') }</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.initial_balance && data?.initial_balance?.map((el, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{el.name}</td>
                                    <td>{formatNumber(el.amount_expenses ?? 0, 2)} €</td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td>{formatNumber(data?.initial_balance?.reduce((carry, item) => carry += parseFloat(item.amount_expenses), 0), 2)} €</td>
                        </tr>
                    </tfoot>
                </table>
            }
            
            { (!visibleSections || !visibleSections.length || (visibleSections.length > 0 && visibleSections.indexOf('incomes') !== -1)) &&
                <table id="movements" className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>RECIBOS EMITIDOS</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.incomes?.map((el, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{el.name}</td>
                                    <td>{formatNumber(el.amount ?? 0, 2)} €</td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td>{formatNumber(data?.incomes?.reduce((carry, item) => carry += parseFloat(item.amount), 0), 2)} €</td>
                        </tr>
                    </tfoot>
                </table>
            }

            { (!visibleSections || !visibleSections.length || (visibleSections.length > 0 && visibleSections.indexOf('incomes_real') !== -1)) &&
                <table id="movements" className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>INGRESOS REALES</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.incomes_real?.map((el, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{el.name}</td>
                                    <td>{formatNumber(el.amount ?? 0, 2)} €</td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td className="text-end">SALDO</td>
                            <td>{formatNumber(data?.incomes_real?.filter(el => el.id === 'receipts' || el.id === 'excess').reduce((carry, el) => carry += el.amount, 0), 2)} €</td>
                        </tr>
                    </tfoot>
                </table>
            }

            { (!visibleSections || !visibleSections.length || (visibleSections.length > 0 && visibleSections.indexOf('pending_receipts') !== -1)) &&
                <table id="movements" className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>DETALLE DE LOS RECIBOS PENDIENTES</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.pending_receipts?.map((el, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{el.name}</td>
                                    <td>{formatNumber(el.amount ?? 0, 2)} €</td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td>{formatNumber(data?.pending_receipts?.reduce((carry, item) => carry += parseFloat(item.amount), 0), 2)} €</td>
                        </tr>
                    </tfoot>
                </table>
            }

            { (!visibleSections || !visibleSections.length || (visibleSections.length > 0 && visibleSections.indexOf('refunded_receipts') !== -1)) &&
                <table id="movements" className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>DETALLE DE RECIBOS DEVUELTOS</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.refunded_receipts?.map((el, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{el.name}</td>
                                    <td>{formatNumber(el.amount ?? 0, 2)} €</td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td>{formatNumber(data?.refunded_receipts?.reduce((carry, item) => carry += parseFloat(item.amount), 0), 2)} €</td>
                        </tr>
                    </tfoot>
                </table>
            }

            { (!visibleSections || !visibleSections.length || (visibleSections.length > 0 && visibleSections.indexOf('expenses') !== -1)) &&
                <table id="movements" className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>BALANCE DE PRESUPUESTOS</th>
                            <th>PRESUPUESTADO</th>
                            <th>GASTADO</th>
                            <th>DIFERENCIA</th>
                        </tr>
                    </thead>
                    <tbody>
                        {jsonToArray(data?.expenses)?.map(estimate => {

                            return (
                                <React.Fragment key={'estimate-'+estimate.key}>
                                    <tr key={'estimate-'+estimate.key} style={{background: 'var(--bs-gray-400)'}}>
                                        <td>{estimate.value.name}</td>
                                        <td>{formatNumber(estimate.value.estimated ?? 0, 2)} €</td>
                                        <td>{formatNumber(estimate.value.amount ?? 0, 2)} €</td>
                                        <td>{formatNumber((estimate.value.estimated ?? 0) - Math.abs(estimate.value.amount ?? 0), 2)} €</td>
                                    </tr>
                                    {jsonToArray(estimate?.value.groups)?.map(group => {
                                        return (
                                            <React.Fragment key={'group-'+group.key}>
                                                <tr style={{background: 'var(--bs-gray-200)'}}>
                                                    <td style={{paddingLeft: '20px'}}>{group.value.name}</td>
                                                    <td>{formatNumber(group.value.estimated ?? 0, 2)} €</td>
                                                    <td>{formatNumber(group.value.amount ?? 0, 2)} €</td>
                                                    <td>{formatNumber((group.value.estimated ?? 0) - Math.abs(group.value.amount ?? 0), 2)} €</td>
                                                </tr>
                                                {jsonToArray(group?.value.titles)?.map(title => {
                                                    return (
                                                        <React.Fragment key={'title-'+title.key}>
                                                            <tr style={{background: 'var(--bs-gray-100)'}}>
                                                                <td style={{paddingLeft: '40px'}}>{title.value.name}</td>
                                                                <td>{formatNumber(title.value.estimated ?? 0, 2)} €</td>
                                                                <td>{formatNumber(title.value.amount ?? 0, 2)} €</td>
                                                                <td>{formatNumber((title.value.estimated ?? 0) - Math.abs(title.value.amount ?? 0), 2)} €</td>
                                                            </tr>
                                                            {jsonToArray(title?.value.expenses)?.map(expenses => {
                                                                return (
                                                                    <React.Fragment key={'expenses-'+expenses.key}>
                                                                        <tr>
                                                                            <td style={{paddingLeft: '60px'}}>{expenses.value.name}</td>
                                                                            <td>{formatNumber(expenses.value.estimated ?? 0, 2)} €</td>
                                                                            <td>{formatNumber(expenses.value.amount ?? 0, 2)} €</td>
                                                                            <td>{formatNumber((expenses.value.estimated ?? 0) - Math.abs(expenses.value.amount ?? 0), 2)} €</td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </React.Fragment>
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            }

            {/* { (!visibleSections || !visibleSections.length || (visibleSections.length > 0 && visibleSections.indexOf('properties_receipts_balance') !== -1)) &&
                <table id="movements" className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>SITUACION PROPIEDADES</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.properties_receipts_balance?.map((el, idx) => {

                            return (
                                <React.Fragment key={idx}>
                                    <tr>
                                        <td>{el.name}</td>
                                        <td>{formatNumber(el.amount ?? 0, 2)} €</td>
                                    </tr>
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td>{formatNumber(data?.properties_receipts_balance?.reduce((carry, item) => carry += parseFloat(item.amount), 0), 2)} €</td>
                        </tr>
                    </tfoot>
                </table>
            } */}

            { (!visibleSections || !visibleSections.length || (visibleSections.length > 0 && visibleSections.indexOf('treasury_balance') !== -1)) &&
                <table id="movements" className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>
                                SALDO BANCARIO A
                                &nbsp;
                                {(() => {
                                    let date = null;
                                    if ( parseInt(year) === parseInt(moment().format('YYYY')) ) {
                                        date = moment().format('YYYY-MM-DD');
                                    } else {
                                        date = year + '-12-31';
                                    }
                                    return moment(date).format('DD-MM-YYYY');
                                })()}
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.treasury_balance?.map((el, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{el.name}</td>
                                    <td>{formatNumber(el.amount ?? 0, 2)} €</td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td>{formatNumber(data?.treasury_balance?.reduce((carry, item) => carry += parseFloat(item.amount), 0), 2)} €</td>
                        </tr>
                    </tfoot>
                </table>
            }   

            { (!visibleSections || !visibleSections.length || (visibleSections.length > 0 && visibleSections.indexOf('incomes_detailed') !== -1)) &&
                <table id="movements" className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th colSpan="100%">INGRESOS POR CUENTAS</th>
                        </tr>
                    </thead>
                    {data?.incomes_detailed?.map((el, idx) => {

                        if ( !el.movements.length ) return null;

                        let header = (
                            <thead>
                                <tr>
                                    <th colSpan="100%" style={{fontWeight: '600'}}>{el.code} {el.name}</th>
                                </tr>
                            </thead>
                        );

                        let rows = el.movements.map((el, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{el.code} {el.name}</td>
                                    <td>{formatNumber(el.amount ?? 0, 2)} €</td>
                                </tr>
                            );
                        });

                        let footer = (
                            <tbody>
                                <tr className="subtotal">
                                    <td></td>
                                    <td>{formatNumber(el.movements?.reduce((carry, item) => carry += parseFloat(item.amount), 0), 2)} €</td>
                                </tr>
                            </tbody>
                        );

                        return (
                            <React.Fragment key={idx}>
                                {header}
                                <tbody>{rows}</tbody>
                                {footer}
                            </React.Fragment>
                        )
                    })}
                    {data?.incomes_detailed?.length === 0 &&
                        <tbody>
                            <tr>
                                <td colSpan="100%">No hay información</td>
                            </tr>
                        </tbody>
                    }
                </table>
            }

            { (!visibleSections || !visibleSections.length || (visibleSections.length > 0 && visibleSections.indexOf('expenses_detailed') !== -1)) &&
                <table id="movements" className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>GASTOS POR CUENTAS</th>
                            <th className="text-center">FACTURADO</th>
                            <th className="text-center">PAGADO</th>
                        </tr>
                    </thead>
                    {data?.expenses_detailed?.map((el, idx) => {

                        if ( !el.movements.length ) return null;

                        let header = (
                            <thead>
                                <tr className="bg-orange">
                                    <th colSpan="100%" style={{fontWeight: '600'}}>{el.code} {el.name}</th>
                                </tr>
                            </thead>
                        );

                        let rows = el.movements.map((el, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>
                                        <span className="badge bg-light text-secondary">{el.type === 'providerinvoice' && 'F'}{el.type === 'counterpart' && 'C'}</span>&nbsp;
                                        {el.code}&nbsp;
                                        {el.name}
                                    </td>
                                    <td className="text-end">{formatNumber(el.amount ?? 0, 2)} €</td>
                                    <td className="text-end">{formatNumber(el.paid ?? 0, 2)} €</td>
                                </tr>
                            );
                        });

                        let footer = (
                            <tbody>
                                <tr className="subtotal">
                                    <td className="text-right">TOTAL FAMILIA</td>
                                    <td>{formatNumber(el.movements?.reduce((carry, item) => carry += parseFloat(item.amount), 0), 2)} €</td>
                                    <td>{formatNumber(el.movements?.reduce((carry, item) => carry += parseFloat(item.paid), 0), 2)} €</td>
                                </tr>
                            </tbody>
                        );

                        return (
                            <React.Fragment key={idx}>
                                {header}
                                <tbody>{rows}</tbody>
                                {footer}
                            </React.Fragment>
                        )
                    })}
                    {data?.expenses_detailed?.length === 0 &&
                        <tbody>
                            <tr>
                                <td colSpan="100%">No hay información</td>
                            </tr>
                        </tbody>
                    }
                    <tfoot>
                        <tr>
                            <td className="text-end">TOTAL CUENTAS</td>
                            <td>{formatNumber(data?.expenses_detailed?.reduce((carry, item) => carry += parseFloat(item.movements.reduce((carry, item) => carry += parseFloat(item.amount), 0)), 0), 2)} €</td>
                            <td>{formatNumber(data?.expenses_detailed?.reduce((carry, item) => carry += parseFloat(item.movements.reduce((carry, item) => carry += parseFloat(item.paid), 0)), 0), 2)} €</td>
                        </tr>
                    </tfoot>
                </table>
            }

            {/* { (!visibleSections || !visibleSections.length || (visibleSections.length > 0 && visibleSections.indexOf('expenses_detailed') !== -1)) &&
                <table id="movements" className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>GASTOS POR CUENTAS</th>
                            <th>IMPORTE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {jsonToArray(data?.expenses_detailed)?.map(estimate => {

                            return (
                                <React.Fragment key={'g-estimate-'+estimate.key}>
                                    <tr key={'g-estimate-'+estimate.key} style={{background: 'var(--bs-gray-400)'}}>
                                        <td>{estimate.value.name}</td>
                                        <td>{formatNumber(estimate.value.amount ?? 0, 2)} €</td>
                                    </tr>
                                    {jsonToArray(estimate?.value.groups)?.map(group => {
                                        return (
                                            <React.Fragment key={'g-group-'+group.key}>
                                                <tr style={{background: 'var(--bs-gray-200)'}}>
                                                    <td style={{paddingLeft: '20px'}}>{group.value.name}</td>
                                                    <td>{formatNumber(group.value.amount ?? 0, 2)} €</td>
                                                </tr>
                                                {jsonToArray(group?.value.titles)?.map(title => {
                                                    return (
                                                        <React.Fragment key={'g-title-'+title.key}>
                                                            <tr style={{background: 'var(--bs-gray-100)'}}>
                                                                <td style={{paddingLeft: '40px'}}>{title.value.name}</td>
                                                                <td>{formatNumber(title.value.amount ?? 0, 2)} €</td>
                                                            </tr>
                                                            {jsonToArray(title?.value.expenses)?.map(expenses => {
                                                                return (
                                                                    <React.Fragment key={'g-expenses-'+expenses.key}>
                                                                        <tr style={{background: '#f8f9fa73'}}>
                                                                            <td style={{paddingLeft: '60px'}}>{expenses.value.name}</td>
                                                                            <td>{formatNumber(expenses.value.amount ?? 0, 2)} €</td>
                                                                        </tr>
                                                                        {expenses?.value?.movements?.map(movement => {
                                                                            return (
                                                                                <React.Fragment key={'g-expenses-movements'+movement.key}>
                                                                                    <tr>
                                                                                        <td style={{paddingLeft: '80px'}}>
                                                                                            <span className="badge bg-light text-secondary">{movement.type === 'providerinvoice' && 'F'}{movement.type === 'counterpart' && 'C'}</span>&nbsp;
                                                                                            {movement.name}
                                                                                        </td>
                                                                                        <td>{formatNumber(movement.amount ?? 0, 2)} €</td>
                                                                                    </tr>
                                                                                </React.Fragment>
                                                                            );
                                                                        })}
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </React.Fragment>
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            } */}
           
            { (!visibleSections || !visibleSections.length || (visibleSections.length > 0 && visibleSections.indexOf('pending_invoices') !== -1)) &&
                <table id="movements" className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>PREVISIONES DE PAGO</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.pending_invoices?.map((el, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{el.name}</td>
                                    <td>{formatNumber(el.amount ?? 0, 2)} €</td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td>{formatNumber(data?.pending_invoices?.reduce((carry, item) => carry += parseFloat(item.amount), 0), 2)} €</td>
                        </tr>
                    </tfoot>
                </table>
            }   
           
            { (!visibleSections || !visibleSections.length || (visibleSections.length > 0 && visibleSections.indexOf('previsions') !== -1)) &&
            
                <table id="movements" className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th colSpan="100%">PREVISIONES DE COBRO</th>
                        </tr>
                    </thead>
                    {data?.previsions?.map((el, idx) => {

                        if ( !el.previsions.length ) return null;

                        let header = (
                            <thead>
                                <tr>
                                    <th colSpan="100%" style={{fontWeight: '600'}}>{el.property?.name}</th>
                                </tr>
                            </thead>
                        );

                        let rows = el.previsions.map((el, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{el.name}</td>
                                    <td>{formatNumber(el.amount ?? 0, 2)} €</td>
                                </tr>
                            );
                        });

                        let footer = (
                            <tbody>
                                <tr className="subtotal fw-bold">
                                    <td className="text-right">TOTAL PROPIEDAD</td>
                                    <td>{formatNumber(el.previsions?.reduce((carry, item) => carry += parseFloat(item.amount), 0), 2)} €</td>
                                </tr>
                            </tbody>
                        );

                        return (
                            <React.Fragment key={idx}>
                                {header}
                                <tbody>{rows}</tbody>
                                {footer}
                            </React.Fragment>
                        );
                    })}
                    {data?.previsions?.length === 0 &&
                        <tbody>
                            <tr>
                                <td colSpan="100%">No hay información</td>
                            </tr>
                        </tbody>
                    }
                </table>
            }

            { (!visibleSections || !visibleSections.length || (visibleSections.length > 0 && visibleSections.indexOf('final_balance_receipts') !== -1)) &&
                <table id="movements" className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>
                                SALDO FINAL DE RECIBOS A&nbsp;
                                {(() => {
                                    let date = null;
                                    if ( parseInt(year) === parseInt(moment().format('YYYY')) ) {
                                        date = moment().format('YYYY-MM-DD');
                                    } else {
                                        date = year + '-12-31';
                                    }
                                    return moment(date).format('DD-MM-YYYY');
                                })()}
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.final_balance && data?.final_balance?.map((el, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{el.name}</td>
                                    <td>{formatNumber(el.amount_receipts ?? 0, 2)} €</td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td>{formatNumber(data?.final_balance?.reduce((carry, item) => carry += parseFloat(item.amount_receipts), 0), 2)} €</td>
                        </tr>
                    </tfoot>
                </table>
            }

            { (!visibleSections || !visibleSections.length || (visibleSections.length > 0 && visibleSections.indexOf('final_balance_expenses') !== -1)) &&
                <table id="movements" className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>
                                SALDO FINAL DE GASTOS A&nbsp;
                                {(() => {
                                    let date = null;
                                    if ( parseInt(year) === parseInt(moment().format('YYYY')) ) {
                                        date = moment().format('YYYY-MM-DD');
                                    } else {
                                        date = year + '-12-31';
                                    }
                                    return moment(date).format('DD-MM-YYYY');
                                })()}
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.final_balance && data?.final_balance?.map((el, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{el.name}</td>
                                    <td>{formatNumber(el.amount_expenses ?? 0, 2)} €</td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td>{formatNumber(data?.final_balance?.reduce((carry, item) => carry += parseFloat(item.amount_expenses), 0), 2)} €</td>
                        </tr>
                    </tfoot>
                </table>
            }
        </ShortStyled>
	);
};

export const sections = {
    'initial_balance_receipts':         'Saldo inicial de recibos',
    'initial_balance_expenses':         'Saldo inicial de gastos',
    'incomes':                          'Recibos emitidos',
    'incomes_real':                     'Ingresos reales',
    'expenses':                         'Balance de presupuestos',
    // 'properties_receipts_balance':      'Situación propiedades',
    'treasury_balance':                 'Saldo bancario',
    'incomes_detailed':                 'Ingresos por cuentas',
    'expenses_detailed':                'Gastos por cuentas',
    'pending_receipts':                 'Detalle de recibos pendientes',
    'refunded_receipts':                'Detalle de recibos devueltos',
    'pending_invoices':                 'Previsiones de pago',
    'previsions':                       'Previsiones de cobro',
    'final_balance_receipts':           'Saldo final de recibos',
    'final_balance_expenses':           'Saldo final de gastos',
};
