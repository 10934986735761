import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import { getCommunity } from 'helpers/community';
import LabeledFrame from 'components/LabeledFrame';
import CustomInput from 'components/CustomInput';

const Table = styled.table`
	font-size: 13px;
	line-height: 15px;

	thead {
		tr {
			th {
				font-weight: 500;

				&:nth-child(1) {
					width: 20px;
					text-align: center;
				}

				&:nth-child(2) {

				}

				&:nth-child(3) {
					width: 150px;
				}

				&:nth-child(4) {
					width: 150px;
				}
			}
		}
	}

	tbody {
		tr {
			td {
				vertical-align: middle;

				&:nth-child(4) {
					position: relative;

					input {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						border: 0;
						padding: 2px 5px;

						outline: none !important;
					}
				}
			}
		}
	}
`;

let axiosCancelToken = null;

export default function ReceiptsIssuancesNewForm(props) {
	const navigate = useNavigate();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';

	let [data, setData] = useState({
		date: moment().format('YYYY-MM-DD'),
		issuances: []
	});
	let [errors, setErrors] = useState({});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		// Get pending
		axios.get('/api/manager/receipts-issuances/get-pending', {
			params: {
				community_id: getCommunity()?.id
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let issuances = [];
			for(let type in response.data.issuanceables) {
				response.data.issuanceables[type].forEach((el) => {
					let momentDate = moment(data.date);

					issuances.push({
						checked: false,
						id: el.id,
						type: type,
						name: (() => {
							if ( type.indexOf('estimate') !== -1 ) return (
								<div>
									{'Presupuesto ' + (type.indexOf('extraordinary') !== -1 ? 'extraordinario' : 'ordinario')}
									<b className="sbold">&nbsp; {el.number_full}</b> {el.description ? '(' + el.description + ')' : ''}
								</div>
							);
							if ( type === 'receipt-refund' ) return 'Devoluciones';
							return '';
						})(),
						description: momentDate.format('MMMM').substr(0, 3).toUpperCase() + ' ' + momentDate.format('YYYY'),
						lastissuance: el.lastissuance,
						receipts_issues_qty: el.receipts_issues_qty ?? null
					});
				});
			}
			setData((prev) => ({...prev, issuances: [...issuances]}));
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}, [data.date]);

	const saveData = (goBack = true) => {
		setErrors({});

		let c = window.confirm('¿Seguro que quieres realizar estas emisiones?');
		if ( !c ) return false;

		data.community_id = getCommunity()?.id;

		axios.post('/api/manager/receipts-issuances/add', data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			if ( popup && goBack ) window.close();
			if ( goBack ) navigate('/receiptsissuances' + (popup ? '?popup=true' : ''));
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const setIssuanceDataField = (idx, field, value) => {
		let newData = {...data};
		newData.issuances[idx][field] = value;
		setData({...newData});
	}

	const toggleAllCheckboxes = (e) => {
		let newData = {...data};
		let equal = newData.issuances.filter(el => el.checked).length === newData.issuances.length;

		if ( e.target.checked ) {
			newData.issuances.forEach(el => el.checked = equal ? false : true);
		} else {
			newData.issuances.forEach(el => el.checked = false);
		}
		setData({...newData});
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			<section>
				<div className="page-title">
					<h1>Emisión de recibos</h1>
					<button onClick={() => navigate('/receiptsissuances')} className="btn btn-sm btn-light ms-auto">Volver</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-8">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-lg-4">
											<div className="mb-3">
												<CustomInput label="Fecha de emisión" type="date" className="form-control form-control-sm" onChange={(e) => setDataField('date', e.target.value)} value={data.date ?? ''} />
												{ errors.date &&
													<div className="invalid-feedback d-block">{ errors.date[0] }</div>
												}
											</div>
										</div>
										<div className="col-lg-12">
											<LabeledFrame label="Emisiones disponibles para realizar">
												<div className="table-responsive">
													<Table className="table table-sm table-bordered mb-0">
														<thead>
															<tr>
																<th>
																	<input type="checkbox" checked={data.issuances.filter(el => el.checked).length === data.issuances.length} onChange={(e) => toggleAllCheckboxes(e)} />
																</th>
																<th>Nueva emisión</th>
																<th>Última emisión</th>
																<th>Descripción</th>
															</tr>
														</thead>
														<tbody>
															{data.issuances.map((el, idx) => {
																return (
																	<tr key={idx}>
																		<td>
																			<input type="checkbox" checked={el.checked} onChange={(e) => setIssuanceDataField(idx, 'checked', e.target.checked)} />
																		</td>
																		<td>
																			<div className="d-flex align-items-center">
																				{el.name}
																				<span className="badge btn-light text-secondary ms-2">
																					Nº {(el.lastissuance?.number ?? 0) + 1}
																					{el.receipts_issues_qty &&
																						<React.Fragment>
																							&nbsp; de {el.receipts_issues_qty}
																						</React.Fragment>
																					}
																				</span>
																			</div>
																			{errors['issuances.' + idx + '.id'] &&
																				<div className="invalid-feedback d-block">{errors['issuances.' + idx + '.id']}</div>
																			}
																		</td>
																		<td>
																		 	{el.lastissuance?.number !== undefined ?
																		 		<React.Fragment>
																					<div><b className="sbold">Nº:</b> {el.lastissuance?.number}</div>
																					<div><b className="sbold">Fecha:</b> {moment(el.lastissuance.date).format('DD-MM-YYYY')}</div>
																				</React.Fragment>
																				:
																				<div>Sin emisiones previas</div>
																			}
																		</td>
																		<td>
																			<input 
																				type="text" 
																				placeholder="Escribe aquí" 
																				value={el.description} 
																				className={errors['issuances.' + idx + '.description'] ? 'bg-danger text-white' : ''}
																				onChange={(e) => setIssuanceDataField(idx, 'description', e.target.value)} 
																			/>
																		</td>
																	</tr>
																);
															})}
															{data.issuances.length <= 0 &&
																<tr><td colSpan="100%">No se han añadido emisiones</td></tr>
															}
														</tbody>
													</Table>
												</div>
												{ errors['issuances'] && 
													<div className="invalid-feedback d-block">{errors['issuances']}</div>
												}
											</LabeledFrame>
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="row">
										<div className="col-4">
											
										</div>
										<div className="col-8 text-end">							
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Emitir recibos</button>							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}