import React, { useState, useEffect } from 'react';
import { formatNumber, ucfirst } from 'helpers/generic';
import styled from 'styled-components';
import { PieChart } from 'react-minimal-pie-chart';

const ExpensesTableStyled = styled.table`

    tbody {
        tr {
            cursor: pointer;

            &.opened {
                font-weight: bold;
            }

            &.mouse-over {
                background: var(--bs-light);
            }

            &.pie-segment-over {
                background: rgba(var(--bs-primary-rgb), 0.2);
            }

            &.mouse-over.pie-segment-over {
                background: rgba(var(--bs-primary-rgb), 0.3);
            }

            &.expense {
                display: none;
                font-size: 11px;

                td {

                    &:first-child {
                        padding-left: 25px;
                    }
                }

                &.visible {
                    display: table-row;
                }
            }
        }
    }
`;

const PieChartWrapper = styled.div`
    position: sticky;
    top: ${props => props.stickyTop}px;
`;

const Expenses = (props) => {
    const expenses = props.data?.expenses ?? [];
    const colors = props.data?.colors?.expenses ?? [];
    const exportChartFix = props.exportChartFix;

    let expenses_total = expenses.reduce((carry, item) => carry+= item.amount, 0) ?? 0;

    let [expensesRowSubrowsVisibles, setExpensesRowSubrowsVisibles] = useState({});
    let [pageTitleBottom, setPageTitleBottom] = useState(0);
    let [trOverIndex, setTrOverIndex] = useState(null);

    const chartData = expenses_total !== 0 ? 
        expenses.map((entry, i) => {
            let row = {
                title: entry.name,
                value: Math.abs(entry.amount),
                color: colors[entry.id] ?? 'var(--bs-light)'
            }

            let color = row.color;
            if ( Object.keys(expensesRowSubrowsVisibles).length > 0 && exportChartFix === null ) {
                if ( expensesRowSubrowsVisibles[i] ) {
                    color = 'var(--bs-primary)';
                } else {
                    if ( trOverIndex === i ) {
                        color = 'var(--bs-secondary)';
                    } else {
                        color = 'var(--bs-gray-400)';
                    }
                }
            } else {
                if ( trOverIndex === i ) {
                    color = 'var(--bs-secondary)';
                }
            }
            
            return {
                ...row,
                color: color
            };
        })
        :
        [
            {
                title: 'No hay datos disponibles',
                value: 100,
                color: 'var(--bs-light)'
            },
        ] 
    ;

    useEffect(() => {
        let pageTitleBottom = document.querySelector('.page-title').getBoundingClientRect().bottom;
        setPageTitleBottom(pageTitleBottom);
    }, []);
    
    const toggleExpensesRowSubrowsVisibles = (idx) => {
        let newExpensesRowSubrowsVisibles = {...expensesRowSubrowsVisibles};
        newExpensesRowSubrowsVisibles[idx] ? delete newExpensesRowSubrowsVisibles[idx] : newExpensesRowSubrowsVisibles[idx] = true;
        setExpensesRowSubrowsVisibles(newExpensesRowSubrowsVisibles);
    }

    return (
        <>
            <PieChartWrapper className="col-md-4 mt-3" stickyTop={pageTitleBottom} data-piecharwrapper="expenses">
                <PieChart
                    data={chartData}
                    radius={45}
                    segmentsShift={(index) => ((expensesRowSubrowsVisibles[index] || trOverIndex === index) && exportChartFix === null ? 4 : 1)}
                    segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
                    onClick={(data, index) => {
                        toggleExpensesRowSubrowsVisibles(index);
                    }}
                    onMouseOver={(data, index) => setTrOverIndex(index)}
                    onMouseOut={() => setTrOverIndex(null)}
                />
            </PieChartWrapper>

            <div className="col-md-8 mt-3">
                <ExpensesTableStyled id="movements" className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>GASTOS COMUNIDAD POR FAMILIAS</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {expenses.map((el, idx) => {
                            let trs = [];
                            trs.push(
                                <tr 
                                    key={'tr-' + idx + '-family'} 
                                    className={(expensesRowSubrowsVisibles[idx] ? 'pie-segment-over opened' : '') + ' ' + (trOverIndex === idx ? 'mouse-over' : '')} 
                                    onClick={() => {
                                        toggleExpensesRowSubrowsVisibles(idx)
                                    }}
                                    onMouseOver={() => setTrOverIndex(idx)}
                                    onMouseOut={() => setTrOverIndex(null)}
                                >
                                    <td>
                                        <div className="d-flex align-items-center">
                                            {colors[el.id] &&
                                                <span className="badge rounded-pill me-2" style={{backgroundColor: colors[el.id]}}>&nbsp;</span>
                                            }
                                            {el.name}
                                        </div>    
                                    </td>
                                    <td></td>
                                    <td>{formatNumber(el.amount ?? 0, 2, true)} €</td>
                                </tr>
                            );

                            el.expenses.forEach((el2, idx2) => {
                                trs.push(
                                    <tr 
                                        key={'tr-' + idx + '-expense-' + idx2} 
                                        className={'expense ' + (expensesRowSubrowsVisibles[idx] ? 'visible pie-segment-over' : '') + ' ' + (trOverIndex === idx ? 'mouse-over' : '')}
                                        onClick={() => {
                                            toggleExpensesRowSubrowsVisibles(idx)
                                        }}
                                        onMouseOver={() => setTrOverIndex(idx)}
                                        onMouseOut={() => setTrOverIndex(null)}
                                    >
                                        <td><i className="bi bi-arrow-return-right"></i> {ucfirst(el2.name)}</td>
                                        <td></td>
                                        <td>{formatNumber(el2.amount ?? 0, 2, true)} €</td>
                                    </tr>
                                );
                            });
                            
                            return (<React.Fragment key={idx}>{trs}</React.Fragment>);
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td>TOTAL GASTOS</td>
                            <td>{formatNumber(expenses_total ?? 0, 2, true)} €</td>
                        </tr>
                    </tfoot>
                </ExpensesTableStyled>
            </div>
        </>
    );
}

export default Expenses;