import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useSearchParams, useParams } from 'react-router-dom';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader } from 'helpers/generic';
import { getCommunity } from 'helpers/community';
import { toast } from 'react-toastify';

const LoaderWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Iframe = styled.iframe`
	width: 100%;
	height: 100%;
	border: 0;
	position: fixed;
	top: 0;
	left: 0;
`;

const ButtonWrapper = styled.div`
	position: fixed;
	bottom: 10px;
	left: 0px;
	width: 100%;
	z-index: 10;
	text-align: center;
`;

let axiosCancelToken = null;

let parentWindowProxyCallback = window.opener?.ReloadDocumentsCallback;

export default function MeetingPdfTemplate(props) {
	const params = useParams();
	const [queryParams] = useSearchParams();
	const template_id = queryParams.get('template_id');

	const iframeRef = useRef(null);
	
	let [loading, setLoading] = useState(true);
	
	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		window.onbeforeunload = () => {
			parentWindowProxyCallback();
		};
	}, []);

	useEffect(() => {
		const getPdf = async () => {
			return await axios.get('/api/manager/meetings/pdf/' + params.id + '?template_id=' + template_id, {
				params: {
					community_id: getCommunity()?.id
				},
				responseType: 'blob'
			}).then((response) => {
				let objectUrl = URL.createObjectURL(response.data);
				iframeRef.current.src = objectUrl;
			}).catch((error) => {
				console.log(error);
			});
		}
		getPdf();

	}, [params.id, template_id]);

	const saveDocument = () => {
		setLoading(true);

		const saveDocumentAjax = async () => {
			await axios.get('/api/manager/meetings/pdf/' + params.id + '?template_id=' + template_id + '&save=true', {
				params: {
					community_id: getCommunity()?.id
				},
			}).then((result) => {
				parentWindowProxyCallback(true);
				window.close();
			}).catch((error) => {
				toast.error('Ha ocurrido un error, inténtalo de nuevo');
			});
		}
		saveDocumentAjax();

		setLoading(false);
	}

	return (
		<PopupLayout>
			{ loading && <LoaderWrapper><CristalLoader /></LoaderWrapper> }
            <Iframe 
				ref={iframeRef}
				src={null}
				onLoad={() => setLoading(false)}
			></Iframe>
			{ !loading &&
				<ButtonWrapper>
					<button className="btn btn-primary btn-lg text-white" onClick={saveDocument}>Guardar en gestor documental</button>
				</ButtonWrapper>
			}
		</PopupLayout>
	);
}