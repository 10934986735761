import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';

const CustomTextareaStyled = styled.div`
	position: relative;
    height: ${props => props.height}px;

    span.custom-textarea-label {
        position: absolute;
        left: 5px;
        width: 90%;
        z-index: 2;
        font-size: 9px;
        color: var(--bs-primary);
        user-select: none;
        background: #f8fafc;
    }

    textarea.custom-textarea-textarea {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        max-height: auto !important;
        z-index: 1;
        padding-top: 20px;
        padding-bottom: 10px;
        padding-left: 5px;
        font-size: 11px !important;
    }
`;

const startHeight = 60;

export default function CustomTextarea(props) {
    let textareaRef = useRef(null);
    let [height, setHeight] = useState(startHeight);

    let textareaProps = {...props};
    if ( textareaProps.hasOwnProperty('className') ) textareaProps.className += ' custom-textarea-textarea';
    if ( textareaProps.hasOwnProperty('placeholder') ) delete textareaProps.placeholder;
    if ( textareaProps.hasOwnProperty('label') ) delete textareaProps.label;

    let label = props.placeholder ?? null;
    if ( props.label ) label = props.label ?? null;

    useEffect(() => {
        let checkInterval = setInterval(() => {
            let taHeight = textareaRef.current.style.height ?? startHeight;
            setHeight(parseInt(taHeight));
        }, 100);

        return function cleanup() {
            clearInterval(checkInterval);
        }
    }, [])
	
	return (
		<CustomTextareaStyled className="custom-textarea" height={height}>
            {label && <span className="custom-textarea-label" onClick={() => textareaRef.current.focus()}>{label}</span>}
			<textarea ref={textareaRef} {...textareaProps} style={{height: startHeight + 'px'}} />
		</CustomTextareaStyled>
	);
}